import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const ConfirmDelete = (props) => {
  const title = props.title ? props.title : 'Confirm Delete Record',
        cancelText = props.cancelText ? props.cancelText: 'Cancel',
        submitText = props.submitText ? props.submitText : 'Confirm Delete',
        submitBtnBsStyle = props.submitBtnBsStyle ? props.submitBtnBsStyle : 'danger'

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.body}
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="default" onClick={props.onClose}>{cancelText}</Button>
        <Button bsStyle={submitBtnBsStyle} onClick={props.onSubmit}>{submitText}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmDelete
