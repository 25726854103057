import React, { useState, useEffect } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { FaWalking, FaBed, FaHeartbeat } from 'react-icons/fa'
import { TbHeartbeat, TbDeviceHeartMonitor, TbHeartRateMonitor } from 'react-icons/tb'
import { SiOxygen } from 'react-icons/si'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import ApiSingleton from '../../utils/Axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material"
import StatBox from "../../components/StatBox/StatBox"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js';
// import "chartjs-adapter-date-fns";
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
);

const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'];

export const lineDateOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1 | 2,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                usePointStyle: true,
            },
        },
        // annotation: {
        //     annotations: {
        //         min: {
        //             type: 'line',
        //             borderColor: 'gray',
        //             borderWidth: 2,
        //             scaleID: 'y',
        //             value: 0,
        //             label: {
        //                 display: true,
        //                 content: '0',
        //                 backgroundColor: 'transparent',
        //                 color: 'black',
        //                 position: 'end',
        //                 xAdjust: 10,
        //                 yAdjust: 10,
        //             },
        //         },
        //         max: {
        //             type: 'line',
        //             borderColor: 'gray',
        //             borderWidth: 2,
        //             scaleID: 'y',
        //             value: 595,
        //             label: {
        //                 display: true,
        //                 content: '595',
        //                 backgroundColor: 'transparent',
        //                 color: 'black',
        //                 position: 'end',
        //                 xAdjust: 10,
        //                 yAdjust: 0,
        //             },
        //         },
        //     },
        // },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Hours of the Day',
            },
            ticks: {
                autoSkip: true,
                maxTicksLimit: 24,
            },
        },
        y: {
            title: {
                display: true,
                text: 'Daytime 1G+ Activity',
            },
            position: 'left',
            offset: true,
        },
    },
};

export const lineOutlierOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1 | 2,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                usePointStyle: true,
            },
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Date',
            },
            offset: true,
        },
        y: {
            title: {
                display: true,
                text: 'Upper Outlier Count',
            },
            position: 'left',
            // max: 30,
        },
    },
};

export const lineLongtermOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1 | 2,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                usePointStyle: true,
            },
        },
        // annotation: {
        //     annotations: {
        //         min: {
        //             type: 'line',
        //             borderColor: 'gray',
        //             borderWidth: 2,
        //             scaleID: 'y',
        //             value: 0,
        //             label: {
        //                 display: true,
        //                 content: '0',
        //                 backgroundColor: 'transparent',
        //                 color: 'black',
        //                 position: 'end',
        //                 xAdjust: 10,
        //                 yAdjust: 10,
        //             },
        //         },
        //         max: {
        //             type: 'line',
        //             borderColor: 'gray',
        //             borderWidth: 2,
        //             scaleID: 'y',
        //             value: 595,
        //             label: {
        //                 display: true,
        //                 content: '595',
        //                 backgroundColor: 'transparent',
        //                 color: 'black',
        //                 position: 'end',
        //                 xAdjust: 10,
        //                 yAdjust: 0,
        //             },
        //         },
        //     },
        // },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Date',
            },
            offset: true,
        },
        y: {
            title: {
                display: true,
                text: 'More 1G Count',
            },
            position: 'left',
            offset: true,
        },
    },
};


export function WakingPhysicalActivityStats() {
    const {state} = useLocation();
    const [seniorId, setSeniorId] = useState(state.id);

    //react-date-picker addition
    const [dateValue, setDate] = useState(new Date("2023-3-5"));
    const [endDateValue, setEndDateValue] = useState(new Date("2023-6-8"));
    const [startDate, setStartDate] = useState(""); //2-18-2023
    const [endDate, setEndDate] = useState(""); //6-8-2023

    // stores all user data
    const [dailyData, setDailyData] = useState({ datasets: [], });
    const [longtermData, setLongtermData] = useState({ datasets: [], });
    const [weeklyData, setWeeklyData] = useState({ datasets: [], });

    // stores current display options
    const [paDateOptions, setPADateOptions] = useState(lineDateOptions);
    const [paOutlierOptions, setPAOutlierOptions] = useState(lineOutlierOptions);
    const [paLongtermOptions, setPALongtermOptions] = useState(lineLongtermOptions);

    // stores current display data
    const [paDateData, setPADateData] = useState({ datasets: [], });
    const [paOutlierData, setPAOutlierData] = useState({ datasets: [], });
    const [paLongtermData, setPALongtermData] = useState({ datasets: [], });

    const [paDateMax, setPADateMax] = useState("N/A");
    const [paDateMin, setPADateMin] = useState("N/A");
    const [paDateMed, setPADateMed] = useState("N/A");

    const [paLongtermMax, setPALongtermMax] = useState("N/A");
    const [paLongtermMin, setPALongtermMin] = useState("N/A");
    const [paLongtermMed, setPALongtermMed] = useState("N/A");

    const [paMaxLine, setPAMaxLine] = useState([]);
    const [paMinLine, setPAMinLine] = useState([]);
    const [paMedLine, setPAMedLine] = useState([]);

    function _fetchDayPA(seniorId) {
        const postData = {
            seniorName: seniorId,
            key: "daymore1G",
        }
        let tempData = null;
        if (seniorId) {
          ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                console.log("getting day pa data from API");
                tempData = JSON.parse(base64_decode(data[0].document));
                console.log("fetched day pa data: ", tempData);
            })
            .then(data => {
                console.log("setting day PA Data");
                // console.log("final data: ", tempData);
                let currentDate = dateValue;
                const dateData = getDateData(currentDate, tempData.daily);
                // console.log("date data: ", dateData);

                setDailyData(tempData.daily); // records all data to pull from later
                setLongtermData(tempData.longterm);
                setDate(currentDate);

                const paDate = getPADateData(dateData);
                const ltData = getLongtermData(endDateValue, tempData.longterm);

                const paOutlier = getPAOutlierData(ltData);
                const paLongtermMax = getPALongtermMax(ltData);
                const paLongtermMin = getPALongtermMin(ltData);
                const paLongtermMed = getPALongtermMed(ltData);
                const stats = getPADateMaxMin(currentDate, ltData);
                const ltStats = getPALTMaxMin(ltData);

                setPAMaxLine(paLongtermMax);
                setPAMinLine(paLongtermMin);
                setPAMedLine(paLongtermMed);

                setPADateMax(stats[0]); // stats elements in order: [max, min, med]
                setPADateMin(stats[1]);
                setPADateMed(stats[2]);

                setPALongtermMax(ltStats[0]); // stats elements in order: [max, min, med]
                setPALongtermMin(ltStats[1]);
                setPALongtermMed(ltStats[2]);

                setPADateOptions({
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: 1 | 2,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                        annotation: {
                            annotations: {
                                min: {
                                    type: 'line',
                                    borderColor: 'gray',
                                    borderWidth: 2,
                                    scaleID: 'y',
                                    value: tempData.benchmark_personalize.low,
                                    label: {
                                        display: true,
                                        content: tempData.benchmark_personalize.low.toString(),
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                        position: 'end',
                                        xAdjust: 10,
                                        yAdjust: 10,
                                    },
                                },
                                max: {
                                    type: 'line',
                                    borderColor: 'gray',
                                    borderWidth: 2,
                                    scaleID: 'y',
                                    value: tempData.benchmark_personalize.high,
                                    label: {
                                        display: true,
                                        content: tempData.benchmark_personalize.high.toString(),
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                        position: 'end',
                                        xAdjust: 10,
                                        yAdjust: 0,
                                    },
                                },
                            },
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Hours of the Day',
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 24,
                            },
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Daytime 1G+ Activity',
                            },
                            position: 'left',
                            offset: true,
                        },
                    },
                });

                setPAOutlierData({
                    labels: [],
                    datasets: [
                        {
                            label: 'Upper Outlier Count',
                            data: paOutlier,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                        },
                    ],
                });

                setPALongtermData({
                    labels: [],
                    datasets: [
                        {
                            label: 'Personalized Threshold',
                            data: [],
                            pointStyle: 'line',
                            backgroundColor: 'black',
                            borderColor: 'black',
                        },
                        {
                            label: 'Daily Max',
                            data: paLongtermMax,
                            pointStyle: 'rect',
                            backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                            pointRadius: 0,
                            fill: '+1',
                        },
                        {
                            label: 'Daily Min',
                            data: paLongtermMin,
                            pointStyle: 'rect',
                            backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                            pointRadius: 0,
                            fill: false,
                        },
                        {
                            label: 'Daily Median',
                            data: paLongtermMed,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                        },
                    ],
                });

                setPADateData({
                    labels: [],
                    datasets: [
                        {
                            label: 'Personalized Threshold',
                            data: [],
                            pointStyle: 'line',
                            backgroundColor: 'black',
                            borderColor: 'black',
                        },
                        {
                            label: 'Daytime More 1G',
                            data: paDate,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                        },
                    ],
                });

                setStartDate(tempData.date.start);
                setEndDate(tempData.date.end);
            })
            .catch((err) => {});
        }
    }

    useEffect(() => {
        // setPADateOptions(lineDateOptions);
        // setPALongtermOptions(lineLongtermOptions);
        // setPAOutlierOptions(lineOutlierOptions);

        _fetchDayPA(seniorId);

        console.log("useEffect ran here");
    }, []);

    // finds the right data that corresponds to the date
    function getDateData(date, data) {
        if (date == null || data[0] == null)
            return null;

        var preDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
        var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

        const dateData = []
        let data_len = data.length;
        let i = 0;
        let n = 0;
        var currDate = new Date(data[0].year, data[0].month - 1, data[0].day); // month - 1 because month is 0 - 11 not 1 - 12

        while (currDate < afterDate) {
            if (i < data_len) {
              currDate = new Date(data[i].year, data[i].month - 1, data[i].day);
              i++;
            }
            else {
              console.log("ERROR -- exceeded max date in BOS getDateData ");
              break;
            }

            // at the correct date
            if (currDate > preDate && currDate < afterDate) {
                dateData[n] = data[i];
                n++;
            }
            else { continue; }
        }

        // PROBLEM: getting one more data point than intended at the end and missing one at beginning
        // console.log("date data: ", dateData);
        return dateData;
    }

    // finds the month that current date is in
    // currently fixed (need to change later)
    function getLongtermData(date, data) {
        if (date == null || data[0] == null)
            return null;

        var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

        const longtermData = []
        let data_len = data.length;
        let i = 0;
        let n = 0;
        var currDate = new Date(data[0].year, data[0].month - 1, data[0].day); // month - 1 because month is 0 - 11 not 1 - 12

        while (currDate < afterDate) {
        if (i < data_len) {
            currDate = new Date(data[i].year, data[i].month - 1, data[i].day);
            i++;
        }
        else {
            console.log("ERROR -- exceeded max date in BOS getDateData ");
            break;
        }

        // at the correct date
        if (currDate < afterDate) {
            if (data[i] != null) {
            longtermData[n] = data[i];
            n++;
            }
        }
        else { continue; }
        }

        // PROBLEM: getting one more data point than intended at the end and missing one at beginning
        // console.log("longterm data: ", longtermData);
        return longtermData;
    }

    function getPAOutlierData(longterm_data) {
        const outlierData = []; // each element is formatted {"date", num outliers}
        let length = longterm_data.length;
        let i = 0;

        while (i < length) {
            outlierData[i] = [longterm_data[i].year.toString() + '-' + longterm_data[i].month.toString() + '-' + longterm_data[i].day.toString(), longterm_data[i].outlier];
            i++;
        }
        return outlierData;
    }

    // returns data for daily graph display
    function getPADateData(date_data) {
        const dateData = []; // each element is a pair of {"hour", "More 1G Value"}
        let i = 0;
        let dataLen = date_data.length;

        while (i < dataLen - 1) {
            dateData[i] = [date_data[i].hour.toString(), date_data[i].more1G];
            // convertTime(date_data[i].year, date_data[i].month - 1, date_data[i].day, date_data[i].hour)
            i++;
        }
        return dateData;
    }

    function getPALongtermMax(longterm_data) {
        const max = []; // elements in form [date, max value]
        let i = 0;
        let length = longterm_data.length;

        while (i < length) {
            max[i] = [longterm_data[i].year.toString() + '-' + longterm_data[i].month.toString() + '-' + longterm_data[i].day.toString(), longterm_data[i].max];
            i++;
        }

        return max;
    }

    function getPALongtermMin(longterm_data) {
        const min = []; // in form [date, min value]
        let i = 0;
        let length = longterm_data.length;

        while (i < length) {
            min[i] = [longterm_data[i].year.toString() + '-' + longterm_data[i].month.toString() + '-' + longterm_data[i].day.toString(), longterm_data[i].min];
            i++;
        }

        return min;
    }

    function getPALongtermMed(longterm_data) {
        const med = []; // in form [date, median value]
        let i = 0;
        let length = longterm_data.length;

        while (i < length) {
            med[i] = [longterm_data[i].year.toString() + '-' + longterm_data[i].month.toString() + '-' + longterm_data[i].day.toString(), longterm_data[i].median];
            i++;
        }

        return med;
    }

    // sets the selected date's max min median values
    function getPADateMaxMin(date, longterm_data) {
        let i = 0;
        let length = longterm_data.length;
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        const stats = ["N/A", "N/A", "N/A"]; // elements are in order [max, min, med]

        while (i < length) {
            if (longterm_data[i].year == year && longterm_data[i].month == month && longterm_data[i].day == day) {
                stats[0] = longterm_data[i].max;
                stats[1] = longterm_data[i].min;
                stats[2] = longterm_data[i].median;
                break;
            }
            else { i++; }
        }

        return stats;
    }

    // TODO: Fix for when implementing multiple long term periods
    // sets the long term's max min median values
    function getPALTMaxMin(longterm_data) {
        let i = 0;
        let length = longterm_data.length;
        const stats = []; // elements are in order [max, min, med]
        const max = [];
        const min = [];
        const med = [];

        while (i < length) {
            max.push(longterm_data[i].max);
            min.push(longterm_data[i].min);
            med.push(longterm_data[i].median);
            i++;
        }

        stats[0] = Math.max.apply(null, max);
        stats[1] = Math.min.apply(null, min);
        const sortMed = med.sort(function (a, b) { return a - b });
        stats[2] = sortMed[sortMed.length % 2];

        return stats;
    }

    // handles the trial time from start to 15 days, 5 weeks, 10 weeks, 15 weeks
    function handleTrialChange(type) {
        let endDate = endDateValue;

        if (type == 1) { // 15 days - 3/4/23
        setEndDateValue(new Date("2023-3-5"));
        endDate = new Date("2023-3-5");
        }
        else if (type == 2) { // 5 weeks - 3/24/23
        setEndDateValue(new Date("2023-3-25"));
        endDate = new Date("2023-3-25");
        }
        else if (type == 3) { // 10 weeks - 4/28/23
        setEndDateValue(new Date("2023-4-29"));
        endDate = new Date("2023-4-29");
        }
        else if (type == 4) { // 15 weeks - 6/7/23
        setEndDateValue(new Date("2023-6-8"));
        endDate = new Date("2023-6-8");
        }
        else {return;}

        const ltData = getLongtermData(endDate, longtermData);

        const paOutlier = getPAOutlierData(ltData);
        const paLongtermMax = getPALongtermMax(ltData);
        const paLongtermMin = getPALongtermMin(ltData);
        const paLongtermMed = getPALongtermMed(ltData);
        const ltStats = getPALTMaxMin(ltData);

        setPAMaxLine(paLongtermMax);
        setPAMinLine(paLongtermMin);
        setPAMedLine(paLongtermMed);
        
        setPALongtermMax(ltStats[0]); // stats elements in order: [max, min, med]
        setPALongtermMin(ltStats[1]);
        setPALongtermMed(ltStats[2]);

        setPAOutlierData({
            labels: [],
            datasets: [
                {
                    label: 'Upper Outlier Count',
                    data: paOutlier,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                },
            ],
        });

        setPALongtermData({
            labels: [],
            datasets: [
                {
                    label: 'Personalized Threshold',
                    data: [],
                    pointStyle: 'line',
                    backgroundColor: 'black',
                    borderColor: 'black',
                },
                {
                    label: 'Daily Max',
                    data: paLongtermMax,
                    pointStyle: 'rect',
                    backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                    pointRadius: 0,
                    fill: '+1',
                },
                {
                    label: 'Daily Min',
                    data: paLongtermMin,
                    pointStyle: 'rect',
                    backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                    pointRadius: 0,
                    fill: false,
                },
                {
                    label: 'Daily Median',
                    data: paLongtermMed,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                },
            ],
        });
        
    }

    function handleDateChange(event) {
        setDate(event);

        const dateData = getDateData(event, dailyData);
        const ltData = getLongtermData(event, longtermData);

        const paDate = getPADateData(dateData);
        const paOutlier = getPAOutlierData(ltData);
        const paLongtermMax = getPALongtermMax(ltData);
        const paLongtermMin = getPALongtermMin(ltData);
        const paLongtermMed = getPALongtermMed(ltData);
        const stats = getPADateMaxMin(event, ltData);
        const ltStats = getPALTMaxMin(ltData);

        setPAMaxLine(paLongtermMax);
        setPAMinLine(paLongtermMin);
        setPAMedLine(paLongtermMed);

        setPADateMax(stats[0]); // stats elements in order: [max, min, med]
        setPADateMin(stats[1]);
        setPADateMed(stats[2]);

        setPALongtermMax(ltStats[0]); // stats elements in order: [max, min, med]
        setPALongtermMin(ltStats[1]);
        setPALongtermMed(ltStats[2]);

        setPADateData({
            labels: [], //[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            datasets: [
                {
                    label: 'Personalized Threshold',
                    data: [],
                    pointStyle: 'line',
                    backgroundColor: 'black',
                    borderColor: 'black',
                },
                {
                    label: 'Daytime More 1G',
                    data: paDate,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                },
            ],
        });

        setPAOutlierData({
            labels: [],
            datasets: [
                {
                    label: 'Upper Outlier Count',
                    data: paOutlier,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                },
            ],
        });

        setPALongtermData({
            labels: [],
            datasets: [
                {
                    label: 'Personalized Threshold',
                    data: [],
                    pointStyle: 'line',
                    backgroundColor: 'black',
                    borderColor: 'black',
                },
                {
                    label: 'Daily Max',
                    data: paLongtermMax,
                    pointStyle: 'rect',
                    backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                    pointRadius: 0,
                    fill: '+1',
                },
                {
                    label: 'Daily Min',
                    data: paLongtermMin,
                    pointStyle: 'rect',
                    backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                    pointRadius: 0,
                    fill: false,
                },
                {
                    label: 'Daily Median',
                    data: paLongtermMed,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                },
            ],
        });
    }

    // navigation to statistical analysis page
    const navigate = useNavigate();
    // navigate to Sleeping Activity Stats Analysis Page
    function handleGoSleepingPAStats(e) {
        e.preventDefault();
        navigate('/spaStats', {state: { id: seniorId }});
    }

    return (
        <Box m="20px">
            <Box display="flex" justifycontext="space-between" alignItems="center">
                <Box width="100%">
                    <Typography variant="h3" fontWeight="bold" sx={{ color: "#530C0C" }}>Waking Physical Activity Statistics</Typography>
                </Box>
                <Box>
                    <button onClick={() => {handleTrialChange(1)}}>15 Days</button>
                </Box>
                <Box>
                    <button onClick={() => {handleTrialChange(2)}}>5 Weeks</button>
                </Box>
                <Box>
                    <button onClick={() => {handleTrialChange(3)}}>10 Weeks</button>
                </Box>
                <Box>
                    <button onClick={() => {handleTrialChange(4)}}>15 Weeks</button>
                </Box>
                <Box p="0 30px">
                    <button onClick={handleGoSleepingPAStats}>Go to Nighttime Data</button>
                  </Box>
                <Box>
                    <DatePicker showIcon={true} minDate={new Date(startDate)} maxDate={new Date(endDate)} onChange={handleDateChange} selected={dateValue} />                </Box>
            </Box>

            {/* GRID AND CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >

                {/** ROW 1 */}
                <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                    <StatBox title={startDate} subtitle="Start Trial" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                </Box>
                <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                    <StatBox title={endDate} subtitle="End Trial" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                </Box>
                <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                    <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                        <Box>
                            <Typography variant="h5" fontWeight="600" color="#530C0C">
                                Daily More 1G Values
                            </Typography>
                        </Box>
                        <Box m="0px 8px">
                            {/* <FaHeartbeat color='#530C0C' /> */}
                        </Box>
                    </Box>
                    <Box height="250px" m="0px 25px">
                        <Line options={paDateOptions} data={paDateData} className='graph' id="chartPA" key={Math.random()} />
                    </Box>
                </Box>
                <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                    <StatBox title={paDateMax} subtitle="Daily Maximum" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                </Box>
                <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                    <StatBox title={paDateMin} subtitle="Daily Minimum" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                </Box>

                {/** ROW 2 */}
                <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                    <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                        <Box>
                            <Typography variant="h5" fontWeight="600" color="#530C0C">
                                Long Term More 1G Values
                            </Typography>
                        </Box>
                        <Box m="0px 8px">
                            {/* <FaHeartbeat color='#530C0C' /> */}
                        </Box>
                    </Box>
                    <Box height="250px" m="0px 25px">
                        <Line options={paLongtermOptions} data={paLongtermData} className='graph' id="chartPA" key={Math.random()} />
                    </Box>
                </Box>
                <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                    <StatBox title={paDateMed} subtitle="Daily Median" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                </Box>
                <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                    <StatBox title={paLongtermMax} subtitle="Long Term Maximum" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
                </Box>
                {/** ROW 3 */}
                <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                    <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                        <Box>
                            <Typography variant="h5" fontWeight="600" color="#530C0C">
                                Long Term More 1G Outlier Count
                            </Typography>
                        </Box>
                        <Box m="0px 8px">
                            {/* <FaHeartbeat color='#530C0C' /> */}
                        </Box>
                    </Box>
                    <Box height="250px" m="0px 25px">
                        <Line options={paOutlierOptions} data={paOutlierData} className='graph' id="chartPA" key={Math.random()} />
                    </Box>
                </Box>
                <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                    <StatBox title={paLongtermMin} subtitle="Long Term Minimum" icon={<TbDeviceHeartMonitor color='#530C0C' size='0px' />}></StatBox>
                </Box>
                <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                    <StatBox title={paLongtermMed} subtitle="Long Term Median" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
                </Box>

            </Box>
        </Box>
    );
}

export default WakingPhysicalActivityStats;