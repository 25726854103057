import React from 'react'
import BaseTable from '../../components/BaseTable'
import { timestampFormatter, timestampWithLapseFormatter, seniorFormatter, enabledFormatter, embeddedNameFormatter } from '../../components/BaseTable/CellFormatter'
import {peopleFormatter} from "../../components/BaseTable/CellFormatter";
class SensorTable extends React.Component {
    constructor(props) {
        super(props)
        this.handleQuery = this.handleQuery.bind(this)
        this.state = {
            query: ''
        }
    }
    handleQuery(query) {
        this.setState({
            query: query
        })
    }
    render() {
        const props = this.props
        const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        }, {
            config: {
                dataField: 'mac',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'MAC'
        }, {
            config: {
                dataField: 'name',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Name',
        }, {
            config: {
                dataField: 'senior',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: seniorFormatter
            },
            label: 'Resident',
        }, {
            config: {
                dataField: 'bleVersion',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter

            },
            label: 'BLE Version',
        }, {
            config: {
                dataField: 'mcuVersion',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'MCU Version',
        }, {
            config: {
                dataField: 'batteryLevel',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Battery(%)',
        }, {
            config: {
                dataField: 'lastSeenUtc',
                dataSort: true,
                dataFormat: timestampWithLapseFormatter,
                formatExtraData: this.state.query
            },
            label: 'Last Seen'
        }, {
            config: {
                dataField: 'enabled',
                dataSort: true,
                width: '90px',
                dataFormat: enabledFormatter
            },
            label: 'Enabled'
        }]

        const addModalFields = [{
            controlId: 'mac',
            label: 'MAC',
            type: 'text',
            required: true
        }, {
            controlId: 'name',
            label: 'Name',
            type: 'text',
            required: true
        }, {
            controlId: 'enabled',
            label: 'Enabled',
            type: 'checkbox',
            required: true
        }, {
            type: 'senior_with_building',
            fields: {
                enterprise: {
                    controlId: 'enterpriseId',
                    label: 'Enterprise',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                venue: {
                    controlId: 'venueId',
                    label: 'Venue',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id',
                },
                building: {
                    controlId: 'buildingId',
                    label: 'Building',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                senior: {
                    controlId: 'seniorId',
                    label: 'Resident',
                    required: true,
                    labelKey: 'userName',
                    valueKey: 'id'
                }
            }
        } ]

        const updateModalFields = ((fields) => {
            return [].concat(fields)
        })(addModalFields)

        const detailModalFields = ((fields) => {
            return [].concat(fields)
        })(addModalFields)

        const addConfig = {
            title: 'Add Band',
            submitText: 'Add',
            cancelText: 'Cancel',
            fields: addModalFields
        }

        const updateConfig = {
            title: 'Edit Band',
            submitText: 'Edit',
            cancelText: 'Cancel',
            fields: updateModalFields
        }

        const detailConfig = {
            title: 'Band Details',
            fields: detailModalFields
        }

        const deleteConfig = {
            title: 'Delete Band',
            body: 'Are you sure you want to delete the selected band?'
        }

        return (
            <BaseTable
                stripe={true}
                height={props.height}
                facilityFilter
                facilityFilterLevel='building'
                urlEntity='sensor'
                sortName={'id'}
                sortOrder={'asc'}
                entityNameField='mac'
                columnProps={columnProps}
                addConfig={addConfig}
                updateConfig={updateConfig}
                detailConfig={detailConfig}
                deleteConfig={deleteConfig}
                onHandleQuery={ this.handleQuery }
            />
        )
    }
}
export default SensorTable