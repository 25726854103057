import React from 'react'
import BaseTable from '../../components/BaseTable'
import { embeddedNameFormatter } from '../../components/BaseTable/CellFormatter'
import {peopleFormatter} from "../../components/BaseTable/CellFormatter";
class StationTable extends React.Component {
    constructor(props) {
        super(props)
        this.handleQuery = this.handleQuery.bind(this)
        this.state = {
            query: ''
        }
    }
    handleQuery(query) {
        this.setState({
            query: query
        })
    }
    render() {
        const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        }, {
            config: {
                dataField: 'name',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Name'
        }, {
            config: {
                dataField: 'description',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Description',
        }, {
            config: {
                dataField: 'building',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Building'
        }, {
            config: {
                dataField: 'venue',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Venue'
        }, {
            config: {
                dataField: 'enterprise',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Enterprise'
        }]

        const addModalFields = [{
            controlId: 'name',
            label: 'Name',
            type: 'text',
            required: true
        }, {
            controlId: 'description',
            label: 'Description',
            type: 'text',
            required: false
        }, {
            type: 'building',
            fields: {
                enterprise: {
                    controlId: 'enterpriseId',
                    label: 'Enterprise',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                venue: {
                    controlId: 'venueId',
                    label: 'Venue',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                building: {
                    controlId: 'buildingId',
                    label: 'Building',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                }
            }
        } ]

        const updateModalFields = ((fields) => {
            return [].concat(fields)
        })(addModalFields)

        const detailModalFields = ((fields) => {
            return [].concat(fields)
        })(addModalFields)

        const addConfig = {
            title: 'Add Station',
            submitText: 'Add',
            cancelText: 'Cancel',
            fields: addModalFields
        }

        const updateConfig = {
            title: 'Edit Station',
            submitText: 'Edit',
            cancelText: 'Cancel',
            fields: updateModalFields
        }

        const detailConfig = {
            title: 'Station Details',
            fields: detailModalFields
        }

        const deleteConfig = {
            title: 'Delete Station',
            body: 'Are you sure you want to delete the selected station?'
        }

        return (
            <BaseTable
                facilityFilter
                facilityFilterLevel='building'
                urlEntity='station'
                entityNameField='name'
                sortName={'id'}
                sortOrder={'asc'}
                columnProps={columnProps}
                addConfig={addConfig}
                updateConfig={updateConfig}
                detailConfig={detailConfig}
                deleteConfig={deleteConfig}
                onHandleQuery={ this.handleQuery }
            />
        )
    }
}
export default StationTable
