import React, { useState, useEffect } from 'react'
import FormGroup from 'react-bootstrap/FormGroup'
import FormControl from 'react-bootstrap/FormControl'
import FormLabel from 'react-bootstrap/FormLabel'
import Col from 'react-bootstrap/Col'
import Select from 'react-select'
import '../react-select.css'
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'
import AsyncSelect from 'react-select/async';

class FormGroupTimeZone extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props)
    this.state = {
      value: {
        timeZone: props.value ? props.value[props.controlId] : null
      },
      timeZoneOptions: []
    }

    this._onTimeZoneChange = this._onTimeZoneChange.bind(this)
    this._getTimeZoneOptions = this._getTimeZoneOptions.bind(this)
  }

  componentDidMount() {
//	  this._getTimeZoneOptions()
  }

  _onTimeZoneChange(value) {
    this._onChange('timeZone', value)
  }

  _onChange(key, value) {
    let newValue = {...this.state.value}
    newValue[key] = value ? value.value : null
    this.setState({
      value: newValue
    })

    const notifyObj = { timeZone: value.value}, notifyFields = { timeZone: value.value}
    this.props.onChange(notifyObj, notifyFields)
  }

  _getTimeZoneOptions() {
    const { showToast } = this.context
    return  ApiSingleton.makeHttpRequest(
      'post',
      '/bstream/api/v1/timeZone/get',
      { dataType: 'json'},
      {}
    ).then((data) => {
      const options = data.map((item, index) => {
        return { value: item, label: item, data: item }
      })
      setTimeZoneOptions(options)
    })
    .catch((err) => {
      showToast('Failed to fetch list of timeZone! Reason: ' + err)
    })
  }

  render() {
    const props = this.props,
          state = this.state,
          labelWidth = props.labelWidth ? props.labelWidth : 3,
          valueWidth = props.valueWidth ? props.valueWidth : 7,
          validationWidth = 12 - labelWidth - valueWidth
    return (
      <div>
        <FormGroup
          controlId={ props.controlId }
          validationState={ props.validationState[props.controlId].status }>
          <Col sm={ labelWidth }>
            <FormLabel>{ props.label }</FormLabel>
          </Col>
          <Col sm={ valueWidth }>
            <AsyncSelect
              name={ props.controlId }
              autoload={ props.autoLoad }
              searchable
              value={ state.value.timeZone }
              loadOptions={ this._getTimeZoneOptions }
              onChange={ this._onTimeZoneChange }
            />
            <FormControl.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[props.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ props.helpText }</p>
          </Col>
        </FormGroup>
      </div>
    )
  }
}

export default FormGroupTimeZone
