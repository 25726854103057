import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { AiFillSmile, AiOutlineCalendar } from 'react-icons/ai';
import { FaWalking , FaBed , FaHeartbeat } from 'react-icons/fa';
import { TbHeartbeat, TbDeviceHeartMonitor, TbHeartRateMonitor } from 'react-icons/tb';
import { SiOxygen } from 'react-icons/si';
import { GiWeightLiftingUp } from 'react-icons/gi';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {de} from 'date-fns/locale';
import ApiSingleton from '../../utils/Axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material"
import StatBox from "../../components/StatBox/StatBox"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import { getDate } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);
  
  export function AggregateDisplay() {
    const [seniorId, setSeniorId] = useState();

    //react-date-picker addition
    const [dateValue, setDate] = useState(new Date("2023-3-5"));

    const [hrvLongterm, setHRVLongterm] = useState({datasets: [],});
    const [hrvMed, setHRVMed] = useState("N/A");
    const [hrvMean, setHRVMean] = useState("N/A");

    const [dhrLongterm, setDHRLongterm] = useState({datasets: [],});
    const [dhrMed, setDHRMed] = useState("N/A");
    const [dhrMean, setDHRMean] = useState("N/A");

    const [nhrLongterm, setNHRLongterm] = useState({datasets: [],});
    const [nhrMed, setNHRMed] = useState("N/A");
    const [nhrMean, setNHRMean] = useState("N/A");

    const [dpaLongterm, setDPALongterm] = useState({datasets: [],});
    const [dpaMed, setDPAMed] = useState("N/A");
    const [dpaMean, setDPAMean] = useState("N/A");

    const [npaLongterm, setNPALongterm] = useState({datasets: [],});
    const [npaMed, setNPAMed] = useState("N/A");
    const [npaMean, setNPAMean] = useState("N/A");

    const [sbpLongterm, setSBPLongterm] = useState({datasets: [],});
    const [sbpMed, setSBPMed] = useState("N/A");
    const [sbpMean, setSBPMean] = useState("N/A");

    const [dbpLongterm, setDBPLongterm] = useState({datasets: [],});
    const [dbpMed, setDBPMed] = useState("N/A");
    const [dbpMean, setDBPMean] = useState("N/A");

    const [bosLongterm, setBOSLongterm] = useState({datasets: [],});
    const [bosMed, setBOSMed] = useState("N/A");
    const [bosMean, setBOSMean] = useState("N/A");

    const [userList, setUserList] = useState([]);

    function _fetchUserList() {
        const postData = {
            seniorName: "UI_metadata",
            key: "UI_metadata",
        }
        let tempData = null;
        ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                console.log("getting user list from API");
                tempData = JSON.parse(base64_decode(data[0].document));
                console.log("fetched user list: ", tempData);
            })
            .then(data => {
                console.log("setting User List");
                setUserList(tempData.patientids);

            })
            .catch((err) => {});
    }

    function _fetchBOS(seniorId) {
        console.log("entered fetch BOS data");
        const postData = {
            seniorName: seniorId,
            key: "bloodOxygenSaturation",
        }
        let tempData = null;
        if (seniorId) {
          ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                tempData = JSON.parse(base64_decode(data[0].document));
                console.log(tempData);
            })
            .then(data => {
              setBOSLongterm(tempData.longterm);

              let med = getUserMed(tempData.longterm);
              setBOSMed(med);

              let mean = getUserMean(tempData.longterm);
              setBOSMean(mean);
            })
            .catch((err) => {});
        }
    
        return tempData;
      }

      function _fetchHRV(seniorId) {
        console.log("entered fetch HRV data");
        const postData = {
            seniorName: seniorId,
            key: "heartRateVariability",
        }
        let tempData = null;
        if (seniorId) {
          ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                tempData = JSON.parse(base64_decode(data[0].document));
                console.log("fetched hrv data: ", tempData);
            })
            .then(data => {
                setHRVLongterm(tempData.longterm);

                let med = getUserMed(tempData.longterm);
                setHRVMed(med);

                let mean = getUserMean(tempData.longterm);
                setHRVMean(mean);
              
            })
            .catch((err) => {});
        }
    
        return tempData;
      }

      function _fetchDayHR(seniorId) {
        const postData = {
            seniorName: seniorId,
            key: "dayheartRate",
        }
        let tempData = null;
        if (seniorId) {
          ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                tempData = JSON.parse(base64_decode(data[0].document)); //data[0].document; 
            })
            .then(data => {
                setDHRLongterm(tempData.longterm);

                let med = getUserMed(tempData.longterm);
                setDHRMed(med);

                let mean = getUserMean(tempData.longterm);
                setDHRMean(mean);
            })
            .catch((err) => {});
        }
    }

    function _fetchNightHR(seniorId) {
        const postData = {
            seniorName: seniorId,
            key: "nightheartRate",
        }
        let tempData = null;
        if (seniorId) {
          ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                tempData = JSON.parse(base64_decode(data[0].document)); //data[0].document; 
            })
            .then(data => {
                setNHRLongterm(tempData.longterm);

                let med = getUserMed(tempData.longterm);
                setNHRMed(med);

                let mean = getUserMean(tempData.longterm);
                setNHRMean(mean);
            })
            .catch((err) => {});
        }
    }

    function _fetchDayPA(seniorId) {
        const postData = {
            seniorName: seniorId,
            key: "daymore1G",
        }
        let tempData = null;
        if (seniorId) {
          ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                tempData = JSON.parse(base64_decode(data[0].document)); //data[0].document; 
            })
            .then(data => {
                setDPALongterm(tempData.longterm);

                let med = getUserMed(tempData.longterm);
                setDPAMed(med);

                let mean = getUserMean(tempData.longterm);
                setDPAMean(mean);
            })
            .catch((err) => {});
        }
    }

    function _fetchNightPA(seniorId) {
        const postData = {
            seniorName: seniorId,
            key: "nightmore1G",
        }
        let tempData = null;
        if (seniorId) {
          ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                tempData = JSON.parse(base64_decode(data[0].document)); //data[0].document; 
            })
            .then(data => {
                setNPALongterm(tempData.longterm);

                let med = getUserMed(tempData.longterm);
                setNPAMed(med);

                let mean = getUserMean(tempData.longterm);
                setNPAMean(mean);
            })
            .catch((err) => {});
        }
    }

    function _fetchBP(seniorId) {
        console.log("entered fetch SBP data");
        const postData = {
            seniorName: seniorId,
            key: "systolic",
        }
        let tempData = null;
        if (seniorId) {
          ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                tempData = JSON.parse(base64_decode(data[0].document));
            })
            .then((data) => {
                console.log("entered fetch SBP data");
                const postData = {
                    seniorName: seniorId,
                    key: "diastolic",
                }
                let tempData1 = null;
                if (seniorId) {
                  ApiSingleton.makeHttpRequest(
                    "post",
                    "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
                    { dataType: "json" },
                    postData
                  )
                    .then((data) => {
                        tempData1 = JSON.parse(base64_decode(data[0].document));
                    })
                    .then(data => {
                        setSBPLongterm(tempData.longterm);
                        setDBPLongterm(tempData1.longterm);

                        let smed = getUserMed(tempData.longterm);
                        let dmed = getUserMed(tempData1.longterm);
                        setSBPMed(smed);
                        setDBPMed(dmed);

                        let smean = getUserMean(tempData.longterm);
                        let dmean = getUserMean(tempData1.longterm);
                        setSBPMean(smean);
                        setDBPMean(dmean);
                      
                    })
                    .catch((err) => {});
                }
            })
            .catch((err) => {});
        }
    
        return tempData;
      }

    useEffect(() => {

        let seniorId = "fc165b5993";
        _fetchUserList();
        _fetchBOS(seniorId);
        _fetchHRV(seniorId);
        _fetchDayHR(seniorId);
        _fetchNightHR(seniorId);
        _fetchDayPA(seniorId);
        _fetchNightPA(seniorId);
        _fetchBP(seniorId);
  
      console.log("useEffect ran here");
    }, []);

    function getMedian (userList) {
        let length = userList.length;
        const medList = [];

        for (let i = 0; i < length; i++) {
            medList[i] = getUserMed()
        }
    }

    // sets the selected date's max min median values
  function getUserMed (longterm_data) {
    if (longterm_data == null) {
      console.log("data is null");
      return longterm_data;
    }
    let length = longterm_data.length;
    const medList = [];

    for (let i = 0; i < length; i++) {
        medList[i] = longterm_data[i].median;
    }

    medList.sort(function(a, b){return a - b});
    let medIdx = Math.round(length / 2);
    // console.log("med idx: ", medIdx);

    return medList[medIdx];
  }

  function getUserMean (longterm_data) {
    let length = longterm_data.length;
    let sum = 0;

    for (let i = 0; i < length; i++) {
        sum = sum + longterm_data[i].mean;
    }

    let mean = sum / length;
    mean = Math.round(mean * 100) / 100;

    return mean;
  }
  
//     // finds the right data that corresponds to the date
//     function getDateData (date, user) {
//       // console.log("**** entered getDateData function");
//       // console.log("Get date data user: ", user);
//       if (date == null || user[0] == null)
//         return null;

//       var preDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
//       var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
  
//       const dateData = []
//       let i = 0;
//       let n = 0;
//       var currDate = new Date(user[0].year, user[0].month - 1, user[0].day); // month - 1 because month is 0 - 11 not 1 - 12
  
//       while (currDate < afterDate) {
//         currDate = new Date(user[i].year, user[i].month - 1, user[i].day);
//         i++;
  
//         // at the correct date
//         if (currDate > preDate && currDate < afterDate) {
//           dateData[n] = user[i];
//           n++;
//         }
//         else {
//           continue;
//         }
//       }
  
//       // PROBLEM: getting one more data point than intended at the end
//       // console.log("date data: ", dateData);
//       return dateData;
//     }

//     function getMonthData (date, data) {
//       if (data == null) {
//         console.log("data is null");
//         return data;
//       }
//       return data;
//     }

//     // finds the month that current date is in
//     // currently fixed (need to change later)
//     function getLongtermData(date, data) {
//       return data;
//     }
  
//     // returns data for daily graph display
//     function getHRDateData(date_data) {
//       const dateData = []; // each element is a pair of {"hour", "heart rate value"}
//       let i = 0;
//       let dataLen = date_data.length;

//       while (i < dataLen - 1) {
//           dateData[i] = [date_data[i].hour.toString(), date_data[i].heartRate];
//           i++;
//       }
//       return dateData;
//     }

//     // returns data for daily graph display
//     function getPADateData(date_data) {
//       let dateData = 0;
//       let dataLen = date_data.length;
//       console.log("dataLen: ", dataLen);

//       for (let i = 0; i < dataLen-1; i++) {
//         dateData = dateData + parseInt(date_data[i].more1G);
//       }

//       return dateData;
//   }
  
//     // get the BOS data for current date
//     function getBOSDateData (date_data) {
//       if (date_data == null) {
//         console.log("data is null");
//         return date_data;
//       }
//       const dateData = []; // each element is a pair of {"hour", "bos"}
//       let i = 0;
//       let n = 0;
//       let prev = -1;
  
//       if (date_data.length > 0) {
//         while (i < 24) {
//           if (date_data[n].hour != i) {
//               if (date_data[n].hour == prev) {
//                   n++;
//                   continue;
//               }
//               else {
//                   dateData[i] = [i.toString(), null];
//               }   
//           }
//           else {
//             return date_data[n].bloodOxygenSaturation;
//           }
//           i++;
//         }
//       }
    
//       return "N/A"; 
//     }
  
//     // get the HRV data for current date
//     function getHRVDateData (date_data) {
//       if (date_data == null) {
//         console.log("data is null");
//         return date_data;
//       }
//       const dateData = []; // each element is a pair of {"hour", "hrv"}
//       let i = 0;
//       let n = 0;
//       let prev = -1;
  
//       if (date_data.length > 0) {
//         while (i < 24) {
//           if (date_data[n].hour != i) {
//               if (date_data[n].hour == prev) {
//                   n++;
//                   continue;
//               }
//               else {
//                   dateData[i] = [i.toString(), null];
//               }   
//           }
//           else {
//             return date_data[n].heartRateVariability;
//           }
//           i++;
//         }
//       }
    
//       return "N/A";
//     }

//     function getBPDateData (date, data) {
//       var preDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
//       var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
//       var currDate = new Date(data[0].year, data[0].month - 1, data[0].day); // month - 1 because month is 0 - 11 not 1 - 12
//       let i = 0;
      
//       while (currDate < afterDate) {
//         currDate = new Date(data[i].year, data[i].month - 1, data[i].day);
//         i++;
  
//         // at the correct date
//         if (currDate > preDate && currDate < afterDate) {
//           return data[i].median;
//         }
//         else {
//           continue;
//         }
//       }

//       return "N/A";
//     }
  
//     // handles date change event
//     function handleDateChange(event) {
//       setDate(event);
//       console.log("event time is: ", event);

//       // const d = getDateData(event, dailyData);
//       const hrvD = getDateData(event, hrvDailyData);
//       const bosD = getDateData(event, bosDailyData);
//       const hrD = getDateData(event, hrDailyData);
//       const wpaD = getDateData(event, wpaDailyData);
//       const npaD = getDateData(event, npaDailyData);

//       const hrvDate = getHRVDateData(hrvD);
//       setHRVDateData(hrvDate);

//       const bosDate = getBOSDateData(bosD);
//       setBOSDateData(bosDate);

//       const hrDate = getHRDateData(hrD);
//       setHRDateData({
//         labels: [],
//         datasets: [
//           {
//               label: 'Personalized Threshold',
//               data: [],
//               pointStyle: 'line',
//               backgroundColor: 'black',
//               borderColor: 'black',
//           },
//           {
//               label: 'Daytime Heart Rate BPM',
//               data: hrDate,
//               pointStyle: 'circle',
//               backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
//               borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
//               spanGaps: true,
//               yAxisID: 'y',
//           },
//         ],
//       });

//       const wpaDate = getPADateData(wpaD);
//       const npaDate = getPADateData(npaD);
//       setWPADateData(wpaDate);
//       setNPADateData(npaDate);

//       setPADateData({
//         labels: ['Waking Activity Ratio', 'Night Activity Ratio'],
//         datasets: [
//           {
//             label: 'Activity Dataset',
//             data: [wpaDate/(wpaDate + npaDate), npaDate/(wpaDate + npaDate)],
//             backgroundColor: ['rgba(170, 1, 20, 0.5)', 'rgba(68, 33, 18, 0.5)'],
//             borderColor: ['rgba(170, 1, 20, 0.5)', 'rgba(68, 33, 18, 0.5)'],
//           },
//         ],
//       });

//       const sDateData = getBPDateData(event, sbpMonthlyData);
//       const dDateData = getBPDateData(event, dbpMonthlyData);
//       setBPDateData(sDateData.toString() + "/" + dDateData.toString());

      
//     }
  
    return (
      <Box m="20px"> 
          <Box display="flex" justifycontext="space-between" alignItems="center">
              <Box width="100%">
                  <Typography variant="h3" fontWeight="bold" sx={{ color: "#530C0C" }}>Aggregate Display</Typography>
              </Box>
              {/* <Box>
                <DatePicker showIcon={true} minDate={new Date("2023-2-18")} maxDate={new Date("2023-6-8")} onChange={setDate} selected={dateValue} />
              </Box> */}
          </Box>
  
          {/* GRID AND CHARTS */}
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
  
              {/** ROW 1 */}
              <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE">
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center">
                    <StatBox title={hrvMed} subtitle="HRV Median" icon={<TbHeartbeat color='#530C0C' size='50px' />}></StatBox>
                </Box>
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center" >
                    <StatBox title={hrvMean} subtitle="HRV Mean" icon={<TbHeartbeat color='#530C0C' size='50px' />}></StatBox>
                </Box>
              </Box>
              <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE">
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center">
                    <StatBox title={bosMed} subtitle="BOS Median" icon={<SiOxygen color='#530C0C' size='50px' />}></StatBox>
                </Box>
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center" >
                    <StatBox title={bosMean} subtitle="BOS Mean" icon={<SiOxygen color='#530C0C' size='50px' />}></StatBox>
                </Box>
              </Box>
              <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE">
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center">
                    <StatBox title={dhrMed} subtitle="Day HR Median" icon={<FaHeartbeat color='#530C0C' size='50px' />}></StatBox>
                </Box>
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center" >
                    <StatBox title={dhrMean} subtitle="Day HR Mean" icon={<FaHeartbeat color='#530C0C' size='50px' />}></StatBox>
                </Box>
              </Box>

              {/** ROW 2 */}
              
              <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE">
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center">
                    <StatBox title={nhrMed} subtitle="Night HR Median" icon={<FaHeartbeat color='#530C0C' size='50px' />}></StatBox>
                </Box>
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center" >
                    <StatBox title={nhrMean} subtitle="Night HR Mean" icon={<FaHeartbeat color='#530C0C' size='50px' />}></StatBox>
                </Box>
              </Box>
              <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE">
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center">
                    <StatBox title={dpaMed} subtitle="Day PA Median" icon={<FaWalking color='#530C0C' size='50px' />}></StatBox>
                </Box>
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center" >
                    <StatBox title={dpaMean} subtitle="Day PA Mean" icon={<FaWalking color='#530C0C' size='50px' />}></StatBox>
                </Box>
              </Box>
              <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE">
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center">
                    <StatBox title={npaMed} subtitle="Night PA Median" icon={<FaBed color='#530C0C' size='50px' />}></StatBox>
                </Box>
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center" >
                    <StatBox title={npaMean} subtitle="Night PA Mean" icon={<FaBed color='#530C0C' size='50px' />}></StatBox>
                </Box>
              </Box>

              {/** ROW 3 */}
              <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE">
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center">
                    <StatBox title={sbpMed} subtitle="Systolic BP Median" icon={<TbDeviceHeartMonitor color='#530C0C' size='50px' />}></StatBox>
                </Box>
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center" >
                    <StatBox title={sbpMean} subtitle="Systolic BP Mean" icon={<TbDeviceHeartMonitor color='#530C0C' size='50px' />}></StatBox>
                </Box>
              </Box>
              
              <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE">
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center">
                    <StatBox title={dbpMed} subtitle="Diastolic BP Median" icon={<TbDeviceHeartMonitor color='#530C0C' size='50px' />}></StatBox>
                </Box>
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center" >
                    <StatBox title={dbpMean} subtitle="Diastolic BP Mean" icon={<TbDeviceHeartMonitor color='#530C0C' size='50px' />}></StatBox>
                </Box>
              </Box>
              <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE">
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center">
                    <StatBox title="" subtitle="" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                </Box>
                <Box m="20px 10px" display="inline-block" alignItems="center" justifycontent="center" >
                    <StatBox title="" subtitle="" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                </Box>
              </Box>

          </Box>
      </Box>
    );
  }
  
  export default AggregateDisplay;

