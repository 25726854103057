import React from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { FormLabel } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ApiSingleton from "../../utils/Axios";
class FormUploadAudio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      audioContent: null,
      audioId: props.audioId ? props.audioId : null,
      fileName: null,
    };
  }

  componentWillMount() {
    if (this.state.audioId) {
      this._fetchFileContentById(this.state.audioId);
    }
  }
  _fetchFileContentById(id) {
    const postData = { id: id };
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/audio/findById",
      { dataType: "json" },
      postData
    )
      .then((data) => {
        if (data) {
          this.setState({
            audioContent: data.content,
            audioId: id,
            fileName: data.fileName,
          });
        }
      })
      .catch((err) => {
        console.log("fail to fetch file content by id: " + id, err);
      });
  }
  deleteAudio(e) {
    e.preventDefault();
    const id = this.state.audioId;
    const postData = { id: this.state.audioId };
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/audio/delete",
      { dataType: "json" },
      postData
    )
      .then((data) => {
        this.setState({
          audioContent: null,
          audioId: null,
          fileName: null,
        });
      })
      .catch((err) => {
        console.log("fail to delete audio by id: " + id, err);
      });
  }
  _onChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file !== null) {
      reader.readAsBinaryString(file);
      reader.onloadend = (ev) => {
        //console.log('onload ev:', ev)
        const content = btoa(reader.result);

        this.setState({
          fileName: file.name,
        });
        //console.log('result:', JSON.stringify(reader.result))
        if (this.props.id !== null) {
          //update audio
          if (this.state.audioId) {
            let url = "/bstream/api/v1/audio/updateForSenior";
            const postData = {
              id: this.state.audioId,
              content: content,
            };
            if (this.props.urlEntity === "senior") {
              postData.seniorId = this.props.id;
            } else {
              postData.relayId = this.props.id;
              url = "/bstream/api/v1/audio/updateForRelay";
            }
            ApiSingleton.makeHttpRequest(
              "post",
              url,
              { dataType: "json" },
              postData
            )
              .then((data) => {
                this.setState({
                  audioContent: content,
                  name: file.name,
                });
              })
              .catch((err) => {
                console.log("fail to update audio for id: " + postData.id, err);
              });
          } else {
            let url = "/bstream/api/v1/audio/createForSenior";
            const postData = {
              name: file.name,
              content: content,
            };
            if (this.props.urlEntity === "senior") {
              postData.seniorId = this.props.id;
            } else {
              postData.relayId = this.props.id;
              url = "/bstream/api/v1/audio/createForRelay";
            }
            ApiSingleton.makeHttpRequest(
              "post",
              url,
              { dataType: "json" },
              postData
            )
              .then((data) => {
                this.setState({
                  audioContent: data.content,
                  audioId: data.id,
                  fileName: data.fileName,
                });
              })
              .catch((err) => {
                console.log("fail to create alert for id: " + postData.id, err);
              });
          }
        }
        // this.props.onChange(this.props.controlId, this.props.required, this.props.validator, value)
      };
    }
  };

  render() {
    const props = this.props,
      state = this.state,
      labelWidth = props.labelWidth ? props.labelWidth : 3,
      valueWidth = props.valueWidth ? props.valueWidth : 7,
      validationWidth = 12 - labelWidth - valueWidth;
    let audioPreview = null;
    let audioFile = null;
    if (state.audioContent) {
      audioFile = atob(state.audioContent);
      let array = new Uint8Array(audioFile.length);
      for (let i = 0; i < audioFile.length; i++) {
        array[i] = audioFile.charCodeAt(i);
      }
      var b = new Blob([array]);
    }
    if (state.audioContent) {
      let fileName = this.state.fileName;
      if (!fileName.endsWith("mp3")) {
        fileName = fileName + ".mp3";
      }
      audioPreview = (
        <div style={{ marginTop: "10px" }}>
          <Col sm={5}>
            <audio
              style={{ height: "40px" }}
              controls
              src={URL.createObjectURL(b)}
              title={fileName}
            ></audio>
          </Col>
          <Col sm={2}>
            <button
              style={{ height: "40px", border: "0px" }}
              className={"btn btn-danger"}
              onClick={(e) => {
                this.deleteAudio(e);
              }}
            >
              <span className={"glyphicon glyphicon-remove"}></span>
            </button>
          </Col>
        </div>
      );
    } else {
      audioPreview = <div>Audio file has not been selected</div>;
    }
    return (
      <Form.Group as={Row} className="mb-3" controlId={props.controlId}>
        <Col sm={labelWidth}>
          <Form.Label style={{ fontWeight: "bold" }}>{props.label}</Form.Label>
        </Col>
        <Col sm={valueWidth}>
          <input
            type="file"
            accept="audio/*"
            style={{ color: "transparent" }}
            onChange={this._onChange}
          />
        </Col>
        <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
          {audioPreview}
        </Col>
      </Form.Group>
    );
  }
}
export default FormUploadAudio;
