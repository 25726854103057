import React from 'react'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Button from 'react-bootstrap/Button'
import BaseFormModal from '../../components/BaseFormModal'
import ConfirmDelete from '../../components/BaseTable/ConfirmDelete'
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'

class FacilityToolbar extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props)

    this.state = {
      addModalData: props.addModalData,
      isConfirmDeleteShown: false,
      isAddModalShown: false,
      isUpdateModalShown: false
    }
    this.onAddModalSubmit = this.onAddModalSubmit.bind(this)
  }

  _getUrlPrefix = () => {
    return '/bstream/api/v1/' + this.props.urlEntity + '/'
  }

  _getChildUrlPrefix = () => {
    return '/bstream/api/v1/' + this.props.childUrlEntity + '/'
  }

  onDelete = () => {
    this.setState({isConfirmDeleteShown: true})
  }

  onConfirmDeleteClose = () => {
    this.setState({isConfirmDeleteShown: false})
  }

  onConfirmDeleteConfirm = () => {
    this._remoteDelete(this.props.data, this.onConfirmDeleteClose)
  }

  onAdd = () => {
    if (this.props.beforeAddModalShown) {
      const addModalData = this.props.beforeAddModalShown({...this.props.addModalData}, this.props.data)
      this.setState({
        addModalData: addModalData,
        isAddModalShown: true
      })
    } else {
      this.setState({isAddModalShown: true})
    }
  }

  onAddModalClose = () => {
    this.setState({isAddModalShown: false})
  }

  onAddModalSubmit(data, successCallback, errorCallback) {
    if (this.props.beforeAddSubmit) {
      // hook to process form data before add submit
      data = this.props.beforeAddSubmit(data)
    }
    switch (this.props.urlEntity) {
      case 'enterprise':
        data.enterpriseId = this.props.data.id
        break
      case 'venue':
        data.enterpriseId = this.props.data.enterpriseId
        data.venueId = this.props.data.id
        break
      case 'building':
        data.enterpriseId = this.props.data.enterpriseId
        data.venueId = this.props.data.venueId
        data.buildingId = this.props.data.id
        break
      case 'floor':
        data.enterpriseId = this.props.data.enterpriseId
        data.venueId = this.props.data.venueId
        data.buildingId = this.props.data.buildingId
        data.floorId = this.props.data.id
        break
      case 'room':
        data.enterpriseId = this.props.data.enterpriseId
        data.venueId = this.props.data.venueId
        data.buildingId = this.props.data.buildingId
        data.floorId = this.props.data.floorId
        data.roomId = this.props.data.id
        break
    }
//    console.log(data)
    this._remoteAdd(data, successCallback, errorCallback)
  }

  onUpdate = () => {
    this.setState({isUpdateModalShown: true, upadteModalData: this.props.data})
  }

  onUpdateModalClose = () => {
    this.setState({isUpdateModalShown: false})
  }

  onUpdateModalSubmit = (data, successCallback, errorCallback) => {
    if (this.props.beforeUpdateSubmit) {
      // hook to process form data before add submit
      data = this.props.beforeUpdateSubmit(data)
    }
    this._remoteUpdate(data, successCallback, errorCallback)
  }

  _getEntityName = (data) => {
    if (this.props.entityNameField) {
      return data[this.props.entityNameField]
    } else {
      return data.id
    }
  }

  _getRemoteResponseErr = (err) => {
    if (err.responseJSON && err.responseJSON.message) {
      return err.responseJSON.message
    } else {
      return ''
    }
  }

  _remoteAdd = (data, successCallback, errorCallback) => {
    ApiSingleton.makeHttpRequest(
      "post",
      this._getChildUrlPrefix() + 'create',
      { dataType: "json" },
      data
    )
      .then((data) => {
        if (successCallback) {
          successCallback(resp)
        }
        this.context.notifySuccess(this.props.childUrlEntity + ' (' + this._getEntityName(data) + ') was added successfully!')
        this.props.remoteLoadSelected()
      })
      .catch((err) => {
        console.log(err)
        if (errorCallback) {
          errorCallback(err)
        }
        const { showToast } = this.context
        showToast('Error adding ' + this.props.childUrlEntity + ' (' + this._getEntityName(data) + ' )! Reason: ' + err)
      });
  }

  _remoteUpdate = (data, successCallback, errorCallback) => {
    ApiSingleton.makeHttpRequest(
      "post",
      this._getUrlPrefix() + 'update',
      { dataType: "json" },
      data
    )
      .then((data) => {
        if (successCallback) {
          successCallback(resp)
        }
        this.context.notifySuccess(this.props.urlEntity + ' (' + this._getEntityName(data) + ') was updated successfully!')
        this._remoteLoadById(data.id, errorCallback)
      })
      .catch((err) => {
        console.error(err)
        if (errorCallback) {
          errorCallback(err)
        }
        const { showToast } = this.context
        showToast('Failed to update ' + this.props.urlEntity + ' (' + this._getEntityName(data) + ' )! Reason: ' + err)
      });
  }

  _remoteLoadById = (id, errorCallback) => {
    ApiSingleton.makeHttpRequest(
      "post",
      this._getUrlPrefix() + 'findById',
      { dataType: "json" },
      {id: id}
    )
      .then((data) => {
        this.props.onTreeNodeUpdated(this.props.urlEntity, data)
      })
      .catch((err) => {
        console.error(err)
        if (errorCallback) {
          errorCallback(err)
        }
        const { showToast } = this.context
        showToast('Failed to update ' + this.props.urlEntity + ' (' + this._getEntityName(data) + ' )! Reason: ' + err)
      });
  }

  _remoteDelete = (data, successCallback, errorCallback) => {
    ApiSingleton.makeHttpRequest(
      "post",
      this._getUrlPrefix() + 'delete',
      { dataType: "json" },
      {
        id: data.id
      }
    )
      .then((data) => {
        if (successCallback) {
          successCallback(resp)
        }
        this.context.notifySuccess(this.props.urlEntity + ' (' + this._getEntityName(data) + ' ) was deleted successfully!')
        this.props.onTreeNodeDeleted(this.props.urlEntity, data)
      })
      .catch((err) => {
        console.error(err)
        if (errorCallback) {
          errorCallback(err)
        }
        const { showToast } = this.context
        showToast('Failed to delete ' + this.props.urlEntity + ' (' + this._getEntityName(data) + ' )! Reason: ' + err)
      });
  }

  render() {
    const props = this.props,
          state = this.state
    if (!props.data) {
      return null
    }
    return (
      <div>
        <div style={{marginBottom: '10px'}}>
          {(() => {
            if (props.addBtnText) {
              return <Button variant='primary' style={{marginLeft:5}} onClick={this.onAdd}> {props.addBtnText} </Button>
            }
          })()}
          {(() => {
            if (props.updateBtnText) {
              return <Button variant='primary' style={{marginLeft:5}} onClick={this.onUpdate}> {props.updateBtnText} </Button>
            }
          })()}
          {(() => {
            if (props.deleteBtnText) {
              return <Button variant='danger' style={{marginLeft:5}} onClick={this.onDelete}> {props.deleteBtnText} </Button>
            }
          })()}
        </div>
        {(() => {
          if (props.addBtnText) {
              return <BaseFormModal
                isAdd={ true }
                show={ state.isAddModalShown }
                data={ state.addModalData }
                title={ props.addModalTitle }
                submitText='Add'
                cancelText='Cancel'
                fields={ props.addModalFields}
                formValidator={ props.addModalFormValidator }
                onClose={ this.onAddModalClose }
                onSubmit={ this.onAddModalSubmit }
              />
          }
        })()}
        {(() => {
          if (props.updateBtnText) {
            return <BaseFormModal
              isAdd={ false }
              show={ state.isUpdateModalShown }
              data={ props.data }
              title={ props.updateModalTitle }
              submitText='Edit'
              cancelText='Cancel'
              fields={ props.updateModalFields}
              formValidator={ props.updateModalFormValidator }
              onClose={ this.onUpdateModalClose }
              onSubmit={ this.onUpdateModalSubmit }
            />
          }
        })()}
        {(() => {
          if (props.deleteBtnText) {
            return <ConfirmDelete
              show={ state.isConfirmDeleteShown }
              data={ props.data }
              title={ props.deleteModalTitle }
              body={ props.deleteModalBody }
              onClose={ this.onConfirmDeleteClose }
              onSubmit={ this.onConfirmDeleteConfirm }
            />
          }
        })()}
      </div>
    )
  }
}

export default FacilityToolbar
