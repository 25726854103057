import React from 'react'
import { Rnd } from 'react-rnd'

const style = {
  textAlign: 'center',
  border: 'solid 3px red',
  borderRadius: '5px'
}

class RoomPosition extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      position: {
        x1: this.props.left,
        y1: this.props.top,
        x2: this.props.right,
        y2: this.props.bottom,
        width: this.props.right - this.props.left,
        height: this.props.bottom - this.props.top
      }
    }
  }

  _onResizeStop = (direction, styleSize, clientSize, delta) => {
    //console.log(direction, styleSize, clientSize, delta)
    const newPosition = {...this.state.position}
    //console.log('before resize: ', newPosition)
    if (direction === 'topLeft' || direction === 'left' || direction === 'top') {
        newPosition.x1 =  newPosition.x2 - styleSize.width
        newPosition.y1 = newPosition.y2 - styleSize.height
    } else if (direction === 'bottomLeft') {
        newPosition.x1 =  newPosition.x2 - styleSize.width
        newPosition.y2 =  newPosition.y1 + styleSize.height
    } else if (direction === 'topRight') {
        newPosition.x2 = newPosition.x1 + styleSize.width
        newPosition.y1 = newPosition.y2 - styleSize.height
    } else if (direction === 'bottomRight' || direction === 'right' || direction === 'bottom') {
        newPosition.x2 =  newPosition.x1 + styleSize.width
        newPosition.y2 = newPosition.y1 + styleSize.height
    }
    newPosition.width = newPosition.x2 - newPosition.x1
    newPosition.height = newPosition.y2 - newPosition.y1

    //console.log('after resize: ', newPosition)
    this.setState({position: newPosition})
//    this.rnd.updateSize({ width: newPosition.width, height: newPosition.height })
//    this.rnd.updatePosition({ x: newPosition.x1, y: newPosition.y1 })
    if (this.props.onChange) {
      this.props.onChange(newPosition)
    }
  }

  _onDragStop = (event, ui) => {
    const newPosition = {...this.state.position}
    newPosition.x1 = ui.position.left
    newPosition.y1 = ui.position.top
    newPosition.x2 = newPosition.x1 + newPosition.width
    newPosition.y2 = newPosition.y1 + newPosition.height

    this.setState({position: newPosition})

    if (this.props.onChange) {
      this.props.onChange(newPosition)
    }
  }

  render() {
    const props = this.props
    const position = this.state.position
    const svg = React.DOM.svg
    const image = React.DOM.image
    return (
      <div style={{position: 'relative'}}>
        <div>
          <svg width={ props.imageWidth } height={ props.imageHeight }>
            <image
              xlinkHref={ props.imageContent }
              x={ 0 }
              y={ 0 }
              width={ props.imageWidth }
              height={ props.imageHeight }
            />
          </svg>
        </div>
        <Rnd
          ref={c => { this.rnd = c }}
          initial={{
            x: position.x1,
            y: position.y1,
            width: position.width,
            height: position.height,
          }}
          style={ style }
          zIndex={ 99 }
          onDragStop={ this._onDragStop }
          onResizeStop={ this._onResizeStop } >
        </Rnd>
      </div>
    )
  }
}

export default RoomPosition
