import React from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Select from 'react-select'
import '../react-select.css'
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'

class FormGroupSelect extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props)

    this.urlPrefix = '/bstream/api/v1/' + props.urlEntity + '/'
    this.state = {
      value: null,
      valueOptions: [],
    }
    this._getOptions = this._getOptions.bind(this)
  }

  _onChange = (value) => {
    this.setState({
      value: value
    })
    this.props.onChange(this.props.controlId, this.props.required, this.props.validator, value.value)
  }

  componentDidMount() {
    const data = this._getOptions();
    data.then(resp => {
      this.setState({
        valueOptions: resp.options
      })
    })
      .catch((err) => {
        showToast('Failed to fetch list of ' + entity + '! Reason: ' + err)
      })
  }

  _getOptions = (input) => {
    const { showToast } = this.context
    const data = {}
    if (this.props.enterpriseId) {
      data.enterpriseId = this.props.enterpriseId
    }
    return ApiSingleton.makeHttpRequest(
      'post',
      this.urlPrefix + 'find',
      { dataType: 'json' },
      data
    ).then((data) => {
      let options = data.map((item, index) => {
        return { value: item[this.props.valueKey], label: item[this.props.labelKey] }
      })
      if (this.props.urlEntity === 'enterprise') {
        options.push({ value: '00000000-0000-0000-0000-000000000000', label: 'Not Specified' })
      }
      return {
        options: options
      }
    },
      (err) => {
        showToast('Failed to fetch list of ' + this.props.urlEntity + '! Reason: ' + err)
      }
    )
  }


  render() {
    const props = this.props,
      state = this.state,
      labelWidth = props.labelWidth ? props.labelWidth : 3,
      valueWidth = props.valueWidth ? props.valueWidth : 7,
      validationWidth = 12 - labelWidth - valueWidth

    return (
      <Form.Group
        controlId={props.controlId}
        validationState={props.validationState.status}>
        <Col sm={labelWidth}>
          <Form.Label style={{ fontWeight: "bold" }}>{props.label}</Form.Label>
        </Col>
        <Col sm={valueWidth}>
          <Select
            name={props.controlId}
            isLoading={props.autoLoad}
            searchable
            value={state.value}
            options={state.valueOptions}
            onChange={this._onChange}
          />
          <Form.Control.Feedback />
        </Col>
        <Col sm={validationWidth}>
          <p>{props.validationState.message}</p>
        </Col>
        <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
          <p>{props.helpText}</p>
        </Col>
      </Form.Group>
    )
  }
}

export default FormGroupSelect
