import React from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import "./sidebar.css";

function AccordionItem({ content, onToggle, active }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <li
    style={{transition:'all 300ms', }}
    // onClick={() => navigate(`/` + content.to)}
    >
      <div
        className={`sidebar-link ${
          location.pathname == `/` + content.to ? `active` : null
        }`}
        onClick={() => {
          if (content.content) onToggle();
          else navigate(`/` + content.to);
        }}
      >
        <div style={{ padding: 4, paddingLeft: 18 }}>
          {/* <i className="fa fa-tachometer" /> */}
          <span style={{ marginRight: 10 }}>
            <i className={`fa ${content.icon}`} />
          </span>
          {content.label}
        </div>
        {content.content ? (
          <span style={{ marginTop: 5 }}>
            <i
              className={`fa fa-caret-left sidebar-state-icon ${
                active ? "rotate-minus-90" : ""
              }`}
              aria-hidden="true"
            />
          </span>
        ) : null}
      </div>
      <div
        className={`sidebar-container nested_wrapper ${active ? "open" : ""}`}
        
      >
        {content.content ? (
          <ul style={{transition:'all 300ms'}}>
            {content.content.map((list, childIndex) => (
              <li
                onClick={() => navigate(`/` + list.to)}
                className={`sidebar-link ${
                  location.pathname == `/` + list.to ? `active-list` : null
                }`}
                key = {childIndex}
              >
                <div>
                  <span style={{ marginRight: 10 }}>
                    <i className={`fa ${list.icon}`} />
                  </span>
                  {list.label}
                </div>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </li>
  );
}

export default AccordionItem;
