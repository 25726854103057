import React, { useState, useEffect } from "react";
import BaseTable from "../../components/BaseTable";
import {
  timestampWithLapseFormatter,
  missingSeniorName,
  missingPlace,
  MissingSensorNote,
} from "../../components/BaseTable/CellFormatter";
import moment from "moment";

const SeniorMissingTable = (props) => {
  const [data, setData] = useState(props.sensors ? props.sensors : []);
  const [query, setQuery] = useState(props.query ? props.query : "");

  const onRefresh = () => {
    if (props.refresh) {
      props.refresh();
    }
  };

  const onRefreshSuspend = () => {
    if (props.refreshSuspend) {
      props.refreshSuspend();
    }
  };

  useEffect(() => {
    setData(props.sensors);
    setQuery(props.query);
  }, [props.sensors, props.query]);

  let filteredData = data;
  if (query !== "") {
    const lowerCaseQuery = query.toLowerCase();
    filteredData = data.filter((row) => {
      const time =
        moment(row.missingTime).local().format("YYYY/MM/DD HH:mm:ss") +
        " (" +
        String(moment(row.missingTime).toNow(true)) +
        " ago)";
      return (
        time.toLowerCase().includes(lowerCaseQuery) ||
        (row.seniorName &&
          row.seniorName.toLowerCase().includes(lowerCaseQuery)) ||
        (row.missingPlace &&
          row.missingPlace.toLowerCase().includes(lowerCaseQuery)) ||
        (row.missingSensorAlert &&
          row.missingSensorAlert.toLowerCase().includes(lowerCaseQuery))
      );
    });
  }

  const columnProps = [
    {
      config: {
        dataField: "seniorName",
        dataSort: false,
        dataFormat: missingSeniorName,
        formatExtraData: query,
      },
      label: "ResidentName",
    },
    {
      config: {
        dataField: "missingTime",
        isKey: true,
        dataSort: false,
        dataFormat: timestampWithLapseFormatter,
        formatExtraData: query,
      },
      label: "Missing Time",
    },
    {
      config: {
        dataField: "missingPlace",
        dataSort: false,
        dataFormat: missingPlace,
        formatExtraData: query,
      },
      label: "Exit Door",
    },
    {
      config: {
        dataField: "missingSensorAlert",
        dataSort: false,
        dataFormat: MissingSensorNote,
        formatExtraData: query,
      },
      label: "Notes",
    },
  ];

  const updateModalFields = [
    {
      controlId: "alertEvent",
      label: "Update Type",
      type: "radiogroup",
      inline: true,
      required: true,
      options: [
        {
          label: "UPDATE",
          value: "UPDATE",
          checked: true,
        },
        {
          label: "CLOSE",
          value: "CLOSE",
        },
      ],
    },
    {
      controlId: "description",
      label: "Description",
      type: "text",
      required: true,
    },
  ];

  const updateConfig = {
    title: "Update Missing Alert",
    submitText: "Update",
    cancelText: "Cancel",
    fields: updateModalFields,
    defaultData: {
      alertEvent: "UPDATE",
      description: "",
    },
    beforeSubmit: (data) => {
      return _.pick(data, ["id", "alertEvent", "description"]);
    },
  };

  return (
    <BaseTable
      autoLoad={false}
      striped={true}
      height={props.height}
      updateConfig={updateConfig}
      pagination={false}
      urlEntity="sensorAlert"
      updateUrl={"triggerEvent"}
      actions={[]}
      columnProps={columnProps}
      hideSelectColumn={true}
      refresh={this.onRefresh}
      refreshSuspend={this.onRefreshSuspend}
      selectAction={{ name: "update" }}
      sortedData={data}
    />
  );
};
export default SeniorMissingTable;
