import React from 'react'
import BaseTable from '../../components/BaseTable'
import { timestampWithLapseFormatter, enabledFormatter, embeddedNameFormatter } from '../../components/BaseTable/CellFormatter'
import {MatchingPasswordRequired, MatchingPasswordAllowUndefined, StripConfirmPassword, StripEmptyAndConfirmPassword} from '../../components/BaseFormModal/Utils'
import _ from 'lodash'
import {hasAudioFormatter, peopleFormatter} from "../../components/BaseTable/CellFormatter";
class RelayTable extends React.Component {
    constructor(props) {
        super(props)
        this.handleQuery = this.handleQuery.bind(this)
        this.state = {
            query: ''
        }
    }
    handleQuery(query) {
        this.setState({
            query: query
        })
    }
    render() {
        const props = this.props
        const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        }, {
            config: {
                dataField: 'name',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Name'
        }, {
            config: {
                dataField: 'mac',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'MAC',
        }, {
            config: {
                dataField: 'description',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Description'
        }, {
            config: {
                dataField: 'building',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Building'
        }, {
            config: {
                dataField: 'floor',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Floor'
        }, {
            config: {
                dataField: 'room',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Room'
        }, {
            config: {
                dataField: 'lastSeenUtc',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: timestampWithLapseFormatter
            },
            label: 'Last Seen'
        }, {
            config: {
                dataField: 'fwVersion',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'FW Version'
        }, {
            config: {
                dataField: 'ipv4Address',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'IPv4 Addr'
        }, {
            config: {
                dataField: 'enabled',
                dataSort: true,
                width: '90px',
                dataFormat: enabledFormatter
            },
            label: 'Enabled'
        },
            {
                config: {
                    dataField: 'audioList',
                    dataSort: false,
                    dataFormat: hasAudioFormatter
                },
                label: 'hasAudio'
            }]

        const addModalFields = [{
            controlId: 'name',
            label: 'Name',
            type: 'text',
            required: true
        }, {
            controlId: 'mac',
            label: 'MAC',
            type: 'text',
            required: true
        }, {
            controlId: 'description',
            label: 'Description',
            type: 'text',
            required: false
        }, {
            controlId: 'password',
            label: 'Password',
            type: 'password',
            required: true
        }, {
            controlId: 'password2',
            label: 'Confirm Password',
            componentClass: 'input',
            type: 'password',
            required: true
        }, {
            controlId: 'isExit',
            label: 'Is Exit Door',
            type: 'checkbox',
            defaultValue: false
        }, {
            controlId: 'enabled',
            label: 'Enabled',
            type: 'checkbox',
            defaultValue: true
        }, {
            controlId: 'canPlayAudio',
            label: 'Can Play Audio',
            type: 'checkbox'
        }, {
            type: 'room_with_relay',
            fields: {
                enterprise: {
                    controlId: 'enterpriseId',
                    label: 'Enterprise',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                venue: {
                    controlId: 'venueId',
                    label: 'Venue',
                    required: false,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                building: {
                    controlId: 'buildingId',
                    label: 'Building',
                    required: false,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                floor: {
                    controlId: 'floorId',
                    label: 'Floor',
                    required: false,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                room: {
                    controlId: 'roomId',
                    label: 'Room',
                    required: false,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                coordinates: {
                    controlId: 'coordinates',
                    label: 'Coordinates',
                    required: false,
                    helpText: 'Drag the cube to change the location of the RockBox'
                }
            }
        },
            {
                controlId: 'uploadAudio',
                type: 'upload_audio',
                label: 'Upload Audio',
                urlEntity: 'relay'
            }]

        const updateModalFields = ((fields) => {
            return _.reduce(fields, (o, v, i) => {
                const newv = {...v}
                if (newv.controlId === 'password' || newv.controlId === 'password2') {
                    delete newv.required
                    newv.helpText = 'Leave it empty for no change'
                }
                o.push(newv)
                return o
            }, [])
        })(addModalFields)

        const detailModalFields = [
            {
                controlId: 'name',
                label: 'Name',
                type: 'text'
            }, {
                controlId: 'mac',
                label: 'MAC',
                type: 'text'
            }, {
                controlId: 'description',
                label: 'Description',
                type: 'text'
            }, {
                controlId: 'enabled',
                label: 'Enabled',
                type: 'checkbox',
                defaultValue: true
            }, {
                controlId: 'isExit',
                label: 'Is Exit Door',
                type: 'checkbox',
                defaultValue: false
            }, {
                controlId: 'canPlayAudio',
                label: 'Can Play Audio',
                type: 'checkbox'
            },
            {
                controlId: 'sshPort',
                label: 'SSH Port',
                type: 'static'
            }, {
                controlId: 'ipv6Address',
                label: 'WiFi Status',
                type: 'static'
            }, {
                type: 'room_with_relay',
                fields: {
                    enterprise: {
                        controlId: 'enterpriseId',
                        label: 'Enterprise',
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    venue: {
                        controlId: 'venueId',
                        label: 'Venue',
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    building: {
                        controlId: 'buildingId',
                        label: 'Building',
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    floor: {
                        controlId: 'floorId',
                        label: 'Floor',
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    room: {
                        controlId: 'roomId',
                        label: 'Room',
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    coordinates: {
                        controlId: 'coordinates',
                        label: 'Coordinates',
                        helpText: 'Location of the RockBox'
                    }
                }
            }]

        const addConfig = {
            title: 'Add RockBox',
            submitText: 'Add',
            cancelText: 'Cancel',
            fields: addModalFields,
            beforeSubmit: StripConfirmPassword,
            formValidator: MatchingPasswordRequired
        }

        const updateConfig = {
            title: 'Edit RockBox',
            submitText: 'Edit',
            cancelText: 'Cancel',
            fields: updateModalFields,
            beforeSubmit: StripEmptyAndConfirmPassword,
            formValidator: MatchingPasswordAllowUndefined
        }

        const detailConfig = {
            title: 'RockBox Detail Info',
            submitText: 'Close',
            cancelText: 'Cancel',
            fields: detailModalFields
        }

        const deleteConfig = {
            title: 'Delete RockBox',
            body: 'Are you sure you want to delete the selected RockBox?'
        }
        return (
            <BaseTable
                facilityFilter
                facilityFilterLevel='floor'
                striped={true}
                height={props.height}
                sortName={'id'}
                sortOrder={'asc'}
                urlEntity='relay'
                entityNameField='name'
                columnProps={columnProps}
                addConfig={addConfig}
                updateConfig={updateConfig}
                detailConfig={detailConfig}
                deleteConfig={deleteConfig}
                onHandleQuery={ this.handleQuery }
            />
        )
    }
}
export default RelayTable
