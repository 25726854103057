import React from 'react'
import BaseTable from '../../components/BaseTable'
import { embeddedNameFormatter, enabledFormatter, sensorListFormatter, hasAudioFormatter } from '../../components/BaseTable/CellFormatter'
import {peopleFormatter} from "../../components/BaseTable/CellFormatter";
class SeniorTable extends React.Component {
    constructor(props) {
        super(props)
        this.handleQuery = this.handleQuery.bind(this)
        this.state = {
            query: ''
        }
    }
    handleQuery(query) {
        this.setState({
            query: query
        })
    }
    render() {
        const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        }, {
            config: {
                dataField: 'userName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'User Name'
        }, {
            config: {
                dataField: 'firstName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'First Name',
        }, {
            config: {
                dataField: 'lastName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Last Name'
        }, {
            config: {
                dataField: 'email',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Email'
        }, {
            config: {
                dataField: 'building',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Building'
        }, {
            config: {
                dataField: 'floor',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Floor'
        }, {
            config: {
                dataField: 'room',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Room'
        }, {
            config: {
                dataField: 'station',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Station'
        }, {
            config: {
                dataField: 'enabled',
                dataSort: true,
                width: '90px',
                dataFormat: enabledFormatter
            },
            label: 'Enabled'
        }, {
            config: {
                dataField: 'sensorList',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: sensorListFormatter
            },
            label: 'RockBand'
        },
            {
                config: {
                    dataField: 'groupPolicy',
                    dataSort: false,
                    formatExtraData: this.state.query,
                    dataFormat: peopleFormatter
                },
                label: 'Group Policy'
            },
            {
                config: {
                    dataField: 'audioList',
                    dataSort: false,
                    formatExtraData: this.state.query,
                    dataFormat: hasAudioFormatter
                },
                label: 'hasAudio'
            }
        ]

        const addModalFields = [{
            controlId: 'userName',
            label: 'User Name',
            type: 'text',
            required: true
        }, {
            controlId: 'firstName',
            label: 'First Name',
            type: 'text',
            required: true
        }, {
            controlId: 'lastName',
            label: 'Last Name',
            type: 'text',
            required: true
        }, {
            controlId: 'email',
            label: 'Email',
            type: 'text'
        }, {
            controlId: 'enabled',
            label: 'Enabled',
            type: 'checkbox',
            required: true
        }, {
            type: 'room_with_station',
            fields: {
                enterprise: {
                    controlId: 'enterpriseId',
                    label: 'Enterprise',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                groupPolicy: {
                    controlId: 'groupPolicyId',
                    label: 'Group Policy',
                    labelKey: 'name',
                    valueKey: 'id'
                },
                venue: {
                    controlId: 'venueId',
                    label: 'Venue',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                building: {
                    controlId: 'buildingId',
                    label: 'Building',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                floor: {
                    controlId: 'floorId',
                    label: 'Floor',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                room: {
                    controlId: 'roomId',
                    label: 'Room',
                    required: true,
                    labelKey: 'name',
                    valueKey: 'id'
                },
                station: {
                    controlId: 'stationId',
                    label: 'Station',
                    required: false,
                    labelKey: 'name',
                    valueKey: 'id'
                }
            }
        },
            {
            controlId: 'uploadAudio',
            type: 'upload_audio',
            label: 'Upload Audio',
            urlEntity: 'senior'
        }
        ]

        const updateModalFields = ((fields) => {
            return [].concat(fields)
        })(addModalFields)

        const detailModalFields = ((fields) => {
            return [].concat(fields)
        })(addModalFields)

        const addConfig = {
            title: 'Add Resident',
            submitText: 'Add',
            cancelText: 'Cancel',
            fields: addModalFields
        }

        const updateConfig = {
            title: 'Edit Resident',
            submitText: 'Edit',
            cancelText: 'Cancel',
            fields: updateModalFields
        }

        const detailConfig = {
            title: 'Resident Details',
            fields: detailModalFields
        }

        const deleteConfig = {
            title: 'Delete Resident',
            body: 'Are you sure you want to delete the selected resident?'
        }

        return (
          <BaseTable
              facilityFilter
              facilityFilterLevel='floor'
              urlEntity='senior'
              entityNameField='userName'
              columnProps={columnProps}
              addConfig={addConfig}
              sortName={'id'}
              sortOrder={'asc'}
              updateConfig={updateConfig}
              detailConfig={detailConfig}
              deleteConfig={deleteConfig}
              onHandleQuery={ this.handleQuery }
          />
    )
  }
}
export default SeniorTable
