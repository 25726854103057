import React, { useContext } from 'react'
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'
import './SystemDashboard.scss'
import _ from 'lodash'

const SummaryCard = (props) => {
  const itemCount = _.size(props.items)
  let itemclass = 'col-xs-6 col-sm-3 col-md-3 col-lg-3'
  if (itemCount === 2) {
    itemclass = 'col-xs-6 col-sm-6 col-md-6 col-lg-6'
  } else if (itemCount === 5) {
    itemclass = 'col-xs-3 col-sm-2 col-md-2 col-lg-2'
  }

  return (
    <div className={props.className}>
      <div className="panel summary-card panel-default">
        <div className="panel-heading">
          <div className="panel-title">{props.titleText}</div>
          <i className={props.titleIcon} aria-hidden="true" style={{ color: '#2f4050' }}></i>
        </div>
        <div className="panel-body  text-center">
          <div className="row col-with-divider">
          { _.map(props.items, (item, key) => {
              const className = item.className ? (itemclass + ' ' + item.className) : itemclass
              return (
                <div className={className} key={key}>
                  <h1>{item.value}</h1>
                  <small>{item.label}</small>
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
    </div>
  )
}

const DashboardRow = (props) => {
  const cardCount = _.size(props.cards)
  let className = 'col-xs-12 col-sm-12 col-md-12 col-lg-12'
  if (cardCount === 2) {
    className = 'col-xs-12 col-sm-6 col-md-6 col-lg-6'
  } else if (cardCount > 2) {
    className = 'col-xs-6 col-sm-4 col-md-4 col-lg-3'
  }
  return (
    <div className="row">
    { _.map(props.cards, (item, key) => (<SummaryCard key={key} className={className} {...item} />)) }
    </div>
  )
}

const defaultData = {
  row1: {
    cards: {
      facility: {
        titleText: 'Directories', // Facilities
        titleIcon: 'fa fa-2x fa-building',
        items: {
          enterprise: {
            value: 'TBD',
            label: 'Enterprises'
          },
          venue: {
            value: 'TBD',
            label: 'Venues'
          },
          building: {
            value: 'TBD',
            label: 'Buildings'
          },
          floor: {
            value: 'TBD',
            label: 'Floors'
          },
          room: {
            value: 'TBD',
            label: 'Rooms'
          }
        }
      },
      people: {
        titleText: 'Users', // People
        titleIcon: 'fa fa-2x fa-users',
        items: {
          senior: {
            value: 'TBD',
            label: 'Residents'
          },
          caregiver: {
            value: 'TBD',
            label: 'Care Givers'
          },
          subscriber: {
            value: 'TBD',
            label: 'Subscribers'
          },
          admin: {
            value: 'TBD',
            label: 'Administrators'
          },
          station: {
              value: 'TBD',
              label: 'Stations'
          }
        }
      }
    }
  },
  row2: {
    cards: {
      sensor: {
        titleText: 'Sensor', //RockBands
        titleIcon: 'fa fa-2x fa-heartbeat',
        items: {
          connected: {
            value: 'TBD',
            label: 'Connected',
            className: 'text-success'
          },
          disconnected: {
            value: 'TBD',
            label: 'Disconnected',
            className: 'text-danger'
          }
        }
      },
      relay: {
        titleText: 'Smart Device', //RockBoxes
        titleIcon: 'fa fa-2x fa-cubes',
        items: {
          connected: {
            value: 'TBD',
            label: 'Connected',
            className: 'text-success'
          },
          disconnected: {
            value: 'TBD',
            label: 'Disconnected',
            className: 'text-danger'
          }
        }
      }
    }
  },
  row3: {
    cards: {
      alertByStatus: {
        titleText: 'Alerts By Status',
        titleIcon: 'fa fa-2x fa-bell',
        items: {
          init: {
            value: 'TBD',
            label: 'Init',
            className: 'text-danger'
          },
          pending: {
            value: 'TBD',
            label: 'Pending',
            className: 'text-warning'
          }
        }
      },
      alertByType: {
        titleText: 'Alerts By Type',
        titleIcon: 'fa fa-2x fa-bell',
        items: {
          sos: {
            value: 'TBD',
            label: 'SOS',
            className: 'text-danger'
          },
          highimpact: {
            value: 'TBD',
            label: 'High Impact',
            className: 'text-danger'
          },
          poweron: {
            value: 'TBD',
            label: 'Power On',
            className: 'text-warning'
          },
          poweroff: {
            value: 'TBD',
            label: 'Power Off',
            className: 'text-warning'
          },

          lowBattery: {
            value: 'TBD',
            label: 'Low Battery',
            className: 'text-warning'
          },
          
          breakAway: {
            value: 'TBD',
            label: 'Break Away',
            className: 'text-warning'
          },
          
         approaching: {
            value: 'TBD',
            label: 'Approaching',
            className: 'text-primary'
         },
         
         leaving: {
            value: 'TBD',
            label: 'Leaving',
            className: 'text-muted'
         },
         
         exitDoor: {
             value: 'TBD',
             label: 'Exit Door',
             className: 'text-primary'
          },
          
          missing: {
              value: 'TBD',
              label: 'Missing',
              className: 'text-primary'
           },
        }
      }
    }
  }
}

class SystemDashboard extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props)
    this.state = {
      data: _.cloneDeep(defaultData)
    }
  }

  componentDidMount() {
    //console.log('SystemDashboard componentDidMount()')
    // refresh every 10 second
    this.timer = setInterval(this._refresh, 10000)
    this._refresh()
  }

  componentWillUnmount() {
    //console.log('SystemDashboard componentWillUnmount()')
    clearInterval(this.timer)
  }

  componentWillReceiveProps(nextProps) {
    //console.log('SystemDashboard componentWillReceiveProps')
    this._refresh(nextProps)
  }

  _refresh  = (props) => {
    const { showToast } = this.context
    let url = '/bstream/api/v1/system/getSummary'
    let data = {}
    const thisProps = props ?  props : this.props
    if (thisProps.venueId) {
      url = '/bstream/api/v1/system/getSummaryByVenueId'
      data.venueId = thisProps.venueId
    } else if (thisProps.enterpriseId) {
      url = '/bstream/api/v1/system/getSummaryByEnterpriseId'
      data.enterpriseId = thisProps.enterpriseId
    }
    ApiSingleton.makeHttpRequest(
      'post',
      url,
      { dataType: 'json' },
      data
    ).then((data) => {
      this.setState({data: this._transformSystemSummary(data)})
    })
    .catch((err) => {
      this.setState({data: _.cloneDeep(defaultData)})
      showToast(`Failed to fetch system summary! Reason: ${err}`)
    })
  }

  _transformSystemSummary = (data) => {
    // console.log('testing', data)
    const oldData = _.cloneDeep(defaultData)
    _.forEach(oldData, (row, key) => {
      _.forEach(row.cards, (card, key) => {
        switch (key) {
          case 'facility':
            card.items.enterprise.value = data.numOfEnterprises
            card.items.venue.value = data.numOfVenues
            card.items.building.value = data.numOfBuildings
            card.items.floor.value = data.numOfFloors
            card.items.room.value = data.numOfRooms
            if (_.isUndefined(data.numOfEnterprises)) {
              delete card.items.enterprise
            }
            if (_.isUndefined(data.numOfVenues)) {
              delete card.items.venue
            }
            break
          case 'people':
            card.items.senior.value = data.numOfSeniors
            card.items.caregiver.value =  data.numOfCareGivers
            card.items.admin.value = data.numOfAdmins
            card.items.subscriber.value = data.numOfSubscribers
            card.items.station.value = data.numOfStations
            break
          case 'sensor':
            card.items.connected.value = data.numOfConnectedSensors
            card.items.disconnected.value = data.numOfDisconnectedSensors
            break
          case 'relay':
            card.items.connected.value = data.numOfConnectedRelays
            card.items.disconnected.value = data.numOfDisconnectedRelays
            break
          case 'alertByStatus':
            card.items.init.value = data.numOfInitAlerts
            card.items.pending.value = data.numOfPendingAlerts
            break
          case 'alertByType':
            card.items.sos.value = data.numOfInitSosAlerts +  data.numOfPendingSosAlerts
            card.items.highimpact.value = data.numOfInitHighImpactAlerts + data.numOfPendingHighImpactAlerts
            card.items.poweroff.value = data.numOfInitPowerOffAlerts + data.numOfPendingPowerOffAlerts
            card.items.poweron.value = data.numOfInitPowerOnAlerts + data.numOfPendingPowerOnAlerts
            card.items.breakAway.value = data.numOfInitBreakAwayAlerts + data.numOfPendingBreakAwayAlerts
            card.items.lowBattery.value = data.numOfInitLowBatteryAlerts + data.numOfPendingLowBatteryAlerts
            card.items.approaching.value = data.numOfInitApproachingAlerts + data.numOfPendingApproachingAlerts
            card.items.leaving.value = data.numOfInitLeavingAlerts + data.numOfPendingLeavingAlerts
            card.items.exitDoor.value = data.numOfInitExitDoorAlerts + data.numOfPendingExitDoorAlerts
            card.items.missing.value = data.numOfInitMissingAlerts + data.numOfPendingMissingAlerts
        }
      })
    })
    return oldData
  }

  render() {
    const state = this.state
    //console.log('SystemDashboard: render() state: ', state)
    return (
      <div>
      { _.map(state.data, (row, key) => {
          return <DashboardRow key={key} {...row} />
        })
      }
      </div>
    )
  }
}

export default SystemDashboard
