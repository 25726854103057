import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import FacilitySelector from '../../components/FacilitySelector'
import FloorMap from '../../components/FloorMap'
import SensorLocationTable from './SensorLocationTable'
import SensorAlertTable from './SensorAlertTable'

import _ from 'lodash'
import moment from 'moment'
import { ToastContext } from '../../context/ToastContext'
import ApiSingleton from '../../utils/Axios'

class Dashboard extends React.Component {
  static contextType = ToastContext
    constructor(props) {
        super(props)
        this._fetchStations = this._fetchStations.bind(this)
        this.state ={
            relays: [],
            sensors: [],
            sensorAlerts: [],
            timer: null,
            stations: [],
            stationIds: [],
            query: ''
        }
  }

  _refreshResume = () => {
	  if (!this.state.timer) {
		  var timer = setInterval(this._refresh, 10000)
		  this.setState({timer:timer})
	  }
  }
  
  _refreshSuspend = () => {
	  if (this.state.timer) {
		clearInterval(this.state.timer)
		this.setState({timer:null})
	  }	  
  }
  
  componentDidMount() {
    // refresh every 10 second
	  this._refreshResume()
  }

  componentWillUnmount() {
	this._refreshSuspend()
  }

//  componentWillReceiveProps(nextProps) {
////    console.log('Dashboard.componentWillReceiveProps() nextProps = ', nextProps)
//    const floorId = nextProps.floorId
//    if (floorId !== this.state.floorId) {
//      this._refresh(floorId)
//    }
//  }

  _onFacilityFilterChange = (filter) => {
    //console.log('Dashboard _onFacilityFilterChange(filter): filter = ', filter)
    this.setState({
      floorId: filter.floorId
    })
    this._refresh(filter.floorId)
  }

  _refresh = (currentFloorId) => {
    const floorId = currentFloorId ? currentFloorId : this.state.floorId
    if (!floorId) {
//      console.log('dashboard has no floor specified.')
      return
    }
    if (this.state.expandedSensorAlert) {
//      console.log('dashboard has alert selected, skip refresh')
      return
    }
    this._fetchFloorById(floorId)
    this._fetchSensors(floorId)
//    this._fetchSensorLocations(floorId)
    this._fetchSensorAlerts(floorId)
  }

  _fetchFloorById = (floorId) => {
    const { showToast } = this.context
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/floor/findById",
      { dataType: "json", id: floorId },
      {}
    ).then((data) => {
      this.setState({floor: data})
      this._fetchStations(data['buildingId'])
    }, (err) => {
      this.setState({floor: null})
      showToast('Failed to fetch floor by ID! Reason: ', err)
    })
  }


    _fetchStations(buildingId) {
        if(buildingId != this.state.buildingId) {
            this.setState({
                buildingId: buildingId,
                stations: [],
                stationIds: []
            })
        }
        ApiSingleton.makeHttpRequest(
          "post",
          "/bstream/api/v1/station/find/",
          { dataType: "json" },
          {buildingId: buildingId}
        ).then((data) => {
            _.reduce(data, (o, v) => {
                if(this._transformStation(v) !== null) {
                    this.setState((state) => {
                        return {
                            stations: state.stations.includes(v.name) ? state.stations : state.stations.concat(v.name),
                            stationIds: state.stationIds.includes(v.id) ? state.stationIds : state.stationIds.concat(v.id)

                        }
                    })
                }
            }, [])
            // console.log('stations', this.state.stations)
            // console.log('stationIds', this.state.stationIds)

            // this.setState({
            //     stations: stations,
            //     stationIds: stationIds
            // })
        }, (err) => {
        })
    }

    _handleSearch = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const query = e.target.search.value
        this.setState({
            query: query
        })
    }
    _handleClear = () => {
        this.setState({
            query : ''
        })
    }
    _handleChange = (e) => {
        const query = e.target.value
        this.setState({
            query: query
        })
    }

    _transformStation(v) {
        const station = {
            id: v.id ? v.id : null,
            name: v.description ? v.description : null
        }
        return station.name == null ? null : station
    }
  _transformSensor(v) {
    const sensor = {
      createUtc: moment.utc(v.createUtc),
      sensorX: (v.x) ? v.x : 0,
      sensorY: (v.y) ? v.y : 0,
      senior: v.senior,
      seniorName: v.senior ? v.senior.firstName + ' ' + v.senior.lastName: 'unknown',
      roomName: v.lastRoom ? v.lastRoom.name : 'unknown',
      alerts: []
    }

    if (v.highImpactAlertTriggered) {
      sensor.alerts.push({type:'HIGH_IMPACT', when:v.highImpactAlertUtc})
    }
    if (v.sosAlertTriggered) {
      sensor.alerts.push({type:'SOS', when:v.sosAlertUtc})
    }
    if (v.powerOffAlertTriggered) {
      sensor.alerts.push({type:'POWER_OFF', when:v.powerOffAlertUtc})
    }
    if(v.lowBatteryAlertTriggered) {
      sensor.alerts.push({type: 'LOW_BATTERY', when: v.lowBatteryAlertUtc})
    }
    if (v.breakAwayAlertTriggered) {
      sensor.alerts.push({type:'BREAK_AWAY', when:v.breakAwayAlertUtc})
    }
    if (v.missingAlertTriggered) {
      sensor.alerts.push({type:'MISSING', when:v.lastSeenUtc})
    }
    if(v.approachingAlertTriggered) {
        sensor.alerts.push({type: 'APPROACHING', when: v.lastSeenUtc})
    }

    if(v.leavingAlertTriggered) {
        sensor.alerts.push({type: 'LEAVING', when: v.lastSeenUtc})
    }

    if(v.exitDoorAlertTriggered) {
        sensor.alerts.push({type: 'EXIT_DOOR', when: v.lastSeenUtc})
    }

    return sensor
  }

  _transformSensorAlert(v) {
    const alert = {
      id: v.id,
      description: v.description,
      alertType: v.alertType,
      alertStatus: v.alertStatus,
      createUtc: moment.utc(v.createUtc),
//      sensorId: v.sensorId,
//      sensorName: v.sensor ? v.sensor.name : 'unknown',
//      sensorX: (v.sensor && v.sensor.x) ? v.sensor.x : 0,
//      sensorY: (v.sensor && v.sensor.y) ? v.sensor.y : 0,
      seniorName: v.senior ? v.senior.firstName + ' ' + v.senior.lastName: 'unknown',
      roomName: v.room ? v.room.name : 'unknown',
      sensorAlertNoteList: this._transformSensorAlertNoteList(v.sensorAlertNoteList)
    }

    return alert
  }

  _transformSensorAlertNoteList(list) {
    return list.map((v) => {
      if (v.adminId) {
        v.who = v.admin.userName
      } else if (v.careGiverId) {
        v.who = v.careGiver.userName
      }
      return v
    })
  }

  _fetchSensors(floorId) {
    const { showToast } = this.context
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/sensor/findByFloorId",
      { dataType: "json" },
      { floorId: floorId, enabled:true }
    ).then((data) => {
      data = _.reduce(_.reverse(_.sortBy(data, 'createUtc')), (o, v, k) => {
        o.push(this._transformSensor(v))
        return o
      }, [])
      this.setState({
        sensors: data
      })
    }, (err) => {
      showToast('Failed to fetch list of RockBand by floor! Reason: ' + err)
    })
  }

  _fetchSensorAlerts(floorId) {
  const { showToast } = this.context
	if (this.state.sensorAlertsLoading) {
	  return
	}
    	const select = [
	  'id', 
	  'description',
	  'alertType',
	  'alertStatus',
	  'createUtc',
	  'sensorId',
	  'sensor:name',
	  'sensor:x',
	  'sensor:y',
	  'senior:firstName',
	  'senior:lastName',
	  'room:name',
	  'sensorAlertNoteList']
	      
	 const postData = {floorId: floorId, noAlertStatus: "DONE", select:JSON.stringify(select), maxResults:100}
	 this.setState({sensorAlertsLoading: true})
   ApiSingleton.makeHttpRequest(
    "post",
    "/bstream/api/v1/sensorAlert/findWithSelect",
    { dataType: "json" },
    postData
  ).then((data) => {
	   const now = Date.now()
	   data = _.reduce(data, (o, v, k) => {
	     // sort record based on relayId and sensorId pair
	     o.push(this._transformSensorAlert(v))
	     return o
	   }, [])

	   data = _.reverse(_.sortBy(data, 'createUtc'))
       this.setState({ sensorAlerts: data, sensorAlertsLoading: false })
	 }, (err) => {
	   showToast('Failed to fetch list of RockBand Alerts by floor! Reason: ' + err)
	   this.setState({ sensorAlerts: null, sensorAlertsLoading: false })
    })
  }

  _handleSensorAlertRowSelect = (row, isSelected) => {
    this.setState({expandedSensorAlert : isSelected ?  row :  null})
  }

  render() {
    const props = this.props,
          state = this.state
//    console.log('Dashboard.render props = ', props, ', State = ', state)
    return (
      <div>
          <Row>
              <form role="search" autoComplete="off" onSubmit={ (e) => e.preventDefault()} style={{marginBottom: 10}}>
                      <div className="input-group col-lg-5">
                          <input type="text" placeholder="Search for something..." className="form-control"
                                     name="search" onChange={(e) => {this._handleChange(e)}} value={this.state.query} />
                          <span className="input-group-addon">
                                    <span className="glyphicon glyphicon-remove" onClick={this._handleClear}></span>
                          </span>
                      </div>
              </form>
          </Row>
          <Row>
              <FacilitySelector
                  fixedFilterType='Floor'
                  onChange={ this._onFacilityFilterChange }
              />
          </Row>
        <Row>
          <FloorMap
            width={ props.width }
            height={ props.height }
            floor={state.floor }
            relays={ state.relays }
            sensors={ state.sensors }
            stations={this.state.stations}
            stationIds={this.state.stationIds}
            show={ false }
            query={ this.state.query }
          />
        </Row>
          <br/>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <div className="panel summary-card panel-default">
              <div className="panel-heading">
                  <div className="panel-title">Residents</div>
                  <i className='fa fa-map-marker' aria-hidden="true"></i>
              </div>
              <div className="panel-body">
                <SensorLocationTable
                    height='320px'
                    data={ state.sensors }
                    query={ state.query }
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <div className="panel summary-card panel-default">
              <div className="panel-heading">
                  <div className="panel-title">Alerts</div>
                  <i className='fa fa-bell' aria-hidden="true" ></i>
              </div>
              <div className="panel-body">
                <SensorAlertTable height='320px'
                  data={ state.sensorAlerts }
                  handleRowSelect={this._handleSensorAlertRowSelect}
                  refresh={this._refresh.bind(this)}
                  refreshResume={this._refreshResume}
                  refreshSuspend={this._refreshSuspend}
                  query={ state.query }
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

}

export default Dashboard
