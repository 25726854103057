import React from 'react'
import FormGroup from 'react-bootstrap/FormGroup'
import { FormLabel }from "react-bootstrap"
import Col from 'react-bootstrap/Col'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'

import CellEditor from './CellEditor'
import CellFormatter from './CellFormatter'

const _ = require('lodash')

const createCellEditor = (onUpdate, props) => (
  <CellEditor onUpdate={ onUpdate } {...props}/>
)

class FormGroupTable extends React.Component {
  constructor(props) {
    super(props)
    const data = this.props.data ? this.props.data : []
    this.state = {
      data: _.map(data, _.clone),
      selectedRowIds: []
    }
  }

  _onAdd = () => {
    const data = [].concat(this.state.data)
    data.push({})
    this.setState({
      data: data
    })
    this.props.onChange(this.props.controlId, this.props.required, this.props.validator, data)
  }

  _onDelete = () => {
    if (this.state.selectedRowIds.length === 0) {
      return
    }
    const data = [].concat(this.state.data)
    _.remove(data, (row) => {
      return _.indexOf(this.state.selectedRowIds, row.id) !== -1
    })
    this.setState({data: data})
    this.props.onChange(this.props.controlId, this.props.required, this.props.validator, data)
  }

  _onSelect = (row, isSelected, e) => {
    const selectedRowIds = [].concat(this.state.selectedRowIds)
    if (isSelected) {
      selectedRowIds.push(row.id)
    } else {
      _.remove(selectedRowIds, (id) => {
        return (id === row.id)
      })
    }
    this.setState({selectedRowIds: selectedRowIds})
  }

  render() {
    const props = this.props,
          state = this.state,
          labelWidth = props.labelWidth ? props.labelWidth : 3,
          valueWidth = props.valueWidth ? props.valueWidth : 7,
          validationWidth = 12 - labelWidth - valueWidth
    return (
      <FormGroup
        controlId={ props.controlId }
        validationState={ props.validationState.status }>
        <Col sm={ labelWidth }>
          <FormLabel>{ props.label }</FormLabel>
        </Col>
        <Col sm={ valueWidth + validationWidth }>
          <div className='form-table'>
            <FormGroupTableToolBar onAdd={ this._onAdd } onDelete={ this._onDelete } selectedRowIds={ state.selectedRowIds }/>
            <BootstrapTable striped hover condensed
              maxHeight={ 120 }
              data={ state.data }
              cellEdit={ {
                mode: 'click' // click cell to edit
              } }
              selectRow={ {
                mode: 'checkbox',  // multi select
                onSelect: this._onSelect
              } }
              options={ {
                onSortChange: props.onSortChange,
                paginationShowsTotal: props.renderShowsTotal,
              } } >
            { props.columnProps.map((column) => (
                <TableHeaderColumn
                  key={column.config.dataField}
                  {...column.config}
                  customEditor={ {
                    getElement: createCellEditor,
                    customEditorParameters: { required: column.config.required, validator: column.config.validator }
                  } }
                  dataFormat={ CellFormatter }
                  formatExtraData={ {
                    required: column.config.required,
                    validator: column.config.validator
                  } }
                >{column.label}</TableHeaderColumn>
              ))
            }
            </BootstrapTable>
          </div>
        </Col>
        <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
          <p>{ props.validationState.message }</p>
        </Col>
        <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
          <p>{ props.helpText }</p>
        </Col>
      </FormGroup>
    )
  }
}

const FormGroupTableToolBar = (props) => {
  return (
    <div className='table-toolbar'>
      <ButtonGroup>
        <Button onClick={ props.onAdd }>
          <span className='fa fa-plus' aria-hidden='true'></span>
        </Button>
        <Button onClick={ props.onDelete } disabled={ props.selectedRowIds.length === 0 }>
          <span className='fa fa-remove' aria-hidden='true'></span>
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default FormGroupTable
