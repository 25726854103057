import React, { useState } from "react";
import FormGroup from "react-bootstrap/FormGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CheckboxGroup from "react-checkbox-group";

class FormGroupCheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  _onChange = (value) => {
    this.setState({
      value: value,
    });
    this.props.onChange(
      this.props.controlId,
      this.props.required,
      this.props.validator,
      value
    );
  };

  render() {
    const props = this.props,
      state = this.state,
      labelWidth = props.labelWidth ? props.labelWidth : 3,
      valueWidth = props.valueWidth ? props.valueWidth : 7,
      validationWidth = 12 - labelWidth - valueWidth;
    return (
      <Form.Group
        as={Row}
        className="mb-3"
        controlId={props.controlId}
        validationState={props.validationState.status}
      >
        <Col sm={labelWidth}>
          <ControlLabel>{props.label}</ControlLabel>
        </Col>
        <CheckboxGroup
          className="col-sm-7"
          name={props.controlId}
          value={state.value}
          onChange={this._onChange}
        >
          {props.options.map((option, index) => {
            if (props.inline) {
              return (
                <label
                  key={props.controlId + index}
                  className="checkbox-inline"
                >
                  <Checkbox value={option.value} /> {option.label}
                </label>
              );
            } else {
              return (
                <div key={props.controlId + index} className="checkbox">
                  <Checkbox value={option.value} /> {option.label}
                </div>
              );
            }
          })}
        </CheckboxGroup>
        <Col sm={validationWidth}>
          <HelpBlock>{props.validationState.message}</HelpBlock>
        </Col>
        <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
          <HelpBlock>{props.helpText}</HelpBlock>
        </Col>
      </Form.Group>
    );
  }
}

export default FormGroupCheckboxGroup;
