import React from 'react'
import { Rnd } from 'react-rnd'

const style = {
  textAlign: 'center',
  border: 'none'
}

const turnOffResize = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false
}
class RelayPosition extends React.Component {
  constructor(props) {
    super(props)

    if (props.value) {
      this.state = {
        position: {
          x: props.value.x,
          y: props.value.y
        }
      }
    } else {
      this.state = {
        position: {
          x: 0,
          y: 0
        }
      }
    }
  }

  _onDragStop = (event, ui) => {
    const newPosition = {...this.state.position}
    newPosition.x = ui.position.left
    newPosition.y = ui.position.top
    this.setState({position: newPosition})

    if (this.props.onChange) {
      this.props.onChange(newPosition)
    }
  }

  render() {
    const props = this.props, state = this.state
    const position = this.state.position
//    const svg = React.DOM.svg
//    const image = React.DOM.image
    const rect = props.roomCoordinates ? {
        x: props.roomCoordinates[0].x,
        y: props.roomCoordinates[0].y,
        width: props.roomCoordinates[1].x - props.roomCoordinates[0].x,
        height: props.roomCoordinates[1].y - props.roomCoordinates[0].y
      } : null
    return (
      <div style={{position: 'relative'}}>
        <div>
          <svg width={ props.imageWidth } height={ props.imageHeight }>
            <image
              xlinkHref={ props.imageContent }
              x={ 0 }
              y={ 0 }
              width={ props.imageWidth }
              height={ props.imageHeight }
            />
            {(() => {
              if (rect) {
                return (
                  <rect
                    x={rect.x}
                    y={rect.y}
                    width={rect.width}
                    height={rect.height}
                    stroke='red'
                    strokeWidth={3}
                    fill='none' />
                )
              }
            })()}
          </svg>
        </div>
        <Rnd
          ref={c => { this.rnd = c }}
          initial={{
            x: state.position.x,
            y: state.position.y
          }}
          style={ style }
          zIndex={ 99 }
          isResizable = { turnOffResize }
          onDragStop={ this._onDragStop }>
            <i className="fa fa-cube fa-lg text-success" aria-hidden="true"></i>
        </Rnd>
      </div>
    )
  }
}

export default RelayPosition
