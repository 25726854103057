/* global $ */
import React from 'react'
import ApproachingWhiteList from "../../routes/GroupPolicy/ApproachingWhiteList";
import LeavingWhiteList from "../../routes/GroupPolicy/LeavingWhiteList";

class FormGroupPolicyList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            enterpriseId: props.data ? props.data.enterpriseId : props.enterpriseId,
        }
        this.relayId = null
    }

    handleEnterpriseChange(enterpriseId) {
        this.setState ({
            enterpriseId: enterpriseId
        })
    }
    render() {
        const props = this.props
        if(props.controlId === 'approachingWhiteList') {
            const data = props.data.approachingWhiteList
            return (
                <div className="panel summary-card panel-default" >
                    <div className="panel-heading">
                        <div className="panel-title">Approaching White List</div>
                    </div>
                    <div className="panel-body">
                        <ApproachingWhiteList
                            data = {(!data || data.length === 0) ? [] : _.castArray(data)}
                            handleEnterpriseChange={this.handleEnterpriseChange}
                            enterpriseId={this.state.enterpriseId}
                            groupPolicyId={this.props.data ? this.props.data.id : null}
                            controlId={this.props.controlId}
                        />
                    </div>
                </div>
            )
        } else {
            const data = props.data.leavingWhiteList
            return (
                <div className="panel summary-card panel-default" >
                    <div className="panel-heading">
                        <div className="panel-title">Leaving White List</div>
                    </div>
                    <div className="panel-body">
                        <LeavingWhiteList
                            data = {(!data || data.length === 0) ? [] : _.castArray(data)}
                            handleEnterpriseChange={this.handleEnterpriseChange}
                            enterpriseId={this.state.enterpriseId}
                            groupPolicyId={this.props.data ? this.props.data.id : null}
                            controlId={this.props.controlId}
                        />
                    </div>
                </div>
            )
        }
    }
}

export default FormGroupPolicyList