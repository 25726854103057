import React from "react";
import Form from "react-bootstrap/Form";
import FormGroupInput from "./FormGroupInput";
import FormGroupRadioGroup from "./FormGroupRadioGroup";
import FormGroupCheckboxGroup from "./FormGroupCheckboxGroup";
import FormGroupCheckbox from "./FormGroupCheckbox";
import FormGroupSelect from "./FormGroupSelect";
import FormGroupImage from "./FormGroupImage";
import FormGroupRoomPosition from "./FormGroupRoomPosition";
import FormGroupRelayPosition from "./FormGroupRelayPosition";
import FormGroupTable from "./FormGroupTable";
import FormGroupCity from "./FormGroupCity";
import FormGroupBuilding from "./FormGroupBuilding";
import FormGroupRoom from "./FormGroupRoom";
import FormGroupRoomWithStation from "./FormGroupRoomWithStation";
import FormGroupBuildingWithStation from "./FormGroupBuildingWithStation";
import FormGroupSenior from "./FormGroupSenior";
import FormGroupSeniorWithBuilding from "./FormGroupSeniorWithBuilding";
import FormGroupStatic from "./FormGroupStatic";
import FormGroupReadOnlyTable from "./FormGroupReadOnlyTable";
import FormGroupTimeZone from "./FormGroupTimeZone";
import FormGroupPolicy from "./FormGroupPolicy";
import FormGroupPolicyList from "./FormGroupPolicyList";
import FormGroupEnterprise from "./FormGroupEnterprise";
import FormGroupRelayList from "./FormGroupRelayList";
import FormGroupPolicyAlert from "./FormGroupPolicyAlert";
import FormUploadAudio from "./FormUploadAudio";
import FormUploadAlert from "./FormUploadAlert";
import FormCaregiverBands from "./FormCaregiverBands";

export const BaseForm = (props) => {
  const labelWidth = props.labelWidth ? props.labelWidth : 3,
    valueWidth = props.valueWidth ? props.valueWidth : 7;
    
  return (
    <Form>
      {props.fields &&
        props.fields.map((field) => {
          if (field.type === "radiogroup") {
            return (
              <FormGroupRadioGroup
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={
                  props.data[field.controlId]
                    ? props.data[field.controlId]
                    : field.value
                }
                validationState={props.validationState[field.controlId]}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "checkboxgroup") {
            return (
              <FormGroupCheckboxGroup
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data[field.controlId]}
                validationState={props.validationState[field.controlId]}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "select") {
            return (
              <FormGroupSelect
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data[field.controlId]}
                validationState={props.validationState[field.controlId]}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "checkbox") {
            return (
              <FormGroupCheckbox
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data[field.controlId]}
                validationState={props.validationState[field.controlId]}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "image") {
            return (
              <FormGroupImage
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data[field.controlId]}
                validationState={props.validationState[field.controlId]}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "room_position") {
            return (
              <FormGroupRoomPosition
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data[field.controlId]}
                data={props.data}
                validationState={props.validationState[field.controlId]}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "relay_position") {
            return (
              <FormGroupRelayPosition
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data[field.controlId]}
                data={props.data}
                validationState={props.validationState[field.controlId]}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "table") {
            return (
              <FormGroupTable
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data[field.controlId]}
                validationState={props.validationState[field.controlId]}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "city") {
            return (
              <FormGroupCity
                key={"city"}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data}
                validationState={props.validationState}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "time_zone") {
            return (
              <FormGroupTimeZone
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data}
                validationState={props.validationState}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "room") {
            return (
              <FormGroupRoom
                key={"room"}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data}
                validationState={props.validationState}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "room_with_station") {
            return (
              <FormGroupRoomWithStation
                key={"room_with_station"}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data}
                validationState={props.validationState}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "room_with_relay") {
            return (
              <FormGroupRoom
                key={"room_with_relay"}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                placeRelay={true}
                value={props.data}
                validationState={props.validationState}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "senior") {
            return (
              <FormGroupSenior
                key={"senior"}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data}
                validationState={props.validationState}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "senior_with_building") {
            return (
              <FormGroupSeniorWithBuilding
                key={"senior_with_building"}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data}
                validationState={props.validationState}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "building") {
            return (
              <FormGroupBuilding
                key={"building"}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data}
                validationState={props.validationState}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "building_with_station") {
            return (
              <FormGroupBuildingWithStation
                key={"building_with_station"}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data}
                validationState={props.validationState}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "static") {
            return (
              <FormGroupStatic
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data[field.controlId]}
                valueRender={field.valueRender}
              />
            );
          } else if (field.type === "readonly-table") {
            return (
              <FormGroupReadOnlyTable
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data[field.controlId]}
                valueRender={field.valueRender}
              />
            );
          } else if (field.type === "group_policy") {
            return (
              <FormGroupPolicy
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value0={props.data[field.fields[0].controlId]}
                value1={props.data[field.fields[1].controlId]}
                value2={props.data[field.fields[2].controlId]}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "group_policy_alert") {
            return <FormGroupPolicyAlert key={field.controlId} {...field} />;
          } else if (field.type === "enterprise") {
            return (
              <FormGroupEnterprise
                key={field.controlId}
                {...field}
                onChange={props.onChange}
                enterprise={props.data ? props.data.enterprise : null}
              />
            );
          } else if (field.type === "relay_list") {
            return (
              <FormGroupRelayList
                key={field.controlId}
                {...field}
                onChange={props.onChange}
              />
            );
          } else if (field.type === "group_policy_list") {
            return (
              <FormGroupPolicyList
                key={field.controlId}
                {...field}
                data={props && props.data ? props.data : {}}
              />
            );
          } else if (field.type === "upload_audio") {
            const urlEntity = field.urlEntity;
            return (
              <FormUploadAudio
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={
                  props.data && props.data["audioList"]
                    ? props.data["audioList"][0]
                    : null
                }
                audioId={
                  props.data &&
                  props.data["audioList"] &&
                  props.data["audioList"][0]
                    ? props.data["audioList"][0].id
                    : null
                }
                id={props.data ? props.data.id : null}
                onChange={props.onChange}
                urlEntity={urlEntity}
              />
            );
          } else if (field.type === "alertAudio") {
            return (
              <FormUploadAlert
                key={"alertAudio"}
                {...field}
                enterpriseId={props.data ? props.data.id : null}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
              />
            );
          } else if (field.type === "caregiver_rockbands") {
            return (
              <FormCaregiverBands
                key={"caregiver_rockbands"}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                sensors={props.data ? props.data.sensorList : []}
              />
            );
          } else {
            // input field
            return (
              <FormGroupInput
                key={field.controlId}
                {...field}
                labelWidth={labelWidth}
                valueWidth={valueWidth}
                value={props.data[field.controlId]}
                validationState={props.validationState[field.controlId]}
                onChange={props.onChange}
                onBlur={props.onBlur}
              />
            );
          }
        })}
    </Form>
  );
};

export default BaseForm;
