import React from 'react'
import moment from 'moment'
import Badge from 'react-bootstrap/Badge'
import BaseTable from './BaseTable'
import reactStringReplace from 'react-string-replace'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Cell} from 'recharts';
export const enabledFormatter = (cell, row) => {
  if (cell) {
    return <i className='fas fa-check-square' aria-hidden='true'></i>
  } else {
    return <i className='far fa-square' aria-hidden='true'></i>
  }
}

export const timestampFormatter = (cell, row, formatExtraData) => {
  if (_.isUndefined(cell) || _.isNull(cell)) {
    return null
  }
  let time = ""
  if (_.isString(cell)) {
    time = moment(cell).local().format("YYYY/MM/DD HH:mm:ss")
  } else {
    time = cell.local().format("YYYY/MM/DD HH:mm:ss")
  }
  if(formatExtraData) {
      const query = formatExtraData
      const content = time
      return (
          <div>
              {reactStringReplace(content, query, (match, i) => (
                  <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
              ))}
          </div>
      )
    }
    return (<p>{time}</p>)
}

export const temperatureFormatter = (cell, row, formatExtraData) => {
    if (_.isUndefined(cell) || _.isNull(cell)) {
        return ""
    }
    if(!Array.isArray(cell) || cell.length === 0) {
        return ""
    }
    const unit = cell[0].unit
    const CustomizedLabel = (props) => {
        const {x, y, last, value, index} = props;
        if(last - 1 === index) {
            return (
                <text x={x} y={y - 5} dy={-4} fill={'#337ab7'} fontSize={15} textAnchor="middle">{value.toFixed(1)}{unit}</text>
            )
        } else {
            return null
        }
    }

    const CustomizedDot = (props) => {
        const {cx, cy, last, index} = props;
        if(last - 1 === index) {
            return (
                <circle cx={cx} cy={cy} r={4} stroke="#337ab7" fill={'none'} />
            )
        } else {
            return null
        }
    }
    return (
       <div style={{width: 800, height: 150, marginLeft: -20}}>
           <ResponsiveContainer width={'100%'}>
               <LineChart
               data={cell}>
               <XAxis
                   domain={[() => moment().subtract('33', 'minutes').utc(), () => moment().utc()]}
                   dataKey="createUtc"
                   tickFormatter={(tick) => moment(tick).local().format('HH:mm')}
                   padding={{ left: 20, top: 20, right: 20 }}
               />
               <YAxis
                   domain={['dataMin - 3', 'dataMax + 3']}
                   type="number"
                   unit={unit}
                   tickFormatter={(tick) => tick.toFixed(1)}
               />
               <CartesianGrid
                   vertical={false}
               />
               <Tooltip  wrapperStyle={{ opacity: 0.6 }}
                         labelStyle={{ color: "#337ab7" }}
                         formatter={function(value, name) {
                             return `${value.toFixed(1)}${unit}`;
                         }}
                         labelFormatter={function(value) {
                             return `time: ${moment(value).format('hh:mm:ss')}`;
                         }}

               />

                   <Legend margin={{ top : 5}}/>
               <Line type='monotone'
                     dataKey="temperature"
                     dot={<CustomizedDot last={cell.length}/>}
                     label={<CustomizedLabel last={cell.length}/>}/>
               </LineChart>
           </ResponsiveContainer>
       </div>
    );

}

export const activityFormatter = (cell, row, formatExtraData) => {
    if (_.isUndefined(cell) || _.isNull(cell)) {
        return ""
    }
    if(!Array.isArray(cell) || cell.length === 0) {
        return ""
    }
    return (
        <div style={{width: 800, height: 300, marginLeft: -20}}>
            <ResponsiveContainer width={'100%'} height={80}>
                <LineChart
                    data={cell} >
                    <XAxis
                        dataKey="createUtc"
                        hide={true}
                        padding={{ left: 20, top: 20, right: 20 }}
                    />
                    <YAxis
                        domain={[dataMin => Math.min(0, dataMin), 'dataMax + 1']}
                        type="number"
                        allowDecimals={false}
                    />
                    <CartesianGrid
                        vertical={false}
                    />
                    <Tooltip
                        wrapperStyle={{ opacity: 0.8 }}
                        labelStyle={{ color: "#99ccff" }}
                        labelFormatter={function(value) {
                            return `time: ${moment(value).format('hh:mm:ss')}`;}}
                    />
                    <Legend />
                    <Line type="monotone" dataKey="more3G" stroke="#8884d8" dot={false} activeDot={{r : 4}}/>
                </LineChart>
            </ResponsiveContainer>
            <ResponsiveContainer width={'100%'} height={100}>
                <LineChart
                    data={cell}
                >
                    <XAxis
                        dataKey="createUtc"
                        padding={{ left: 20, top: 20, right: 20 }}
                        hide={true}
                    />
                    <YAxis
                        domain={[dataMin => Math.min(0, dataMin), 'dataMax + 1']}
                        type="number"
                        allowDecimals={false}
                    />
                    <CartesianGrid
                        vertical={false}
                    />
                    <Tooltip
                        wrapperStyle={{ opacity: 0.8 }}
                        labelStyle={{ color: "#99ccff" }}
                        labelFormatter={function(value) {
                            return `time: ${moment(value).format('hh:mm:ss')}`;}}
                    />
                    <Legend />
                    <Line type="monotone" dataKey="more2G" activeDot={{r : 6}} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <ResponsiveContainer width={'100%'} height={120}>
                <LineChart
                    data={cell}
                >
                    <XAxis
                        domain={[dataMin => moment(dataMin).subtract('30', 'seconds').utc(), 'dataMax']}
                        dataKey="createUtc"
                        tickFormatter={(tick) => moment(tick).local().format('HH:mm')}
                        padding={{ left: 20, top: 20, right: 20 }}
                    />
                    <YAxis
                        domain={[dataMin => Math.min(0, dataMin), 'dataMax + 1']}
                        type="number"
                        allowDecimals={false}
                    />
                    <CartesianGrid
                        vertical={false}
                    />
                    <Tooltip
                        wrapperStyle={{ opacity: 0.8 }}
                        labelStyle={{ color: "#99ccff" }}
                        labelFormatter={function(value) {
                            return `time: ${moment(value).format('hh:mm:ss')}`;}}
                    />
                    <Legend />

                    <Line type="monotone" dataKey="more1G" stroke="#82ca9d" activeDot={{r : 8}} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export const ecgFormatter = (cell, row, formatExtraData) => {
    if (_.isUndefined(cell) || _.isNull(cell)) {
        return ""
    }
    if(!Array.isArray(cell) || cell.length === 0) {
        return ""
    }
    console.info('cell', cell)
    
    return (
        <div style={{width: 800, height: 300, marginLeft: -20}}>
            <ResponsiveContainer width={'100%'} height={80}>
                <LineChart
                    data={cell} >
                    <XAxis                    	   
                        dataKey="createUtc"
                        hide={true}
                        padding={{ left: 20, top: 20, right: 20 }}
                    />
                    <YAxis
                        domain={[dataMin => Math.min(0, dataMin), dataMax => Math.ceil(dataMax)]}
                        type="number"
                        allowDecimals={false}
                    />
                    <CartesianGrid
                        vertical={false}
                    />
                    <Tooltip
                        wrapperStyle={{ opacity: 0.8 }}
                        labelStyle={{ color: "#99ccff" }}
                        labelFormatter={function(value) {
                            return `time: ${moment(value).format('hh:mm:ss')}`;}}
                    />
                    <Legend />
                    <Line name='Heart Rate' type="monotone" dataKey="heartRate" stroke="#8884d8" dot={false} activeDot={{r : 4}}/>
                </LineChart>
            </ResponsiveContainer>
            <ResponsiveContainer width={'100%'} height={100}>
                <LineChart
                    data={cell}
                >
                    <XAxis
                        dataKey="createUtc"
                        padding={{ left: 20, top: 20, right: 20 }}
                        hide={true}
                    />
                    <YAxis
                        domain={[dataMin => Math.min(0, dataMin), dataMax => Math.ceil(dataMax)]}
                        type="number"
                        allowDecimals={false}
                    />
                    <CartesianGrid
                        vertical={false}
                    />
                    <Tooltip
                        wrapperStyle={{ opacity: 0.8 }}
                        labelStyle={{ color: "#99ccff" }}
                        labelFormatter={function(value) {
                            return `time: ${moment(value).format('hh:mm:ss')}`;}}
                    />
                    <Legend />
                    <Line name='RR' type="monotone" dataKey="rr" activeDot={{r : 6}} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <ResponsiveContainer width={'100%'} height={120}>
                <LineChart
                    data={cell}
                >
                    <XAxis
                       // domain={[dataMin => moment(dataMin).subtract('30', 'seconds').utc(), 'dataMax']}
                        dataKey="createUtc"
                        tickFormatter={(tick) => moment(tick).local().format('mm:ss')}
                        padding={{ left: 20, top: 20, right: 20 }}
                    />
                    <YAxis
                        domain={[dataMin => Math.floor(dataMin), dataMax => Math.ceil(dataMax)]}
                        type="number"
                        allowDecimals={false}
                    />
                    <CartesianGrid
                        vertical={false}
                    />
                    <Tooltip
                        wrapperStyle={{ opacity: 0.8 }}
                        labelStyle={{ color: "#99ccff" }}
                        labelFormatter={function(value) {
                            return `time: ${moment(value).format('hh:mm:ss')}`;}}
                    />
                    <Legend />

                    <Line name='ECG' type="monotone" dataKey="ecgValue" stroke="#82ca9d" activeDot={{r : 8}} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export const timestampWithLapseFormatter = (cell, row, formatExtraData) => {
  if (_.isUndefined(cell) || _.isNull(cell)) {
    return ""
  }
  if (_.isString(cell)) {
    cell = moment(cell)
  }
  const query = formatExtraData,
        content = cell.local().format("YYYY/MM/DD HH:mm:ss") + ' (' + cell.toNow(true) + ' ago)'
    if(query != null) {
        return (
            <div>
                {reactStringReplace(content, query, (match, i) => (
                    <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
                ))}
            </div>)
    } else {
        return content
    }
}

export const embeddedNameFormatter = (cell, row, formatExtraData) => {
  if (_.isUndefined(cell) || _.isNull(cell)) {
    return ""
  }
    const query = formatExtraData
    const content = cell.name
    if(query != '') {
        return (
            <p>
                {reactStringReplace(content, query, (match, i) => (
                    <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
                ))}
            </p>
        )
    }
    return <p>{cell.name}</p>
}
export const peopleFormatter = (cell, row, formatExtraData) => {
    if(cell) {
        const query = formatExtraData
        const content = String(cell)
        if(query != '') {
            return (
                <div>
                    {reactStringReplace(content, query, (match, i) => (
                        <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
                    ))}
                </div>
            )
        }
        return (<p>{cell}</p>)
    } else {
        return null
    }
}

export const seniorFormatter = (cell, row, formatExtraData) => {
  if (_.isUndefined(cell) || _.isNull(cell)) {
    return ""
  }
  const query = formatExtraData
  const content = cell.firstName + ' ' + cell.lastName
    if(query != '') {
        return (
            <div>
                {reactStringReplace(content, query, (match, i) => (
                    <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
                ))}
            </div>
        )
    }
    return (<p>{cell.firstName + ' ' + cell.lastName}</p>)
}
export const sensorLocationFormatter = (cell, row, formatExtraData) => {
  if(cell) {
    const query = formatExtraData
    const content = String(cell)
    if(query != '') {
        return (
            <div>
                {reactStringReplace(content, query, (match, i) => (
                    <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
                ))}
            </div>
        )
    }
      return (<p>{cell}</p>)
  } else {
    return null
  }
}

export const sensorListFormatter = (cell, row, formatExtraData) => {
  if (_.isUndefined(cell) || _.isNull(cell)) {
    return ""
  }
  const query = formatExtraData
  let content = ''
  if (!_.isArray(cell)) {
    return ""
  }
  if (cell.length === 0) {
    return ""

  } else if (cell.length === 1) {
      content = cell[0].name
      if(query != '') {
          return (
              <div>
                  {reactStringReplace(content, query, (match, i) => (
                      <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
                  ))}
              </div>
          )
      } else {
          return (<span>{content}</span>)
      }
  } else {
      content = cell[0].name
      for(let i = 0; i < cell.length; i++) {
          if(cell[i].enabled) {
              content = cell[i].name
              break
          }
      }
      if(query != '') {
          return (
              <p>
                  {reactStringReplace(content, query, (match, i) => (
                      <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
                  ))}
                  <Badge>{cell.length}</Badge>
              </p>
          )
      } else {
          return (<span>{content}<Badge>{cell.length}</Badge></span>)
      }

  }
}

export const caregiverSensorListFormatter = (cell, row, formatExtraData) => {
    if (_.isUndefined(cell) || _.isNull(cell)) {
        return ""
    }
    if (!_.isArray(cell)) {
        return ""
    }
    if (cell.length === 0) {
        return ""
    }
    let content = cell[0].name
    let lowBattery = cell[0].batteryLevel < 10
    let breakAway = cell[0].breakAwayAlertTriggered
    for(let i = 0; i < cell.length; i++) {
        if(cell[i].enabled) {
            content = cell[i].name
            lowBattery = cell[i].batteryLevel < 10
            breakAway = cell[i].breakAwayAlertTriggered
            break
        }
    }

    let lowBatteryIcon = '',
        breakAwayIcon = ''
    if(lowBattery) {
        lowBatteryIcon = 'fa fa-battery-1'
    }
    if(breakAway) {
        breakAwayIcon = 'fa fa-chain-broken'
    }
    return (<span>{content}{lowBattery && <i className={lowBatteryIcon} style={{color: 'red'}}></i>}
        {breakAway && <i className={breakAwayIcon} style={{color: 'red'}}></i>}
            </span>)
}
export const expandableRelay = (cell, row, formatExtraData) => {
  if(cell) {
      const query = formatExtraData
      const content = String(cell)
      if(query != '') {
          if (row.items.length > 1) {
              return (
                  <span>
                      {reactStringReplace(content, query, (match, i) => (
                          <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
                      ))}
                          <Badge> {row.items.length} </Badge>
                  </span>
              )
          } else {
              return(
                  <div>
                      {reactStringReplace(content, query, (match, i) => (
                          <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
                        )
                    )}
                </div>)
          }
      }
      if (row.items.length > 1) {
          return (<span> {cell} <Badge>{row.items.length}</Badge> </span>)
      } else {
          return (<span>{cell}</span>)
      }
  }
}

//const toggleAlertNote = (rowId, e) => {
//  const text = e.target.innerHTML
//  if (text === 'Show more') {
//    $('#notes_'+rowId).show()
//    e.target.innerHTML = 'Show less'
//  } else {
//    $('#notes_'+rowId).hide()
//    e.target.innerHTML = 'Show more'
//  }
//}

//export const expandableAlertNotes = (cell, row) => {
//  if (cell.length === 0) {
//    return null
//  } else if (cell.length === 1) {
//    return (<div>{cell[0].description}</div>)
//  } else {
//    return (
//      <div>
//        <div>
//          { cell[0].description }
//        </div>
//        <div id={'notes_' + row.id} style={{display: 'none'}}>
//          { cell.slice(1).map((note) => (<div key={note.id}>{note.description}</div>)) }
//        </div>
//        <a className='alert-note-toggle' onClick={toggleAlertNote.bind(this, row.id)}>Show more</a>
//      </div>
//    )
//  }
//}

export const expandableAlertNotes = (cell, row) => {
  if (cell && cell.length > 0) {
    return (<span> <Badge>{cell.length}</Badge> </span>)
  } else {
    return null
  }
}
export const hasAudioFormatter = (cell, row) => {
    if(cell && Array.isArray(cell) && cell.length >= 1) {
        return <i className='far fa-check-square' aria-hidden='true'></i>
    } else {
        return <i className='far fa-square' aria-hidden='true'></i>
    }
}
export const missingPlace = (cell, row, formatExtraData) => {
    const content = cell, query = formatExtraData
    if(cell) {
        return (
            <p> {reactStringReplace(content, query, (match, i) => (
            <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
        ))}</p>)
    } else {
        return null
    }
}
export const MissingSensorNote = (cell, row, formatExtraData) => {
  const content = cell, query = formatExtraData
  if(cell) {
    return (
        <p> {reactStringReplace(content, query, (match, i) => (
        <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
    ))}</p>)
  } else {
    return null
  }
}
export const missingSeniorName = (cell, row, formatExtraData) => {
  const content = cell, query = formatExtraData
  if(cell) {
    return (
        <p> {reactStringReplace(content, query, (match, i) => (
        <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
    ))}</p>)
  } else {
    return null
  }
}
export const sensorAlertListFormatter = (cell, row) => {
  if (_.isUndefined(cell) || _.isNull(cell) || cell.length == 0) {
    return <span className='fa fa-check fa-lg text-success' aria-hidden="true"></span>
  }
  let index = 0
  const list = cell.map(function(alert) {
    index++
    const when = timestampFormatter(alert.when)
    const duration = moment(alert.when).toNow(true) + ' ago'
    let iconClass = ''
    switch (alert.type) {
      case 'SOS':
        iconClass = 'fa fa-ambulance fa-lg'
        break
      case 'HIGH_IMPACT':
        iconClass = 'fa fa-medkit fa-lg'
        break
      case 'POWER_OFF':
        iconClass = 'fa fa-power-off fa-lg'
        break
      case 'LOW_BATTERY':
        iconClass = 'fa fa-battery-1 fa-lg'
        break
      case 'BREAK_AWAY':
        iconClass = 'fa fa-chain-broken fa-lg'
        break
      case 'MISSING':
        iconClass = 'fa fa-deaf fa-lg'
        break
      default:
        iconClass = 'fa fa-bell fa-lg'
    }
    iconClass += '  text-danger'
    return (<div key={index}>
      <span className={iconClass} aria-hidden="true"></span>
      <span>{'  '}{duration}</span>
    </div>)
  })
  return (<span>{list}</span>)
}

export const sensorAlertListTableFormatter = (cell, row) => {
  if (_.isUndefined(cell) || _.isNull(cell) || cell.length == 0) {
    return <span>No Notes Found</span>
  }
  return (
    <BaseTable
      autoLoad={ false }
      striped={ true }
      pagination={ false }
      actions={ [] }
      maxHeight='150px'
      columnProps={ [{
        config: {
          dataField: 'createUtc',
          isKey: true,
          width: '30%',
          dataFormat: timestampFormatter
        },
        label: 'When'
      }, {
        config: {
          dataField: 'who',
          width: '20%',
          dataSort: false
        },
        label: 'Who',
      }, {
        config: {
          dataField: 'description',
          dataSort: false
        },
        label: 'Description'
      }] }
      hideSelectColumn={ true }
      data={ cell }
    />
  )
}

export const energyFormatter = (cell, row, formatExtraData) => {
    if (_.isUndefined(cell) || _.isNull(cell)) {
        return ""
    }
    if(!Array.isArray(cell) || cell.length === 0) {
        return ""
    }
    return (
        <div style={{width: 800, height: 120, marginLeft: -20}}>
            <ResponsiveContainer width={'100%'} height={'100%'}>
                <LineChart
                    data={cell}
                >
                    <XAxis
                        domain={[dataMin => moment(dataMin).subtract('30', 'seconds').utc(), 'dataMax']}
                        dataKey="createUtc"
                        tickFormatter={(tick) => moment(tick).local().format('HH:mm')}
                        padding={{ left: 20, top: 20, right: 20 }}
                    />
                    <YAxis
                        domain={[dataMin => Math.min(0, dataMin), 'dataMax + 1']}
                        type="number"
                        allowDecimals={false}
                    />
                    <CartesianGrid
                        vertical={false}
                    />
                    <Tooltip
                        wrapperStyle={{ opacity: 0.8 }}
                        labelStyle={{ color: "#99ccff" }}
                        labelFormatter={function(value) {
                            return `time: ${moment(value).format('hh:mm:ss')}`;}}
                    />
                    <Legend />

                    <Line name='Energy' type="monotone" dataKey="energy" stroke="#82ca9d" activeDot={{r : 8}} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export const dailyEnergyFormatter = (cell, row, formatExtraData) => {
    if (_.isUndefined(cell) || _.isNull(cell)) {
        return ""
    }
    if(!Array.isArray(cell) || cell.length === 0) {
        return ""
    }
    return (
        <div style={{width: 800, height: 240, marginLeft: -20}}>
            <ResponsiveContainer width={'100%'} height={'50%'}>
                <LineChart data={cell}>
                    <XAxis
                        domain={[dataMin => moment(dataMin).subtract('0.5', 'days').utc(), 'dataMax']}
                        dataKey="startUtc"
                        tickFormatter={(tick) => moment(tick).local().format('MM-DD')}
                        padding={{ left: 20, top: 20, right: 20 }}
                    />
                    <YAxis
                        domain={[dataMin => Math.min(0, dataMin), 'dataMax + 1']}
                        type="number"
                        allowDecimals={false}
                    />
                    <CartesianGrid
                        vertical={false}
                    />
                    <Tooltip
                        wrapperStyle={{ opacity: 0.8 }}
                        labelStyle={{ color: "#99ccff" }}
                        labelFormatter={function(value) {
                            return `time: ${moment(value).format('YYYY-MM-DD')}`;}}
                    />
                    <Legend />

                    <Line name='Daily Active Energy' type="linear" dataKey="activeTotalEnergy" stroke="#82ca9d" activeDot={{r : 8}} dot={true} />
                    <Line name='Daily Resting Energy' type="linear" dataKey="restingTotalEnergy" stroke="#ffca9d" activeDot={{r : 8}} dot={true} />
                </LineChart>
            </ResponsiveContainer>

            <ResponsiveContainer width={'100%'} height={'50%'}>
    				<LineChart data={cell}>
    					<XAxis
    						domain={[dataMin => moment(dataMin).subtract('0.5', 'days').utc(), 'dataMax']}
    						dataKey="startUtc"
    						tickFormatter={(tick) => moment(tick).local().format('MM-DD')}
    						padding={{ left: 20, top: 20, right: 20 }}
    					/>
    				<YAxis
    					domain={[dataMin => Math.min(0, dataMin), 'dataMax + 1']}
    					type="number"
    					allowDecimals={false}
    				/>
    				<CartesianGrid
    					vertical={false}
    				/>
    				<Tooltip
    					wrapperStyle={{ opacity: 0.8 }}
    					labelStyle={{ color: "#99ccff" }}
    					labelFormatter={function(value) {
    						return `time: ${moment(value).format('YYYY-MM-DD')}`;}}
    				/>
    				<Legend />

    				<Line name='Daily Active Duration' type="linear" dataKey="activeTrending" stroke="#82ca9d" activeDot={{r : 8}} dot={true} />
    				<Line name='Daily Resting Duration' type="linear" dataKey="restingTrending" stroke="#ffca9d" activeDot={{r : 8}} dot={true} />
    		</LineChart>
    	</ResponsiveContainer>
        </div>
    );
}

export const dailyDurationFormatter = (cell, row, formatExtraData) => {
    if (_.isUndefined(cell) || _.isNull(cell)) {
        return ""
    }
    if(!Array.isArray(cell) || cell.length === 0) {
        return ""
    }
    return (
        <div style={{width: 800, height: 120, marginLeft: -20}}>
            <ResponsiveContainer width={'100%'} height={'100%'}>
            		<LineChart data={cell}>
            			<XAxis
            				domain={[dataMin => moment(dataMin).subtract('0.5', 'days').utc(), 'dataMax']}
            				dataKey="startUtc"
            					tickFormatter={(tick) => moment(tick).local().format('MM-DD')}
            				padding={{ left: 20, top: 20, right: 20 }}
            			/>
            			<YAxis
            				domain={[dataMin => Math.min(0, dataMin), 'dataMax + 1']}
            				type="number"
            				allowDecimals={false}
            			/>
            			<CartesianGrid
            				vertical={false}
            			/>
            			<Tooltip
            				wrapperStyle={{ opacity: 0.8 }}
            				labelStyle={{ color: "#99ccff" }}
            				labelFormatter={function(value) {
            					return `time: ${moment(value).format('YYYY-MM-DD')}`;}}
            			/>
            			<Legend />

            			<Line name='Daily Active Duration' type="linear" dataKey="activeTrending" stroke="#82ca9d" activeDot={{r : 8}} dot={true} />
            			<Line name='Daily Resting Duration' type="linear" dataKey="restingTrending" stroke="#ffca9d" activeDot={{r : 8}} dot={true} />
            		</LineChart>
            	</ResponsiveContainer>
        </div>
    );
}

export const stepCountFormatter = (cell, row, formatExtraData) => {
    if (_.isUndefined(cell) || _.isNull(cell)) {
        return ""
    }
    if(!Array.isArray(cell) || cell.length === 0) {
        return ""
    }
    return (
        <div style={{width: 800, height: 120, marginLeft: -20}}>
            <ResponsiveContainer width={'100%'} height={'100%'}>
                <LineChart
                    data={cell}
                >
                    <XAxis
                        domain={[dataMin => moment(dataMin).subtract('30', 'seconds').utc(), 'dataMax']}
                        dataKey="createUtc"
                        tickFormatter={(tick) => moment(tick).local().format('HH:mm')}
                        padding={{ left: 20, top: 20, right: 20 }}
                    />
                    <YAxis
                        domain={[dataMin => Math.min(0, dataMin), 'dataMax + 1']}
                        type="number"
                        allowDecimals={false}
                    />
                    <CartesianGrid
                        vertical={false}
                    />
                    <Tooltip
                        wrapperStyle={{ opacity: 0.8 }}
                        labelStyle={{ color: "#99ccff" }}
                        labelFormatter={function(value) {
                            return `time: ${moment(value).format('hh:mm:ss')}`;}}
                    />
                    <Legend />

                    <Line name='Step Count' type="monotone" dataKey="stepCount" stroke="#82ca9d" activeDot={{r : 8}} dot={true} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}


