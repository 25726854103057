import React from 'react'
import { Tab, Col, Row, Nav, NavItem } from 'react-bootstrap'
import FloorMap from '../../components/FloorMap'
import FloorSummary from './FloorSummary'
import SensorLocationTable from './SensorLocationTable'
import SensorRssiTable from './SensorRssiTable'
import SensorAlertTable from './SensorAlertTable'
import ClosedSensorAlertTable from './ClosedSensorAlertTable'
import SeniorMissingTable from './SeniorMissingTable'
import SensorAlertSubscription from './SensorAlertSubscription'
import _ from 'lodash'
import moment from 'moment'
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'

class FloorDashboard extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props);
    this.state ={
      activeTab: 'floorMap',
      relays: [],
      sensors: [],
      stations: [],
      stationIds: [],
      sensorLocations: [],
      sensorRssis: [],
      sensorAlerts: [],
      missingSensors: [],
      missingSensorIds: [],
      missingSensorClosedAlerts: [],
      timer: null,
      buildingId: null,
      query: '',
      summary: {
        relayCount: '',
        sensorCount: '',
        sensorAlerts: null
      },
    }
    //hashmap: key is the sensorId, value is the sensor
    this.sensorAlerts = new Map()
    this.closedSensorAlerts = new Map()
  }
  _refreshResume = () => {
	if (!this.state.timer) {
		var timer = setInterval(this._refresh_periodically, 10000)
		this.setState({timer:timer})
	}
  }


  _refreshSuspend = () => {
	if (this.state.timer) {
		clearInterval(this.state.timer)	  
		this.setState({timer:null})
	}
  }
  componentDidMount() {
      // refresh every 10 second
      this._refreshResume()

  }


  //wss://api.aiportal.co/bstream/sensorAlert/floor/"+floorId
  subscriptionSensorAlerts(floorId) {
      this.url = "wss://api.aiportal.co/bstream/connect";
      this.socket = new WebSocket(this.url);
      var stompClient = Stomp.over(this.socket)
      const that = this
      stompClient.connect({}, function(frame) {
          const sensorAlert = stompClient.subscribe("/bstream/sensorAlert/floor/" + floorId, function(message){
              let prevSensorAlerts = that.state.sensorAlerts
              const updateSensorAlert = message.body
              //if there is some update for the sensor alert:
              if(updateSensorAlert) {
                  const sensorAlert = JSON.parse(updateSensorAlert)
                  const operation = sensorAlert.operation
                  //create a new alert
                  if(operation === 'create') {
                      if(!that.sensorAlerts.has(sensorAlert.id)) {
                          prevSensorAlerts.push(that._transformSensorAlert(sensorAlert))
                          that.sensorAlerts.set(sensorAlert.id, sensorAlert)
                      }
                      that.setState({
                          sensorAlerts: prevSensorAlerts
                      }, () => {
                          console.log('createTesting', that.state.sensorAlerts)
                      })

                  } else if(operation === 'update') {
                      let closed = false
                      const filteredArray = prevSensorAlerts.filter((alert) => {
                          if(alert.id === sensorAlert.id) {
                              if(sensorAlert.alertStatus === 'DONE' && alert.alertStatus ==='INIT') {
                                  closed = true
                              }
                          }
                          return alert.id !== sensorAlert.id
                      })
                      //just update other info: not close the alert
                      if(!closed) {
                          filteredArray.push(that._transformSensorAlert(sensorAlert))
                          that.setState({
                              sensorAlerts: filteredArray
                          }, () => {
                              console.log('updateNONCloseTesting', that.state.sensorAlerts)
                          })
                          //close the alert:
                      } else {
                          that.setState((state) => {
                              that.sensorAlerts.delete(sensorAlert.id)
                              that.closedSensorAlerts.set(sensorAlert.id, sensorAlert)
                              return {
                                  sensorAlerts: filteredArray,
                                  closedSensorAlerts: state.closedSensorAlerts.push(that._transformSensor(sensorAlert))
                              }
                          }, () => {
                              console.log('updateCloseTesting', that.state.sensorAlerts)
                          })
                      }


                  }
              }

          })
      })
  }

  componentWillUnmount() {
    //clearInterval(this.timer)
	  this._refreshSuspend()
  }

  componentWillReceiveProps(nextProps) {
    const floorId = nextProps.floorId
    // this.floorId = floorId
    if (floorId && floorId !== this.props.floorId) {
        // this.floorId = floorId
      this._refresh(true, floorId)

    }
  }

  _refresh_periodically = () => {
    this._refresh(true)
  }
  
  _refresh = (forceload, currentFloorId, newState) => {
    const state = this.state
    const floorId = currentFloorId ? currentFloorId : this.props.floorId
    if (!floorId) {
      return
    }
    let activeTab
    if (newState && newState.activeTab) {
      activeTab = newState.activeTab
    } else {
      activeTab = state.activeTab
    }
    if (newState) {
      this.setState(newState)
    }

    // always refresh the alerts to make sure the totalAlerts count is correct at whole time
    this._fetchSensorAlerts(false, floorId)
    // this.subscription(floorId)

    if (activeTab === 'floorMap') {
        // this.floorId = floorId
      this._fetchFloorById(floorId)
      this._fetchRelays(floorId)
      this._fetchSensors(floorId)
      //subscription: websocket:
    } else if (activeTab === 'sensorLocation') {
      if (forceload || !state.expandedSensorLocation) {
        // avoid refresh when any row is expanded
        this._fetchSensorLocations(floorId)
      }
    } else if (activeTab === 'sensorRssi') {
      if (forceload || !state.expandedSensorRssi) {
        // avoid refresh when any row is expanded
        this._fetchSensorRssis(floorId)
    } else if (activeTab === 'sensorAlert') {
          // this._fetchSensorAlerts(false, floorId)
          // this.subscription(floorId)
      }

    } else if (activeTab === 'sensorAlertClosed') {
      if (forceload || !state.expandedSensorAlertClosed) {
        // avoid refresh when any row is expanded
        this._fetchSensorAlerts(true, floorId)
      }
    }
  }

  _fetchFloorById(floorId) {
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/floor/findById",
      { dataType: "json" },
      {id: floorId}
    ).then((data) => {
      this.setState({floor: data})
      this._fetchStations(data['buildingId'])

    })
    .catch((err) => {
      this.setState({floor: null})
      const { showToast } = this.context
      showToast('Failed to fetch floor by ID! Reason: ' + err)
    })
  }

  _fetchRelays(floorId) {
    if (this.state.relaysLoading) {
      return
    }
    this.setState({relaysLoading: true})
    const select = [
    		'id', 
    		'x', 
    		'y', 
    		'name', 
    		'lastSeenUtc', 
    		'room:name', 
    		'lastUpdateSensorUtc'
    		]
        ApiSingleton.makeHttpRequest(
          "post",
          "/bstream/api/v1/relay/findWithSelect",
          { dataType: "json" },
          { floorId: floorId, enabled:true, select:JSON.stringify(select), orderBy: 'name',  ascendingOrder: true }
        )
          .then((data) => { this._fetchRooms(floorId, data) })
          .catch((err) => {
            const { showToast } = this.context
            showToast('Failed to fetch list of Rockboxes! Reason: ' + err)
            this.setState({relaysLoading: false})
          });
  }

  _fetchRooms(floorId, relays) {
    let fakeX = 10
    _.forEach(relays, (relay, index) => {
        relay.roomName = relay.room ? relay.room.name : 'Unknown'
        // work around for relay without location
        if (!relay.x  && !relay.y) {
          relay.x = fakeX
          relay.y = 5
          fakeX += 10
        }        
    })
    this.setState({
        relays: relays,
        summary: _.assign(this.state.summary, {relayCount: relays.length}),
        relaysLoading: false
    })
  }

  _transformSensorLatestLocation(v, alertSummary) {
    const sensor = {
      createUtc: moment.utc(v.createUtc),
      relayId: v.relayId,
      sensorId: v.sensorId,
      sensorName: v.sensor ? v.sensor.name : 'unknown',
      sensorMac: v.sensor ? v.sensor.mac : 'unknown',
      sensorX: (v.sensor && v.sensor.x) ? v.sensor.x : 0,
      sensorY: (v.sensor && v.sensor.y) ? v.sensor.y : 0,
      sensorRange: v.range,
      senior: v.senior ? v.senior : {},
      seniorName: v.senior ? v.senior.firstName + ' ' + v.senior.lastName: 'unknown',
      roomName: v.room ? v.room.name : 'unknown',
      relayName: v.relay ? v.relay.name : 'unknown',
      relayMac: v.relay ? v.relay.mac : 'unknown',
      alerts: []
    }
    if (v.sensor.highImpactAlertTriggered) {
      sensor.alerts.push({type:'HIGH_IMPACT', when:v.sensor.highImpactAlertUtc})
      if (alertSummary) alertSummary.highImpactAlertCount ++
    }
    if (v.sensor.sosAlertTriggered) {
      sensor.alerts.push({type:'SOS', when:v.sensor.sosAlertUtc})
      if (alertSummary) alertSummary.sosAlertCount ++
    }
    if (v.sensor.powerOffAlertTriggered) {
      sensor.alerts.push({type:'POWER_OFF', when:v.sensor.powerOffAlertUtc})
      if (alertSummary) alertSummary.powerOffAlertCount ++
    }
    if (v.sensor.lowBatteryAlertTriggered) {
       sensor.alerts.push({type:'LOW_BATTERY', when:v.sensor.lowBatteryAlertUtc})
       if (alertSummary) alertSummary.lowBatteryAlertCount ++
    }
    if (v.sensor.breakAwayAlertTriggered) {
        sensor.alerts.push({type:'BREAK_AWAY', when:v.sensor.breakAwayAlertUtc})
        if (alertSummary) alertSummary.breakAwayAlertCount ++
    }
    if (v.sensor.missingAlertTriggered) {
      sensor.alerts.push({type:'MISSING', when:v.sensor.lastSeenUtc})
      if (alertSummary) alertSummary.missingAlertCount ++
    }
    if (v.sensor.approachingAlertTriggered) {
      sensor.alerts.push({type: 'APPROACHING', when: v.sensor.lastSeenUtc})
      if (alertSummary) alertSummary.approachingAlertCount++
    }

    if (v.sensor.leavingAlertTriggered) {
      sensor.alerts.push({type:'LEAVING', when:v.sensor.lastSeenUtc})
      if (alertSummary) alertSummary.leavingAlertCount ++
    }

    if (v.sensor.exitDoorAlertTriggered) {
        sensor.alerts.push({type:'EXIT_DOOR', when:v.sensor.lastSeenUtc})
        if (alertSummary) alertSummary.exitDoorAlertCount ++
      }

    // TODO: temporary workaround for lacking x and y from backend
    if (sensor.sensorX === 0 && sensor.sensorY === 0) {
      sensor.sensorX = v.relay.x + 30
      sensor.sensorY = v.relay.y + 30
      //sensor.alerts.push('powerOffAlertTriggered')
    }

    return sensor
  }

  _transformSensor(v, alertSummary) {
	    const sensor = {
	      createUtc: moment.utc(v.createUtc),
	      relayId: v.lastRelay.id,
	      sensorId: v.id,
	      sensorName: v.name ? v.name : 'unknown',
	      sensorMac: v.mac ? v.mac : 'unknown',
	      sensorX: (v.x) ? v.x : 0,
	      sensorY: (v.y) ? v.y : 0,
	      sensorRange: 0,
	      seniorName: v.senior ? v.senior.firstName + ' ' + v.senior.lastName: 'unknown',
	      roomName: v.lastRoom ? v.lastRoom.name : 'unknown',
	      relayName: v.lastRelay ? v.lastRelay.name : 'unknown',
	      relayMac: v.lastRelay ? v.lastRelay.mac : 'unknown',
	      batteryLevel: v.batteryLevel,
          senior: v.senior,
          isClosed: v.missingAlertManuallyCleared,
          isMissing: v.missingAlertTriggered,
          missingPlace: v.lastRoom ? v.lastRoom.name : 'unknown',
          missingTime: v.lastSeenUtc,
          sensorAlertNoteList: [],
	      alerts: []
	    }
	    if (v.highImpactAlertTriggered) {
	      sensor.alerts.push({type:'HIGH_IMPACT', when:v.highImpactAlertUtc})
	      if (alertSummary) alertSummary.highImpactAlertCount ++
	    }
	    if (v.sosAlertTriggered) {
	      sensor.alerts.push({type:'SOS', when:v.sosAlertUtc})
	      if (alertSummary) alertSummary.sosAlertCount ++
	    }
	    if (v.powerOffAlertTriggered) {
	      sensor.alerts.push({type:'POWER_OFF', when:v.powerOffAlertUtc})
	      if (alertSummary) alertSummary.powerOffAlertCount ++
	    }
	    if(v.lowBatteryAlertTriggered) {
	        sensor.alerts.push({type: 'LOW_BATTERY', when: v.lowBatteryAlertUtc})
            if (alertSummary) alertSummary.lowBatteryAlertCount ++
        }
	    if (v.breakAwayAlertTriggered) {
	      sensor.alerts.push({type:'BREAK_AWAY', when:v.breakAwayAlertUtc})
	      if (alertSummary) alertSummary.breakAwayAlertCount ++
	    }
	    if (v.missingAlertTriggered) {
	    	  sensor.alerts.push({type:'MISSING', when:v.lastSeenUtc})
	    	  if (alertSummary) alertSummary.missingAlertCount ++	    		  
	    }
        if (v.approachingAlertTriggered) {
            sensor.alerts.push({type: 'APPROACHING', when: v.lastSeenUtc})
            if (alertSummary) alertSummary.approachingAlertCount++
        }

        if (v.leavingAlertTriggered) {
          sensor.alerts.push({type:'LEAVING', when:v.lastSeenUtc})
          if (alertSummary) alertSummary.leavingAlertCount ++
        }

        if (v.exitDoorAlertTriggered) {
            sensor.alerts.push({type:'EXIT_DOOR', when:v.lastSeenUtc})
            if (alertSummary) alertSummary.exitDoorAlertCount ++
          }

	    // TODO: temporary workaround for lacking x and y from backend
	    if (sensor.sensorX === 0 && sensor.sensorY === 0 && v.lastRelay) {
	      sensor.sensorX = v.lastRelay.x + 30
	      sensor.sensorY = v.lastRelay.y + 30
	      //sensor.alerts.push('powerOffAlertTriggered')
	    }
	    return sensor
  }

  _transformSensorAlert(v) {
	let closedUtc
	if (v.closedUtc) {
		const createUtc = moment.utc(v.createUtc)
		closedUtc = moment.utc(v.closedUtc)
		const duration = closedUtc.to(createUtc, true)
		closedUtc = closedUtc.local().format('YYYY/MM/DD HH:mm:ss') + ' (closed in ' + duration + ')'
	} else {
		closedUtc = 'NA'
	}
    const alert = {
      id: v.id,
      description: v.description,
      alertType: v.alertType,
      alertStatus: v.alertStatus,
      createUtc: moment.utc(v.createUtc),
      closedUtc: closedUtc,
      lastUpdateUtc: v.lastUpdateUtc ? moment.utc(v.lastUpdateUtc) : null,
      sensorId: v.sensorId,
      sensorName: v.sensor ? v.sensor.name : 'unknown',
      sensorX: (v.sensor && v.sensor.x) ? v.sensor.x : 0,
      sensorY: (v.sensor && v.sensor.y) ? v.sensor.y : 0,
      seniorName: v.senior ? v.senior.firstName + ' ' + v.senior.lastName: 'unknown',
      roomName: v.room ? v.room.name : 'unknown',
      sensorAlertNoteList: this._transformSensorAlertNoteList(v.sensorAlertNoteList)
    }

    return alert
  }


  _transformSensorAlertNoteList(list) {
    return list.map((v) => {
      if (v.adminId) {
        v.who = v.admin.userName
      } else if (v.careGiverId) {
        v.who = v.careGiver.userName
      }
      return v
    })
  }

  _fetchMissingSensorAlertNotes(sensorId, missingSensor) {
    let alertNote = ""
      const select = [
          'id',
          'sensorAlertNoteList']
      const postData = (() => {
          return {sensorId: sensorId, alertType: 'MISSING',
              select:JSON.stringify(select), maxResults:1, ascendingOrder: false}
      })()
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/sensorAlert/findWithSelect",
        { dataType: "json" },
        postData
      )
        .then((data) => {
          if(data && data[0] && data[0].sensorAlertNoteList && data[0].sensorAlertNoteList[0]) {
            alertNote =  data[0].sensorAlertNoteList[0].description
          }
          missingSensor.id = data && data[0] ? data[0].id : null
          })
        .catch((err) => {
          const { showToast } = this.context
          showToast('Failed to fetch list of RockBand Alerts by floor! Reason: ' + err)
        });
      return alertNote
  }

  _fetchSensors(floorId) {
    if (this.state.sensorsLoading) {
      return
    }
    this.setState({sensorsLoading: true})
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/sensor/findByFloorId",
      { dataType: "json" },
      { floorId: floorId, enabled:true }
    )
      .then((data) => {
        const alertSummary = {
          highImpactAlertCount: 0,
          sosAlertCount: 0,
          powerOffAlertCount: 0,
          breakAwayAlertCount: 0,
          missingAlertCount: 0,
          lowBatteryAlertCount: 0,
          approachingAlertCount: 0,
          leavingAlertCount: 0,
          exitDoorAlertCount: 0
        };
        // const missingPlaces = new Map()
        // const missingCounts = new Map()
  
        const missingSensors = []
        data = _.reduce(_.sortBy(data, 'lastSeenUtc'), (o, v, k) => {
          const sensorData = this._transformSensor(v, alertSummary)
          o.push(sensorData)
            const diff =  moment().utc().diff(moment(v.lastSeenUtc))
            if(sensorData.isMissing && diff >= 3 * 24 * 3600 * 1000) {
                const missingSensor = {
                  seniorName: sensorData.seniorName,
                  missingTime: v.lastSeenUtc,
                  missingPlace: sensorData.missingPlace,
                }
                missingSensor.missingSensorAlert = this._fetchMissingSensorAlertNotes(v.id, missingSensor)
                missingSensors.push(missingSensor)
            }
  
          // const sensorData = this._transformSensor(v, alertSummary)
          // o.push(sensorData);
          // if(sensorData.isMissing) {
            // if(!missingPlaces.has(sensorData.missingPlace)) {
            //     missingCounts.set(sensorData.missingPlace, 1)
            //     missingPlaces.set(sensorData.missingPlace, [].concat(sensorData))
            // } else {
            //     missingCounts.set(sensorData.missingPlace, missingCounts.get(sensorData.missingPlace) + 1)
            //     missingPlaces.set(sensorData.missingPlace, missingPlaces.get(sensorData.missingPlace).concat(sensorData))
            // }
          // }
          return o
        }, []);
  
        // const missingSensors = []
        // const missingSensorIds = []
        // const places = []
          // for (const [key, value] of missingCounts) {
          //     if (value >= 3 && !places.includes(key)) {
          //         places.push(key)
          //         const sensors = missingPlaces.get(key)
          //         for(let i = 0; i < sensors.length; i++) {
          //             if(!missingSensorIds.includes(sensors[i].sensorId)) {
          //                 missingSensorIds.push(sensors[i].sensorId)
          //                 const select = [
          //                     'id',
          //                     'sensorAlertNoteList']
          //                 const postData = (() => {
          //                     return {sensorId: sensors[i].sensorId, alertType: 'MISSING',
          //                         select:JSON.stringify(select), maxResults:1, ascendingOrder: false}
          //                 })()
          //                 $.post({
          //                     async: false,
          //                     url: '/bstream/api/v1/sensorAlert/findWithSelect',
          //                     headers: { 'Content-type': 'application/x-www-form-urlencoded' },
          //                     data: postData,
          //                     dataType: 'json'
          //                     //get the alert note:
          //                 }).then((data) => {
          //                     if(data && data[0] && data[0].sensorAlertNoteList && data[0].sensorAlertNoteList[0]) {
          //                         sensors[i].missingSensorAlert = data[0].sensorAlertNoteList[0].description
          //                     }
          //                     sensors[i].id = data[0].id
          //                 }, (err) => {
          //                     const { showToast } = this.context    
          //                     showToast('Failed to fetch list of RockBand Alerts by floor! Reason: ' + err)
          //                 })
          //                 missingSensors.push(sensors[i])
          //             }
          //         }
          //     }
          // }
        this.setState({
          missingSensors: missingSensors,
          sensors: data,
          summary: _.assign(this.state.summary, {
          sensorCount: data.length, sensorAlerts: alertSummary}),
          sensorsLoading: false
        });
      })
      .catch((err) => {
        const { showToast } = this.context
        showToast('Failed to fetch list of RockBand by floor! Reason: ' + err)
        this.setState({sensorsLoading: false})
      });
  }
    _fetchStations(buildingId) {
      if(buildingId != this.state.buildingId) {
          this.setState({
              buildingId: buildingId,
              stations: [],
              stationIds: []
          })
      }
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/station/find/",
        { dataType: "json" },
        {buildingId: buildingId}
      )
        .then((data) => {
          _.reduce(data, (o, v) => {
            if(this._transformStation(v) !== null) {
                this.setState((state) => {
                    return {
                        stations: state.stations.includes(v.name) ? state.stations : state.stations.concat(v.name),
                        stationIds: state.stationIds.includes(v.id) ? state.stationIds : state.stationIds.concat(v.id)

                    }
                })
            }
        }, []);
        })
        .catch((err) => {});
    }
    _transformStation(v) {
        const station = {
            id: v.id ? v.id : null,
            name: v.description ? v.description : null
        }
        return station.name == null ? null : station
    }

    _fetchSensorLocations(floorId) {

    if (this.state.sensorLocationsLoading) {
      return
    }
    this.setState({sensorLocationsLoading: true})
    const select = [
    		'createUtc', 
    		'relayId', 
    		'sensorId',
    		'sensor',  
    		'range', 
    		'senior:firstName', 
    		'senior:lastName',
    		'room:name',
    		'relay:name',
    		'relay:mac']
        ApiSingleton.makeHttpRequest(
          "post",
          "/bstream/api/v1/sensorLocation/findSensorLatestLocationByFloorId",
          { dataType: "json" },
          { floorId: floorId, select:JSON.stringify(select) }
        )
          .then((data) => {
            const now = Date.now()
            data = _.reduce(data, (o, v, k) => {
              // sort record based on relayId and sensorId pair
              const key = v.sensorId
              if (!o[key]) {
                o[key] = []
              }
              o[key].push(this._transformSensorLatestLocation(v))
              return o
            }, {})
            data = _.reduce(data, (o, v, k) => {
              const v1 = _.reverse(_.sortBy(v, 'createUtc'))
              const v2 =  v1[0]
              v2.items = v1
              o.push(v2)
              return o
            }, [])
            data = _.sortBy(data, 'createUtc')
            this.setState({
              sensorLocations: data,
              sensorLocationsLoading: false
            })
          })
          .catch((err) => {
            const { showToast } = this.context
            showToast('Failed to fetch list of RockBand location by floor! Reason: ' + err)
            this.setState({
              sensorLocations: null,
              sensorLocationsLoading: false
            })
          });
  }

  _fetchSensorRssis(floorId) {
    if (this.state.sensorRssisLoading) {
      return
    }
    this.setState({sensorRssisLoading: true})
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/sensorRssi/findSensorLatestRssiByFloorId",
      { dataType: "json" },
      { floorId: floorId }
    )
      .then((data) => {
        const now = Date.now()
        data = _.reduce(data, (o, v, k) => {
          // sort record based on relayId and sensorId pair
          const key = v.sensorId
          if (!o[key]) {
            o[key] = []
          }
          o[key].push({
            createUtc: moment.utc(v.createUtc),
            relayId: v.relayId,
            sensorId: v.sensorId,
            sensorName: v.sensor ? v.sensor.name : 'unknown',
            sensorMac: v.sensor ? v.sensor.mac : 'unknown',
            sensorRssi: v.rssi,
            seniorName: v.senior ? v.senior.firstName + ' ' + v.senior.lastName: 'unknown',
            roomName: v.room ? v.room.name : 'unknown',
            relayName: v.relay ? v.relay.name : 'unknown',
            relayMac: v.relay ? v.relay.mac : 'unknown',
            batteryLevel: v.sensor ? v.sensor.batteryLevel : ''
          })
          // this.rssi.set(key, o[key])
          return o
        }, {})
        data = _.reduce(data, (o, v, k) => {
          const v1 = _.reverse(_.sortBy(v, 'createUtc'))
          const v2 =  v1[0]
          v2.items = v1
          o.push(v2)
          return o
        }, [])
  
  
        data = _.chain(data).sortBy('sensorRssi').sortBy('createUtc').value()
        this.setState({
          sensorRssis: data,
          sensorRssisLoading: false
        })
      })
      .catch((err) => {
        const { showToast } = this.context
        showToast('Failed to fetch list of RockBand location by floor! Reason: ' + err)
        this.setState({
          sensorRssis: null,
          sensorRssisLoading: false
        })
      });
  }

  _fetchSensorAlerts = (closed, floorId) => {
    if (!floorId) {
      return
    }
    if (!closed && this.state.sensorAlertsLoading) {
      return
    } else if (closed && this.state.sensorAlertsClosedLoading) {
      return
    }
    	const select = [
    		'id', 
    		'description',
    		'alertType',
    		'alertStatus',
    		'createUtc',
    		'closedUtc',
    		'lastUpdateUtc',
    		'sensorId',
    		'sensor:name',
    		'sensor:x',
    		'sensor:y',
    		'senior:firstName',
    		'senior:lastName',
    		'room:name',
    		'sensorAlertNoteList']
      
    const postData = (() => {
      if (!closed) {
    	    this.setState({sensorAlertsLoading: true})
    		  return {floorId: floorId, noAlertStatus: "DONE", select:JSON.stringify(select), maxResults:100}
      } else {
          this.setState({sensorAlertsClosedLoading: true})
          return {floorId: floorId, alertStatus: "DONE", select:JSON.stringify(select), maxResults:100}
      }
    })()
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/sensorAlert/findWithSelect",
      { dataType: "json" },
      postData
    )
      .then((data) => {
        const now = Date.now()
      data = _.reduce(data, (o, v, k) => {
        // sort record based on relayId and sensorId pair
        o.push(this._transformSensorAlert(v))
        return o
      }, [])

      let closedData = _.remove(data, (item) => {
        if(item.alertStatus === 'DONE') {
            if(!this.closedSensorAlerts.has(item.id)) {
                this.closedSensorAlerts.set(item.id, item)
            }
        } else {
            if(!this.sensorAlerts.has(item.id)) {
                this.sensorAlerts.set(item.id, item)
            }
        }
        return item.alertStatus === 'DONE'
      })

      if (!closed) {
        data = _.reverse(_.sortBy(data, 'createUtc'))
      } else {
        closedData = _.reverse(_.sortBy(closedData, 'createUtc'))
      }
      if (!closed) {
        this.setState({
          sensorAlerts: data,
          sensorAlertsLoading: false
        })
      }else {
        this.setState({
          missingSensorClosedAlerts: closedData,
          sensorAlertsClosed: closedData,
          sensorAlertsClosedLoading: false
        })
      }
      })
      .catch((err) => {
        const { showToast } = this.context
        showToast('Failed to fetch list of RockBand Alerts by floor! Reason: ' + err)
      if (!closed) {
        this.setState({
          sensorAlerts: null,
          sensorAlertsLoading: false
        })
      } else {
        this.setState({
          sensorAlertsClosed: null,
          sensorAlertsClosedLoading: false
        })
      }
      });
  }

  _handleTabSelect = (key) => {
    this.setState({
        stations: [],
        query: ''
    })
    this._refresh(true, this.props.floorId, {activeTab: key})
  }

  _handleSensorLocationRowSelect = (row, isSelected) => {
    this.setState({expandedSensorLocation : isSelected ?  row :  null})
  }

  _handleSensorRssiRowSelect = (row, isSelected) => {
    this.setState({expandedSensorRssi : isSelected ?  row :  null})
  }

  _handleSensorAlertRowSelect = (row, isSelected) => {
    this.setState({expandedSensorAlert : isSelected ?  row :  null})
  }
  _handleClosedSensorAlertRowSelect = (row, isSelected) => {
    this.setState({expandedSensorAlertClosed : isSelected ?  row :  null})
  }

  _getTabTitleWithLoadingIcon = (title, stateParams) => {
    let loading = false
    stateParams.forEach((stateParam) => {
      if (this.state[stateParam]) {
        loading = true
      }
    })
    if (loading) {
      return <div>{title}<img src="/assets/ajax-loader.gif" style={{marginLeft: '15px'}}/></div>
    } else if (title === 'RockBand Alerts (Active)') {
    	  const totalAlerts = this.state.sensorAlerts ? this.state.sensorAlerts.length : 0
      if (this.state.activeTab === 'sensorAlert') {
        return <span>{title} <span className="badge" style={{backgroundColor: '#b94a48', color: '#ffffff'}}>{totalAlerts}</span></span>
      } else {
        return <span>{title} <span className="badge" style={{backgroundColor: '#b94a48'}}>{totalAlerts}</span></span>
      }
    } else {
      return title
    }
  }

  _handleSearch = (e) => {
      e.preventDefault()
      e.stopPropagation()
      const query = e.target.search.value
      this.setState({
          query: query
      })
  }
  _handleClear = () => {
    this.setState({
        query : ''
    })
  }
  _handleChange = (e) => {
      const query = e.target.value
      this.setState({
          query: query
      })
  }
    handleData = () => {

    }
  render() {
    const props = this.props,
          state = this.state
    return (
        <Tab.Container defaultActiveKey={'floorMap'} onSelect={this._handleTabSelect} id='floor-dashboard'>
            <Row className="clearfix">
                <Col sm={12} md={9} lg={9}>
                    <Nav bsStyle="tabs" style={{borderBottomColor: "transparent"}}>
                        <NavItem eventKey={'floorMap'}>
                            {this._getTabTitleWithLoadingIcon('Floor Map', ['sensorsLoading', 'relaysLoading'])}
                        </NavItem>
                        <NavItem eventKey={'sensorLocation'}>
                            {this._getTabTitleWithLoadingIcon('RockBand Locations', ['sensorLocationsLoading'])}
                        </NavItem>
                        <NavItem eventKey={'sensorRssi'}>
                            {this._getTabTitleWithLoadingIcon('RockBand RSSIs', ['sensorRssisLoading'])}
                        </NavItem>
                        <NavItem eventKey={'sensorAlert'}>
                            {this._getTabTitleWithLoadingIcon('RockBand Alerts (Active)', ['sensorAlertsLoading'])}
                        </NavItem>
                        <NavItem eventKey={'sensorAlertClosed'}>
                            {this._getTabTitleWithLoadingIcon('RockBand Alerts (Closed)', ['sensorAlertsClosedLoading'])}
                        </NavItem>
                    </Nav>
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <div className="navbar-header">
                        <form role="search" onSubmit={(e) => e.preventDefault() } autoComplete="off">
                            <div className="input-group">
                                {/*implement the function of the search bar*/}
                                <input type="text" placeholder="Search for something..." className="form-control"
                                       name="search" onChange={(e) => {this._handleChange(e)}} value={this.state.query} />
                                <span className="input-group-addon">
                                    <span className="glyphicon glyphicon-remove" onClick={this._handleClear}></span>
                                </span>
                            </div>
                        </form>
                    </div>
                </Col>
                <Col sm={12} md={12} lg={12}>
                    <Tab.Content >
                        <Tab.Pane eventKey={'floorMap'}>
                            <Row>
                                <Col sm={12} md={4} lg={4}>
                                    <FloorSummary
                                        query={this.state.query}
                                        data={ state.summary } />
                                    {state.missingSensors.length > 0 &&
                                    <SeniorMissingTable
                                        query={ this.state.query }
                                        sensors={state.missingSensors}
                                        height='250px'
                                        refreshResume={this._refreshResume}
                                        refreshSuspend={this._refreshSuspend}
                                        refresh={this._fetchSensors.bind(this, this.props.floorId)}
                                    /> }
                                </Col>
                                <Col sm={12} md={8} lg={8}>
                                    <FloorMap
                                        width={ props.width }
                                        height={ props.height }
                                        floor={state.floor }
                                        relays={ state.relays }
                                        sensors={ state.sensors }
                                        stations={ state.stations }
                                        stationIds={ state.stationIds }
                                        show={ true }
                                        query={this.state.query}
                                    />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'sensorLocation'}>
                            <SensorLocationTable
                                data={ state.sensorLocations }
                                handleRowSelect={this._handleSensorLocationRowSelect}
                                query={this.state.query}
                                refresh={this._fetchSensorLocations.bind(this, this.props.floorId)}
                                refreshResume={this._refreshResume}
                                refreshSuspend={this._refreshSuspend}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={'sensorRssi'}>
                            <SensorRssiTable
                            height='auto'
                            data={ state.sensorRssis }
                            query={this.state.query}
                            handleRowSelect={this._handleSensorRssiRowSelect}
                            refresh={this._fetchSensorRssis.bind(this, this.props.floorId)}
                            refreshResume={this._refreshResume}
                            refreshSuspend={this._refreshSuspend}
                            />
                        </Tab.Pane>

                        <Tab.Pane eventKey={'sensorAlert'}>
                            <SensorAlertSubscription
                                floorId={this.props.floorId}
                            />
                            <SensorAlertTable
                                data={ state.sensorAlerts }
                                height='auto'
                                handleRowSelect={this._handleSensorAlertRowSelect}
                                query={this.state.query}
                                refresh={this._fetchSensorAlerts.bind(this, false, this.props.floorId)}
                                refreshResume={this._refreshResume}
                                refreshSuspend={this._refreshSuspend}
                            />
                        </Tab.Pane>

                        <Tab.Pane eventKey={'sensorAlertClosed'}>
                            <ClosedSensorAlertTable
                            height='auto'
                            data={ state.sensorAlertsClosed }
                            query={this.state.query}
                            handleRowSelect={this._handleClosedSensorAlertRowSelect}
                            refresh={this._fetchSensorAlerts.bind(this, true, this.props.floorId)}
                            refreshResume={this._refreshResume}
                            refreshSuspend={this._refreshSuspend}
                            />
                        </Tab.Pane>

                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>

    )
  }

}

export default FloorDashboard
