/*
THINGS TO DO

*** NEED TO CHANGE THE OTHER STUFF TO BE BASED OFF OF ONE TEMPLATE
*** MAKES IT EASIER FOR ME TO MODIFY AND CHANGE/ALTER THE DISPLAY (TAKES LESS TIME)

seniorName: 07a0cf0fa9 (ONLY KNOW THIS ONE FOR SURE)
key: dataConsistency

*/

import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { AiFillSmile, AiFillFrown, AiFillMeh, AiOutlineCalendar } from 'react-icons/ai';
import { FaWalking , FaBed , FaHeartbeat } from 'react-icons/fa';
import { TbHeartbeat, TbDeviceHeartMonitor, TbHeartRateMonitor } from 'react-icons/tb';
import { SiOxygen } from 'react-icons/si';
import { GiWeightLiftingUp } from 'react-icons/gi';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {de} from 'date-fns/locale';
import ApiSingleton from '../../utils/Axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material"
import StatBox from "../../components/StatBox/StatBox"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut, Line, Pie, Chart } from 'react-chartjs-2';
import { getDate } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'];

export const lineHROptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1 | 2,
  plugins: {
      legend: {
          position: 'top',
          labels: {
              usePointStyle: true,
          },
      },
  },
  scales: {
      x: {
          title: {
              display: true,
              text: 'Hours of the Day',
          },
          ticks: {
              autoSkip: true,
              maxTicksLimit: 24,
          },
      },
      y: {
          title: {
              display: true,
              text: 'Heart Rate BPM',
          },
          position: 'left',
          offset: true,
      },
  },
};
  
export const barPAOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1 | 2,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Hours',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Active Energy Burned',
        },
      },
    },
};
  
export const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2 | 1,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: [],
      },
    },
    scales: {
    },
};
  
export function DataConsistencyTable() {
    const location = useLocation();
    const [seniorId, setSeniorId] = useState("07a0cf0fa9"); // location.state.id

    //react-date-picker addition
    const [dateValue, setDate] = useState();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [bosDateCount, setBOSDateCount] = useState(0);
    const [hrvDateCount, setHRVDateCount] = useState(0);
    const [ecgDailyCount, setECGDailyCount] = useState(0);
    const [stepCountCount, setStepCountCount] = useState(0);
    const [systolicCount, setSystolicCount] = useState(0);
    const [diastolicCount, setDiastolicCount] = useState(0);

    const [hrMonthOptions, setHRMonthOptions] = useState({datasets: [],});
    const [hrMonthData, setHRMonthData] = useState({datasets: [],});

    const [more1GMonthOptions, setMore1GMonthOptions] = useState({datasets: [],});
    const [more1GMonthData, setMore1GMonthData] = useState({datasets: [],});

    const [complianceDailyData, setComplianceDailyData] = useState(0);
    const [complianceMonthOptions, setComplianceMonthOptions] = useState({datsets: [],});
    const [complianceMonthData, setComplianceMonthData] = useState({datasets: [],});

    const [complianceColor, setComplianceColor] = useState("");

    const [data, setData] = useState({datasets: [],});

    function _fetchDataConsistency(seniorId) {
        const postData = {
            seniorName: seniorId,
            key: "dataConsistency",
        }
        let tempData = null;
        if (seniorId) {
          ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                tempData = JSON.parse(base64_decode(data[0].document));
            })
            .then(data => {
                let currentDate = null;
                if (dateValue) { currentDate = dateValue; } 
                else {
                    // setDate(new Date(tempData.date.start));
                    // currentDate = new Date(tempData.date.start);
                    setDate(new Date("2023-04-19"));
                    currentDate = new Date("2023-04-19");
                }

                const monthData = getMonthData(currentDate, tempData.daily);

                setData(tempData.daily); // records all data to pull from later
                setStartDate("2023-04-18"); //tempData.date.start
                setEndDate(tempData.date.end); 
                setDate(currentDate);

                const monthLabels = getMonthLabels(monthData);
                const phrMonth = getMonthStat(monthData, "PercentageHeartRate");
                const chrMonth = getMonthStat(monthData, "CountHeartRate");
                const pmore1GMonth = getMonthStat(monthData, "Percentagemore1G");
                const cmore1GMonth = getMonthStat(monthData, "Countmore1G");

                let cBOSDate = getDateData(currentDate, monthData, "PercentbloodOxygenSaturation");
                let phrvDate = getDateData(currentDate, monthData, "PercentheartRateVariability");
                let ecgDate = getDateData(currentDate, monthData, "CountecgClassification");
                let stepCount = getDateData(currentDate, monthData, "CountstepCount");
                let csystolic = getDateData(currentDate, monthData, "Countsystolic");
                let cdiastolic = getDateData(currentDate, monthData, "Countdiastolic");

                let complianceData = getMonthCompliance(monthData);
                let complianceDaily = parseInt(getDateData(currentDate, monthData, "CountHeartRate")) / 2;
                let compliance = setComplianceIcon(complianceDaily);

                setHRMonthOptions({
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: 1 | 2,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Days of the Month',
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 31,
                            },
                        },
                        y1: {
                            title: {
                                display: true,
                                text: '% Data Collected',
                            },
                            position: 'left',
                            offset: true,
                            beginAtZero: true,
                        },
                        y2: {
                            title: {
                                display: true,
                                text: 'Data Point Count',
                            },
                            position: 'right',
                            offset: true,
                            beginAtZero: true,
                        }
                    },
                })

                setHRMonthData({
                    labels: monthLabels,
                    datasets: [
                        {
                            type: 'line',
                            label: 'Data Point Count',
                            data: chrMonth,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // the color of the points
                            borderColor: 'rgba(0, 0, 0, 0.8)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y2',
                        },
                        {
                            type: 'bar',
                            label: '% Data Collected',
                            data: phrMonth,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y1',
                        },
                    ],
                });

                setMore1GMonthOptions({
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: 1 | 2,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Days of the Month',
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 31,
                            },
                        },
                        y1: {
                            title: {
                                display: true,
                                text: '% Data Collected',
                            },
                            position: 'left',
                            offset: true,
                            beginAtZero: true,
                        },
                        y2: {
                            title: {
                                display: true,
                                text: 'Data Point Count',
                            },
                            position: 'right',
                            offset: true,
                            beginAtZero: true,
                        }
                    },
                })

                setMore1GMonthData({
                    labels: monthLabels,
                    datasets: [
                        {
                            type: 'line',
                            label: 'Data Point Count',
                            data: cmore1GMonth,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)', // the color of the points
                            borderColor: 'rgba(0, 0, 0, 0.8)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y2',
                        },
                        {
                            type: 'bar',
                            label: '% Data Collected',
                            data: pmore1GMonth,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y1',
                        },
                    ],
                });

                setComplianceMonthOptions({
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: 1 | 2,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                        annotation: {
                            annotations: {
                                max: {
                                    type: 'line',
                                    borderColor: 'gray',
                                    borderWidth: 2,
                                    scaleID: 'y',
                                    value: 12,
                                    label: {
                                        display: true,
                                        content: "12",
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                        position: 'end',
                                        xAdjust: 10,
                                        yAdjust: 10,
                                    },
                                },
                            },
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Days of the Month',
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 31,
                            },
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Hours Watch Worn',
                            },
                            position: 'left',
                            offset: true,
                        },
                    },
                });

                setComplianceMonthData({
                    labels: monthLabels,
                    datasets: [
                        {
                            label: 'Compliance Threshold',
                            data: [],
                            pointStyle: 'line',
                            backgroundColor: 'black',
                            borderColor: 'black',
                        },
                        {
                            type: 'bar',
                            label: 'Hours Worn',
                            data: complianceData,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                        },
                    ],
                });

                setBOSDateCount(cBOSDate);
                setHRVDateCount(phrvDate);
                setECGDailyCount(ecgDate);
                setStepCountCount(stepCount);
                setSystolicCount(csystolic);
                setDiastolicCount(cdiastolic);
                setComplianceDailyData(complianceDaily);
                setComplianceColor(compliance);
          })
            .catch((err) => {});
        }
      }

    useEffect(() => {

        _fetchDataConsistency(seniorId);

    }, []);

    /* Get Month Data
    ** The date is format in year-month-day string format separated by "-"
    ** want to split up the 3 different values into 3 diff int in array [year, month, day]
    ** find all data points in that month
    ** input: String date - the month to display
    **        Array data - the complete data of the json file???
    ** return: data list of data points in that month
    */
    function getMonthData (date, data) {
        // base error null catch
        if (date == null || data[0] == null) {
            console.log("month data is null");
            return null;
        }

        // convert string date into Date object and filter by month
        let newDate = date;
        let preMonth = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
        let postMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0); // month + 1 because 0 gets last day of prev month

        const monthData = [];
        let idx = 0;
        let n = 0;
        let currDate = new Date(data[0].localDate);
    
        while (currDate < postMonth) {
            currDate = new Date(data[idx].localDate);
            idx++;
    
            // at the correct month
            if ((currDate.getMonth() == preMonth.getMonth()) && (currDate < postMonth)) {
                monthData[n] = data[idx];
                n++;
            }
            else continue;
        }
    
        // PROBLEM: getting one more data point than intended at the end
        // console.log("date data: ", dateData);
        return monthData;
    }

    // input:   date        the day that you want data from
    //          month_data  all data points from selected month
    //          stat_type   name of stat to return
    //
    // return:  value of wanted stat on wanted day
    function getDateData (date, month_data, stat_type) {
        if (date == null || month_data[0] == null) return null;

        let selectDay = date.getDate();
        let idx = 0;
        let data_len = month_data.length;

        while (idx < data_len - 1) {
            const dateArray = month_data[idx].localDate.split("-"); // split into [year, month, day]
            // console.log("dateArray: ", dateArray);
            // console.log("selectDay: ", selectDay, "   dateArray[2]: ", Number(dateArray[2]));

            if (selectDay == Number(dateArray[2]))
                return month_data[idx][stat_type];
            
            idx++;
        }

        // console.log("date not found");
        return "N/A";
    }

    // returns an array of day labels for the given month
    function getMonthLabels (month_data) {
        // catches null input
        if (month_data[0] == null) return null;

        const labels = [];
        let idx = 0;
        let data_len = month_data.length;

        while (idx < data_len - 1) {
            const dateArray = month_data[idx].localDate.split("-");
            labels[idx] = dateArray[2].toString();
            idx++;
        }

        return labels;
    }

    // input:   month_data - data for selected month
    //          stat_type - which section I want to extract from the month
    //
    // return:  array of values that correspond with only the stat_type of the selected month
    function getMonthStat (month_data, stat_type) {
        // base checking case not null
        if (month_data[0] == null || stat_type == null) {
            console.log("getMonthStat is null");
            return null;
        }

        const statArray = [];
        let idx = 0;
        let data_len = month_data.length;
        
        while (idx < data_len - 1) {
            statArray[idx] = month_data[idx][stat_type];
            idx++;
        }

        return statArray;
    }

    // input:   month_data - data for selected month
    // 
    // CALCULATION IS BASED OFF HEART RATE COUNT (ONCE EVERY 30 MINS) > 24 is compliant
    //  
    // return:  array of values that correspond with the compliance rating of user
    function getMonthCompliance (month_data) {
        // base check for non null
        if (month_data[0] == null) { return []; }

        const complianceArray = [];
        let idx = 0;
        let data_len = month_data.length;

        while (idx < data_len - 1) {
            complianceArray[idx] = month_data[idx].CountHeartRate / 2; // one count every 30 mins\
            idx++;
        }

        return complianceArray;
    }
  
    // handles date change event
    function handleDateChange(event) {
        
        const mData = getMonthData(event, data);
        const monthLabels = getMonthLabels(mData);
        const phrMonth = getMonthStat(mData, "PercentageHeartRate");
        const chrMonth = getMonthStat(mData, "CountHeartRate");
        const pmore1GMonth = getMonthStat(mData, "Percentagemore1G");
        const cmore1GMonth = getMonthStat(mData, "Countmore1G");

        let cBOSDate = getDateData(event, mData, "PercentbloodOxygenSaturation");
        let phrvDate = getDateData(event, mData, "PercentheartRateVariability");
        let ecgDate = getDateData(event, mData, "CountecgClassification");
        let stepCount = getDateData(event, mData, "CountstepCount");
        let csystolic = getDateData(event, mData, "Countsystolic");
                let cdiastolic = getDateData(event, mData, "Countdiastolic");

        let complianceData = getMonthCompliance(mData);
        let complianceDaily = parseInt(getDateData(event, mData, "CountHeartRate")) / 2;
        let compliance = setComplianceIcon(complianceDaily);

        setHRMonthData({
            labels: monthLabels,
            datasets: [
                {
                    type: 'line',
                    label: 'Data Point Count',
                    data: chrMonth,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // the color of the points
                    borderColor: 'rgba(0, 0, 0, 0.8)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y2',
                },
                {
                    type: 'bar',
                    label: '% Data Collected',
                    data: phrMonth,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y1',
                },
            ],
        });

        setMore1GMonthData({
            labels: monthLabels,
            datasets: [
                {
                    type: 'line',
                    label: 'Data Point Count',
                    data: cmore1GMonth,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // the color of the points
                    borderColor: 'rgba(0, 0, 0, 0.8)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y2',
                },
                {
                    type: 'bar',
                    label: '% Data Collected',
                    data: pmore1GMonth,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y1',
                },
            ],
        });

        setComplianceMonthData({
            labels: monthLabels,
            datasets: [
                {
                    label: 'Compliance Threshold',
                    data: [],
                    pointStyle: 'line',
                    backgroundColor: 'black',
                    borderColor: 'black',
                },
                {
                    type: 'bar',
                    label: 'Hours Worn',
                    data: complianceData,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                },
            ],
        });
        
        setBOSDateCount(cBOSDate);
        setHRVDateCount(phrvDate);
        setECGDailyCount(ecgDate);
        setStepCountCount(stepCount);
        setSystolicCount(csystolic);
        setDiastolicCount(cdiastolic);
        setComplianceDailyData(complianceDaily);
        setComplianceColor(compliance);

        setDate(event);
    }

    // SETS THE COMPLIANCE ICON AND COLOR DYNAMICALLY
    // input:   value - the total hours of watch worn (CURRENTLY CALCULATED VIA HR COUNT)
    //
    // return:  icon that represents the level of compliance
    function setComplianceIcon(value) {
        if (parseInt(value) < 12) { return <AiFillFrown color="red" size='50px' /> }
        else if (parseInt(value) > 12) { return <AiFillSmile color="green" size='50px' /> }
        else if (parseInt(value) == 12) { return <AiFillMeh color="#D5B60A" size='50px' /> }
        else { return <AiFillMeh color="grey" size='50px' /> }
    }


    // // navigation to All Patients data consistency page
    // const navigate = useNavigate();
    // function handleGoAllStats(e) {
    //     e.preventDefault();
    //     navigate('/allPatients');
    // }
  
    return (
      <Box m="20px"> 
          <Box display="flex" justifycontext="space-between" alignItems="center">
                <Box width="100%">
                    <Typography variant="h3" fontWeight="bold" sx={{ color: "#530C0C" }}>{seniorId}</Typography>
                </Box>
                {/* <Box>
                    <button onClick={handleGoAllStats}>All Patients Data Consistenty</button>      
                </Box> */}
                <Box>
                    <DatePicker showIcon={true} minDate={new Date("2023-04-19")} maxDate={new Date(endDate)} onChange={handleDateChange} selected={dateValue} />
                </Box>
          </Box>
  
          {/* GRID AND CHARTS */}
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
  
              {/** ROW 1 */}
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={startDate} subtitle="Start Trial" icon={<GiWeightLiftingUp color='green' size='0px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={endDate} subtitle="End Trial" icon={<GiWeightLiftingUp color='green' size='0px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" >
                <StatBox title={complianceDailyData} subtitle="Hours Compliance" icon={complianceColor}></StatBox> 
              </Box>

              {/** ROW 2 */}
              <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" >
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Monthly Compliance
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        <AiFillSmile color='#530C0C' />
                    </Box>
                </Box>
                <Box height="250px" m="0px 25px">
                    <Line options={complianceMonthOptions} data={complianceMonthData} className='graph' key={Math.random()} />
                </Box>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={hrvDateCount} subtitle="% HRV Collected" icon={<FaHeartbeat color='#530C0C' size='50px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={bosDateCount} subtitle="% BOS Collected" icon={<SiOxygen color='#530C0C' size='50px' />}></StatBox>
              </Box>

              {/** ROW 3 */}
              <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" >
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Monthly Heart Rate Data Consistency
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        <FaHeartbeat color='#530C0C' />
                    </Box>
                </Box>
                <Box height="250px" m="0px 25px">
                    <Chart type='bar' options={hrMonthOptions} data={hrMonthData} className='graph' key={Math.random()} />
                </Box>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={ecgDailyCount} subtitle="ECG Classification Count" icon={<FaHeartbeat color='#530C0C' size='50px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" >
                <StatBox title={stepCountCount} subtitle="Step Count Count" icon={<FaWalking color='#530C0C' size='50px' />}></StatBox>
              </Box>

              {/** ROW 4 */}
              <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" >
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Monthly More 1G Data Consistency
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        <FaWalking color='#530C0C' />
                    </Box>
                </Box>
                <Box height="250px" m="0px 25px">
                    <Bar options={more1GMonthOptions} data={more1GMonthData} className='graph' key={Math.random()} />
                </Box>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={systolicCount} subtitle="Systolic Count" icon={<TbDeviceHeartMonitor color='#530C0C' size='50px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" >
                <StatBox title={diastolicCount} subtitle="Diastolic Count" icon={<TbDeviceHeartMonitor color='#530C0C' size='50px' />}></StatBox>
              </Box>
          </Box>
      </Box>
    );
}
  
export default DataConsistencyTable;