import React, { useState, useEffect } from "react";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import "./TreeView.css";

export const TreeView = (props) => {
  const {
    levels = 2,
    expandIcon = "glyphicon glyphicon-plus",
    collapseIcon = "glyphicon glyphicon-minus",
    emptyIcon = "glyphicon",
    nodeIcon = "glyphicon glyphicon-stop",
    color,
    backColor,
    borderColor,
    onhoverColor = "#F5F5F5",
    selectedColor = "#FFFFFF",
    selectedBackColor = "#428bca",
    enableLinks = false,
    highlightSelected = true,
    showBorder = true,
    showTags = true,
    nodes = [],
    onTreeNodeExpandChange,
    onTreeNodeSelectChange,
  } = props;

  const [activeNode, setActiveNode] = React.useState(null);

  const setNodeId = (node) => {
    if (!node.nodes) return;

    node.nodes.forEach((node) => {
      node.nodeId = nodes.length;
      nodes.push(node);
      setNodeId(node);
    });
  };

  useEffect(() => {
    // console.log(data);
  }, []);

  const data = props.data;
  setNodeId({ nodes: data });

  const children = data.map((node, index) => (
    <TreeNode
      key={node.nodeId}
      node={node}
      level={1}
      visible={true}
      options={props}
      setActiveNode={setActiveNode}
      onTreeNodeExpandChange={onTreeNodeExpandChange}
      onTreeNodeSelectChange={onTreeNodeSelectChange}
    />
  ));

  return (
    <div id="treeview" className="treeview">
      {/* <ul className="list-group">{children}</ul> */}
      <ListGroup activeKey={activeNode} as="ol">
        {children}
      </ListGroup>
    </div>
  );
};

export const TreeNode = (props) => {
  const [expanded, setExpanded] = useState(
    props.node.state && props.node.state.hasOwnProperty("expanded")
      ? props.node.state.expanded
      : props.level < props.options.levels
      ? true
      : false
  );
  const [selected, setSelected] = useState(
    props.node.state && props.node.state.hasOwnProperty("selected")
      ? props.node.state.selected
      : false
  );

  const toggleExpanded = (id, event) => {
    const newExpanded = !expanded;
    setExpanded(newExpanded);

    if (props.onTreeNodeExpandChange) {
      props.onTreeNodeExpandChange(props.node, newExpanded);
    }
    // event.stopPropagation();
  };

  const toggleSelected = (id, event) => {
    const newSelected = !selected;
    setSelected(newSelected);
    if (props.onTreeNodeSelectChange) {
      props.onTreeNodeSelectChange(props.node, newSelected);
    }
    // event.stopPropagation();
  };
  const node = props.node;
  const options = props.options;

  useEffect(() => {
    // console.log(options);
    toggleExpanded(null)
  }, []);

  var style;
  if (!props.visible) {
    style = {
      display: "none",
    };
  } else {
    if (options.highlightSelected && selected) {
      style = {
        color: options.selectedColor,
        backgroundColor: options.selectedBackColor,
      };
    } else {
      style = {
        color: node.color || options.color,
        backgroundColor: node.backColor || options.backColor,
      };
    }

    if (!options.showBorder) {
      style.border = "none";
    } else if (options.borderColor) {
      style.border = "1px solid " + options.borderColor;
    }
  }

  var indents = [];
  for (var i = 0; i < props.level - 1; i++) {
    indents.push(<span key={"indent_" + i} className="indent"></span>);
  }
  var expandCollapseIcon;
  if (node.nodes) {
    if (!expanded) {
      expandCollapseIcon = (
        <span
          key="expandCollapseIcon"
          className={options.expandIcon}
          onClick={() => toggleExpanded(node.nodeId)}
        >
          <IconPlus IconMinus size={13} />
        </span>
      );
    } else {
      expandCollapseIcon = (
        <span
          key="expandCollapseIcon"
          className={options.collapseIcon}
          onClick={() => toggleExpanded(node.nodeId)}
        >
          <IconMinus size={13} />
        </span>
      );
    }
  } else {
    expandCollapseIcon = (
      <IconPlus size={13} />
      // <span key="expandCollapseIcon" className={options.emptyIcon}></span>
    );
  }
  var nodeIcon;
  if (node.loading) {
    nodeIcon = (
      <span key="nodeIcon" className="icon">
        <i className="fa fa-spinner fa-spin"></i>
      </span>
    );
  } else {
    nodeIcon = (
      <span key="nodeIcon" className="icon">
        <i className={node.icon || options.nodeIcon}></i>
      </span>
    );
  }
  var nodeText;
  if (options.enableLinks) {
    nodeText = (
      <a key="nodeLink" href={node.href}>
        {node.text}
      </a>
    );
  } else {
    nodeText = <span key="nodeText">{node.text}</span>;
  }

  var badges;
  if (node.tags) {
    badges = node.tags.map((tag, index) => {
      return (
        <Badge key={"badges_" + index} bg="secondary">
          {tag}
        </Badge>
      );
    });
  }
  var children = [];
  if (node.nodes) {
    node.nodes.forEach((node, index) => {
      children.push(
        <TreeNode
          key={"node_" + index}
          node={node}
          level={props.level + 1}
          visible={expanded && props.visible}
          options={options}
          setActiveNode={props.setActiveNode}
          onTreeNodeExpandChange={props.onTreeNodeExpandChange}
          onTreeNodeSelectChange={props.onTreeNodeSelectChange}
        />
      );
    });
  }
  return (
    <>
      <ListGroup.Item
        action
        href={`#${node.nodeId}`}
        key={node.nodeId}
        as="li"
        className={`d-flex justify-content-between align-items-start ${
          !props.visible ? `d-none` : ``
        }`}
        onClick={(e) => {
          props.setActiveNode(`#${node.nodeId}`);
          toggleSelected(node.nodeId, e);
        }}
      >
        <div>{indents}</div>
        <div>{expandCollapseIcon}</div>
        <div>{nodeIcon}</div>
        <div className="ms-2 me-auto">
          <div className="fw-bold" style={{ fontSize: 14 }}>
            {nodeText}
          </div>
        </div>
        {badges}
      </ListGroup.Item>
      {children}
    </>
  );
};

export default TreeView;
