import React from 'react'
import BaseTable from '../../components/BaseTable'
import { timestampFormatter, expandableRelay, sensorLocationFormatter } from '../../components/BaseTable/CellFormatter'
import moment from "moment"
class SensorRssiTable extends React.Component {
  constructor(props) {
      super(props)
      this.handleSortChange = this.handleSortChange.bind(this)
      this.state = {
          data: [],
          sortName: undefined,
          sortOrder: undefined,
          query: props.query ? props.query : ''
      }
  }

  componentWillReceiveProps(nextProps) {
        let data = nextProps.data
        if (data) {
            if(this.state.sortName) {
                data = this.state.sortOrder === 'asc' ? _.sortBy(data, this.state.sortName) : _.reverse( _.sortBy(data, this.state.sortName))
            }
            this.setState({
                data: data,
                query: nextProps.query
            })
        }
    }
    handleSortChange = (sortName, sortOrder) => {
        this.setState((state) => {
            return {
                sortName: sortName,
                sortOrder: sortOrder,
                data: sortOrder === 'asc' ? _.sortBy(state.data, sortName) : _.reverse(_.sortBy(state.data, sortName))
            }
        })
    }
  render() {
    const props = this.props
      const columnProps = [{
          config: {
              dataField: 'createUtc',
              isKey: true,
              dataSort: true,
              dataFormat: timestampFormatter,
              formatExtraData: this.state.query
          },
          label: 'Timestamp'
      }, {
          config: {
              dataField: 'sensorName',
              dataSort: true,
              dataFormat: sensorLocationFormatter,
              formatExtraData: this.state.query
          },
          label: 'RockBand'
      }, {
          config: {
              dataField: 'seniorName',
              dataSort: true,
              dataFormat: sensorLocationFormatter,
              formatExtraData: this.state.query
          },
          label: 'Resident'
      }, {
          config: {
              dataField: 'relayName',
              dataSort: true,
              dataFormat: expandableRelay,
              formatExtraData: this.state.query
          },
          label: 'RockBox',
      }, {
          config: {
              dataField: 'roomName',
              dataSort: true,
              dataFormat: sensorLocationFormatter,
              formatExtraData: this.state.query
          },
          label: 'Room'
      }, {
          config: {
              dataField: 'sensorRssi',
              dataSort: true,
              dataFormat: sensorLocationFormatter,
              formatExtraData: this.state.query
          },
          label: 'RSSI'
      }, {
          config: {
              dataField: 'batteryLevel',
              dataSort: true,
              dataFormat: sensorLocationFormatter,
              formatExtraData: this.state.query
          },
          label: 'Battery'
      }]

      const expandColumnProps = [{
          config: {
              dataField: 'createUtc',
              isKey: true,
              dataFormat: timestampFormatter
          },
          label: 'Timestamp'
      }, {
          config: {
              dataField: 'relayName',
              dataSort: false
          },
          label: 'RockBox',
      }, {
          config: {
              dataField: 'roomName',
              dataSort: false
          },
          label: 'Room'
      }, {
          config: {
              dataField: 'sensorRssi',
              dataSort: false
          },
          label: 'RSSI'
      }]

      const isExpandableRow = (row) => {
          return row.items.length > 1
      }

      const expandComponent = (row) => {
          return  (
              <BaseTable
                  autoLoad={ false }
                  striped={ false }
                  pagination={ false }
                  actions={ [] }
                  columnProps={ expandColumnProps }
                  sortedData={ row.items }
              />
          )
      }
    let data = this.state.data
      if (this.state.query && this.state.query !== '') {
          const query = this.state.query.toLowerCase()
          data = data.filter(row => {
              return (row.createUtc && moment(row.createUtc).local().format("YYYY/MM/DD HH:mm:ss").toLowerCase().includes(query))||
                  (row.sensorName && row.sensorName.toLowerCase().includes(query)) ||
                  (row.seniorName && row.seniorName.toLowerCase().includes(query)) ||
                  (row.relayName && row.relayName.toLowerCase().includes(query)) ||
                  (row.roomName && row.roomName.toLowerCase().includes(query)) ||
                  (row.sensorRssi && String(row.sensorRssi).toLowerCase().includes(query)) ||
                  (row.batteryLevel && String(row.batteryLevel).toLowerCase().includes(query))
          })
      }
    return (
        <BaseTable autoLoad={false}
                   striped={ true }
                   height={props.height}
                   pagination={ false }
                   actions={[]}
                   columnProps={columnProps}
                   sortChange={this.handleSortChange}
                   isExpandableRow={ isExpandableRow }
                   expandComponent={ expandComponent }
                   hideSelectColumn={ true }
                   { ...props }
                   sortedData={data}
        />
    )
  }
}

export default SensorRssiTable
