import React from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Select from 'react-select'
import Utils from '../../utils'
import '../react-select.css'
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'
import AsyncSelect from 'react-select/async';

const RoomControlLevels = ['enterprise', 'venue', 'building', 'floor', 'room', 'groupPolicy']
const StationControlLevels = ['enterprise', 'groupPolicy', 'venue', 'building', 'station', 'groupPolicy']

class FormGroupRoomWithStation extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props)
    this.state = {
      value: {
        enterprise: props.value ? { label: props.value.enterprise.name, value: props.value.enterprise.id } : null,
        venue: props.value ? { label: props.value.venue.name, value: props.value.venue.id } : null,
        building: props.value ? { label: props.value.building.name, value: props.value.building.id } : null,

        floor: props.value ? { label: props.value.floor.name, value: props.value.floor.id } : null,
        room: props.value ? { label: props.value.room.name, value: props.value.room.id } : null,
        
        // enterprise: props.value ? props.value[props.fields.enterprise.controlId] : null,
        // venue: props.value ? props.value[props.fields.venue.controlId] : null,
        // building: props.value ? props.value[props.fields.building.controlId] : null,
        // floor: props.value ? props.value[props.fields.floor.controlId] : null,
        // room: props.value ? props.value[props.fields.room.controlId] : null,
        station: props.value ? props.value[props.fields.station.controlId] : null,
        groupPolicy: props.value && props.fields.groupPolicy ? props.value[props.fields.groupPolicy.controlId] : null,
      },
      venueOptions: [],
      buildingOptions: [],
      floorOptions: [],
      roomOptions: [],
      groupPolicyOptions: []
    }

    this._onEnterpriseChange = this._onEnterpriseChange.bind(this)
    this._onGroupPolicyChange = this._onGroupPolicyChange.bind(this)

    this._onVenueChange = this._onVenueChange.bind(this)
    this._onBuildingChange = this._onBuildingChange.bind(this)
    this._onFloorChange = this._onFloorChange.bind(this)
    this._onRoomChange = this._onRoomChange.bind(this)
    this._onStationChange = this._onStationChange.bind(this)


    this._getEnterpriseOptions = this._getEnterpriseOptions.bind(this)
    this._getGroupPolicyOptions = this._getGroupPolicyOptions.bind(this)
    this._getVenueOptions = this._getVenueOptions.bind(this)
    this._getBuildingOptions = this._getBuildingOptions.bind(this)
    this._getFloorOptions = this._getFloorOptions.bind(this)
    this._getRoomOptions = this._getRoomOptions.bind(this)
    this._getStationOptions = this._getStationOptions.bind(this)


  }

  componentDidMount() {
    //console.log('FormGroupRoom componentDidMount ', this.state.value, this.props.value)
    if (this.state.value.enterprise) {
      this._getVenueOptions(this.state.value.enterprise, false)

    }
    if (this.state.value.venue) {
      this._getBuildingOptions(this.state.value.venue, false)
    }
    if (this.state.value.building) {
      this._getFloorOptions(this.state.value.building, false)
      this._getStationOptions(this.state.value.building, false)
    }
    if (this.state.value.floor) {
      this._getFloorImage(this.state.value.floor)
      this._getRoomOptions(this.state.value.floor, false)
    }
    if(this.state.value.enterprise) {
        this._getGroupPolicyOptions(this.state.value.enterprise, false)
    }
  }


  _onEnterpriseChange(value) {
    this._onChange('enterprise', value)
    if (value.value) {
      this._getVenueOptions(value.value, true)
    }
  }

  _onVenueChange(value) {
    this._onChange('venue', value)
    if (value.value) {
      this._getBuildingOptions(value.value, true)
    }
  }

  _onGroupPolicyChange(value) {
    this._onChange('groupPolicy', value)
  }

  _onBuildingChange(value) {
    this._onChange('building', value)
    if (value.value) {
      this._getFloorOptions(value.value, true)
      this._getStationOptions(value.value, true)
    }
  }

  _onFloorChange(value) {
    this._onChange('floor', value)
    if (value && value.data && value.data.image) {
      this.setState({
        imageContent: atob(value.data.image.content)
      })
    } else {
      this.setState({
        imageContent: null,
        roomCoordinates: null
      })
    }
    if (value && value.value) {
      this._getRoomOptions(value.value, true)
    }
  }

  _onRoomChange(value) {
    this._onChange('room', value)
    if (value && value.data) {
      this.setState({
        roomCoordinates: value.data.coordinates
      })
    } else {
      this.setState({
        roomCoordinates: null
      })
    }
  }

  _onStationChange(value) {
    this._onChange('station', value)
  }

  _onChange(key, value) {
    let newValue = {...this.state.value}
    newValue[key] = value ? value.value : null

    const controlLevels = (key != 'station') ? RoomControlLevels : StationControlLevels
    const subLevels = _.slice(controlLevels, _.indexOf(controlLevels, key) + 1)

    // based on the attribute being changed, we need to reset the values of others
    newValue = Utils.setNull(newValue, subLevels)

    this.setState({
      value: newValue
    })

    // based on the type of the filter, we need to pick out the set of attribute to notify parents
    const notifyValue = _.pick(newValue, _.slice(controlLevels, 0, _.indexOf(controlLevels, key) + 1))
    const notifyObj = {}, notifyFields = {}
    _.forEach(notifyValue, (item, key) => {
      const {controlId, required, validator} = this.props.fields[key]
      notifyObj[controlId] = item
      notifyFields[controlId] = this.props.fields[key]
    })
    // based on the attribute being changed, notify
    this.props.onChange(notifyObj, notifyFields)

  }

  _getEnterpriseOptions() {
    return this._getOptions('enterprise', 'name', {})
  }

  _getVenueOptions(enterpriseId, resetDownstream) {
    this.setState({
      isVenueLoading: true
    })
    this._getOptions('venue', 'name', {enterpriseId: enterpriseId}).then((data) => {
      this._onVenueOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onVenueOptionsLoad(null, resetDownstream)
    })
  }
  _getGroupPolicyOptions(enterpriseId, resetDownstream) {
      this.setState({
          isGroupPolicyLoading: true
      })
      //get group policies of a certain enterprise:
      this._getOptions('groupPolicy', 'name', {enterpriseId: enterpriseId}).then((data) => {
          this._onGroupPolicyLoad(data, resetDownstream)
      }, (err) => {
          this._onGroupPolicyLoad(null, resetDownstream)
      })
  }

    _onVenueOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isVenueLoading: false,
        venueOptions: data ? data.options : [],
        buildingOptions: [],
        floorOptions: [],
        roomOptions: []
      })
    } else {
      this.setState({
        isVenueLoading: false,
        venueOptions: data ? data.options : []
      })
    }
  }
  _onGroupPolicyLoad(data, resetDownstream) {
      if(data) {
        const options = data.options
        options.push({value: '00000000-0000-0000-0000-000000000000', label: 'Not Specified'})
        this.setState({
            isGroupPolicyLoading: false,
            groupPolicyOptions:options,
        })
      } else {
        this.setState({
            groupPolicyOptions: []
        })
      }
      // if (resetDownstream) {
      //     this.setState({
      //         isGroupPolicyLoading: false,
      //         groupPolicyOptions: data ? data.options : [],
      //     })
      // } else {
      //     this.setState({
      //         isGroupPolicyLoading: false,
      //         groupPolicyOptions: data ? data.options : []
      //     })
      // }
  }

  _getBuildingOptions(venueId, resetDownstream) {
    this.setState({
      isBuildingLoading: true
    })
    this._getOptions('building', 'name', {venueId: venueId}).then((data) => {
      this._onBuildOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onBuildOptionsLoad(null, resetDownstream)
    })
  }

  _onBuildOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isBuildingLoading: false,
        buildingOptions: data ? data.options : [],
        floorOptions: [],
        roomOptions: []
      })
    } else {
      this.setState({
        isBuildingLoading: false,
        buildingOptions: data ? data.options : []
      })
    }
  }

  _getFloorOptions(buildingId, resetDownstream) {
    this.setState({
      isFloorLoading: true
    })
    this._getOptions('floor', 'name', {buildingId: buildingId}).then((data) => {
      this._onFloorOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onFloorOptionsLoad(null, resetDownstream)
    })
  }

  _onFloorOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isFloorLoading: false,
        floorOptions: data ? data.options : [],
        roomOptions: []
      })
    } else {
      this.setState({
        isFloorLoading: false,
        floorOptions: data ? data.options : []
      })
    }
  }

  _getRoomOptions(floorId, resetDownstream) {
    this.setState({
      isRoomLoading: true
    })
    this._getOptions('room', 'name', {floorId: floorId}).then((data) => {
      this._onRoomOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onRoomOptionsLoad(null, resetDownstream)
    })
  }

  _onRoomOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isRoomLoading: false,
        roomOptions: data ? data.options : []
      })
    } else {
      this.setState({
        isRoomLoading: false,
        roomOptions: data ? data.options : []
      })
    }
  }

  _getStationOptions(stationId, resetDownstream) {
    this.setState({
      isStationLoading: true
    })
    this._getOptions('station', 'name', {stationId: stationId}).then((data) => {
      this._onStationOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onStationOptionsLoad(null, resetDownstream)
    })
  }

  _onStationOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isStationLoading: false,
        stationOptions: data ? data.options : []
      })
    } else {
      this.setState({
        isStationLoading: false,
        stationOptions: data ? data.options : []
      })
    }
  }


  _getOptions(entity, orderBy, filter) {
    const { showToast } = this.context
    if (orderBy) {
      if (!filter) {
        filter = {}
      }
      filter.orderBy = orderBy
      filter.ascendingOrder = true
    }
    return  ApiSingleton.makeHttpRequest(
      'post',
      '/bstream/api/v1/' + entity + '/find',
      { dataType: 'json'},
      filter
    ).then((data) => {
      const options = data.map((item, index) => {
        return {value: item[this.props.fields[entity].valueKey], label: item[this.props.fields[entity].labelKey], data: item}
      })
      return {
        options: options
      }
    },
    (err) => {
      showToast('Failed to fetch list of ' + entity + '! Reason: ' + err)
    })
  }

  _getFloorImage(floorId) {
    const { showToast } = this.context
    return  ApiSingleton.makeHttpRequest(
      'post',
      '/bstream/api/v1/floor/findById',
      { dataType: 'json', id: floorId},
      {}
    ).then((data) => {
      if (data.image && data.image.content) {
        this.setState({
          imageContent: atob(data.image.content)
        })
      }
    },
    (err) => {
      showToast('Failed to fetch floor data by ID! Reason: ' + err)
    })
  }

  render() {
    const props = this.props,
          fields = props.fields,
          state = this.state,
          labelWidth = props.labelWidth ? props.labelWidth : 3,
          valueWidth = props.valueWidth ? props.valueWidth : 7,
          validationWidth = 12 - labelWidth - valueWidth
    return (
      <div>
        <Form.Group
          controlId={ fields.enterprise.controlId }
          validationState={ props.validationState[fields.enterprise.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label style={{ fontWeight: "bold" }}>{ fields.enterprise.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <AsyncSelect
              name={ fields.enterprise.controlId}
              autoload={ props.autoLoad }
              searchable
              value={ state.value.enterprise }
              loadOptions={ this._getEnterpriseOptions }
              onChange={ this._onEnterpriseChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.enterprise.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.enterprise.helpText }</p>
          </Col>
        </Form.Group>


        <Form.Group
          controlId={ fields.venue.controlId }
          validationState={ props.validationState[fields.venue.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label style={{ fontWeight: "bold" }}>{ fields.venue.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <Select
              name={ fields.venue.controlId}
              searchable
              value={ state.value.venue }
              isLoading={ state.isVenueLoading }
              options= { state.venueOptions }
              onChange={ this._onVenueChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.venue.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.venue.helpText }</p>
          </Col>
        </Form.Group>
        <Form.Group
          controlId={ fields.building.controlId }
          validationState={ props.validationState[fields.building.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label style={{ fontWeight: "bold" }}>{ fields.building.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <Select
              name={ fields.building.controlId}
              searchable
              value={ state.value.building }
              isLoading={ state.isBuildingLoading }
              options= { state.buildingOptions }
              onChange={ this._onBuildingChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.building.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.building.helpText }</p>
          </Col>
        </Form.Group>
        <Form.Group
          controlId={ fields.floor.controlId }
          validationState={ props.validationState[fields.floor.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label style={{ fontWeight: "bold" }}>{ fields.floor.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <Select
              name={ fields.floor.controlId}
              searchable
              value={ state.value.floor }
              isLoading={ state.isFloorLoading }
              options= { state.floorOptions }
              onChange={ this._onFloorChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.floor.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.floor.helpText }</p>
          </Col>
        </Form.Group>
        <Form.Group
          controlId={ fields.room.controlId }
          validationState={ props.validationState[fields.room.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label style={{ fontWeight: "bold" }}>{ fields.room.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <Select
              name={ fields.room.controlId}
              searchable
              value={ state.value.room }
              isLoading={ state.isRoomLoading }
              options= { state.roomOptions }
              onChange={ this._onRoomChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.room.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.room.helpText }</p>
          </Col>
        </Form.Group>
        <Form.Group
          controlId={ fields.station.controlId }
          validationState={ props.validationState[fields.station.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label>{ fields.station.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <Select
              name={ fields.station.controlId}
              searchable
              value={ state.value.station }
              isLoading={ state.isStationLoading }
              options= { state.stationOptions }
              onChange={ this._onStationChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.station.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.station.helpText }</p>
          </Col>
        </Form.Group>
          <Form.Group
              controlId={ fields.groupPolicy.controlId }
              validationState={ props.validationState[fields.groupPolicy.controlId].status }>
              <Col sm={ labelWidth }>
                  <Form.Label style={{ fontWeight: "bold" }}>{ fields.groupPolicy.label }</Form.Label>
              </Col>
              <Col sm={ valueWidth }>
                  <Select
                      name={ fields.groupPolicy.controlId}
                      searchable
                      value={ state.value.groupPolicy }
                      isLoading={ state.isGroupPolicyLoading }
                      options= { state.groupPolicyOptions }
                      onChange={ this._onGroupPolicyChange }
                  />
                  <Form.Control.Feedback />
              </Col>
              <Col sm={ validationWidth }>
                  <p>{ props.validationState[fields.groupPolicy.controlId].message }</p>
              </Col>
              <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
                  <p>{ fields.groupPolicy.helpText }</p>
              </Col>
          </Form.Group>
      </div>
    )
  }
}

export default FormGroupRoomWithStation
