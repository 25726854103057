import React from 'react'
import BaseTree from '../../components/BaseTree'

const FacilityTree = ({ data, onTreeNodeExpandChange, onTreeNodeSelectChange }) => {
  return (
    <BaseTree
      data={data}
      showTags={true}
      onTreeNodeExpandChange={onTreeNodeExpandChange}
      onTreeNodeSelectChange={onTreeNodeSelectChange}
    />
  )
}

export default FacilityTree
