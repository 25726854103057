import React from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import "../react-select.css";
import _ from "lodash";
import Utils from "../../utils";
import ApiSingleton from "../../utils/Axios";
import { useState } from "react";
import { useEffect } from "react";

const filterTypeOptionsByLevel = {
  enterprise: [
    {
      value: "System",
      label: "System",
    },
    {
      value: "Enterprise",
      label: "Enterprise",
    },
  ],
  venue: [
    {
      value: "System",
      label: "System",
    },
    {
      value: "Enterprise",
      label: "Enterprise",
    },
    {
      value: "Venue",
      label: "Venue",
    },
  ],
  building: [
    {
      value: "System",
      label: "System",
    },
    {
      value: "Enterprise",
      label: "Enterprise",
    },
    {
      value: "Venue",
      label: "Venue",
    },
    {
      value: "Building",
      label: "Building",
    },
  ],
  station: [
    {
      value: "System",
      label: "System",
    },
    {
      value: "Enterprise",
      label: "Enterprise",
    },
    {
      value: "Venue",
      label: "Venue",
    },
    {
      value: "Building",
      label: "Building",
    },
    {
      value: "Station",
      label: "Station",
    },
  ],
  floor: [
    {
      value: "System",
      label: "System",
    },
    {
      value: "Enterprise",
      label: "Enterprise",
    },
    {
      value: "Venue",
      label: "Venue",
    },
    {
      value: "Building",
      label: "Building",
    },
    {
      value: "Floor",
      label: "Floor",
    },
  ],
};

const FacilityControlLevels = [
  "filterType",
  "enterpriseId",
  "venueId",
  "buildingId",
  "floorId",
  "roomId",
];

const filterValueOptionsByType = {
  System: ["filterType"],
  Enterprise: ["filterType", "enterpriseId"],
  Venue: ["filterType", "enterpriseId", "venueId"],
  Building: ["filterType", "enterpriseId", "venueId", "buildingId"],
  Floor: ["filterType", "enterpriseId", "venueId", "buildingId", "floorId"],
  Room: [
    "filterType",
    "enterpriseId",
    "venueId",
    "buildingId",
    "floorId",
    "roomId",
  ],
};

function FacilitySelector(props) {
  const [value, setValue] = useState({
    enterpriseId: props.value ? props.value.enterpriseId : null,
    venueId: props.value ? props.value.venueId : null,
    buildingId: props.value ? props.value.buildingId : null,
    floorId: props.value ? props.value.floorId : null,
  });

  // Selector Value Placeholder
  const [enterpriseValue, setEnterpriseValue] = useState({
    label: "",
    value: "",
  });
  const [venueValue, setVenueValue] = useState({ label: "", value: "" });
  const [buildingValue, setBuildingValue] = useState({ label: "", value: "" });
  const [floorValue, setFloorValue] = useState({ label: "", value: "" });

  const [filter, setFilter] = useState({
    filterType: props.value
      ? props.filterType
      : props.fixedFilterType
        ? props.fixedFilterType
        : "System",
  });
  const [filterValue, setFilterValue] = useState({
    label: "System",
    value: "System",
  });
  const [filterTypeOptions, setFilterTypeOptions] = useState(
    props.filterLevel
      ? filterTypeOptionsByLevel[props.filterLevel]
      : filterTypeOptionsByLevel.floor
  );
  const [enterpriseOptions, setEnterpriseOptions] = useState([]);
  const [isEnterpriseLoading, setIsEnterpriseLoading] = useState(false);
  const [isVenueLoading, setIsVenueLoading] = useState(false);
  const [isBuildingLoading, setIsBuildingLoading] = useState(false);
  const [isFloorLoading, setIsFloorLoading] = useState(false);
  const [venueOptions, setVenueOptions] = useState([]);
  const [valueOptions, setValueOptions] = useState([]);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [floorOptions, setFloorOptions] = useState([]);
  const [optionsLoadTypes, setOptionsLoadTypes] = useState({
    enterprise: ["Enterprise", "Venue", "Building", "Floor"],
    venue: ["Venue", "Building", "Floor"],
    building: ["Building", "Floor"],
    floor: ["Floor"],
  });

  if (props.value && props.filterType === "Floor") {
    _onfilterTypeChange({
      value: "Floor",
      label: "Floor",
    });
  }

  useEffect(() => {
    if (props.fixedFilterType) {
      _getEnterpriseOptions(true);
    }

    if (props.value && props.filterType === "Floor") {
      _onfilterTypeChange({ value: "Floor", label: "Floor" });
    }
  });

  const _shouldLoadOptions = (type, filterType) => {
    const currentfilterType = filterType ? filterType : filterType;
    return true; // _.indexOf(optionsLoadTypes[type], currentfilterType) !== -1
  };

  const _onfilterTypeChange = (value) => {
    props.onChange({ filterType: value.value });
    setFilter({ filterType: value.value });
    setFilterValue(value);
    _onChange("filterType", value);
    if (
      value &&
      value.value &&
      _shouldLoadOptions("enterprise", filter.filterType)
    ) {
      _getEnterpriseOptions(true);
    }
  };

  const _onChange = (key, value) => {
    //    console.log('FacilitySelector._onChange(key, value) key = ', key, ', value = ', value)
    let newValue = {
      ...value,
    };

    newValue[key] = value ? value.value : null;

    const subLevels = _.slice(
      FacilityControlLevels,
      _.indexOf(FacilityControlLevels, key) + 1
    );

    // based on the attribute being changed, we need to reset the values of others
    newValue = Utils.setNull(newValue, subLevels);

    // based on the type of the filter, we need to pick out the set of attribute to notify parents
    newValue = _.pick(newValue, filterValueOptionsByType[filter.filterType]);

    setValue(newValue);
  };

  const _onEnterpriseChange = (value) => {
    props.setIds(value.value)
    _onChange("enterpriseId", value);
    setValue({ enterpriseId: value });
    setEnterpriseValue(value);
    if (value && value.value && _shouldLoadOptions("venue")) {
      _getVenueOptions(value.value, true);
    }
  };

  const _onVenueChange = (value) => {
     _onChange("venueId", value);
    setValue({ venueId: value });
    setVenueValue(value);
    if (value && value.value && _shouldLoadOptions("building")) {
      _getBuildingOptions(value.value, true);
    }
  };

  const _onBuildingChange = (value) => {
    _onChange("buildingId", value);
    setValue({ buildingId: value });
    setBuildingValue(value);
    if (value && value.value && _shouldLoadOptions("floor")) {
      _getFloorOptions(value.value, true);
    }
  };

  const _onFloorChange = (value) => {
    _onChange("floorId", value);
    setValue({ floorId: value });
    setFloorValue(value);
  };

  const _getEnterpriseOptions = (resetDownstream) => {
    setIsEnterpriseLoading(true);
    _getOptions("enterprise", "name", {}).then(
      (data) => {
        _onEnterpriseOptionsLoad(data, resetDownstream);
      },
      (err) => {
        _onEnterpriseOptionsLoad(null, resetDownstream);
      }
    );
  };

  const _onEnterpriseOptionsLoad = (data, resetDownstream) => {
    const firstValue = data && data.options.length > 0 ? data.options[0] : null;
    if (resetDownstream) {
      setIsEnterpriseLoading(false);
      setEnterpriseOptions(data ? data.options : []);
      setValueOptions([]);
      setBuildingOptions([]);
      setFloorOptions([]);
      _onEnterpriseChange(firstValue);
    } else {
      setIsEnterpriseLoading(false);
      setEnterpriseOptions(data ? data.options : []);
    }
  };

  const _getVenueOptions = (enterpriseId, resetDownstream) => {
    setIsVenueLoading(true);

    _getOptions("venue", "name", {
      enterpriseId: enterpriseId,
    }).then(
      (data) => {
        _onVenueOptionsLoad(data, resetDownstream);
      },
      (err) => {
        _onVenueOptionsLoad(null, resetDownstream);
      }
    );
  };

  const _onVenueOptionsLoad = (data, resetDownstream) => {
    const firstValue = data && data.options.length > 0 ? data.options[0] : null;
    if (resetDownstream) {
      setIsVenueLoading(false);
      setVenueOptions(data ? data.options : []);
      setBuildingOptions([]);
      setFloorOptions([]);
      _onVenueChange(firstValue);
    } else {
      setIsVenueLoading(false);
      setVenueOptions(data ? data.options : []);
    }
  };

  const _getBuildingOptions = (venueId, resetDownstream) => {
    setIsBuildingLoading(true);
    _getOptions("building", "name", {
      venueId: venueId,
    }).then(
      (data) => {
        _onBuildOptionsLoad(data, resetDownstream);
      },
      (err) => {
        _onBuildOptionsLoad(null, resetDownstream);
      }
    );
  };

  const _onBuildOptionsLoad = (data, resetDownstream) => {
    const firstValue = data && data.options.length > 0 ? data.options[0] : null;
    if (resetDownstream) {
      setIsBuildingLoading(false);
      setBuildingOptions(data ? data.options : []);
      setFloorOptions([]);
      _onBuildingChange(firstValue);
    } else {
      setIsBuildingLoading(false);
      setBuildingOptions(data ? data.options : []);
    }
  };

  const _getFloorOptions = (buildingId, resetDownstream) => {
    setIsFloorLoading(true);
    _getOptions("floor", "name", {
      buildingId: buildingId,
    }).then(
      (data) => {
        _onFloorOptionsLoad(data, resetDownstream);
      },
      (err) => {
        _onFloorOptionsLoad(null, resetDownstream);
      }
    );
  };

  const _onFloorOptionsLoad = (data, resetDownstream) => {
    const firstValue = data && data.options.length > 0 ? data.options[0] : null;
    if (resetDownstream) {
      setIsFloorLoading(false);
      setFloorOptions(data ? data.options : []);
      _onFloorChange(firstValue);
    } else {
      setIsFloorLoading(false);
      setFloorOptions(data ? data.options : []);
    }
  };

  const _getOptions = (entity, orderBy, filter) => {
    if (orderBy) {
      if (!filter) {
        filter = {};
      }
      filter.orderBy = orderBy;
      filter.ascendingOrder = true;
    }
    /* return $.post({
      url: '/bstream/api/v1/' + entity + '/find',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded' },
      data: filter,
      dataType: 'json'
    }).then((data) => {
      const options = data.map((item, index) => {
        return {value: item.id, label: item.name}
      })
      return {
        options: options
      }
    },
    (err) => {
      console.error('Failed to fetch list of ' + entity + '! Reason: ', err)
    }) */
    return new Promise((resolve, reject) => {
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/" + entity + "/find",
        {
          dataType: "json",
        },
        filter
      )
        .then((data) => {
          const options = data.map((item, index) => {
            return {
              value: item.id,
              label: item.name,
            };
          });
          resolve({
            options: options,
          });
        })
        .catch((err) => {
          reject(Error(`Failed to fetch list of ${entity}! Reason: , ${err}`));
        });
    });
  };

  return (
    <Row
      style={{
        marginBottom: "10px",
        marginLeft: "-5px",
      }}
    >
      {" "}
      {(() => {
        if (!props.fixedFilterType) {
          return (
            <Col sm={3} md={2} lg={2}>
              <p> {props.filterLabel} </p>
              <Select
                name={"filterType"}
                searchable={false}
                clearable={false}
                value={
                  filterValue //
                }
                options={filterTypeOptions}
                onChange={_onfilterTypeChange}
                menuContainerStyle={{
                  zIndex: 999,
                }}
              />{" "}
            </Col>
          );
        }
      })()}{" "}
      {(() => {
        if (filter.filterType !== "System" && filter.filterType !== undefined) {
          return (
            <Col sm={5} md={2} lg={2}>
              <p> Enterprise </p>
              <Select
                name={"enterprise"}
                searchable
                clearable={false}
                value={
                  enterpriseValue //value.enterpriseId
                }
                isLoading={isEnterpriseLoading}
                options={enterpriseOptions}
                onChange={_onEnterpriseChange}
                menuContainerStyle={{
                  zIndex: 999,
                }}
              />{" "}
            </Col>
          );
        }
      })()}{" "}
      {(() => {
        if (
          _.indexOf(["System", "Enterprise"], filter.filterType) === -1 &&
          filter.filterType !== undefined
        ) {
          return (
            <Col sm={4} md={2} lg={2}>
              <p> Venue </p>
              <Select
                name={"venue"}
                searchable
                clearable={false}
                value={venueValue}
                isLoading={isVenueLoading}
                options={venueOptions}
                onChange={_onVenueChange}
                menuContainerStyle={{
                  zIndex: 999,
                }}
              />{" "}
            </Col>
          );
        }
      })()}{" "}
      {(() => {
        if (
          _.indexOf(["System", "Enterprise", "Venue"], filter.filterType) ===
          -1 &&
          filter.filterType !== undefined
        ) {
          return (
            <Col smOffset={3} mdOffset={0} lgOffset={0} sm={4} md={2} lg={2}>
              <p> Building </p>
              <Select
                name={"building"}
                searchable
                clearable={false}
                value={buildingValue}
                isLoading={isBuildingLoading}
                options={buildingOptions}
                onChange={_onBuildingChange}
                menuContainerStyle={{
                  zIndex: 999,
                }}
              />{" "}
            </Col>
          );
        }
      })()}{" "}
      {(() => {
        if (
          _.indexOf(
            ["System", "Enterprise", "Venue", "Building"],
            filter.filterType
          ) === -1 &&
          filter.filterType !== undefined
        ) {
          return (
            <Col sm={5} md={2} lg={2}>
              <p> Floor </p>{" "}
              <Select
                name={"floor"}
                searchable
                clearable={false}
                value={floorValue}
                isLoading={isFloorLoading}
                options={floorOptions}
                onChange={_onFloorChange}
                menuContainerStyle={{
                  zIndex: 999,
                }}
              />{" "}
            </Col>
          );
        }
      })()}{" "}
    </Row>
  );
}

export default FacilitySelector;
