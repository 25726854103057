import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { AiFillSmile, AiOutlineCalendar } from 'react-icons/ai';
import { FaWalking , FaBed , FaHeartbeat } from 'react-icons/fa';
import { TbHeartbeat, TbDeviceHeartMonitor, TbHeartRateMonitor } from 'react-icons/tb';
import { SiOxygen } from 'react-icons/si';
import { GiWeightLiftingUp } from 'react-icons/gi';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {de} from 'date-fns/locale';
import ApiSingleton from '../../utils/Axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material"
import StatBox from "../../components/StatBox/StatBox"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import { getDate } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'];

export const lineHROptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1 | 2,
  plugins: {
      legend: {
          position: 'top',
          labels: {
              usePointStyle: true,
          },
      },
      // annotation: {
      //     annotations: {
      //         min: {
      //             type: 'line',
      //             borderColor: 'gray',
      //             borderWidth: 2,
      //             scaleID: 'y',
      //             value: 72,
      //             label: {
      //                 display: true,
      //                 content: '72',
      //                 backgroundColor: 'transparent',
      //                 color: 'black',
      //                 position: 'end',
      //                 xAdjust: 10,
      //                 yAdjust: 10,
      //             },
      //         },
      //         max: {
      //             type: 'line',
      //             borderColor: 'gray',
      //             borderWidth: 2,
      //             scaleID: 'y',
      //             value: 119,
      //             label: {
      //                 display: true,
      //                 content: '119',
      //                 backgroundColor: 'transparent',
      //                 color: 'black',
      //                 position: 'end',
      //                 xAdjust: 10,
      //                 yAdjust: 0,
      //             },
      //         },
      //     },
      // },
  },
  scales: {
      x: {
          title: {
              display: true,
              text: 'Hours of the Day',
          },
          ticks: {
              autoSkip: true,
              maxTicksLimit: 24,
          },
      },
      y: {
          title: {
              display: true,
              text: 'Heart Rate BPM',
          },
          position: 'left',
          offset: true,
      },
  },
};
  
  export const barPAOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1 | 2,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Hours',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Active Energy Burned',
        },
      },
    },
  };
  
  export const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2 | 1,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: [],
      },
    },
    scales: {
    },
  };
  
  export function UserDisplayTable() {
    const location = useLocation();
    const [seniorId, setSeniorId] = useState(location.state.id);

    //react-date-picker addition
    const [dateValue, setDate] = useState(new Date("2023-3-5"));
    const [startDate, setStartDate] = useState(""); //2-18-2023
    const [endDate, setEndDate] = useState(""); //6-8-2023

    const [wpaLTData, setWPALTData] = useState({datasets: [],}); 
    const [wpaDailyData, setWPADailyData] = useState({datasets: [],});
    const [wpaDateData, setWPADateData] = useState([]);
    const [wpaDateCount, setWPADateCount] = useState(0);

    const [npaLTData, setNPALTData] = useState({datasets: [],}); 
    const [npaDailyData, setNPADailyData] = useState({datasets: [],});
    const [npaDateData, setNPADateData] = useState([]);
    const [npaDateCount, setNPADateCount] = useState(0);

    const [paOptions, setPAOptions] = useState(barPAOptions);
    const [paDateData, setPADateData] = useState({datasets: [],});

    const [hrDailyData, setHRDailyData] = useState({datasets: [],});
    const [hrDateData, setHRDateData] = useState({datasets: [],}); //{datasets: [],});
    const [hrOptions, setHROptions] = useState(lineHROptions);

    const [hrvDailyData, setHRVDailyData] = useState({datasets: [],});
    const [hrvDateData, setHRVDateData] = useState("N/A");

    const [bosDailyData, setBOSDailyData] = useState({datasets: [],});
    const [bosDateData, setBOSDateData] = useState("N/A");

    const [sbpMonthlyData, setSBPMonthlyData] = useState({datasets: [],});
    const [dbpMonthlyData, setDBPMonthlyData] = useState({datasets: [],});
    const [bpDateData, setBPDateData] = useState("N/A");

    const [ecgDailyData, setECGDailyData] = useState({datasets: [],});
    const [ecgDateData, setECGDateData] = useState("N/A");

    const [rhrDailyData, setRHRDailyData] = useState({datasets: [],});
    const [rhrDateData, setRHRDateData] = useState("N/A");

    const [data, setData] = useState({});

    // function _fetchDailyHR(seniorId) {
    //   const postData = {
    //       seniorName: seniorId,
    //       key: "dayheartRate",
    //   }
    //   let tempData = null;
    //   if (seniorId) {
    //     ApiSingleton.makeHttpRequest(
    //       "post",
    //       "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
    //       { dataType: "json" },
    //       postData
    //     )
    //       .then((data) => {
    //           tempData = JSON.parse(base64_decode(data[0].document));
    //           // console.log("fetched day hr data: ", tempData);
    //       })
    //       .then(data => {
    //         const postData = {
    //           seniorName: seniorId,
    //           key: "restingHeartRate",
    //         }
    //         let rhrtempData = null;
    //         if (seniorId) {
    //           ApiSingleton.makeHttpRequest(
    //             "post",
    //             "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
    //             { dataType: "json" },
    //             postData
    //           )
    //             .then((data) => {
    //               rhrtempData = JSON.parse(base64_decode(data[0].document));
    //               console.log("RHR temp Data: ", rhrtempData);
    //             })
    //             .then(data => {
    //               let currentDate = dateValue;
    //               const dateData = getDateData(currentDate, tempData.daily);

    //               setRHRDailyData(rhrtempData.daily);
    //               const rhrdateData = getDateData(currentDate, rhrtempData.daily);
    //               const rhrDate = getRHRDateData(rhrdateData);
    //               console.log("rhrDate data: ", rhrDate);
      
    //               setRHRDateData(rhrDate);
      
    //               setHRDailyData(tempData.daily); // records all data to pull from later
    //               setDate(currentDate);
      
    //               const hrDate = getHRDateData(dateData);
      
    //               setHROptions({
    //                 responsive: true,
    //                 maintainAspectRatio: true,
    //                 aspectRatio: 1 | 2,
    //                 plugins: {
    //                     legend: {
    //                         position: 'top',
    //                         labels: {
    //                             usePointStyle: true,
    //                         },
    //                     },
    //                     annotation: {
    //                         annotations: {
    //                             min: {
    //                                 type: 'line',
    //                                 borderColor: 'gray',
    //                                 borderWidth: 2,
    //                                 scaleID: 'y',
    //                                 value: tempData.benchmark_personalize.low,
    //                                 label: {
    //                                     display: true,
    //                                     content: tempData.benchmark_personalize.low.toString(),
    //                                     backgroundColor: 'transparent',
    //                                     color: 'black',
    //                                     position: 'end',
    //                                     xAdjust: 10,
    //                                     yAdjust: 10,
    //                                 },
    //                             },
    //                             max: {
    //                                 type: 'line',
    //                                 borderColor: 'gray',
    //                                 borderWidth: 2,
    //                                 scaleID: 'y',
    //                                 value: tempData.benchmark_personalize.high,
    //                                 label: {
    //                                     display: true,
    //                                     content: tempData.benchmark_personalize.high.toString(),
    //                                     backgroundColor: 'transparent',
    //                                     color: 'black',
    //                                     position: 'end',
    //                                     xAdjust: 10,
    //                                     yAdjust: 0,
    //                                 },
    //                             },
    //                         },
    //                     },
    //                 },
    //                 scales: {
    //                     x: {
    //                         title: {
    //                             display: true,
    //                             text: 'Hours of the Day',
    //                         },
    //                         ticks: {
    //                             autoSkip: true,
    //                             maxTicksLimit: 24,
    //                         },
    //                     },
    //                     y: {
    //                         title: {
    //                             display: true,
    //                             text: 'Heart Rate BPM',
    //                         },
    //                         position: 'left',
    //                         offset: true,
    //                     },
    //                     y1: {
    //                       title: {
    //                         display:true,
    //                         text: 'Resting Heart Rate BPM',
    //                       },
    //                       position: 'right',
    //                       offset: true,
    //                     },
    //                 },
    //               })
      
    //               setHRDateData({
    //                   labels: [],
    //                   datasets: [
    //                       {
    //                           label: 'Personalized Threshold',
    //                           data: [],
    //                           pointStyle: 'line',
    //                           backgroundColor: 'black',
    //                           borderColor: 'black',
    //                       },
    //                       {
    //                           label: 'Daytime Heart Rate BPM',
    //                           data: hrDate,
    //                           pointStyle: 'circle',
    //                           backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
    //                           borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
    //                           spanGaps: true,
    //                           yAxisID: 'y',
    //                       },
    //                       {
    //                           label: 'Resting Heart Rate BPM',
    //                           data: rhrDate,
    //                           pointStyle: 'circle',
    //                           backgroundColor: 'rgba(34, 139, 34, 0.5)', // the color of the points
    //                           borderColor: 'rgba(34, 139, 34, 0.5)', // the color of the line
    //                           spanGaps: true,
    //                           yAxisID: 'y1',
    //                           xAxisID: 'x',
    //                     },
    //                   ],
    //               });
    //           })
    //             .catch((err) => {});
    //         }
        
    //         return tempData;
    //       })
    //     //   .then(data => {
    //     //     let currentDate = dateValue;
    //     //     const dateData = getDateData(currentDate, tempData.daily);

    //     //     setHRDailyData(tempData.daily); // records all data to pull from later
    //     //     setDate(currentDate);

    //     //     const hrDate = getHRDateData(dateData);

    //     //     setHROptions({
    //     //       responsive: true,
    //     //       maintainAspectRatio: true,
    //     //       aspectRatio: 1 | 2,
    //     //       plugins: {
    //     //           legend: {
    //     //               position: 'top',
    //     //               labels: {
    //     //                   usePointStyle: true,
    //     //               },
    //     //           },
    //     //           annotation: {
    //     //               annotations: {
    //     //                   min: {
    //     //                       type: 'line',
    //     //                       borderColor: 'gray',
    //     //                       borderWidth: 2,
    //     //                       scaleID: 'y',
    //     //                       value: tempData.benchmark_personalize.low,
    //     //                       label: {
    //     //                           display: true,
    //     //                           content: tempData.benchmark_personalize.low.toString(),
    //     //                           backgroundColor: 'transparent',
    //     //                           color: 'black',
    //     //                           position: 'end',
    //     //                           xAdjust: 10,
    //     //                           yAdjust: 10,
    //     //                       },
    //     //                   },
    //     //                   max: {
    //     //                       type: 'line',
    //     //                       borderColor: 'gray',
    //     //                       borderWidth: 2,
    //     //                       scaleID: 'y',
    //     //                       value: tempData.benchmark_personalize.high,
    //     //                       label: {
    //     //                           display: true,
    //     //                           content: tempData.benchmark_personalize.high.toString(),
    //     //                           backgroundColor: 'transparent',
    //     //                           color: 'black',
    //     //                           position: 'end',
    //     //                           xAdjust: 10,
    //     //                           yAdjust: 0,
    //     //                       },
    //     //                   },
    //     //               },
    //     //           },
    //     //       },
    //     //       scales: {
    //     //           x: {
    //     //               title: {
    //     //                   display: true,
    //     //                   text: 'Hours of the Day',
    //     //               },
    //     //               ticks: {
    //     //                   autoSkip: true,
    //     //                   maxTicksLimit: 24,
    //     //               },
    //     //           },
    //     //           y: {
    //     //               title: {
    //     //                   display: true,
    //     //                   text: 'Heart Rate BPM',
    //     //               },
    //     //               position: 'left',
    //     //               offset: true,
    //     //           },
    //     //       },
    //     //     })

    //     //     setHRDateData({
    //     //         labels: [],
    //     //         datasets: [
    //     //             {
    //     //                 label: 'Personalized Threshold',
    //     //                 data: [],
    //     //                 pointStyle: 'line',
    //     //                 backgroundColor: 'black',
    //     //                 borderColor: 'black',
    //     //             },
    //     //             {
    //     //                 label: 'Daytime Heart Rate BPM',
    //     //                 data: hrDate,
    //     //                 pointStyle: 'circle',
    //     //                 backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
    //     //                 borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
    //     //                 spanGaps: true,
    //     //                 yAxisID: 'y',
    //     //             },
    //     //         ],
    //     //     });
    //     // })
    //       .catch((err) => {});
    //   }
    // }
    function _fetchDailyHR(seniorId) {
      const postData = {
          seniorName: seniorId,
          key: "dayheartRate",
      }
      let tempData = null;
      if (seniorId) {
        ApiSingleton.makeHttpRequest(
          "post",
          "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
          { dataType: "json" },
          postData
        )
          .then((data) => {
              tempData = JSON.parse(base64_decode(data[0].document));
              // console.log("fetched day hr data: ", tempData);
          })
          .then(data => {
            let currentDate = dateValue;
            const dateData = getDateData(currentDate, tempData.daily);

            setHRDailyData(tempData.daily); // records all data to pull from later
            setDate(currentDate);

            const hrDate = getHRDateData(dateData);

            setHROptions({
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 1 | 2,
              plugins: {
                  legend: {
                      position: 'top',
                      labels: {
                          usePointStyle: true,
                      },
                  },
                  annotation: {
                      annotations: {
                          min: {
                              type: 'line',
                              borderColor: 'gray',
                              borderWidth: 2,
                              scaleID: 'y',
                              value: tempData.benchmark_personalize.low,
                              label: {
                                  display: true,
                                  content: tempData.benchmark_personalize.low.toString(),
                                  backgroundColor: 'transparent',
                                  color: 'black',
                                  position: 'end',
                                  xAdjust: 10,
                                  yAdjust: 10,
                              },
                          },
                          max: {
                              type: 'line',
                              borderColor: 'gray',
                              borderWidth: 2,
                              scaleID: 'y',
                              value: tempData.benchmark_personalize.high,
                              label: {
                                  display: true,
                                  content: tempData.benchmark_personalize.high.toString(),
                                  backgroundColor: 'transparent',
                                  color: 'black',
                                  position: 'end',
                                  xAdjust: 10,
                                  yAdjust: 0,
                              },
                          },
                      },
                  },
              },
              scales: {
                  x: {
                      title: {
                          display: true,
                          text: 'Hours of the Day',
                      },
                      ticks: {
                          autoSkip: true,
                          maxTicksLimit: 24,
                      },
                  },
                  y: {
                      title: {
                          display: true,
                          text: 'Heart Rate BPM',
                      },
                      position: 'left',
                      offset: true,
                  },
              },
            })

            setHRDateData({
                labels: [],
                datasets: [
                    {
                        label: 'Personalized Threshold',
                        data: [],
                        pointStyle: 'line',
                        backgroundColor: 'black',
                        borderColor: 'black',
                    },
                    {
                        label: 'Daytime Heart Rate BPM',
                        data: hrDate,
                        pointStyle: 'circle',
                        backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                        borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                        spanGaps: true,
                        yAxisID: 'y',
                    },
                ],
            });
        })
          .catch((err) => {});
      }
    }

    function _fetchDailyPA(seniorId) {
      const postData = {
          seniorName: seniorId,
          key: "daymore1G",
      }
      let tempData = null;
      if (seniorId) {
        ApiSingleton.makeHttpRequest(
          "post",
          "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
          { dataType: "json" },
          postData
        )
          .then((data) => {
              tempData = JSON.parse(base64_decode(data[0].document));
              console.log("fetched day pa data: ", tempData);
          })
          .then(data => {
            const postData = {
              seniorName: seniorId,
              key: "nightmore1G",
            }
            let tempData1 = null;
            if (seniorId) {
              ApiSingleton.makeHttpRequest(
                "post",
                "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
                { dataType: "json" },
                postData
              )
                .then((data) => {
                    tempData1 = JSON.parse(base64_decode(data[0].document));
                    console.log("fetched night pa data: ", tempData1);
                })
                .then(data => {
                  console.log("****setting pa date data");
                  let currentDate = dateValue;
                  const wltData = getLongtermData(currentDate, tempData.longterm);
                  const wpaltDate = getDateData(currentDate, wltData);
                  const nltData = getLongtermData(currentDate, tempData1.longterm);
                  const npaltDate = getDateData(currentDate, nltData);
                  // console.log("*** daily_count: ", npaltDate[0].daily_count);

                  setWPALTData(tempData.longterm);
                  setWPADateCount(wpaltDate[0].daily_count);

                  setNPALTData(tempData1.longterm);
                  setNPADateCount(npaltDate[0].daily_count);

                  const wpaData = getDateData(currentDate, tempData.daily);
                  const npaData = getDateData(currentDate, tempData1.daily);

                  setWPADailyData(tempData.daily);
                  setNPADailyData(tempData1.daily);

                  const wpaDate = getPADateData(wpaData);
                  const npaDate = getPADateData(npaData);

                  setWPADateData(wpaDate);
                  setNPADateData(npaDate);

                  setPADateData({
                    labels: ['Waking Activity Ratio', 'Night Activity Ratio'],
                    datasets: [
                      {
                        label: 'Activity Dataset',
                        data: [wpaDate/(wpaDate + npaDate), npaDate/(wpaDate + npaDate)],
                        backgroundColor: ['rgba(170, 1, 20, 0.5)', 'rgba(68, 33, 18, 0.5)'],
                        borderColor: ['rgba(170, 1, 20, 0.5)', 'rgba(68, 33, 18, 0.5)'],
                      },
                    ],
                  });  
                })
                .catch((err) => {});
            }
          })
          .catch((err) => {});
      }

    }

    function _fetchDailyHRV(seniorId) {
      const postData = {
          seniorName: seniorId,
          key: "heartRateVariability",
      }
      let tempData = null;
      if (seniorId) {
        ApiSingleton.makeHttpRequest(
          "post",
          "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
          { dataType: "json" },
          postData
        )
          .then((data) => {
              tempData = JSON.parse(base64_decode(data[0].document));
              // console.log("fetched hrv data: ", tempData);
          })
          .then(data => {
            // console.log("setting HRV data");
            setHRVDailyData(tempData.daily);
  
            let currentDate = dateValue;
            const dateData = getDateData(currentDate, tempData.daily);
            const hrvDate = getHRVDateData(dateData);

            setHRVDateData(hrvDate);
          })
          .catch((err) => {});
      }
  
      return tempData;
    }

    function _fetchDailyBOS(seniorId) {
      const postData = {
          seniorName: seniorId,
          key: "bloodOxygenSaturation",
      }
      let tempData = null;
      if (seniorId) {
        ApiSingleton.makeHttpRequest(
          "post",
          "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
          { dataType: "json" },
          postData
        )
          .then((data) => {
            tempData = JSON.parse(base64_decode(data[0].document));
          })
          .then(data => {
            setBOSDailyData(tempData.daily);

            let currentDate = dateValue;
            const dateData = getDateData(currentDate, tempData.daily);
            const bosDate = getBOSDateData(dateData);

            setBOSDateData(bosDate);
            setStartDate(tempData.date.start);
            setEndDate(tempData.date.end);
          })
          .catch((err) => {});
      }
  
      return tempData;
    }

    function _fetchDailyBP(seniorId) {
      console.log("entered fetch SBP data");
      const postData = {
          seniorName: seniorId,
          key: "systolic",
      }
      let tempData = null;
      if (seniorId) {
        ApiSingleton.makeHttpRequest(
          "post",
          "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
          { dataType: "json" },
          postData
        )
          .then((data) => {
            console.log("getting sys sbp data from API");
              tempData = JSON.parse(base64_decode(data[0].document));
              console.log("fetched sys sbp data: ", tempData);
          })
          .then((data) => {
              console.log("entered fetch SBP data");
              const postData = {
                  seniorName: seniorId,
                  key: "diastolic",
              }
              let tempData1 = null;
              if (seniorId) {
                ApiSingleton.makeHttpRequest(
                  "post",
                  "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
                  { dataType: "json" },
                  postData
                )
                  .then((data) => {
                    console.log("getting dia bp data from API");
                      tempData1 = JSON.parse(base64_decode(data[0].document));
                      console.log("fetched dia bp data: ", tempData1);
                  })
                  .then(data => {
                    console.log("setting SBP data");
                    let currentDate = dateValue
  
                    const sDateData = getBPDateData(currentDate, tempData.longterm);
                    const dDateData = getBPDateData(currentDate, tempData1.longterm);
                    
                    setSBPMonthlyData(tempData.longterm);
                    setDBPMonthlyData(tempData1.longterm);
                    setBPDateData(sDateData.toString() + "/" + dDateData.toString());
                  })
                  .catch((err) => {});
              }
          })
          .catch((err) => {});
      }
  
      return tempData;
    }

    function _fetchDailyRHR(seniorId) {
      const postData = {
          seniorName: seniorId,
          key: "restingHeartRate",
      }
      let tempData = null;
      if (seniorId) {
        ApiSingleton.makeHttpRequest(
          "post",
          "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
          { dataType: "json" },
          postData
        )
          .then((data) => {
            tempData = JSON.parse(base64_decode(data[0].document));
            console.log("RHR temp Data: ", tempData);
          })
          .then(data => {
            setRHRDailyData(tempData.daily);

            let currentDate = dateValue;
            const dateData = getDateData(currentDate, tempData.daily);
            const rhrDate = getRHRDateData(dateData);

            setRHRDateData(rhrDate);
          })
          .catch((err) => {});
      }
  
      return tempData;
    }
  
    useEffect(() => {

      _fetchDailyHR(seniorId);
      _fetchDailyPA(seniorId);
      _fetchDailyHRV(seniorId);
      _fetchDailyBOS(seniorId);
      _fetchDailyBP(seniorId);
      _fetchDailyRHR(seniorId);
  
      console.log("useEffect ran here");
    }, []);
  
    // finds the right data that corresponds to the date
    function getDateData (date, user) {
      // console.log("**** entered getDateData function");
      // console.log("Get date data user: ", user);
      if (date == null || user[0] == null)
        return null;

      var preDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
      var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
  
      const dateData = []
      let i = 0;
      let n = 0;
      var currDate = new Date(user[0].year, user[0].month - 1, user[0].day); // month - 1 because month is 0 - 11 not 1 - 12
  
      while (currDate < afterDate) {
        currDate = new Date(user[i].year, user[i].month - 1, user[i].day);
        i++;
  
        // at the correct date
        if (currDate > preDate && currDate < afterDate) {
          dateData[n] = user[i];
          n++;
        }
        else {
          continue;
        }
      }
  
      // PROBLEM: getting one more data point than intended at the end
      // console.log("date data: ", dateData);
      return dateData;
    }

    function getMonthData (date, data) {
      if (data == null) {
        console.log("data is null");
        return data;
      }
      return data;
    }

    // finds the month that current date is in
    // currently fixed (need to change later)
    function getLongtermData(date, data) {
      return data;
    }
  
    // returns data for daily graph display
    function getHRDateData(date_data) {
      const dateData = []; // each element is a pair of {"hour", "heart rate value"}
      let i = 0;
      let dataLen = date_data.length;

      while (i < dataLen - 1) {
          dateData[i] = [date_data[i].hour.toString(), date_data[i].heartRate];
          i++;
      }
      return dateData;
    }

    // returns data for daily graph display
    function getPADateData(date_data) {
      let dateData = 0;
      let dataLen = date_data.length;
      console.log("dataLen: ", dataLen);

      for (let i = 0; i < dataLen-1; i++) {
        dateData = dateData + parseInt(date_data[i].more1G);
      }

      return dateData;
  }
  
    // get the BOS data for current date
    function getBOSDateData (date_data) {
      if (date_data == null) {
        console.log("data is null");
        return date_data;
      }
      const dateData = []; // each element is a pair of {"hour", "bos"}
      let i = 0;
      let n = 0;
      let prev = -1;
  
      if (date_data.length > 0) {
        while (i < 24) {
          if (date_data[n].hour != i) {
              if (date_data[n].hour == prev) {
                  n++;
                  continue;
              }
              else {
                  dateData[i] = [i.toString(), null];
              }   
          }
          else {
            return date_data[n].bloodOxygenSaturation;
          }
          i++;
        }
      }
    
      return "N/A"; 
    }
  
    // get the HRV data for current date
    function getHRVDateData (date_data) {
      if (date_data == null) {
        console.log("data is null");
        return date_data;
      }
      const dateData = []; // each element is a pair of {"hour", "hrv"}
      let i = 0;
      let n = 0;
      let prev = -1;
  
      if (date_data.length > 0) {
        while (i < 24) {
          if (date_data[n].hour != i) {
              if (date_data[n].hour == prev) {
                  n++;
                  continue;
              }
              else {
                  dateData[i] = [i.toString(), null];
              }   
          }
          else {
            return date_data[n].heartRateVariability;
          }
          i++;
        }
      }
    
      return "N/A";
    }

    function getBPDateData (date, data) {
      var preDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
      var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      var currDate = new Date(data[0].year, data[0].month - 1, data[0].day); // month - 1 because month is 0 - 11 not 1 - 12
      let i = 0;
      
      while (currDate < afterDate) {
        currDate = new Date(data[i].year, data[i].month - 1, data[i].day);
        i++;
  
        // at the correct date
        if (currDate > preDate && currDate < afterDate) {
          return data[i].median;
        }
        else {
          continue;
        }
      }

      return "N/A";
    }

     // get the RHR data for current date
    //  function getRHRDateData (date_data) {
    //   if (date_data == null) {
    //     console.log("data is null");
    //     return date_data;
    //   }
    //   const dateData = []; // each element is a pair of {"hour", "resting hr"}
    //   let i = 0;
    //   let n = 0;
    //   let prev = -1;
  
    //   // if (date_data.length > 0) {
    //   //   while (i < 24) {
    //   //     if (date_data[n].hour != i) {
    //   //         if (date_data[n].hour == prev) {
    //   //             n++;
    //   //             continue;
    //   //         }
    //   //         else {
    //   //             dateData[i] = [i.toString(), null];
    //   //         }   
    //   //     }
    //   //     else {
    //   //         dateData[i] = [date_data[n].hour.toString(), date_data[n].restingHeartRate]; //date_data[n].hour.toString(), 
    //   //         prev = date_data[n].hour;
    //   //         n++;
    //   //     }
    //   //     i++;
    //   //   }
    //   // }
      
    //   if (date_data.length > 0) {
    //     while (i < 24) {
    //       if (date_data[n].hour == prev) {
    //         n++;
    //         continue;
    //       }
    //       else {
    //         dateData[i] = [date_data[n].hour.toString(), date_data[n].restingHeartRate];
    //         prev = date_data[n].hour;
    //         n++;
    //       }
    //       i++;
    //     }
    //   }

    //   return dateData;
    // }
    function getRHRDateData (date_data) {
      if (date_data == null) {
        console.log("data is null");
        return date_data;
      }
      const dateData = []; // each element is a pair of {"hour", "rhr"}
      let i = 0;
      let n = 0;
      let prev = -1;
  
      if (date_data.length > 0) {
        while (i < 24) {
          if (date_data[n].hour != i) {
              if (date_data[n].hour == prev) {
                  n++;
                  continue;
              }
              else {
                  dateData[i] = [i.toString(), null];
              }   
          }
          else {
            return date_data[n].restingHeartRate;
          }
          i++;
        }
      }
    
      return "N/A"; 
    }
  
    // handles date change event
    function handleDateChange(event) {
      setDate(event);
      console.log("event time is: ", event);

      // const d = getDateData(event, dailyData);
      const hrvD = getDateData(event, hrvDailyData);
      const bosD = getDateData(event, bosDailyData);
      const hrD = getDateData(event, hrDailyData);
      const rhrD = getDateData(event, rhrDailyData);
      const wpaD = getDateData(event, wpaDailyData);
      const npaD = getDateData(event, npaDailyData);

      const hrvDate = getHRVDateData(hrvD);
      setHRVDateData(hrvDate);

      const bosDate = getBOSDateData(bosD);
      setBOSDateData(bosDate);

      const rhrDate = getRHRDateData(rhrD);
      setRHRDateData(rhrDate);

      const hrDate = getHRDateData(hrD);
      setHRDateData({
        labels: [],
        datasets: [
          {
              label: 'Personalized Threshold',
              data: [],
              pointStyle: 'line',
              backgroundColor: 'black',
              borderColor: 'black',
          },
          {
              label: 'Heart Rate BPM',
              data: hrDate,
              pointStyle: 'circle',
              backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
              borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
              spanGaps: true,
              yAxisID: 'y',
          },
          // {
          //     label: 'Resting Heart Rate BPM',
          //     data: rhrDate,
          //     pointStyle: 'circle',
          //     backgroundColor: 'rgba(34, 139, 34, 0.5)', // the color of the points
          //     borderColor: 'rgba(34, 139, 34, 0.5)', // the color of the line
          //     spanGaps: true,
          //     yAxisID: 'y',
          // },
        ],
      });

      const wpaDate = getPADateData(wpaD);
      const npaDate = getPADateData(npaD);
      setWPADateData(wpaDate);
      setNPADateData(npaDate);

      setPADateData({
        labels: ['Waking Activity Ratio', 'Night Activity Ratio'],
        datasets: [
          {
            label: 'Activity Dataset',
            data: [wpaDate/(wpaDate + npaDate), npaDate/(wpaDate + npaDate)],
            backgroundColor: ['rgba(170, 1, 20, 0.5)', 'rgba(68, 33, 18, 0.5)'],
            borderColor: ['rgba(170, 1, 20, 0.5)', 'rgba(68, 33, 18, 0.5)'],
          },
        ],
      });

      const sDateData = getBPDateData(event, sbpMonthlyData);
      const dDateData = getBPDateData(event, dbpMonthlyData);
      setBPDateData(sDateData.toString() + "/" + dDateData.toString());
    }
  
    // navigation to statistical analysis page
    const navigate = useNavigate();
    function handleGoHRStats(e) {
        e.preventDefault();
        navigate('/dhrStats', {state: {id: seniorId}});
    }
  
    // navigate to BOS stats analysis page
    function handleGoBOSStats(e) {
      e.preventDefault();
      navigate('/bosStats', {state: {id: seniorId}});
    }
  
    // navigate to Step Count stats analysis page
    function handleGoSCStats(e) {
      e.preventDefault();
      navigate('/scStats', {state: {id: seniorId}});
    }
  
    // navigate to Waking Activity Stats Analysis Page
    function handleGoWakingPAStats(e) {
      e.preventDefault();
      navigate('/wpaStats', {state: {id: seniorId}});
    }
  
    // navigate to Sleeping Activity Stats Analysis Page
    function handleGoSleepingPAStats(e) {
      e.preventDefault();
      navigate('/spaStats', {state: {id: seniorId}});
    }

    //navigate to BP Stats Analysis Page
    function handleGoBPStats(e) {
        e.preventDefault();
        navigate('/bpStats', {state: {id: seniorId}});
    }

    //navigate to HRV Stats Analysis Page
    function handleGoHRVStats(e) {
        e.preventDefault();
        navigate('/hrvStats', {state: {id: seniorId}});
    }

    //navigate to ECG Stats Analysis Page
    function handleGoECGStats(e) {
        e.preventDefault();
        navigate('/ecgStats', {state: {id: seniorId}});
    }

    //navigate to Resting Heart Rate Analysis Page
    function handleGoRHRStats(e) {
      e.preventDefault();
      navigate('/rhrStats', {state: {id: seniorId}});
    }
  
    return (
      <Box m="20px"> 
          <Box display="flex" justifycontext="space-between" alignItems="center">
              <Box width="100%">
                  <Typography variant="h3" fontWeight="bold" sx={{ color: "#530C0C" }}>{seniorId}</Typography>
              </Box>
              <Box>
                <DatePicker showIcon={true} minDate={new Date(startDate)} maxDate={new Date(endDate)} onChange={handleDateChange} selected={dateValue} />
              </Box>
          </Box>
  
          {/* GRID AND CHARTS */}
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
  
              {/** ROW 1 */}
              {/* <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title="Normal" subtitle="Risk Profile" icon={<AiFillSmile color='green' size='50px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title="Normal" subtitle="Wellness Profile" icon={<GiWeightLiftingUp color='green' size='50px' />}></StatBox>
              </Box> */}
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={startDate} subtitle="Start Trial" icon={<GiWeightLiftingUp color='green' size='0px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={endDate} subtitle="End Trial" icon={<GiWeightLiftingUp color='green' size='0px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoHRVStats}>
                <StatBox title={hrvDateData} subtitle="HRV" icon={<TbHeartbeat color='#530C0C' size='50px' />}></StatBox>
              </Box>

              {/** ROW 2 */}
              <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" onClick={handleGoHRStats} >
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Heart Rate
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        <FaHeartbeat color='#530C0C' />
                    </Box>
                </Box>
                <Box height="250px" m="0px 25px">
                    <Line options={hrOptions} data={hrDateData} className='graph' id="chartHR" key={Math.random()} />
                </Box>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoBPStats} >
                <StatBox title={bpDateData} subtitle="Blood Pressure" icon={<TbDeviceHeartMonitor color='#530C0C' size='50px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoBOSStats} >
                <StatBox title={bosDateData} subtitle="Blood Oxygen Sat" icon={<SiOxygen color='#530C0C' size='50px' />}></StatBox>
              </Box>

              {/** ROW 3 */}
              <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE" onClick={handleGoWakingPAStats}>
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Physical Activity Ratio
                        </Typography>
                    </Box>
                    <Box m="0px 5px">
                        <FaWalking color='#530C0C' />
                    </Box>
                </Box>
                <Box height="225px" m="0px 0px">
                    <Doughnut options={pieOptions} data={paDateData} className='graph' size='200px' />
                </Box>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={wpaDateCount} subtitle="Waking Activity Count" icon={<FaWalking color='#530C0C' size='50px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={ecgDateData} subtitle="ECG" icon={<TbHeartRateMonitor color='#530C0C' size='50px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={npaDateCount} subtitle="Night Activity Count" icon={<FaBed color='#530C0C' size='50px' />}></StatBox>
              </Box>
              <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoRHRStats} >
                <StatBox title={rhrDateData} subtitle="Resting Heart Rate" icon={<FaHeartbeat color='#530C0C' size='50px' />}></StatBox>
              </Box>
          </Box>
      </Box>
    );
  }
  
  export default UserDisplayTable;


      // <Box m="20px"> 
      //     <Box display="flex" justifycontext="space-between" alignItems="center">
      //         <Box width="100%">
      //             <Typography variant="h3" fontWeight="bold" sx={{ color: "#530C0C" }}>{seniorId}</Typography>
      //         </Box>
      //         <Box>
      //           <DatePicker showIcon={true} minDate={new Date("2023-2-18")} maxDate={new Date("2023-6-8")} onChange={handleDateChange} selected={dateValue} />
      //         </Box>
      //     </Box>
  
      //     {/* GRID AND CHARTS */}
      //     <Box
      //       display="grid"
      //       gridTemplateColumns="repeat(12, 1fr)"
      //       gridAutoRows="140px"
      //       gap="20px"
      //     >
  
      //         {/** ROW 1 */}
      //         <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" onClick={handleGoHRStats} >
      //             <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
      //                 <Box>
      //                     <Typography variant="h5" fontWeight="600" color="#530C0C">
      //                         Heart Rate
      //                     </Typography>
      //                 </Box>
      //                 <Box m="0px 8px">
      //                     <FaHeartbeat color='#530C0C' />
      //                 </Box>
      //             </Box>
      //             <Box height="250px" m="0px 25px">
      //                 <Line options={hrOptions} data={hrDateData} className='graph' id="chartHR" key={Math.random()} />
      //                 {/* {console.log("Current HR Data: ", data.users[0].hr)} */}
      //             </Box>
      //         </Box>
      //         <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
      //             <StatBox title="Normal" subtitle="Risk Profile" icon={<AiFillSmile color='green' size='50px' />}></StatBox>
      //         </Box>
      //         <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
      //             <StatBox title="Normal" subtitle="Wellness Profile" icon={<GiWeightLiftingUp color='green' size='50px' />}></StatBox>
      //         </Box>
  
      //         {/** ROW 2 */}
      //         <Box gridColumn="span 4" gridRow="span 2" backgroundColor="#DEDEDE" onClick={handleGoWakingPAStats}>
      //             <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
      //                 <Box>
      //                     <Typography variant="h5" fontWeight="600" color="#530C0C">
      //                         Physical Activity Ratio
      //                     </Typography>
      //                 </Box>
      //                 <Box m="0px 5px">
      //                     <FaWalking color='#530C0C' />
      //                 </Box>
      //             </Box>
      //             <Box height="225px" m="0px 0px">
      //                 <Doughnut options={pieOptions} data={paDateData} className='graph' size='200px' />
      //             </Box>
      //         </Box>
      //         <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoBOSStats} >
      //             <StatBox title={wpaDateCount} subtitle="Waking Activity Count" icon={<FaWalking color='#530C0C' size='50px' />}></StatBox>
      //         </Box>
      //         <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoBOSStats} >
      //             <StatBox title={bosDateData} subtitle="Blood Oxygen Sat" icon={<SiOxygen color='#530C0C' size='50px' />}></StatBox>
      //         </Box>
      //         <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoHRVStats}>
      //             <StatBox title={npaDateCount} subtitle="Night Activity Count" icon={<FaBed color='#530C0C' size='50px' />}></StatBox>
      //         </Box>
      //         <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoHRVStats}>
      //             <StatBox title={hrvDateData} subtitle="HRV" icon={<TbHeartbeat color='#530C0C' size='50px' />}></StatBox>
      //         </Box>
      //     </Box>
      // </Box>