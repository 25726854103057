import React from "react";
import LoginPage from "../routes/Login/LoginPage";
import Dashboard from "../routes/Dashboard2/Dashboard";
import FacilityPanel from "../routes/FacilityConfig/FacilityPanel";
import SubscriberPanel from "../routes/Subscriber/SubscriberPanel";
import CareGiverPanel from "../routes/CareGiver/CareGiverPanel";
import SeniorPanel from "../routes/Senior/SeniorPanel";
import AdminPanel from "../routes/Admin/AdminPanel";
import RelayPanel from "../routes/RelayConfig/RelayPanel";
import StationPanel from "../routes/Station/StationPanel";
import SensorPanel from "../routes/Sensor/SensorPanel";
import EnergyPanel from "../routes/Energy/EnergyPanel";
import DailyEnergyPanel from "../routes/DailyEnergy/DailyEnergyPanel";
import ActivityPanel from "../routes/Activity/ActivityPanel";
import StepCountPanel from "../routes/StepCount/StepCountPanel";
import TemperaturePanel from "../routes/Temperature/TemperaturePanel";
import GroupPolicyPanel from "../routes/GroupPolicy/GroupPolicyPanel";
import ECGPanel from "../routes/ECG/ECGPanel";
import SeniorSummaryReport from "../routes/SeniorSummaryReport/SeniorSummaryReport";
import Report from "../routes/SeniorIndividualReport/Report";
import Questionnaire from "../routes/Questionnaire/Questionnaire";
import SeniorCaregiverPanel from "../routes/CareGiverSenior/SeniorCaregiverPanel";
import Main from "../routes/CareGiverDashboard/Main";
import MenuWithSuspense from "../components/Menu";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import TopNav from "../components/TopNav";
import constants from "../configs/constants";
import "../styles/core.scss";
import "../layouts/CoreLayout/CoreLayout.scss";

import UserListPanel from "../routes/UserList/UserListPanel";
import UserDisplayPanel from "../routes/UserDisplay/UserDisplayPanel";
import BOSStatsPanel from "../routes/BOSStats/BOSStatsPanel";
import BloodPressureStatsPanel from "../routes/BloodPressureStats/BloodPressureStatsPanel";
import DayHeartRateStatsPanel from "../routes/HeartRateStats/DayHeartRateStatsPanel";
import NightHeartRateStatsPanel from "../routes/HeartRateStats/NightHeartRateStatsPanel";
import HeartRateVarStatsPanel from "../routes/HeartRateVarStats/HeartRateVarStatsPanel";
import SleepingPhysicalActivityStatsPanel from "../routes/PhysicalActivityStats/SleepingPhysicalActivityStatsPanel";
import WakingPhysicalActivityStatsPanel from "../routes/PhysicalActivityStats/WakingPhysicalActivityStatsPanel";
import StepCountStatsPanel from "../routes/StepCountStats/StepCountStatsPanel";
import ECGStatsPanel from "../routes/ECGStats/ECGStatsPanel";
import RestingHeartRateStatsPanel from "../routes/RestingHeartRateStats/RestingHeartRateStatsPanel";
import AggregateDisplayPanel from "../routes/AggregateDisplay/AggregateDisplayPanel";
import DataConsistencyPanel from "../routes/DataConsistency/DataConsistencyPanel";
import AllPatientsPanel from "../routes/DataConsistency/AllPatientsPanel";

const AppContainer = () => {
  const userData = JSON.parse(
    window.localStorage.getItem(constants.userDataKeyName)
  );
  const login = {
    isAdmin: userData ? userData.isAdmin : false,
  };

  if (userData) {
    
    // if user exist . 

    return (
        <Router>
          <div id="wrapper" className="row">
            <MenuWithSuspense login={login} location={window.location} />
            <div
              id="page-wrapper"
              className="gray-bg"
              style={{ minHeight: "100vh" }}
            >
              <TopNav username={userData.userName || ""} />
              <Routes>
                <Route path="/" element={<Navigate replace to="/dashboard2" />} />
                {/* Admin Dashboard Route */}
                <Route path="/dashboard2" element={<Dashboard />} />
                {/* Caregiver Dashboard Route */}
                <Route path="/dashboardCaregiver" element={<Main />} />
                {/* Facilities Route */}
                <Route exact path="/facilities" element={<FacilityPanel />} />
                {/* People Routes */}
                <Route
                  exact
                  path="/subscribers"
                  element={<SubscriberPanel />}
                />
                <Route exact path="/caregivers" element={<CareGiverPanel />} />
                <Route exact path="/seniors" element={<SeniorPanel />} />
                <Route exact path="/admins" element={<AdminPanel />} />
                <Route exact path="/stations" element={<StationPanel />} />
                {/* Device Routes */}
                <Route exact path="/relays" element={<RelayPanel />} />
                <Route exact path="/bands" element={<SensorPanel />} />
                <Route exact path="/energy" element={<EnergyPanel />} />
                {/* ADDED PATHS */}
                <Route 
                  exact
                  path="/dataConsistency"
                  element={<DataConsistencyPanel />}
                />
                <Route 
                  exact
                  path="/allPatients"
                  element={<AllPatientsPanel />}
                />
                <Route
                  exact
                  path="/aggregateDisplay"
                  element={<AggregateDisplayPanel />}
                />
                <Route
                  exact
                  path="/userList"
                  element={<UserListPanel />}
                />
                <Route
                  exact
                  path="/userDisplay"
                  element={<UserDisplayPanel />}
                />
                <Route
                  exact
                  path="/bosStats"
                  element={<BOSStatsPanel />}
                />
                <Route
                  exact
                  path="/bpStats"
                  element={<BloodPressureStatsPanel />}
                />
                <Route
                  exact
                  path="/nhrStats"
                  element={<NightHeartRateStatsPanel />}
                />
                <Route
                exact
                path="/dhrStats"
                element={<DayHeartRateStatsPanel />}
                />
                <Route
                exact
                path="/rhrStats"
                element={<RestingHeartRateStatsPanel />}
                />
                <Route
                exact
                path="/hrvStats"
                element={<HeartRateVarStatsPanel />}
                />
                <Route
                exact
                path="/spaStats"
                element={<SleepingPhysicalActivityStatsPanel />}
                />
                <Route
                exact
                path="/wpaStats"
                element={<WakingPhysicalActivityStatsPanel />}
                />
                <Route
                exact
                path="/scStats"
                element={<StepCountStatsPanel />}
                />
                <Route
                exact
                path="/ecgStats"
                element={<ECGStatsPanel />}
                />
                {/* Activities Routes */}
                <Route
                  exact
                  path="/dailyEnergy"
                  element={<DailyEnergyPanel />}
                />
                <Route exact path="/activity" element={<ActivityPanel />} />
                <Route exact path="/stepCount" element={<StepCountPanel />} />
                {/* Other Sensors Routes */}
                <Route
                  exact
                  path="/temperature"
                  element={<TemperaturePanel />}
                />
                <Route exact path="/ECG" element={<ECGPanel />} />
                {/* Policy Routes */}
                <Route
                  exact
                  path="/GroupPolicy"
                  element={<GroupPolicyPanel />}
                />
                <Route
                  exact
                  path="/SeniorIndividualReports"
                  element={<Report />}
                />
                {/* Reports Routes */}
                <Route
                  exact
                  path="/SeniorSummaryReports"
                  element={<SeniorSummaryReport />}
                />
                <Route
                  exact
                  path="/Questionnaire"
                  element={<Questionnaire />}
                />
                <Route
                  exact
                  path="/seniorsCaregiver"
                  element={<SeniorCaregiverPanel />}
                />
              </Routes>
            </div>
          </div>
        </Router>
    );
  } else {
    return (
      <div style={{ height: "100%" }}>
        <Router history={history}>
          <Routes>
            <Route exact path="/login" element={<LoginPage />} />
          </Routes>
        </Router>
      </div>
    );
  }
};

export default AppContainer;
