import React, { useState, useEffect } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { AiFillSmile, AiOutlineCalendar } from 'react-icons/ai'
import { FaWalking , FaBed , FaHeartbeat } from 'react-icons/fa'
import { TbHeartbeat, TbDeviceHeartMonitor, TbHeartRateMonitor } from 'react-icons/tb'
import { SiOxygen } from 'react-icons/si' 
import { GiWeightLiftingUp } from 'react-icons/gi'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import ApiSingleton from '../../utils/Axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material"
import StatBox from "../../components/StatBox/StatBox"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'];

export const lineDateOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1 | 2,
  plugins: {
        legend: {
            position: 'top',
            labels: {
                usePointStyle: true,
            },
        },
        scaleID: 'y',
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Hours of the Day',
            },
            ticks: {
                autoSkip: true,
                maxTicksLimit: 24,
            },
        },
        y: {
            title: {
                display: true,
                text: 'Heart Rate BPM',
            },
            position: 'left',
            offset: true,
        },
    },
};

export const lineOutlierOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1 | 2,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                usePointStyle: true,
            },
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Date',
            },
            offset: true,
        },
        y: {
            title: {
                display: true,
                text: 'Upper Outlier Count',
            },
            position: 'left',
            max: 50,
        },
    },
};

export const lineLongtermOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1 | 2,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                usePointStyle: true,
            },
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Date',
            },
            offset: true,
        },
        y: {
            title: {
                display: true,
                text: 'Heart Rate BPM',
            },
            position: 'left',
            offset: true,
        },
    },
};

export function NightHeartRateStats() {
    const {state} = useLocation();
    const [seniorId, setSeniorId] = useState(state.id);

    //react-date-picker addition
    const [dateValue, setDate] = useState(new Date("2023-3-3"));
    const [endDateValue, setEndDateValue] = useState(new Date("2023-6-8"));
    const [startDate, setStartDate] = useState(""); //2-18-2023
    const [endDate, setEndDate] = useState(""); //6-8-2023

    // stores all user data
    const [dailyData, setDailyData] = useState({ datasets: [], });
    const [longtermData, setLongtermData] = useState({ datasets: [], });
    const [weeklyData, setWeeklyData] = useState({ datasets: [], });

    // stores current display options
    const [hrDateOptions, setHRDateOptions] = useState(lineDateOptions);
    const [hrOutlierOptions, setHROutlierOptions] = useState(lineLongtermOptions);
    const [hrLongtermOptions, setHRLongtermOptions] = useState(lineLongtermOptions);

    // stores current display data
    const [hrDateData, setHRDateData] = useState({ datasets: [], });
    const [hrOutlierData, setHROutlierData] = useState({ datasets: [], });
    const [hrLongtermData, setHRLongtermData] = useState({ datasets: [], });

    const [hrDateMax, setHRDateMax] = useState("N/A");
    const [hrDateMin, setHRDateMin] = useState("N/A");
    const [hrDateMed, setHRDateMed] = useState("N/A");

    const [hrLongtermMax, setHRLongtermMax] = useState("N/A");
    const [hrLongtermMin, setHRLongtermMin] = useState("N/A");
    const [hrLongtermMed, setHRLongtermMed] = useState("N/A");

    const [hrMaxLine, setHRMaxLine] = useState([]);
    const [hrMinLine, setHRMinLine] = useState([]);
    const [hrMedLine, setHRMedLine] = useState([]);

    function _fetchNightHR(seniorId) {
        const postData = {
            seniorName: seniorId,
            key: "nightheartRate",
        }
        let tempData = null;
        if (seniorId) {
          ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                // console.log("getting night hr data from API");
                tempData = JSON.parse(base64_decode(data[0].document)); //data[0].document; 
                // console.log("fetched night hr data: ", tempData);
            })
            .then(data => {
                // console.log("setting night hr Data");
                let currentDate = dateValue;
                // console.log("getting current night date data");
                const dateData = getDateData(currentDate, tempData.daily);
                // console.log("date data: ", dateData);
                
                setDailyData(tempData); // records all data to pull from later
                setDate(currentDate);
    
                const hrDate = getHRDateData(dateData);
                const ltData = getLongtermData(endDateValue, tempData.longterm);
    
                setLongtermData(tempData.longterm);
                setDate(currentDate);

                const hrOutlier = getHROutlierData(ltData);
                const hrLongtermMax = getHRLongtermMax(ltData);
                const hrLongtermMin = getHRLongtermMin(ltData);
                const hrLongtermMed = getHRLongtermMed(ltData);
                const stats = getHRDateMaxMin(currentDate, ltData);
                const ltStats = getHRLTMaxMin(ltData);
    
                setHRMaxLine(hrLongtermMax);
                setHRMinLine(hrLongtermMin);
                setHRMedLine(hrLongtermMed);
    
                setHRDateMax(stats[0]); // stats elements in order: [max, min, med]
                setHRDateMin(stats[1]);
                setHRDateMed(stats[2]);
    
                setHRLongtermMax(ltStats[0]); // stats elements in order: [max, min, med]
                setHRLongtermMin(ltStats[1]);
                setHRLongtermMed(ltStats[2]);

                setHRDateOptions({
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: 1 | 2,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                        annotation: {
                            annotations: {
                                min: {
                                    type: 'line',
                                    borderColor: 'gray',
                                    borderWidth: 2,
                                    scaleID: 'y',
                                    value: tempData.benchmark_personalize.low,
                                    label: {
                                        display: true,
                                        content: tempData.benchmark_personalize.low.toString(),
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                        position: 'end',
                                        xAdjust: 10,
                                        yAdjust: 10,
                                    },
                                },
                                max: {
                                    type: 'line',
                                    borderColor: 'gray',
                                    borderWidth: 2,
                                    scaleID: 'y',
                                    value: tempData.benchmark_personalize.high,
                                    label: {
                                        display: true,
                                        content: tempData.benchmark_personalize.high.toString(),
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                        position: 'end',
                                        xAdjust: 10,
                                        yAdjust: 0,
                                    },
                                },
                            },
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Hours of the Day',
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 24,
                            },
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Heart Rate BPM',
                            },
                            position: 'left',
                            offset: true,
                        },
                    },
                });

                setHRLongtermOptions({
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: 1 | 2,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                        annotation: {
                            annotations: {
                                min: {
                                    type: 'line',
                                    borderColor: 'gray',
                                    borderWidth: 2,
                                    scaleID: 'y',
                                    value: tempData.benchmark_personalize.low,
                                    label: {
                                        display: true,
                                        content: tempData.benchmark_personalize.low.toString(),
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                        position: 'end',
                                        xAdjust: 10,
                                        yAdjust: 10,
                                    },
                                },
                                max: {
                                    type: 'line',
                                    borderColor: 'gray',
                                    borderWidth: 2,
                                    scaleID: 'y',
                                    value: tempData.benchmark_personalize.high,
                                    label: {
                                        display: true,
                                        content: tempData.benchmark_personalize.high.toString(),
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                        position: 'end',
                                        xAdjust: 10,
                                        yAdjust: 0,
                                    },
                                },
                            },
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Date',
                            },
                            offset: true,
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Heart Rate BPM',
                            },
                            position: 'left',
                            offset: true,
                        },
                    },
                });
    
                setHROutlierData({
                    labels: [],
                    datasets: [
                        {
                            label: 'Upper Outlier Count',
                            data: hrOutlier,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                        },
                    ],
                });
    
                setHRLongtermData({
                    labels: [],
                    datasets: [
                        {
                            label: 'Personalized Threshold',
                            data: [],
                            pointStyle: 'line',
                            backgroundColor: 'black',
                            borderColor: 'black',
                        },
                        {
                            label: 'Daily Max',
                            data: hrLongtermMax,
                            pointStyle: 'rect',
                            backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                            pointRadius: 0,
                            fill: '+1',
                        },
                        {
                            label: 'Daily Min',
                            data: hrLongtermMin,
                            pointStyle: 'rect',
                            backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                            pointRadius: 0,
                            fill: false,
                        },
                        {
                            label: 'Daily Median',
                            data: hrLongtermMed,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                        },
                    ],
                });
    
                setHRDateData({
                    labels: [], 
                    datasets: [
                        {
                            label: 'Personalized Threshold',
                            data: [],
                            pointStyle: 'line',
                            backgroundColor: 'black',
                            borderColor: 'black',
                        },
                        {
                            label: 'Daytime Heart Rate BPM',
                            data: hrDate,
                            pointStyle: 'circle',
                            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                            spanGaps: true,
                            yAxisID: 'y',
                        },
                    ],
                });

                setStartDate(tempData.date.start);
                setEndDate(tempData.date.end);
            })
            .catch((err) => {});
        }
    }

      useEffect(() => {

          _fetchNightHR(seniorId);

        //   console.log("useEffect ran here");
      }, []);
  
      // finds the right data that corresponds to the date
      function getDateData(date, data) {
        if (date == null || data[0] == null)
            return null;

        var preDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
        var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

        const dateData = []
        let data_len = data.length;
        let i = 0;
        let n = 0;
        var currDate = new Date(data[0].year, data[0].month - 1, data[0].day); // month - 1 because month is 0 - 11 not 1 - 12

        while (currDate < afterDate) {
            if (i < data_len) {
            currDate = new Date(data[i].year, data[i].month - 1, data[i].day);
            i++;
            }
            else {
            console.log("ERROR -- exceeded max date in HR getDateData ");
            break;
            }

            // at the correct date
            if (currDate > preDate && currDate < afterDate) {
                dateData[n] = data[i];
                n++;
            }
            else { continue; }
        }

        // PROBLEM: getting one more data point than intended at the end and missing one at beginning
        // console.log("date data: ", dateData);
        return dateData;
      }
  
      // finds the month that current date is in
      // currently fixed (need to change later)
      function getLongtermData(date, data) {
        if (date == null || data[0] == null)
            return null;

        var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

        const longtermData = []
        let data_len = data.length;
        let i = 0;
        let n = 0;
        var currDate = new Date(data[0].year, data[0].month - 1, data[0].day); // month - 1 because month is 0 - 11 not 1 - 12

        while (currDate < afterDate) {
        if (i < data_len) {
            currDate = new Date(data[i].year, data[i].month - 1, data[i].day);
            i++;
        }
        else {
            console.log("ERROR -- exceeded max date in BOS getDateData ");
            break;
        }

        // at the correct date
        if (currDate < afterDate) {
            if (data[i] != null) {
            longtermData[n] = data[i];
            n++;
            }
        }
        else { continue; }
        }

        // PROBLEM: getting one more data point than intended at the end and missing one at beginning
        // console.log("longterm data: ", longtermData);
        return longtermData;
    }
  
      function getHROutlierData(longterm_data) {
          const outlierData = []; // each element is formatted {"date", num outliers}
          let length = longterm_data.length;
          let i = 0;
  
          while (i < length) {
              outlierData[i] = [longterm_data[i].year.toString() + '-' + longterm_data[i].month.toString() + '-' + longterm_data[i].day.toString(), longterm_data[i].outlier];
              i++;
          }
          return outlierData;
      }
  
      // returns data for daily graph display
      function getHRDateData(date_data) {
          const dateData = []; // each element is a pair of {"hour", "heart rate value"}
          let i = 0;
          let dataLen = date_data.length;
  
          while (i < dataLen - 1) {
              dateData[i] = [date_data[i].hour.toString(), date_data[i].heartRate];
              i++;
          }
          return dateData;
      }
  
      function getHRLongtermMax(longterm_data) {
          const max = []; // elements in form [date, max value]
          let i = 0;
          let length = longterm_data.length;
  
          while (i < length) {
              max[i] = [longterm_data[i].year.toString() + '-' + longterm_data[i].month.toString() + '-' + longterm_data[i].day.toString(), longterm_data[i].max];
              i++;
          }
  
          return max;
      }
  
      function getHRLongtermMin(longterm_data) {
          const min = []; // in form [date, min value]
          let i = 0;
          let length = longterm_data.length;
  
          while (i < length) {
              min[i] = [longterm_data[i].year.toString() + '-' + longterm_data[i].month.toString() + '-' + longterm_data[i].day.toString(), longterm_data[i].min];
              i++;
          }
  
          return min;
      }
  
      function getHRLongtermMed(longterm_data) {
          const med = []; // in form [date, median value]
          let i = 0;
          let length = longterm_data.length;
  
          while (i < length) {
              med[i] = [longterm_data[i].year.toString() + '-' + longterm_data[i].month.toString() + '-' + longterm_data[i].day.toString(), longterm_data[i].median];
              i++;
          }
  
          return med;
      }
  
      // sets the selected date's max min median values
      function getHRDateMaxMin(date, longterm_data) {
          let i = 0;
          let length = longterm_data.length;
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          let day = date.getDate();
          const stats = ["N/A", "N/A", "N/A"]; // elements are in order [max, min, med]
  
          while (i < length) {
              if (longterm_data[i].year == year && longterm_data[i].month == month && longterm_data[i].day == day) {
                  stats[0] = longterm_data[i].max;
                  stats[1] = longterm_data[i].min;
                  stats[2] = longterm_data[i].median;
                  break;
              }
              else { i++; }
          }
  
          return stats;
      }
  
      // TODO: Fix for when implementing multiple long term periods
      // sets the long term's max min median values
      function getHRLTMaxMin(longterm_data) {
          let i = 0;
          let length = longterm_data.length;
          const stats = []; // elements are in order [max, min, med]
          const max = [];
          const min = [];
          const med = [];
  
          while (i < length) {
              max.push(longterm_data[i].max);
              min.push(longterm_data[i].min);
              med.push(longterm_data[i].median);
              i++;
          }
  
          stats[0] = Math.max.apply(null, max);
          stats[1] = Math.min.apply(null, min);
          const sortMed = med.sort(function (a, b) { return a - b });
          stats[2] = sortMed[sortMed.length % 2];
  
          return stats;
      }

      // handles the trial time from start to 15 days, 5 weeks, 10 weeks, 15 weeks
    function handleTrialChange(type) {
        let endDate = endDateValue;

        if (type == 1) { // 15 days - 3/4/23
        setEndDateValue(new Date("2023-3-5"));
        endDate = new Date("2023-3-5");
        }
        else if (type == 2) { // 5 weeks - 3/24/23
        setEndDateValue(new Date("2023-3-25"));
        endDate = new Date("2023-3-25");
        }
        else if (type == 3) { // 10 weeks - 4/28/23
        setEndDateValue(new Date("2023-4-29"));
        endDate = new Date("2023-4-29");
        }
        else if (type == 4) { // 15 weeks - 6/7/23
        setEndDateValue(new Date("2023-6-8"));
        endDate = new Date("2023-6-8");
        }
        else {return;}

        const ltData = getLongtermData(endDate, longtermData);

        const hrOutlier = getHROutlierData(ltData);
        const hrLongtermMax = getHRLongtermMax(ltData);
        const hrLongtermMin = getHRLongtermMin(ltData);
        const hrLongtermMed = getHRLongtermMed(ltData);
        const ltStats = getHRLTMaxMin(ltData);

        setHRMaxLine(hrLongtermMax);
        setHRMinLine(hrLongtermMin);
        setHRMedLine(hrLongtermMed);

        setHRLongtermMax(ltStats[0]); // stats elements in order: [max, min, med]
        setHRLongtermMin(ltStats[1]);
        setHRLongtermMed(ltStats[2]);

        setHROutlierData({
            labels: [],
            datasets: [
                {
                    label: 'Upper Outlier Count',
                    data: hrOutlier,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                },
            ],
        });

        setHRLongtermData({
            labels: [],
            datasets: [
                {
                    label: 'Personalized Threshold',
                    data: [],
                    pointStyle: 'line',
                    backgroundColor: 'black',
                    borderColor: 'black',
                },
                {
                    label: 'Daily Max',
                    data: hrLongtermMax,
                    pointStyle: 'rect',
                    backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                    pointRadius: 0,
                    fill: '+1',
                },
                {
                    label: 'Daily Min',
                    data: hrLongtermMin,
                    pointStyle: 'rect',
                    backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                    pointRadius: 0,
                    fill: false,
                },
                {
                    label: 'Daily Median',
                    data: hrLongtermMed,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                },
            ],
        });

        setHRDateData({
            labels: [], 
            datasets: [
                {
                    label: 'Personalized Threshold',
                    data: [],
                    pointStyle: 'line',
                    backgroundColor: 'black',
                    borderColor: 'black',
                },
                {
                    label: 'Daytime Heart Rate BPM',
                    data: hrDate,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                },
            ],
        });
    }
  
      function handleDateChange(event) {
          setDate(event);
  
          const dateData = getDateData(event, dailyData);
          const ltData = getLongtermData(endDateValue, longtermData);
          const statData = longtermData
  
          const hrDate = getHRDateData(dateData);
          const hrOutlier = getHROutlierData(ltData);
          const hrLongtermMax = getHRLongtermMax(ltData);
          const hrLongtermMin = getHRLongtermMin(ltData);
          const hrLongtermMed = getHRLongtermMed(ltData);
          const stats = getHRDateMaxMin(event, statData);
          const ltStats = getHRLTMaxMin(ltData);
  
          setHRMaxLine(hrLongtermMax);
          setHRMinLine(hrLongtermMin);
          setHRMedLine(hrLongtermMed);
  
          setHRDateMax(stats[0]); // stats elements in order: [max, min, med]
          setHRDateMin(stats[1]);
          setHRDateMed(stats[2]);
  
          setHRLongtermMax(ltStats[0]); // stats elements in order: [max, min, med]
          setHRLongtermMin(ltStats[1]);
          setHRLongtermMed(ltStats[2]);
  
          setHRDateData({
              labels: [], //[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
              datasets: [
                  {
                      label: 'Personalized Threshold',
                      data: [],
                      pointStyle: 'line',
                      backgroundColor: 'black',
                      borderColor: 'black',
                  },
                  {
                      label: 'Daytime Heart Rate BPM',
                      data: hrDate,
                      pointStyle: 'circle',
                      backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                      borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                      spanGaps: true,
                      yAxisID: 'y',
                  },
              ],
          });
  
          setHROutlierData({
              labels: [],
              datasets: [
                  {
                      label: 'Upper Outlier Count',
                      data: hrOutlier,
                      pointStyle: 'circle',
                      backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                      borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                      spanGaps: true,
                      yAxisID: 'y',
                  },
              ],
          });
  
          setHRLongtermData({
              labels: [],
              datasets: [
                  {
                      label: 'Personalized Threshold',
                      data: [],
                      pointStyle: 'line',
                      backgroundColor: 'black',
                      borderColor: 'black',
                  },
                  {
                      label: 'Daily Max',
                      data: hrLongtermMax,
                      pointStyle: 'rect',
                      backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                      borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                      spanGaps: true,
                      yAxisID: 'y',
                      pointRadius: 0,
                      fill: '+1',
                  },
                  {
                      label: 'Daily Min',
                      data: hrLongtermMin,
                      pointStyle: 'rect',
                      backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                      borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                      spanGaps: true,
                      yAxisID: 'y',
                      pointRadius: 0,
                      fill: false,
                  },
                  {
                      label: 'Daily Median',
                      data: hrLongtermMed,
                      pointStyle: 'circle',
                      backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                      borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                      spanGaps: true,
                      yAxisID: 'y',
                  },
              ],
          });
      }

    // navigation to statistical analysis page
    const navigate = useNavigate();
    // navigate to Sleeping Activity Stats Analysis Page
    function handleGoDayHR(e) {
        e.preventDefault();
        navigate('/dhrStats', { state: { id: seniorId } });
    }
  
      return (
          <Box m="20px">
              <Box display="flex" justifycontext="space-between" alignItems="center">
                    <Box width="100%">
                        <Typography variant="h3" fontWeight="bold" sx={{ color: "#530C0C" }}>Nighttime Heart Rate Statistics</Typography>
                    </Box>
                    <Box>
                        <button onClick={() => {handleTrialChange(1)}}>15 Days</button>
                    </Box>
                    <Box>
                        <button onClick={() => {handleTrialChange(2)}}>5 Weeks</button>
                    </Box>
                    <Box>
                        <button onClick={() => {handleTrialChange(3)}}>10 Weeks</button>
                    </Box>
                    <Box>
                        <button onClick={() => {handleTrialChange(4)}}>15 Weeks</button>
                    </Box>
                    <Box p="0 30px">
                        <button onClick={handleGoDayHR}>Go to Daytime Data</button>
                    </Box>
                    <Box>
                        <DatePicker showIcon={true} minDate={new Date(startDate)} maxDate={new Date(endDate)} onChange={handleDateChange} selected={dateValue} />
                    </Box>
              </Box>
  
              {/* GRID AND CHARTS */}
              <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gridAutoRows="140px"
                  gap="20px"
              >
  
                  {/** ROW 1 */}
                  <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                        <StatBox title={startDate} subtitle="Start Trial" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                    </Box>
                    <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                        <StatBox title={endDate} subtitle="End Trial" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                    </Box>
                  <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                      <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                          <Box>
                              <Typography variant="h5" fontWeight="600" color="#530C0C">
                                  Daily Nighttime Heart Rate
                              </Typography>
                          </Box>
                          <Box m="0px 8px">
                              {/* <FaHeartbeat color='#530C0C' /> */}
                          </Box>
                      </Box>
                      <Box height="250px" m="0px 25px">
                          <Line options={hrDateOptions} data={hrDateData} className='graph' id="chartHR" key={Math.random()} />
                      </Box>
                  </Box>
                  <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                      <StatBox title={hrDateMax} subtitle="Daily Maximum" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                  </Box>
                  <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                      <StatBox title={hrDateMin} subtitle="Daily Minimum" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                  </Box>
  
                  {/** ROW 2 */}
                  <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                      <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                          <Box>
                              <Typography variant="h5" fontWeight="600" color="#530C0C">
                                  Long Term Nighttime Heart Rate
                              </Typography>
                          </Box>
                          <Box m="0px 8px">
                              {/* <FaHeartbeat color='#530C0C' /> */}
                          </Box>
                      </Box>
                      <Box height="250px" m="0px 25px">
                          <Line options={hrLongtermOptions} data={hrLongtermData} className='graph' id="chartHR" key={Math.random()} />
                      </Box>
                  </Box>
                  <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                      <StatBox title={hrDateMed} subtitle="Daily Median" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
                  </Box>
                  <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                      <StatBox title={hrLongtermMax} subtitle="Long Term Maximum" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
                  </Box>
                  {/** ROW 3 */}
                  <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                      <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                          <Box>
                              <Typography variant="h5" fontWeight="600" color="#530C0C">
                                  Long Term Nighttime Heart Rate Outlier Count
                              </Typography>
                          </Box>
                          <Box m="0px 8px">
                              {/* <FaHeartbeat color='#530C0C' /> */}
                          </Box>
                      </Box>
                      <Box height="250px" m="0px 25px">
                          <Line options={hrOutlierOptions} data={hrOutlierData} className='graph' id="chartHR" key={Math.random()} />
                      </Box>
                  </Box>
                  <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                      <StatBox title={hrLongtermMin} subtitle="Long Term Minimum" icon={<TbDeviceHeartMonitor color='#530C0C' size='0px' />}></StatBox>
                  </Box>
                  <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                      <StatBox title={hrLongtermMed} subtitle="Long Term Median" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
                  </Box>
  
              </Box>
          </Box>
      );
  }

export default NightHeartRateStats;