import React from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import Select from 'react-select'
import '../react-select.css'
import AsyncSelect from 'react-select/async';

import RelayPosition from './RelayPosition'
import Utils from '../../utils'
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'

const RoomControlLevels = ['enterprise', 'venue', 'building', 'floor', 'room']

class FormGroupRoom extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props)

    this.state = {
      value: {
        floor: props.value ? { label: props.value.floor.name, value: props.value.floor.id } : null,
        room: props.value ? { label: props.value.room.name, value: props.value.room.id } : null,
        coordinates: props.value ? {x: props.value.x, y: props.value.y} : null,
        enterprise: props.value ? { label: props.value.enterprise.name, value: props.value.enterprise.id } : null,
        venue: props.value ? { label: props.value.venue.name, value: props.value.venue.id } : null,
        building: props.value ? { label: props.value.building.name, value: props.value.building.id } : null,
      },
      venueOptions: [],
      buildingOptions: [],
      floorOptions: [],
      roomOptions: [],
      imageContent: props.value && props.value.floor && props.value.floor.image ? atob(props.value.floor.image.content) : null,
      roomCoordinates: props.value && props.value.room ? props.value.room.coordinates : null
    }

    this._onEnterpriseChange = this._onEnterpriseChange.bind(this)
    this._onVenueChange = this._onVenueChange.bind(this)
    this._onBuildingChange = this._onBuildingChange.bind(this)
    this._onFloorChange = this._onFloorChange.bind(this)
    this._onRoomChange = this._onRoomChange.bind(this)
    this._onCoordinatesChange = this._onCoordinatesChange.bind(this)

    this._getEnterpriseOptions = this._getEnterpriseOptions.bind(this)
    this._getVenueOptions = this._getVenueOptions.bind(this)
    this._getBuildingOptions = this._getBuildingOptions.bind(this)
    this._getFloorOptions = this._getFloorOptions.bind(this)
    this._getRoomOptions = this._getRoomOptions.bind(this)
  }

  componentDidMount() {
    if (this.state.value.enterprise) {
      this._getVenueOptions(this.state.value.enterprise.value, false)
    }
    if (this.state.value.venue) {
      this._getBuildingOptions(this.state.value.venue.value, false)
    }
    if (this.state.value.building) {
      this._getFloorOptions(this.state.value.building.value, false)
    }
    if (this.state.value.floor) {
      this._getFloorImage(this.state.value.floor.value)
      this._getRoomOptions(this.state.value.floor.value, false)
    }
  }

  _onEnterpriseChange(value) {
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        enterprise: value
      }
    }))
    // this._onChange('enterprise', value)
    if (value.value) {
      this._getVenueOptions(value.value, true)
    }
  }

  _onVenueChange(value) {
    // this._onChange('venue', value)
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        venue: value
      }
    }))
    if (value.value) {
      this._getBuildingOptions(value.value, true)
    }
  }

  _onBuildingChange(value) {
    // const fieldProps = this.props.fields.building
    // this._onChange('building', value)
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        building: value
      }
    }))
    if (value.value) {
      this._getFloorOptions(value.value, true)
    }
  }

  _onFloorChange(value) {
    // this._onChange('floor', value)
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        floor: value,
        room: null
      }
    }))
    if (value && value.data && value.data.image) {
      this.setState({
        imageContent: atob(value.data.image.content)
      })
    } else {
      this.setState({
        imageContent: null,
        roomCoordinates: null
      })
    }
    if (value && value.value) {
      this._getRoomOptions(value.value, true)
    }
  }

  _onRoomChange(value) {
    // this._onChange('room', value)
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        room: value
      }
    }))
    if (value && value.data) {
      this.setState({
        roomCoordinates: value.data.coordinates
      })
    } else {
      this.setState({
        roomCoordinates: null
      })
    }
  }

  _onCoordinatesChange(value) {
    const {controlId, required, validator} = this.props.fields.coordinates
    const newValue = {...this.state.value}
    newValue['coordinates'] = value ? value.value : null
    this.setState({
      value: newValue
    })
    this.props.onChange('x', required, validator, value.x)
    this.props.onChange('y', required, validator, value.y)
  }

  _onChange(key, value) {
    let newValue = {...this.state.value}
    newValue[key] = value ? value.value : null

    const subLevels = _.slice(RoomControlLevels, _.indexOf(RoomControlLevels, key) + 1)

    // based on the attribute being changed, we need to reset the values of others
    newValue = Utils.setNull(newValue, subLevels)

    this.setState({
      value: newValue
    })

    // based on the type of the filter, we need to pick out the set of attribute to notify parents
    const notifyValue = _.pick(newValue, _.slice(RoomControlLevels, 0, _.indexOf(RoomControlLevels, key) + 1))
    const notifyObj = {}, notifyFields = {}
    _.forEach(notifyValue, (item, key) => {
      const {controlId, required, validator} = this.props.fields[key]
      notifyObj[controlId] = item
      notifyFields[controlId] = this.props.fields[key]
    })
    // based on the attribute being changed, notify
    this.props.onChange(notifyObj, notifyFields)

  }

  _getEnterpriseOptions() {
    return this._getOptions('enterprise', 'name', {})
  }

  _getVenueOptions(enterpriseId, resetDownstream) {
    this.setState({
      isVenueLoading: true
    })
    this._getOptions('venue', 'name', {enterpriseId: enterpriseId}).then((data) => {
      this._onVenueOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onVenueOptionsLoad(null, resetDownstream)
    })
  }

  _onVenueOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isVenueLoading: false,
        venueOptions: data ? data.options : [],
        buildingOptions: [],
        floorOptions: [],
        roomOptions: []
      })
    } else {
      this.setState({
        isVenueLoading: false,
        venueOptions: data ? data.options : []
      })
    }
  }

  _getBuildingOptions(venueId, resetDownstream) {
    this.setState({
      isBuildingLoading: true
    })
    this._getOptions('building', 'name', {venueId: venueId}).then((data) => {
      this._onBuildOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onBuildOptionsLoad(null, resetDownstream)
    })
  }

  _onBuildOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isBuildingLoading: false,
        buildingOptions: data ? data.options : [],
        floorOptions: [],
        roomOptions: []
      })
    } else {
      this.setState({
        isBuildingLoading: false,
        buildingOptions: data ? data.options : []
      })
    }
  }

  _getFloorOptions(buildingId, resetDownstream) {
    this.setState({
      isFloorLoading: true
    })
    this._getOptions('floor', 'name', {buildingId: buildingId}).then((data) => {
      this._onFloorOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onFloorOptionsLoad(null, resetDownstream)
    })
  }

  _onFloorOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isFloorLoading: false,
        floorOptions: data ? data.options : [],
        roomOptions: []
      })
    } else {
      this.setState({
        isFloorLoading: false,
        floorOptions: data ? data.options : []
      })
    }
  }

  _getRoomOptions(floorId, resetDownstream) {
    this.setState({
      isRoomLoading: true
    })
    this._getOptions('room', 'name', {floorId: floorId}).then((data) => {
      this._onRoomOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onRoomOptionsLoad(null, resetDownstream)
    })
  }

  _onRoomOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isRoomLoading: false,
        roomOptions: data ? data.options : []
      })
    } else {
      this.setState({
        isRoomLoading: false,
        roomOptions: data ? data.options : []
      })
    }
  }

  _getOptions(entity, orderBy, filter) {
    const { showToast } = this.context
    if (orderBy) {
      if (!filter) {
        filter = {}
      }
      filter.orderBy = orderBy
      filter.ascendingOrder = true
    }
    return  ApiSingleton.makeHttpRequest(
      'post',
      '/bstream/api/v1/' + entity + '/find',
      { dataType: 'json'},
      filter
    ).then((data) => {
      const options = data.map((item, index) => {
        return {value: item[this.props.fields[entity].valueKey], label: item[this.props.fields[entity].labelKey], data: item}
      })
      return {
        options: options
      }
    })
    .catch((err) => {
      showToast(`Failed to fetch list of ' + entity + '! Reason: ${err}`)
    })
  }

  _getFloorImage(floorId) {
    const { showToast } = this.context
    return  ApiSingleton.makeHttpRequest(
      'post',
      '/bstream/api/v1/floor/findById',
      { dataType: 'json', id: floorId},
      {}
    ).then((data) => {
      if (data.image && data.image.content) {
        this.setState({
          imageContent: atob(data.image.content)
        })
      }
    })
    .catch((err) => {
      showToast(`Failed to fetch floor data by ID! Reason: ${err}`)
    })
  }

  render() {
    const props = this.props,
          fields = props.fields,
          state = this.state,
          labelWidth = props.labelWidth ? props.labelWidth : 3,
          valueWidth = props.valueWidth ? props.valueWidth : 7,
          validationWidth = 12 - labelWidth - valueWidth   

    return (
      <div>
        <Form.Group
          controlId={ fields.enterprise.controlId }
          validationState={ props.validationState[fields.enterprise.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label style={{ fontWeight: "bold" }}>{ fields.enterprise.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <AsyncSelect
              name={ fields.enterprise.controlId}
              autoload={ props.autoLoad }
              searchable
              value={ state.value.enterprise }
              loadOptions={ this._getEnterpriseOptions }
              onChange={ this._onEnterpriseChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.enterprise.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.enterprise.helpText }</p>
          </Col>
        </Form.Group>
        <Form.Group
          controlId={ fields.venue.controlId }
          validationState={ props.validationState[fields.venue.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label style={{ fontWeight: "bold" }}>{ fields.venue.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <Select
              name={ fields.venue.controlId}
              searchable
              value={ state.value.venue }
              isLoading={ state.isVenueLoading }
              options= { state.venueOptions }
              onChange={ this._onVenueChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.venue.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.venue.helpText }</p>
          </Col>
        </Form.Group>
        <Form.Group
          controlId={ fields.building.controlId }
          validationState={ props.validationState[fields.building.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label>{ fields.building.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <Select
              name={ fields.building.controlId}
              searchable
              value={ state.value.building }
              isLoading={ state.isBuildingLoading }
              options= { state.buildingOptions }
              onChange={ this._onBuildingChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.building.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.building.helpText }</p>
          </Col>
        </Form.Group>
        <Form.Group
          controlId={ fields.floor.controlId }
          validationState={ props.validationState[fields.floor.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label>{ fields.floor.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <Select
              name={ fields.floor.controlId}
              searchable
              value={ state.value.floor }
              isLoading={ state.isFloorLoading }
              options= { state.floorOptions }
              onChange={ this._onFloorChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.floor.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.floor.helpText }</p>
          </Col>
        </Form.Group>
        <Form.Group
          controlId={ fields.room.controlId }
          validationState={ props.validationState[fields.room.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label>{ fields.room.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <Select
              name={ fields.room.controlId}
              searchable
              value={ state.value.room }
              isLoading={ state.isRoomLoading }
              options= { state.roomOptions }
              onChange={ this._onRoomChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.room.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.room.helpText }</p>
          </Col>
        </Form.Group>
        {(() => {
          if (props.placeRelay) {
            return (
              <Form.Group
                controlId={ fields.coordinates.controlId }
                validationState={ props.validationState[fields.coordinates.controlId].status }>
                <Col sm={ labelWidth }>
                  <Form.Label>{ fields.coordinates.label }</Form.Label>
                </Col>
                <Col sm={ valueWidth }>
                  <p>{ fields.coordinates.helpText }</p>
                </Col>
                <Col smOffset={ labelWidth } sm={ valueWidth } style={{overflow: 'auto'}}>
                  <RelayPosition
                    imageContent={ state.imageContent }
                    imageWidth={800} imageHeight={500}
                    roomCoordinates={ state.roomCoordinates }
                    value={ state.value.coordinates }
                    onChange={ this._onCoordinatesChange } />
                </Col>
              </Form.Group>
            )
          }
        })()}

      </div>
    )
  }
}

export default FormGroupRoom
