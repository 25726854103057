import React from 'react'
import PropertyGrid from '../../components/PropertyGrid'
import FacilityToolbar from './FacilityToolbar'

const SelectedFacilityView = (props) => {

  if (!props.data) {
    return null
  }
  return (
    <div>
      <FacilityToolbar
        data={ props.data }
        remoteLoadSelected= { props.remoteLoadSelected }
        onTreeNodeUpdated={ props.onTreeNodeUpdated }
        onTreeNodeDeleted={ props.onTreeNodeDeleted }
        {...props.options}
      />
      <PropertyGrid data={props.data} fields={props.options ? props.options.displayFields : {}} />
    </div>
  )
}

export default SelectedFacilityView
