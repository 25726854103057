import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ToastContext } from '../../context/ToastContext'

import Select from "react-select";
import AsyncSelect from 'react-select/async';

import "../react-select.css";

import Utils from "../../utils";
import ApiSingleton from "../../utils/Axios";

const BuildingControlLevels = ["enterprise", "venue", "building"];

class FormGroupBuilding extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props);

    this.state = {
      value: {
        enterprise: props.value ? { label: props.value.enterprise.name, value: props.value.enterprise.id } : null,
        venue: props.value ? { label: props.value.venue.name, value: props.value.venue.id } : null,
        building: props.value ? { label: props.value.building.name, value: props.value.building.id } : null,
        
        // enterprise: props.value
        //   ? props.value[props.fields.enterprise.controlId]
        //   : null,
        // venue: props.value ? props.value[props.fields.venue.controlId] : null,
        // building: props.value
        //   ? props.value[props.fields.building.controlId]
        //   : null,
      },
      buildingOptions: [],
    };

    this._onEnterpriseChange = this._onEnterpriseChange.bind(this);
    this._onVenueChange = this._onVenueChange.bind(this);
    this._onBuildingChange = this._onBuildingChange.bind(this);

    this._getEnterpriseOptions = this._getEnterpriseOptions.bind(this);
    this._getVenueOptions = this._getVenueOptions.bind(this);
    this._getBuildingOptions = this._getBuildingOptions.bind(this);
  }

  componentDidMount() {
    if (this.state.value.enterprise) {
      this._getVenueOptions(this.state.value.enterprise, false);
    }
    if (this.state.value.venue) {
      this._getBuildingOptions(this.state.value.venue, false);
    }
  }

  _onEnterpriseChange(value) {
    this._onChange("enterprise", value);
    if (value.value) {
      this._getVenueOptions(value.value, true);
    }
  }

  _onVenueChange(value) {
    this._onChange("venue", value);
    if (value.value) {
      this._getBuildingOptions(value.value, true);
    }
  }

  _onBuildingChange(value) {
    this._onChange("building", value);
  }

  _onChange(key, value) {
    let newValue = { ...this.state.value };
    newValue[key] = value ? value.value : null;

    const subLevels = _.slice(
      BuildingControlLevels,
      _.indexOf(BuildingControlLevels, key) + 1
    );

    // based on the attribute being changed, we need to reset the values of others
    newValue = Utils.setNull(newValue, subLevels);

    this.setState({
      value: newValue,
    });

    // based on the type of the filter, we need to pick out the set of attribute to notify parents
    const notifyValue = _.pick(
      newValue,
      _.slice(
        BuildingControlLevels,
        0,
        _.indexOf(BuildingControlLevels, key) + 1
      )
    );
    const notifyObj = {},
      notifyFields = {};
    _.forEach(notifyValue, (item, key) => {
      const { controlId, required, validator } = this.props.fields[key];
      notifyObj[controlId] = item;
      notifyFields[controlId] = this.props.fields[key];
    });
    // based on the attribute being changed, notify
    this.props.onChange(notifyObj, notifyFields);
  }

  _getEnterpriseOptions() {
    return this._getOptions("enterprise", "name", {});
  }

  _getVenueOptions(enterpriseId, resetDownstream) {
    this.setState({
      isVenueLoading: true,
    });
    this._getOptions("venue", "name", { enterpriseId: enterpriseId }).then(
      (data) => {
        this.setState({
          isVenueLoading: false,
          venueOptions: data ? data.options : [],
        });
      },
      (err) => {
        this.setState({
          isVenueLoading: false,
          venueOptions: [],
        });
      }
    );
  }

  _getBuildingOptions(venueId, resetDownstream) {
    this.setState({
      isBuildingLoading: true,
    });
    this._getOptions("building", "name", { venueId: venueId }).then(
      (data) => {
        this.setState({
          isBuildingLoading: false,
          buildingOptions: data ? data.options : [],
        });
      },
      (err) => {
        this.setState({
          isBuildingLoading: false,
          buildingOptions: [],
        });
      }
    );
  }

  _getOptions(entity, orderBy, filter) {
    const { showToast } = this.context
    if (orderBy) {
      if (!filter) {
        filter = {};
      }
      filter.orderBy = orderBy;
      filter.ascendingOrder = true;
    }
    return ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/" + entity + "/find",
      { dataType: "json" },
      filter
    )
      .then((data) => {
        const options = data.map((item, index) => {
          return {
            value: item[this.props.fields[entity].valueKey],
            label: item[this.props.fields[entity].labelKey],
          };
        });
        return {
          options: options,
        };
      })
      .catch((err) => {
        showToast(`Failed to fetch list of ' + entity + '! Reason: ${err}`)
      });
  }

  render() {
    const props = this.props,
      fields = props.fields,
      state = this.state,
      labelWidth = props.labelWidth ? props.labelWidth : 3,
      valueWidth = props.valueWidth ? props.valueWidth : 7,
      validationWidth = 12 - labelWidth - valueWidth;
    return (
      <div>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId={fields.enterprise.controlId}
          validationState={
            props.validationState[fields.enterprise.controlId].status
          }
        >
          <Col sm={labelWidth}>
            <Form.Label style={{ fontWeight: "bold" }}>{fields.enterprise.label}</Form.Label>
          </Col>
          <Col sm={valueWidth}>
            <AsyncSelect
              name={fields.enterprise.controlId}
              autoload={props.autoLoad}
              searchable
              value={state.value.enterprise}
              loadOptions={this._getEnterpriseOptions}
              // onChange={ this._onEnterpriseChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={validationWidth}>
            <p>{props.validationState[fields.enterprise.controlId].message}</p>
          </Col>
          <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
            <p>{fields.enterprise.helpText}</p>
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId={fields.venue.controlId}
          validationState={props.validationState[fields.venue.controlId].status}
        >
          <Col sm={labelWidth}>
            <Form.Label style={{ fontWeight: "bold" }}>{fields.venue.label}</Form.Label>
          </Col>
          <Col sm={valueWidth}>
            <Select
              name={fields.venue.controlId}
              searchable
              value={state.value.venue}
              isLoading={state.isVenueLoading}
              options={state.venueOptions}
              onChange={this._onVenueChange}
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={validationWidth}>
            <p>{props.validationState[fields.venue.controlId].message}</p>
          </Col>
          <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
            <p>{fields.venue.helpText}</p>
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId={fields.building.controlId}
          validationState={
            props.validationState[fields.building.controlId].status
          }
        >
          <Col sm={labelWidth}>
            <Form.Label style={{ fontWeight: "bold" }}>{fields.building.label}</Form.Label>
          </Col>
          <Col sm={valueWidth}>
            <Select
              name={fields.building.controlId}
              searchable
              value={state.value.building}
              isLoading={state.isBuildingLoading}
              options={state.buildingOptions}
              onChange={this._onBuildingChange}
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={validationWidth}>
            <p>{props.validationState[fields.building.controlId].message}</p>
          </Col>
          <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
            <p>{fields.building.helpText}</p>
          </Col>
        </Form.Group>
      </div>
    );
  }
}

export default FormGroupBuilding;
