import React from 'react'
import BaseTable from '../../components/BaseTable'
import { timestampFormatter } from '../../components/BaseTable/CellFormatter'
import moment from "moment";
import {peopleFormatter, sensorLocationFormatter} from "../../components/BaseTable/CellFormatter";

class SensorLocationTable extends React.Component {
    constructor(props) {
        super(props)
        this.handleSortChange = this.handleSortChange.bind(this)
        this.state = {
            data: [],
            sortName: undefined,
            sortOrder: undefined,
            query: props.query ? props.query : ''
        }
    }
    componentWillReceiveProps(nextProps) {
        let data = nextProps.data
        if (data) {
            if(this.state.sortName) {
                data = this.state.sortOrder == 'asc' ? _.sortBy(data, this.state.sortName) : _.reverse( _.sortBy(data, this.state.sortName))
            }
            this.setState({
                data: data,
                query: nextProps.query
            })
        }
    }
    handleSortChange = (sortName, sortOrder) => {
        this.setState((state) => {
            return {
                sortName: sortName,
                sortOrder: sortOrder,
                data: sortOrder == 'asc' ? _.sortBy(state.data, sortName) : _.reverse(_.sortBy(state.data, sortName))
            }
        })
    }
    render() {
        const props = this.props
        let data = this.state.data
        if (this.state.query && this.state.query !== '') {
            const query = this.state.query && this.state.query.toLowerCase()
            data = data.filter(row => {
                return (moment(row.createUtc) && moment(row.createUtc).local().format("YYYY/MM/DD HH:mm:ss").toLowerCase().includes(query))||
                    (row.seniorName && row.seniorName.toLowerCase().includes(query)) ||
                    (row.roomName && row.roomName.toLowerCase().includes(query))}
            )
        }
        const columnProps = [{
            config: {
                dataField: 'createUtc',
                isKey: true,
                formatExtraData: this.state.query,
                dataFormat: timestampFormatter,
                dataSort: true
            },
            label: 'Time'
        }, {
            config: {
                dataField: 'seniorName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Resident'
        }, {
            config: {
                dataField: 'roomName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Room'
        }]
        return (
            <BaseTable
                autoLoad={ false }
                striped={ true }
                pagination={ false }
                actions={ [] }
                columnProps={columnProps}
                hideSelectColumn={ true }
                sortChange={this.handleSortChange}
                { ...props }
                sortedData={data}
            />
        )
    }
}


export default SensorLocationTable
