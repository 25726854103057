import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import './LoginPage.scss'

const LoginPage = (props) => {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [loginMessage, setLoginMessage] = useState('')
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    // refresh every 10 second
    if (
      props.location &&
      props.location.state &&
      props.location.state.sessionTimeout
    ) {
      const timer = setInterval(checkSessionState, 10000)
      return () => clearInterval(timer)
    }
  }, [props.location])

  const handleUserNameChange = (e) => {
    setUserName(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const trimmedUserName = userName.trim()
    const trimmedPassword = password.trim()
    if (!trimmedUserName || !trimmedPassword) {
      return
    }
    const tuples = { userName: trimmedUserName, password: trimmedPassword }
    auth.login(tuples, () => {
      setLoginMessage('Your Login has failed! Please check Username and Password. Try Again.')
    })
  }

  return (
    <div>
      <div className='login-body' />
      <div className='login-grad' />
      <div className='login-header'>
        <img src='/assets/aicare_logo.png' height='60' width='150' />
      </div>
      <form className='login-form'>
        {loginMessage && (
          <div className='alert alert-danger'>{loginMessage}</div>
        )}
        <input
          type='text'
          placeholder='User Name'
          value={userName}
          onChange={handleUserNameChange}
        />
        <input
          type='password'
          placeholder='Password'
          value={password}
          onChange={handlePasswordChange}
        />
        <input type='submit' value='Login' onClick={handleSubmit} />
      </form>
    </div>
  )
}

export default LoginPage
