import React from 'react'
import BaseTable from '../../components/BaseTable'
import { timestampFormatter, expandableAlertNotes, sensorLocationFormatter } from '../../components/BaseTable/CellFormatter'
import moment from "moment";
class SensorAlertTable extends React.Component {
    constructor(props) {
        super(props)
        this.handleSortChange = this.handleSortChange.bind(this)
        this.state = {
            data: [],
            sortName: undefined,
            sortOrder: undefined,
            query: props.query ? props.query : ''
        }
    }
    componentWillReceiveProps(nextProps) {
        let data = nextProps.data
        if (data) {
            if(this.state.sortName) {
                data = this.state.sortOrder === 'asc' ? _.sortBy(data, this.state.sortName) : _.reverse( _.sortBy(data, this.state.sortName))
            }
            this.setState({
                data: data,
                query: nextProps.query
            })
        }
    }
    handleSortChange = (sortName, sortOrder) => {
        this.setState((state) => {
            return {
                sortName: sortName,
                sortOrder: sortOrder,
                data: sortOrder === 'asc' ? _.sortBy(state.data, sortName) : _.reverse(_.sortBy(state.data, sortName))
            }
        })

    }
    render() {
        const props = this.props
        const columnProps = [{
            config: {
                dataField: 'createUtc',
                isKey: true,
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: timestampFormatter
            },
            label: 'Created Time'
        }, {
            config: {
                dataField: 'sensorName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'RockBand'
        }, {
            config: {
                dataField: 'seniorName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Resident'
        }, {
            config: {
                dataField: 'roomName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Room'
        }, {
            config: {
                dataField: 'description',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Description'
        }, {
            config: {
                dataField: 'alertType',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Alert Type'
        }, {
            config: {
                dataField: 'alertStatus',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Alert Status'
        }, {
            config: {
                dataField: 'sensorAlertNoteList',
                dataSort: true,
                dataFormat: expandableAlertNotes
            },
            label: 'Notes',
        }]

        const updateModalFields = [{
            controlId: 'alertEvent',
            label: 'Update Type',
            type: 'radiogroup',
            inline: true,
            required: true,
//  value: 'UPDATE',
            options: [{
                label: 'UPDATE',
                value: 'UPDATE',
                checked: true
            }, {
                label: 'CLOSE',
                value: 'CLOSE'
            }]
        }, {
            controlId: 'description',
            label: 'Description',
            type: 'text',
            required: true
        }]

        const updateConfig = {
            title: 'Update RockBand Alert',
            submitText: 'Update',
            cancelText: 'Cancel',
            fields: updateModalFields,
            defaultData: {
                alertEvent: 'UPDATE',
                description: ''
            },
            beforeSubmit: (data) => {
                return _.pick(data, ['id', 'alertEvent', 'description'])
            }
        }

        const expandColumnProps = [{
            config: {
                dataField: 'createUtc',
                isKey: true,
                width: '20%',
                dataFormat: timestampFormatter
            },
            label: 'When'
        }, {
            config: {
                dataField: 'who',
                width: '20%',
                dataSort: false
            },
            label: 'Who',
        }, {
            config: {
                dataField: 'description',
                dataSort: false
            },
            label: 'Description'
        }]

        const isExpandableRow = (row) => {
            return row.sensorAlertNoteList.length > 0
        }

        const expandComponent = (row) => {
            return  (
                <BaseTable
                    autoLoad={ false }
                    striped={ false }
                    pagination={ false }
                    columnProps={ expandColumnProps }
                    sortedData={ row.sensorAlertNoteList }
                    hideFilterSearchBar={ true }
                />
            )
        }
        let data = this.state.data
        if (this.state.query && this.state.query !== '') {
            const query = this.state.query.toLowerCase()
            data = data.filter(row => {
                return (row.createUtc && moment(row.createUtc).local().format("YYYY/MM/DD HH:mm:ss").toLowerCase().includes(query))||
                    (row.sensorName && row.sensorName.toLowerCase().includes(query)) ||
                    (row.seniorName && row.seniorName.toLowerCase().includes(query)) ||
                    (row.roomName && row.roomName.toLowerCase().includes(query)) ||
                    (row.description && row.description.toLowerCase().includes(query)) ||
                    (row.alertType && row.alertType.toLowerCase().includes(query))
            })
        }
        return (
            <BaseTable autoLoad={false}
                       height={props.height}
                       pagination={ false }
                       sortChange={this.handleSortChange}
                       urlEntity='sensorAlert'
                       updateUrl='triggerEvent'
                       actions={[{name: 'update'}]}
                       updateConfig={updateConfig}
                       columnProps={columnProps}
                       isExpandableRow={ isExpandableRow }
                       expandComponent={ expandComponent }
                       { ...props }
                       sortedData={data}
                       hideFilterSearchBar={ true }
            />
        )
    }
}

export default SensorAlertTable
