import React from 'react'
import {MatchingPasswordRequired, MatchingPasswordAllowUndefined, StripConfirmPassword, StripEmptyAndConfirmPassword} from '../../components/BaseFormModal/Utils'
import _ from 'lodash'
import Draggable from 'react-draggable';

const displayFields = {
  name: {
    label: 'Name'
  },
  description: {
    label: 'Description'
  },
  street: {
    label: 'Street'
  },
  city: {
    label: 'City'
  },
  state: {
    label: 'State'
  },
  country: {
    label: 'Country'
  },
  enabled: {
    label: 'Enabled',
    render: (value) => {
      if (value) {
        return <i className='far fa-check-square' aria-hidden='true'></i>
      } else {
        return <i className='far fa-square' aria-hidden='true'></i>
      }
    }
  }
}

const displayEnterpriseFields = {
  name: {
    label: 'Name'
  },
  description: {
    label: 'Description'
  },
  street: {
    label: 'Street'
  },
  city: {
    label: 'City'
  },
  state: {
    label: 'State'
  },
  country: {
    label: 'Country'
  },
  enabled: {
    label: 'Enabled',
    render: (value) => {
      if (value) {
        return <i className='far fa-check-square' aria-hidden='true'></i>
      } else {
        return <i className='far fa-square' aria-hidden='true'></i>
      }
    }
  },
  hasVenueAdmin: {
    label: 'Has Venue Admin',
    render: (value) => {
      if (value) {
        return <i className='far fa-check-square' aria-hidden='true'></i>
      } else {
        return <i className='far fa-square' aria-hidden='true'></i>
      }
    }
  }
}

const displayBuildingModalFields = {
  name: {
    label: 'Name'
  },
  description: {
    label: 'Description'
  },
  street: {
    label: 'Street'
  },
  city: {
    label: 'City'
  },
  state: {
    label: 'State'
  },
  country: {
    label: 'Country'
  },
  timeZone: {
	label: 'Time Zone'
  },
  enabled: {
    label: 'Enabled',
	render: (value) => {
  	  if (value) {
	    return <i className='far fa-check-square' aria-hidden='true'></i>
	  } else {
	    return <i className='far fa-square' aria-hidden='true'></i>
	  }
    }
  }
}


const displayFloorFields = {
  name: {
    label: 'Name'
  },
  description: {
    label: 'Description'
  },
  image: {
    label: 'Image',
    render: (value, data) => {

      const imageContent = value && value.content ? atob(value.content) : null
      const rooms = data.rooms.map((room, index) => (
        <rect key={ 'room_' + index }
              x={ room[0].x }
              y={ room[0].y }
              width={ room[1].x - room[0].x }
              height={ room[1].y - room[0].y }
              stroke='red'
              strokeWidth={3}
              fill='none' />
        )
      )
      return (
          <Draggable>
              <svg width={800} height={500} >
                  <image xlinkHref={imageContent} x={0} y={0}
                         width={800} height={500} />
                  { rooms }
              </svg>
          </Draggable>

      )
    }
  }
}

const displayRoomFields = {
  name: {
    label: 'Name'
  },
  description: {
    label: 'Description'
  },
  coordinates: {
    label: 'Image',
    render: (value, data) => {
      const imageContent = data.image ? atob(data.image.content) : null
      const relays = data.relays.map((relay, index) => (
        <div key={'relay_' + index} style={{position: 'absolute', left: relay.x, top: relay.y,}}>
          <i className="fa fa-cube fa-lg text-success" aria-hidden="true"></i>
        </div>
      ))

      let rect = value ? {
        x: value[0].x,
        y: value[0].y,
        width: value[1].x - value[0].x,
        height: value[1].y - value[0].y
      } : null
      return (
          <Draggable>
              <div style={{position: 'relative'}}>
                  <svg width={800} height={500}>
                      <image xlinkHref={imageContent} x={0} y={0}
                             width={800} height={500} />
                      {(() => {
                          if (rect) {
                              return (
                                  <rect
                                      x={rect.x}
                                      y={rect.y}
                                      width={rect.width}
                                      height={rect.height}
                                      stroke='red'
                                      strokeWidth={3}
                                      fill='none' />
                              )
                          }
                      })()}
                  </svg>
                  { relays }
              </div>
          </Draggable>
      )
    }
  }
}

const displayRelayFields = {
  name: {
    label: 'Name'
  },
  description: {
    label: 'Description'
  },
  coordinates: {
    label: 'Location',
    render: (value, data) => {
//      console.log('relay render ', value, data)
      const imageContent = data.roomImage ? atob(data.roomImage.content) : null
      let rect = data.roomCoordinates ? {
        x: data.roomCoordinates[0].x,
        y: data.roomCoordinates[0].y,
        width: data.roomCoordinates[1].x - data.roomCoordinates[0].x,
        height: data.roomCoordinates[1].y - data.roomCoordinates[0].y
      } : null
      return (
          <Draggable>
              <div style={{position: 'relative'}} >
                  <svg width={800} height={500}>
                      <image xlinkHref={imageContent} x={0} y={0}
                             width={800} height={500} />
                      {(() => {
                          if (rect) {
                              return (
                                  <rect
                                      x={rect.x}
                                      y={rect.y}
                                      width={rect.width}
                                      height={rect.height}
                                      stroke='red'
                                      strokeWidth={3}
                                      fill='none' />
                              )
                          }
                      })()}
                  </svg>
                  {(() => {
                      if (value) {
                          return (
                              <div style={{position: 'absolute', left: data.x, top: data.y}}>
                                  <i className="fa fa-cube fa-lg text-success" aria-hidden="true"></i>
                              </div>
                          )
                      }
                  })()}
              </div>
          </Draggable>
      )
    }
  }
}

const addModalFields = [{
  controlId: 'name',
  label: 'Name',
  type: 'text',
  required: true
}, {
  controlId: 'description',
  label: 'Description',
  type: 'text'
}, {
  controlId: 'street',
  label: 'Street',
  type: 'text'
}, {
  type: 'city',
  fields: {
    country: {
      controlId: 'countryId',
      label: 'Country',
      required: false,
      labelKey: 'name',
      valueKey: 'id'
    },
    state: {
      controlId: 'stateId',
      label: 'State',
      required: false,
      labelKey: 'name',
      valueKey: 'id'
    },
    city: {
      controlId: 'cityId',
      label: 'City',
      required: false,
      labelKey: 'name',
      valueKey: 'id'
    }
  }
}, {
  controlId: 'enabled',
  label: 'Enabled',
  type: 'checkbox',
  defaultValue: true
}]

const updateModalFields = ((fields) => {
  return [].concat(fields)
})(addModalFields)

const addEnterpriseModalFields = [{
  controlId: 'name',
  label: 'Name',
  type: 'text',
  required: true
}, {
  controlId: 'description',
  label: 'Description',
  type: 'text'
}, {
  controlId: 'street',
  label: 'Street',
  type: 'text'
}, {
  type: 'city',
  fields: {
    country: {
      controlId: 'countryId',
      label: 'Country',
      required: false,
      labelKey: 'name',
      valueKey: 'id'
    },
    state: {
      controlId: 'stateId',
      label: 'State',
      required: false,
      labelKey: 'name',
      valueKey: 'id'
    },
    city: {
      controlId: 'cityId',
      label: 'City',
      required: false,
      labelKey: 'name',
      valueKey: 'id'
    }
  }
}, {
  controlId: 'enabled',
  label: 'Enabled',
  type: 'checkbox',
  defaultValue: true
}, {
  controlId: 'hasVenueAdmin',
  label: 'Has Venue Admin',
  type: 'checkbox',
  defaultValue: false
}]

/*
const updateEnterpriseModalFields = ((fields) => {
  return [].concat(fields)
})(addEnterpriseModalFields)
*/

const addBuildingModalFields = [{
  controlId: 'name',
  label: 'Name',
  type: 'text',
  required: true
}, {
  controlId: 'description',
  label: 'Description',
  type: 'text'
}, {
  controlId: 'street',
  label: 'Street',
  type: 'text'
}, {
  type: 'city',
  fields: {
    country: {
      controlId: 'countryId',
      label: 'Country',
      required: false,
      labelKey: 'name',
      valueKey: 'id'
    },
    state: {
      controlId: 'stateId',
      label: 'State',
      required: false,
      labelKey: 'name',
      valueKey: 'id'
    },
    city: {
      controlId: 'cityId',
      label: 'City',
      required: false,
      labelKey: 'name',
      valueKey: 'id'
    }
  }
}, {
  type: 'time_zone',
  controlId: 'timeZone',
  label: 'Time Zone',
  required: true,
  labelKey: 'name',
  valueKey: 'id'
},
{
  controlId: 'enabled',
  label: 'Enabled',
  type: 'checkbox',
  defaultValue: true
}]

const updateBuildingModalFields = ((fields) => {
  return [].concat(fields)
})(addBuildingModalFields)

const addFloorModalFields = [{
  controlId: 'name',
  label: 'Name',
  type: 'text',
  required: true
}, {
  controlId: 'description',
  label: 'Description',
  type: 'text'
}, {
  controlId: 'image',
  label: 'Image',
  type: 'image'
}]

const updateFloorModalFields = ((fields) => {
  return [].concat(fields)
})(addFloorModalFields)

const addRoomModalFields =[{
  controlId: 'name',
  label: 'Name',
  type: 'text',
  required: true
}, {
  controlId: 'description',
  label: 'Description',
  type: 'text'
}, {
  controlId: 'coordinates',
  label: 'Coordinates',
  helpText: 'Drag and resize the rectangle to change the location of the room',
  type: 'room_position'
}]

const updateRoomModalFields = ((fields) => {
  return [].concat(fields)
})(addRoomModalFields)

const addRelayModalFields =[{
  controlId: 'name',
  label: 'Name',
  type: 'text',
  required: true
}, {
  controlId: 'description',
  label: 'Description',
  type: 'text'
}, {
  controlId: 'mac',
  label: 'MAC',
  type: 'text'
}, {
  controlId: 'password',
  label: 'Password',
  type: 'password'
}, {
  controlId: 'password2',
  label: 'Confirm Password',
  componentClass: 'input',
  type: 'password',
  required: true
}, {
  controlId: 'enabled',
  label: 'Enabled',
  type: 'checkbox',
  defaultValue: true
}, {
  controlId: 'coordinates',
  label: 'Coordinates',
  type: 'relay_position',
  helpText: 'Drag the cube to change the location of the RockBox'
}]

const updateRelayModalFields = ((fields) => {
  return _.reduce(fields, (o, v, i) => {
    const newv = {...v}
    if (newv.controlId === 'password' || newv.controlId === 'password2') {
      delete newv.required
      newv.helpText = 'Leave it empty for no change'
    }
    o.push(newv)
    return o
  }, [])
})(addRelayModalFields)

const updateEnterpriseModalFields = [
    {
        controlId: 'name',
        label: 'Name',
        type: 'text',
        required: true
    }, {
        controlId: 'description',
        label: 'Description',
        type: 'text'
    }, {
        controlId: 'street',
        label: 'Street',
        type: 'text'
    }, {
        type: 'city',
        fields: {
            country: {
                controlId: 'countryId',
                label: 'Country',
                required: false,
                labelKey: 'name',
                valueKey: 'id'
            },
            state: {
                controlId: 'stateId',
                label: 'State',
                required: false,
                labelKey: 'name',
                valueKey: 'id'
            },
            city: {
                controlId: 'cityId',
                label: 'City',
                required: false,
                labelKey: 'name',
                valueKey: 'id'
            }
        }
    }, {
        controlId: 'enabled',
        label: 'Enabled',
        type: 'checkbox',
        defaultValue: true
    },
    {
      controlId: 'hasVenueAdmin',
      label: 'Has Venue Admin',
      type: 'checkbox',
      defaultValue: false
    }, {
        type: 'alertAudio',
        fields: [
            {
                controlId: 'uploadMissingAudio',
                label: 'Upload Missing Audio',
                urlEntity: 'enterprise',
                alertType: 'MISSING_AUDIO'
            },
            {
                controlId: 'uploadSosAudio',
                label: 'Upload SOS Audio',
                urlEntity: 'enterprise',
                alertType: 'SOS_AUDIO'
            },
            {
                controlId: 'uploadHighImpactAudio',
                label: 'Upload High Impact Audio',
                urlEntity: 'enterprise',
                alertType: 'HIGH_IMPACT_AUDIO'
            },
            {
                controlId: 'uploadExitDoorAudio',
                label: 'Upload Exit Door Audio',
                urlEntity: 'enterprise',
                alertType: 'EXIT_DOOR_AUDIO'
            },
            {
                controlId: 'uploadApproachingAudio',
                label: 'Upload Approaching Audio',
                urlEntity: 'enterprise',
                alertType: 'APPROACHING_AUDIO'
            },
            {
                controlId: 'uploadLeavingAudio',
                label: 'Upload Leaving Audio',
                urlEntity: 'enterprise',
                alertType: 'LEAVING_AUDIO'
            },
        ]
    },
]
export const FacilityOptions = {
  system: {
    urlEntity: 'system',
    childUrlEntity: 'enterprise',
    entityNameField: 'name',
    displayFields: displayFields,
    addBtnText: 'Add Enterprise',
    addModalTitle: 'Add New Enterprise',
    addModalFields: addEnterpriseModalFields,
    addModalData: {}
  },

  enterprise: {
    urlEntity: 'enterprise',
    childUrlEntity: 'venue',
    entityNameField: 'name',
    displayFields: displayEnterpriseFields,
    addBtnText: 'Add Venue',
    addModalTitle: 'Add New Venue',
    addModalFields: addModalFields,
    addModalData: {},
    updateBtnText: 'Edit Enterprise',
    updateModalTitle: 'Edit Enterprise',
    updateModalFields: updateEnterpriseModalFields,
    deleteBtnText: 'Delete Enterprise',
    deleteModalTitle: 'Delete Enterprise',
    deleteModalBody: 'Are you sure you want to delete this enterprise record?'
  },

  venue: {
    urlEntity: 'venue',
    childUrlEntity: 'building',
    entityNameField: 'name',
    displayFields: displayFields,
    addBtnText: 'Add Building',
    addModalTitle: 'Add New Building',
    addModalFields: addBuildingModalFields,
    addModalData: {},
    updateBtnText: 'Edit Venue',
    updateModalTitle: 'Edit Venue',
    updateModalFields: updateModalFields,
    deleteBtnText: 'Delete Venue',
    deleteModalTitle: 'Delete Venue',
    deleteModalBody: 'Are you sure you want to delete this venue record?'
  },

  building: {
    urlEntity: 'building',
    childUrlEntity: 'floor',
    entityNameField: 'name',
    displayFields: displayBuildingModalFields,
    beforeAddSubmit: (data) => {
      data.imageType = data.image.imageFile.type
      data.imageContent = btoa(data.image.imageContent)
      delete data.image
      return data
    },
    addBtnText: 'Add Floor',
    addModalTitle: 'Add New Floor',
    addModalFields: addFloorModalFields,
    addModalData: {},
    updateBtnText: 'Edit Building',
    updateModalTitle: 'Edit Building',
    updateModalFields: updateBuildingModalFields,
    deleteBtnText: 'Delete Building',
    deleteModalTitle: 'Delete Building',
    deleteModalBody: 'Are you sure you want to delete this building record?'
  },

  floor: {
    urlEntity: 'floor',
    childUrlEntity: 'room',
    entityNameField: 'name',
    displayFields: displayFloorFields,
    beforeAddSubmit: (data) => {
      data.coordinates = JSON.stringify(data.coordinates)
      return data
    },
    addBtnText: 'Add Room',
    addModalTitle: 'Add New Room',
    addModalFields: addRoomModalFields,
    addModalData: {},
    beforeAddModalShown: (addModalData, data) => {
      if (data.image) {
        addModalData.image = {}
        addModalData.image.content = data.image.content
      }
      return addModalData
    },
    updateBtnText: 'Edit Floor',
    updateModalTitle: 'Edit Floor',
    updateModalFields: updateFloorModalFields,
    beforeUpdateSubmit: (data) => {
      if (data.image && data.image.imageFile) {
        data.imageType = data.image.imageFile.type
      }
      if (data.image && data.image.imageContent) {
        data.imageContent = btoa(data.image.imageContent)
      }
      delete data.image
      delete data.imageId
      delete data.coordinates
      return data
    },
    deleteBtnText: 'Delete Floor',
    deleteModalTitle: 'Delete Floor',
    deleteModalBody: 'Are you sure you want to delete this floor record?'
  },

  room: {
    urlEntity: 'room',
    childUrlEntity: 'relay',
    entityNameField: 'name',
    displayFields: displayRoomFields,
    beforeAddModalShown: (addModalData, data) => {
      if (data.image) {
        addModalData.roomImage = {}
        addModalData.roomImage.content = data.image.content
      }
      if (data.coordinates) {
        addModalData.roomCoordinates = data.coordinates
      }
      return addModalData
    },
    beforeAddSubmit: (data) => {
      StripConfirmPassword(data)
      delete data.roomImage
      delete data.roomCoordinates
      data.x = data.coordinates.x
      data.y = data.coordinates.y
      delete data.coordinates
      return data
    },
    addModalFormValidator: MatchingPasswordRequired,
    addBtnText: 'Add RockBox',
    addModalTitle: 'Add New RockBox',
    addModalFields: addRelayModalFields,
    addModalData: {},
    beforeUpdateSubmit: (data) => {
      data.coordinates = JSON.stringify(data.coordinates)
      return data
    },
    updateBtnText: 'Edit Room',
    updateModalTitle: 'Edit Room',
    updateModalFields: updateRoomModalFields,
    deleteBtnText: 'Delete Room',
    deleteModalTitle: 'Delete Room',
    deleteModalBody: 'Are you sure you want to delete this room record?'
  },

  relay: {
    urlEntity: 'relay',
    childUrlEntity: null,
    entityNameField: 'name',
    displayFields: displayRelayFields,
    updateBtnText: 'Edit RockBox',
    updateModalTitle: 'Edit RockBox',
    updateModalFields: updateRelayModalFields,
    beforeUpdateSubmit: (data) => {
      StripEmptyAndConfirmPassword(data)
      delete data.roomImage
      delete data.roomCoordinates
      data.x = data.coordinates.x
      data.y = data.coordinates.y
      delete data.coordinates
      return data
    },
    updateModalFormValidator: MatchingPasswordAllowUndefined,
    deleteBtnText: 'Delete RockBox',
    deleteModalTitle: 'Delete RockBox',
    deleteModalBody: 'Are you sure you want to delete this rockbox record?'
  }
}

export default FacilityOptions
