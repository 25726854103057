import React from 'react'
import BaseTable from '../../components/BaseTable'
import { timestampFormatter, sensorAlertListTableFormatter, sensorLocationFormatter } from '../../components/BaseTable/CellFormatter'
import moment from "moment";
class SensorAlertTable extends React.Component {
    constructor(props) {
        super(props)
        this.handleSortChange = this.handleSortChange.bind(this)
        this.state = {
            data: [],
            sortName: undefined,
            sortOrder: undefined,
            query: props.query ? props.query : ''
        }

    }
    componentWillReceiveProps(nextProps) {
        let data = nextProps.data
        if (data) {
            if(this.state.sortName) {
                data = this.state.sortOrder == 'asc' ? _.sortBy(data, this.state.sortName) : _.reverse( _.sortBy(data, this.state.sortName))
            }
            this.setState({
                data: data,
                query: nextProps.query
            })
        }
    }
    handleSortChange = (sortName, sortOrder) => {
        this.setState((state) => {
            return {
                sortName: sortName,
                sortOrder: sortOrder,
                data: sortOrder == 'asc' ? _.sortBy(state.data, sortName) : _.reverse(_.sortBy(state.data, sortName))
            }
        })
    }
    render() {
        const props = this.props
        const columnProps = [{
            config: {
                dataField: 'createUtc',
                isKey: true,
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: timestampFormatter
            },
            label: 'Time'
        }, {
            config: {
                dataField: 'alertType',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Alert Type'
        }, {
            config: {
                dataField: 'seniorName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Resident'
        }, {
            config: {
                dataField: 'roomName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Room'
        }]

        const updateModalFields = [{
            controlId: 'createUtc',
            label: 'Timestamp',
            type: 'static',
            valueRender: timestampFormatter
        }, {
            controlId: 'alertType',
            label: 'Alert Type',
            type: 'static'
        }, {
            controlId: 'alertStatus',
            label: 'Alert Status',
            type: 'static'
        }, {
            controlId: 'description',
            label: 'Description',
            type: 'static'
        }, {
            controlId: 'seniorName',
            label: 'Resident',
            type: 'static'
        }, {
            controlId: 'roomName',
            label: 'Room',
            type: 'static'
        }, {
            controlId: 'sensorAlertNoteList',
            label: 'Notes',
            type: 'readonly-table',
            valueRender: sensorAlertListTableFormatter
        }, {
            controlId: 'alertEvent',
            label: 'Update Type',
            type: 'radiogroup',
            inline: true,
            required: true,
//  value: 'UPDATE',
            options: [{
                label: 'UPDATE',
                value: 'UPDATE',
                checked: true
            }, {
                label: 'CLOSE',
                value: 'CLOSE'
            }]
        }, {
            controlId: 'updateNote',
            label: 'Update Note',
            type: 'text',
            required: true
        }]

        const updateConfig = {
            title: 'Update RockBand Alert',
            submitText: 'Update',
            cancelText: 'Cancel',
            fields: updateModalFields,
            defaultData: {
                alertEvent: 'UPDATE',
                updateNote: ''
            },
            beforeSubmit: (data) => {
                data = _.pick(data, ['id', 'alertEvent', 'updateNote'])
                data.description = data.updateNote
                delete data.updateNote
                return data
            }
        }
        let data = this.state.data
        if (this.state.query && this.state.query !== '') {
            const query = this.state.query.toLowerCase()
            data = data.filter(row => {
                return (row.createUtc && moment(row.createUtc).local().format("YYYY/MM/DD HH:mm:ss").toLowerCase().includes(query))||
                    (row.alertType && row.alertType.toLowerCase().includes(query)) ||
                    (row.seniorName && row.seniorName.toLowerCase().includes(query)) ||
                    (row.roomName && row.roomName.toLowerCase().includes(query))
            })
        }
        return (
            <BaseTable
                autoLoad={ false }
                striped={ true }
                pagination={ false }
                actions={ [] }
                urlEntity='sensorAlert'
                updateUrl='triggerEvent'
                columnProps={columnProps}
                hideSelectColumn={ true }
                updateConfig={updateConfig}
                selectAction={{name: 'update'}}
                sortChange={this.handleSortChange}
                clearSelectOnUpdateModalClose={ true }
                { ...props }
                sortedData={data}
            />
        )
    }
}

export default SensorAlertTable
