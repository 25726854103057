import React from 'react'
import FormGroup from 'react-bootstrap/FormGroup'
import FormControl from 'react-bootstrap/FormControl'
import { FormLabel } from "react-bootstrap"
import Col from 'react-bootstrap/Col'

class FormGroupStatic extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const props = this.props,
      labelWidth = props.labelWidth ? props.labelWidth : 3,
      valueWidth = props.valueWidth ? props.valueWidth : 9,
      value = props.valueRender ? props.valueRender(props.value) : props.value
    return (
      <FormGroup
        controlId={props.controlId} >
        <Col sm={labelWidth}>
          <FormLabel>{props.label}</FormLabel>
        </Col>
        <Col sm={valueWidth}>
          {/* <FormControl.Static> */}
          <>
            {value}
          </>
          {/* </FormControl.Static> */}
        </Col>
        <Col smOffset={labelWidth} sm={valueWidth}>
          <p>{props.helpText}</p>
        </Col>
      </FormGroup>
    )
  }
}

export default FormGroupStatic
