import React from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useCollapse } from "react-collapsed";
import "./sidebar.css";
import AccordionItem from "./AccordionItem";

function SideBar(props) {
  const location = useLocation();
  // const [{ route }] = matchRoutes(routes, location)
  const [active, setActive] = React.useState("0");
  const [isExpanded, setExpanded] = React.useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
  });
  

  const handleToggle = (index) => {
    if (active === index) {
      return setActive("0");
    }
    setActive(index);
  };

  React.useEffect(() => {
    // console.log(location);
  }, []);

  return (
    <div style={{ marginLeft: -10 }} className="sidebar">
      <div className="sidebar-container">
        <ul style={{ margin: 0, padding: 0 }}>
          {props.content.map((content, index) => (
            <div  key={index} >
            <AccordionItem
              onToggle={() => handleToggle(index)}
              active={active === index}
              content={content}
            />
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
