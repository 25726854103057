import React from 'react'
import moment from 'moment'
import { Tooltip } from 'react-bootstrap';
import reactStringReplace from "react-string-replace";

class Relay extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      showLabel: false,
    }
  }

  _onMouseOver = (e) => {
    this.setState({ showLabel:true })
  }

  _onMouseOut = (e) => {
    this.setState({ showLabel:false })
  }

  render () {
    const props = this.props,
          state = this.state;

    let iconclass='';
    if (props.lastSeenUtc) {
        //const when = moment(props.lastSeenUtc).local().format("YYYY/MM/DD HH:mm:ss")
     	const diff = moment().diff(moment(props.lastSeenUtc))
     	if (diff < 5 * 60 * 1000) {
          	iconclass = 'fa fa-cube fa-lg text-aicare-theme'     		
     	} else {
          	iconclass = 'fa fa-cube fa-lg text-aicare-theme-red'     		
     	}
    } else {
    	  iconclass = 'fa fa-cube fa-lg text-aicare-theme-orange'
    }
    const fontSize = props.scale > 1 ? 18 : props.scale * 18;
    let display = false
    if(props.opacity == 0.8 && props.query != '') {
      if(props.name.toLowerCase().includes(props.query.toLowerCase())
          || props.roomName.toLowerCase().includes(props.query.toLowerCase())) {
          display = true
      }
    }
    return (
      <div
        onMouseOver={ this._onMouseOver}
        onMouseOut={ this._onMouseOut }
        style={ {position: 'absolute', left: ( props.x - props.centerX) * props.scale + props.centerX ,
            top: (props.y - props.centerY) * props.scale + props.centerY,
            opacity: props.opacity} }>
        <i className={ iconclass } aria-hidden="true" style={{fontSize: fontSize}}></i>
        <RelayLabel showLabel={ state.showLabel || display } {...props} opacity={props.opacity} />
      </div>
    )
  }
}

const RelayLabel = (props) => {
  if (props.showLabel && props.opacity == 0.8) {
    const fillcolor = 'rgb(128,128,128)';
    const margin = -60;

    return (
        <Tooltip placement="bottom" className="in" id="tooltip"
                 style={{marginLeft: margin}}>
            {/*<div style={{position: 'absolute', left: 32, top: 0, color: fillcolor }}>*/}
                {/*{props.name}*/}
                {/*<div>{props.roomName}</div>*/}
            {/*</div>*/}
            <p>{reactStringReplace(props.name, props.query, (match, i) => (
                <span key={i} style={{color: 'yellow', fontWeight: 'bold'}}>{match}</span>
            ))}
            </p>
            <p>{reactStringReplace(props.roomName, props.query, (match, i) => (
                <span key={i} style={{color: 'yellow', fontWeight: 'bold'}}>{match}</span>
            ))}
            </p>
        </Tooltip>
    )
  } else {
    return null
  }
}


export default Relay
