import React from 'react'
import QuestionnaireViewTable from '../../components/QuestionnaireViewTable'
import QuestionnaireResultViewTable from '../../components/QuestionnaireResultViewTable'
import { timestampWithLapseFormatter } from '../../components/BaseTable/CellFormatter'
import {peopleFormatter} from "../../components/BaseTable/CellFormatter";
import moment from 'moment'

const totalRportsFormatter = (cell, row, formatExtraData) => {
	let total = cell ? cell.length : 0
	return (<p>{total}</p>)
}

const dateFormatter = (cell, row, formatExtraData) => {
	let when = cell ? moment(cell).format('llll') : 0
	return (<p>{when}</p>)
}

const lastUpdateFormatter = (cell, row, formatExtraData) => {
	if (cell.length == 0) {
		return (<p>Never</p>)
	}
	
	let last = cell.reduce((x, y) => {
				return moment(x.createUtc).isAfter(moment(y.createUtc)) ? x : y
			}, 
			cell[0]
	)
	let when = moment(last.createUtc)
	let content = when.local().format("YYYY/MM/DD HH:mm:ss") + ' (' + when.toNow(true) + ' ago)'
	return (<p>{content}</p>)
}

const answerFormatter = (cell, row, formatExtraData) => {
	let value = cell[formatExtraData]
	if (value == undefined) {
		return (<p>NA</p>)
	}
	return (<p title={value.question}>{value.score}({value.answer})</p>)
}

const qTypeFormatter = (cell, row, formatExtraData) => {
	if (cell.length == 0) {
		return (<p>NA</p>)
	}
	
	let value = cell[0]
	if (value.question.includes('fever')) {
		return (<p>Covid-19</p>)		
	} else if (value.question.includes('eat')) {
		return (<p>ADL</p>)				
	} else {
		return (<p>NA</p>)		
	}
}

class Questionnaire extends React.Component {
    constructor(props) {
        super(props)
        this.handleQuery = this.handleQuery.bind(this)
        this.expandableRow = this.expandableRow.bind(this)
        this.expandComponent = this.expandComponent.bind(this)
        this.state = {
        }
    }

    handleQuery(query) {
        this.setState({
            query: query
        })
    }
    
    expandableRow(row) {
    	return row.reports.length > 0
    }

    expandComponent(row) {
    	let list = row.reports
    	if (list == undefined || list.length == 0) {
    		return
    	}

    	let data = list
    	let questionnaireList = row.reports
        const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        }, {
            config: {
                dataField: 'createUtc',
                dataSort: true,
                dataFormat: timestampWithLapseFormatter
            },
            label: 'Time'
        }, {
            config: {
                dataField: 'questionnaire',
                dataSort: true,
                dataFormat: qTypeFormatter
            },
            label: 'Type',
        }, {
            config: {
                dataField: 'totalScore',
                dataSort: true,
            },
            label: 'Total Score',
        }, {
            config: {
                dataField: 'questionnaire',
                dataSort: true,
                formatExtraData: 0,
                dataFormat: answerFormatter
            },
            label: 'Q1'
        }, {
            config: {
                dataField: 'questionnaire',
                dataSort: true,
                formatExtraData: 1,
                dataFormat: answerFormatter
            },
            label: 'Q2'
        }, {
            config: {
                dataField: 'questionnaire',
                dataSort: true,
                formatExtraData: 2,
                dataFormat: answerFormatter
            },
            label: 'Q3'
        }, {
            config: {
                dataField: 'questionnaire',
                dataSort: true,
                formatExtraData: 3,
                dataFormat: answerFormatter
            },
            label: 'Q4'
        }, {
            config: {
                dataField: 'questionnaire',
                dataSort: true,
                formatExtraData: 4,
                dataFormat: answerFormatter
            },
            label: 'Q5'
        }, {
            config: {
                dataField: 'questionnaire',
                dataSort: true,
                formatExtraData: 5,
                dataFormat: answerFormatter
            },
            label: 'Q6'
        }, {
            config: {
                dataField: 'questionnaire',
                dataSort: true,
                formatExtraData: 6,
                dataFormat: answerFormatter
            },
            label: 'Q7'
        },
        ]

        return (
        	<QuestionnaireResultViewTable 
        		data={ data } 
        		urlEntity='questionnaire'
                entityNameField='userName'
                questionnaireList={questionnaireList}
                striped={true}
        		pagination={false}
        		reqPayload={{seniorId: row.id}}
        		columnProps={columnProps}
        	/>
        )
    }
    
    render() {
        const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        }, {
            config: {
                dataField: 'userName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'User Name'
        }, {
            config: {
                dataField: 'firstName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'First Name',
        }, {
            config: {
                dataField: 'lastName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Last Name'
    	}, {
    		config: {
    			dataField: 'reports',
    			dataSort: true,
    			dataFormat: totalRportsFormatter,
    		},
    		label: 'Reports'
		}, {
			config: {
				dataField: 'reports',
				dataSort: true,
				dataFormat: lastUpdateFormatter,
			},
			label: 'Lastest Report Time'
		},	    	
        ]

        return (
            <QuestionnaireViewTable
                facilityFilter
                facilityFilterLevel='floor'
                urlEntity='senior'
                entityNameField='userName'
                columnProps={columnProps}
                sortName={'id'}
                sortOrder={'asc'}
            	expandableRow={this.expandableRow}
            	expandComponent={this.expandComponent}
                onHandleQuery={ this.handleQuery }
            />
        )
	}
}

export default Questionnaire
