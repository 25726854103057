import React from 'react'
import FacilitySelector from '../../components/FacilitySelector'
import Row from 'react-bootstrap/Row'
import { embeddedNameFormatter, enabledFormatter, sensorListFormatter,
    hasAudioFormatter, temperatureFormatter } from '../../components/BaseTable/CellFormatter'
import {dailyEnergyFormatter, peopleFormatter} from "../../components/BaseTable/CellFormatter";
import moment from "moment";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import BaseTableToolBar from "../../components/BaseTable/BaseTableToolBar";
import ApiSingleton from '../../utils/Axios';

export default class DailyEnergyTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1, // current page number
            sizePerPage: 10, // current size per page,
            totalDataSize: 0,
            seniors: [],
            reqPayload: {},
            timer: null,
            showEnabledOnly: true
        }
        this.renderShowsTotal = this.renderShowsTotal.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.onSizePerPageList = this.onSizePerPageList.bind(this)
        this._refresh = this._refresh.bind(this)
    }

    _refreshResume = () => {
        if (!this.state.timer) {
            var timer = setInterval(this._refresh, 10000)
            this.setState({timer:timer})
        }
    }
    _refreshSuspend = () => {
        if (this.state.timer) {
            clearInterval(this.state.timer)
            this.setState({timer:null})
        }
    }

    _updateSeniorsEnergy = (seniors) => {
        if(seniors) {
            const residents = seniors
            for(let i = 0; i < seniors.length; i++) {
                residents[i].energy = this._fetchEnergies(seniors[i].id)
            }
            this.setState({
                seniors: residents
            })
        }
    }
    _refresh = () => {
        if(this.state.seniors) {
            this._updateSeniorsEnergy(this.state.seniors)
        }
        // this._fetchSeniors(this.state.reqPayload, this.state.currentPage, this.state.sizePerPage)
    }

    onClickShowEnabledOnly = (e) => {
        this._fetchSeniors(this.state.reqPayload, this.state.currentPage, this.state.sizePerPage, e.target.checked)
        this.setState({
            showEnabledOnly: e.target.checked
        })
    }

    componentDidMount() {
        // refresh every 10 second
        this._fetchSeniors(this.state.reqPayload, this.state.currentPage, this.state.sizePerPage, this.state.showEnabledOnly)
        this._refreshResume()
    }

    componentWillUnmount() {
        this._refreshSuspend()
    }

    _fetchSeniors(reqPayload, page, sizePerPage, enabled) {
        const select = ['id', 'userName', 'firstName', 'lastName', 'enable']
        const postData = {
            page: page - 1,
            pageSize: sizePerPage,
            select: JSON.stringify(select)
        }
        if(enabled) {
            postData.enabled = true
        }
        if(reqPayload) {
            if(reqPayload.enterpriseId) {
                postData.enterpriseId = reqPayload.enterpriseId
            }
            if(reqPayload.venueId) {
                postData.venueId = reqPayload.venueId
            }
            if(reqPayload.floorId) {
                postData.floorId = reqPayload.floorId
            }
            if(reqPayload.buildingId) {
                postData.buildingId = reqPayload.buildingId
            }
            if(reqPayload.roomId) {
                postData.roomId = reqPayload.roomId
            }
        }
        // console.log('fetchSeniors', postData)
        ApiSingleton.makeHttpRequest(
            'post',
            '/bstream/api/v1/senior/findWithPageAndSelect',
            { dataType: 'json'},
            postData
          ).then((data) => {
            const seniors = _.sortBy(data.list, 'id')
            for(let i = 0; i < seniors.length; i++) {
                seniors[i].energy = this._fetchEnergies(seniors[i].id)
            }
            this.setState({
                seniors: seniors,
                totalDataSize: data.totalCount,
                currentPage: page,
                sizePerPage: sizePerPage
            })
          })
          .catch((err) => {
              console.log(err)
          })
    }

    _fetchEnergies(seniorId) {
        var energyList = []
        const maxStartUtc = moment().utc().format("YYYY-MM-DD").toString() + "T09:00:00.000Z"
        const minStartUtc = moment().subtract('180', 'days').utc().format("YYYY-MM-DD").toString() + "T09:00:00.000Z"
        const postData = {
            seniorId: seniorId,
            minStartUtc: minStartUtc,
            maxStartUtc: maxStartUtc,
            orderBy: 'startUtc',
            duration: 1,
            ascendingOrder: false,
            maxResults: 7
        }
        if(seniorId) {
            ApiSingleton.makeHttpRequest(
                "post",
                "/bstream/api/v1/seniorReport/findRange",
                { dataType: "json" },
                postData
              )
                .then((data) => {
                    if (data) {
                        for (let i = 0; i < data.length; i++) {
                        		let e = data[i]
                        		if (e.dailyActivity !== null) {
                        			var o = {}
                        			o.startUtc = e.startUtc
                        			if (e.dailyActivity.activeTotalEnergy !== null) {
                        				o.activeTotalEnergy = e.dailyActivity.activeTotalEnergy
                        			}
                        			if (e.dailyActivity.restingTotalEnergy !== null) {
                        				o.restingTotalEnergy = e.dailyActivity.restingTotalEnergy
                        			}
                        			if (e.dailyActivity.activeTotalEnergy !== null) {
                        				o.activeTrending = e.dailyActivity.activeTrending
                        			}
                        			if (e.dailyActivity.restingTotalEnergy !== null) {
                        				o.restingTrending = e.dailyActivity.restingTrending
                        			}
                        			energyList.push(o)
                        		}
                        }
                    }
                })
                .catch((err) => {});
        }
        
        energyList.sort((x, y) => moment(x.startUtc).valueOf() - moment(y.startUtc).valueOf())
        return energyList
    }

    renderShowsTotal(start, to, total) {
        return (
            <span style={{float: 'right'}}>
        Total: {total}
      </span>
        )
    }

    onSizePerPageList(sizePerPage) {
        this._fetchSeniors(this.state.reqPayload, this.state.currentPage, sizePerPage, this.state.showEnabledOnly)
        this.setState({
            sizePerPage: sizePerPage
        })
    }

    onPageChange(page, sizePerPage) {
        this._fetchSeniors(this.state.reqPayload, page, sizePerPage, this.state.showEnabledOnly)
    }


    _onFacilityFilterChange = (filter) => {
        this._fetchSeniors(filter, 1, 10, this.state.showEnabledOnly)
        this.setState({
            reqPayload: filter
        })
    }

    render() {
        const state = this.state
        const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        }, {
            config: {
                dataField: 'userName',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'User Name'
        }, {
            config: {
                dataField: 'firstName',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'First Name',
        }, {
            config: {
                dataField: 'lastName',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Last Name'
        }, {
                config: {
                    dataField: 'energy',
                    dataSort: false,
                    formatExtraData: this.state.query,
                    dataFormat: dailyEnergyFormatter,
                    width: '800px'
                },
                label: 'Daily Energy'
            }

        ]

        return (
            <div>
                <Row>
                    <FacilitySelector onChange={this._onFacilityFilterChange}
                                      filterLabel='Filter By'
                    />
                </Row>
                <BaseTableToolBar
                    actions={[{name: 'refresh'}]}
                    onClickShowEnabledOnly={ this.onClickShowEnabledOnly }
                    showEnabledOnly={ state.showEnabledOnly }
                    onRefresh={ this._refresh }
                />
                <BootstrapTable
                    ref='table'
                    data={ this.state.seniors }
                    remote={true}
                    fetchInfo={{ dataTotalSize: state.totalDataSize }}
                    pagination={true}
                    options={{
                        page: state.currentPage,
                        sizePerPageList: [ 10, 25, 50, 100 ],
                        sizePerPage: state.sizePerPage,
                        paginationShowsTotal: this.renderShowsTotal,
                        onPageChange: this.onPageChange,
                        onSizePerPageList: this.onSizePerPageList,
                    }}>
                    { columnProps.map((column) => {
                        return (
                            <TableHeaderColumn {...column.config}
                                               key={column.config.dataField}
                                               width={ column.config.width }
                            >
                                {column.label}
                            </TableHeaderColumn>
                        )
                    })
                    }
                </BootstrapTable>

            </div>
        )
    }
}