import React from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import RoomPosition from './RoomPosition'

class FormGroupRoomPosition extends React.Component {
  constructor(props) {
    super(props)
    const imageContent = (props.data && props.data.image) ? atob(props.data.image.content) : null
    const coord = props.value
    if (coord) {
      this.state = {
        imageContent: imageContent,
        left: coord[0].x,
        top: coord[0].y,
        right: coord[1].x,
        bottom: coord[1].y
      }
    } else {
      this.state = {
        imageContent: imageContent,
        left: 0,
        top: 0,
        right: 50,
        bottom: 50
      }
    }
  }

  _onChange = (value) => {
    //console.log('FormGroupRoomPosition onChange, value = ', value)
    const coordinates = [{
      x: value['x1'],
      y: value['y1']
    }, {
      x: value['x2'],
      y: value['y2']
    }]
//    value = {
//      imageContent: this.state.imageContent,
//      coordinates: coordinates
//    }
    this.props.onChange(this.props.controlId, this.props.required, this.props.validator, coordinates)
  }

  render() {
    const props = this.props,
          state = this.state,
          labelWidth = props.labelWidth ? props.labelWidth : 3,
          valueWidth = 8
    return (
      <Form.Group
        controlId={ props.controlId }
        validationState={ props.validationState.status }>
        <Col sm={ labelWidth }>
          <Form.Label style={{ fontWeight: "bold" }}>{ props.label }</Form.Label>
        </Col>
        <Col sm={ valueWidth }>
          <p>{ props.helpText }</p>
        </Col>
        <Col smOffset={ labelWidth } sm={ valueWidth } style={{overflow: 'auto'}}>
          <RoomPosition
            imageContent={ state.imageContent }
            imageWidth={800} imageHeight={500}
            left={ state.left }
            top={ state.top }
            right={ state.right }
            bottom={ state.bottom }
            onChange={ this._onChange } />
        </Col>
      </Form.Group>
    )
  }
}

export default FormGroupRoomPosition
