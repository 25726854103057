/* global $ */
import React from 'react'
import { createRoot } from 'react-dom/client'
import AppContainer from './containers/AppContainer'
import { AuthProvider } from '../src/context/AuthContext'
import { ToastProvider } from '../src/context/ToastContext'
import Toast from '../src/components/Toast/Toast'

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')
const root = createRoot(MOUNT_NODE)

let render = () => {
  root.render(
    <ToastProvider>
      <AuthProvider>
        <AppContainer />
        <Toast />
      </AuthProvider>
    </ToastProvider>
  )
}
// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = (error) => {
      const RedBox = require('redbox-react').default
      root.render(<RedBox error={error} />)
    }

    //  Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        // console.error(error)
        renderError(error)
      }
    }

    // Setup hot module replacement
    /* module.hot.accept('./routes', () =>
      setImmediate(() => {
        root.unmountComponentAtNode(MOUNT_NODE)
        render()
      })
    ) */
  }
}

// ========================================================
// Go!
// ========================================================
render()
