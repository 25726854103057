import React from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Select from 'react-select'
import Utils from '../../utils'
import '../react-select.css'
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'
import AsyncSelect from 'react-select/async';

const SeniorControlLevels = ['enterprise', 'senior']

class FormGroupSenior extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props)

    this.state = {
      value: {
        enterprise: props.value ? props.value[props.fields.enterprise.controlId] : null,
        senior: props.value ? props.value[props.fields.senior.controlId] : null
      },
      seniorOptions: []
    }

    this._onEnterpriseChange = this._onEnterpriseChange.bind(this)
    this._onSeniorChange = this._onSeniorChange.bind(this)

    this._getEnterpriseOptions = this._getEnterpriseOptions.bind(this)
    this._getSeniorOptions = this._getSeniorOptions.bind(this)
  }

  componentDidMount() {
    if (this.state.value.enterprise) {
      this._getSeniorOptions(this.state.value.enterprise, false)
    }
  }

  _onEnterpriseChange(value) {
    this._onChange('enterprise', value)
    if (value.value) {
      this._getSeniorOptions(value.value, true)
    }
  }

  _onSeniorChange(value) {
    this._onChange('senior', value)
  }

  _onChange(key, value) {
    let newValue = {...this.state.value}
    newValue[key] = value ? value.value : null

    const subLevels = _.slice(SeniorControlLevels, _.indexOf(SeniorControlLevels, key) + 1)

    // based on the attribute being changed, we need to reset the values of others
    newValue = Utils.setNull(newValue, subLevels)

    this.setState({
      value: newValue
    })

    // based on the type of the filter, we need to pick out the set of attribute to notify parents
    const notifyValue = _.pick(newValue, _.slice(SeniorControlLevels, 0, _.indexOf(SeniorControlLevels, key) + 1))
    const notifyObj = {}, notifyFields = {}
    _.forEach(notifyValue, (item, key) => {
      const {controlId, required, validator} = this.props.fields[key]
      notifyObj[controlId] = item
      notifyFields[controlId] = this.props.fields[key]
    })
    // based on the attribute being changed, notify
    this.props.onChange(notifyObj, notifyFields)
  }

  _getEnterpriseOptions() {
    return this._getOptions('enterprise', 'name', {})
  }

  _getSeniorOptions(enterpriseId, resetDownstream) {
    this.setState({
      isSeniorLoading: true
    })
    this._getOptions('senior', 'username', {enterpriseId: enterpriseId})
    .then((data) => {
      this.setState({
        isSeniorLoading: false,
        seniorOptions: data ? data.options : []
      })
    }, (err) => {
      this.setState({
        isSeniorLoading: false,
        seniorOptions: []
      })
    })
  }

  _getOptions(entity, orderBy, filter) {
    const { showToast } = this.context
    if (orderBy) {
      if (!filter) {
        filter = {}
      }
      filter.orderBy = orderBy
      filter.ascendingOrder = true
    }
    return  ApiSingleton.makeHttpRequest(
      'post',
      '/bstream/api/v1/' + entity + '/find',
      { dataType: 'json'},
      filter
    ).then((data) => {
      const options = data.map((item, index) => {
        return {value: item[this.props.fields[entity].valueKey], label: item[this.props.fields[entity].labelKey]}
      })
      return {
        options: options
      }
    },
    (err) => {
      showToast('Failed to fetch list of ' + entity + '! Reason: ' + err)
    })
  }

  render() {
    const props = this.props,
          fields = props.fields,
          state = this.state,
          labelWidth = props.labelWidth ? props.labelWidth : 3,
          valueWidth = props.valueWidth ? props.valueWidth : 7,
          validationWidth = 12 - labelWidth - valueWidth
    return (
      <div>
        <Form.Group
          controlId={ fields.enterprise.controlId }
          validationState={ props.validationState[fields.enterprise.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label style={{ fontWeight: "bold" }}>{ fields.enterprise.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <AsyncSelect
              name={ fields.enterprise.controlId}
              autoload={ props.autoLoad }
              searchable
              value={ state.value.enterprise }
              loadOptions={ this._getEnterpriseOptions }
              onChange={ this._onEnterpriseChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.enterprise.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.enterprise.helpText }</p>
          </Col>
        </Form.Group>
        <Form.Group
          controlId={ fields.senior.controlId }
          validationState={ props.validationState[fields.senior.controlId].status }>
          <Col sm={ labelWidth }>
            <Form.Label style={{ fontWeight: "bold" }}>{ fields.senior.label }</Form.Label>
          </Col>
          <Col sm={ valueWidth }>
            <Select
              name={ fields.senior.controlId}
              searchable
              value={ state.value.senior }
              isLoading={ state.isSeniorLoading }
              options= { state.seniorOptions }
              onChange={ this._onSeniorChange }
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={ validationWidth }>
            <p>{ props.validationState[fields.senior.controlId].message }</p>
          </Col>
          <Col smOffset={ labelWidth } sm={ valueWidth + validationWidth }>
            <p>{ fields.senior.helpText }</p>
          </Col>
        </Form.Group>
      </div>
    )
  }
}

export default FormGroupSenior
