import React from "react";
import FormGroup from "react-bootstrap/FormGroup";
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";

import { RadioGroup, Radio } from "react-radio-group";

const FormGroupRadioGroup = (props) => {
  const [selectedValue, setSelectedValue] = React.useState(null);

  const _onChange = (value) => {
    setSelectedValue(value);

    props.onChange(props.controlId, props.required, props.validator, value);
  };

  const labelWidth = props.labelWidth ? props.labelWidth : 3,
    valueWidth = props.valueWidth ? props.valueWidth : 7,
    validationWidth = 12 - labelWidth - valueWidth;

  return (
    <Form.Group
      controlId={props.controlId}
      as={Row}
      className="mb-3"
      validationState={props.validationState.status}
    >
      <Col sm={labelWidth}>
        <Form.Label>{props.label}</Form.Label>
      </Col>

      <RadioGroup
        className="col-sm-7"
        name={props.controlId}
        selectedValue={selectedValue}
        onChange={_onChange}
      >
        {props.options.map((option, index) => {
          if (props.inline) {
            return (
              <label key={props.controlId + index} className="radio-inline">
                <Radio value={option.value} /> {option.label}
              </label>
            );
          } else {
            return (
              <div key={props.controlId + index} className="radio">
                <Radio value={option.value} /> {option.label}
              </div>
            );
          }
        })}
      </RadioGroup>
      <Col sm={validationWidth}>
        <p>{props.validationState.message}</p>
      </Col>
      <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
        <p>{props.helpText}</p>
      </Col>
    </Form.Group>
  );
};

export default FormGroupRadioGroup;
