import React from 'react'
import { Tooltip } from 'react-bootstrap';
import reactStringReplace from "react-string-replace";
class Sensor extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      showLabel: false,
    }
  }

  _onMouseOver = (e) => {
    this.setState({ showLabel:true })
  }

  _onMouseOut = (e) => {
    this.setState({ showLabel:false })
  }

  render () {
    const props = this.props,
          state = this.state,
          hasAlerts = props.alerts.length > 0,
          alertTypes = props.alerts.map((alert) => {
            return alert.type
          })
      // console.log('alert', alertTypes + ";;;;");
      // console.log(this.props);
    let iconclass = hasAlerts ? 'fa fa-map-marker fa-3x text-danger animated infinite zoomIn' : 'fa fa-map-marker fa-2x text-success'
    if (alertTypes.indexOf('MISSING') !== -1 && props.alerts.length === 1) {
      iconclass = 'fa fa-map-marker fa-3x text-warning animated infinite zoomIn'
    }
    if (alertTypes.indexOf('APPROACHING') !== -1 && props.alerts.length === 1) {
        iconclass = 'fa fa-map-marker fa-3x text-primary animated infinite zoomIn'
    }

    if (alertTypes.indexOf('LEAVING') !== -1 && props.alerts.length === 1) {
        iconclass = 'fa fa-map-marker fa-3x text-muted animated infinite zoomIn'
    }

    if (alertTypes.indexOf('EXIT_DOOR') !== -1 && props.alerts.length === 1) {
        iconclass = 'fa fa-map-marker fa-3x text-primary animated infinite zoomIn'
    }

    const sSensorWidth = props.scale > 1 ? 14 : 14 * props.scale;
    const sSensorHeight = props.scale > 1 ? 26 : 26 * props.scale;

    const IconDiv = props.scale > 1 ? 13 : props.scale * 13;

    const lSensorWidth = props.scale > 1 ? 22 : 22 * props.scale;
    const lSensorHeight = props.scale > 1 ? 38 : 38 * props.scale;
    const margin = props.scale > 1 ? -4 : props.scale * (-4);

    const divSizeStation = hasAlerts ? { left: 32, top: 41 } : {left: 24, top : 28}

    let offset = hasAlerts ? {left: 11, top: 38} : {left: 7, top: 26};

      if(props.showStation) {
        if(hasAlerts) {
            offset = {
                left: offset.left + margin + IconDiv,
                top: offset.top
            }
        } else {
            offset = {
                left: offset.left + margin + IconDiv,
                top: offset.top
            }
        }

      }

    if(props.scale < 1) {
        offset = {
            left: offset.left * props.scale,
            top: offset.top * props.scale}
    }

    const position = {
      left: ( props.sensorX - props.centerX) * props.scale + props.centerX - offset.left,
      top: ( props.sensorY - props.centerY) * props.scale + props.centerY - offset.top
      // left: this.props.scale * props.sensorX  - offset.left - this.props.offsetX,
      // top: this.props.scale * props.sensorY - offset.top - this.props.offsetY
    }
    // console.log('sensorNew:'+this.props.senior['userName'], position.left + "," + position.top + ";");
    // if (position.left < 0) {
    //   position.left = 0
    // }
    // if (position.top < 0) {
    //   position.top = 0
    // }
    // console.log(props);
    const stationId = this.props.senior ? this.props.senior['stationId'] : null ;
    let index = -1;
    if(stationId != null) index = this.props.stationIds.indexOf(stationId);
    let alert = '';
    let stationIcon = (index === -1 || index >= this.props.iconStations.length) ? '' : this.props.iconStations[index];
    if(hasAlerts) {
        if(alertTypes.indexOf('SOS') !== -1) {
            stationIcon += ' text-danger'
        }else if(alertTypes.indexOf('MISSING') !== -1) {
          stationIcon += ' text-warning'
        } else if(alertTypes.indexOf('APPROACHING') !== -1) {
          stationIcon += 'text-primary'
        } else if(alertTypes.indexOf('LEAVING') !== -1) {
            stationIcon += 'text-muted'
        } else if(alertTypes.indexOf('EXIT_DOOR') !== -1) {
            stationIcon += 'text-primary'
        } else {
            stationIcon += ' text-danger'
        }
    } else {
      stationIcon += ' text-success'
    }
    //39: --3x
    let fontSize = hasAlerts ? 39 : 26
    let markSize = 13
    markSize = props.scale > 1 ? markSize : markSize * props.scale
    fontSize = props.scale > 1 ? fontSize : props.scale * fontSize
    let displayLabel = props.query && props.seniorName.toLowerCase().includes(props.query.toLowerCase())
                    || (props.query && props.roomName.toLowerCase().includes(props.query.toLowerCase()))
      let alertDisplay = false
      if(hasAlerts) {
          for(let i = 0; i < props.alerts.length; i++) {
              let alert = props.alerts[i].type;
              if(props.query && alert.toLowerCase().includes(props.query.toLowerCase())) {
                  alertDisplay = true
              }
          }
      }
      displayLabel = displayLabel || alertDisplay
      //show the same station:
      if(props.showStation) {
          //no same station is selected:        one same station is selected:
          if(props.sameStationIds.length === 0 || (props.sameStationIds != null && props.sameStationIds.includes(stationId))) {
              return (
                  <div
                      onMouseOver={ this._onMouseOver}
                      onMouseOut={ this._onMouseOut }
                      style={ {position: 'absolute', left: position.left, top: position.top} }>
                      <span className={stationIcon} style={{marginRight: margin, fontSize: markSize}}></span>
                      {/*<span>{props.sensorX}, {props.sensorY}; {position.left}, {position.top}; </span>*/}
                      <i className={ iconclass } aria-hidden="true" style={{fontSize: fontSize}}></i>
                      <SensorLabel showLabel={ state.showLabel || displayLabel }
                                   {...props} hasAlert={hasAlerts} alert={alertTypes}
                                   position={{left: position.left, top: position.top} }/>
                  </div>
              )
          } else if(props.sameStationIds.length == props.stations.length) {
              return (
                  <div
                      onMouseOver={ this._onMouseOver}
                      onMouseOut={ this._onMouseOut }
                      style={ {position: 'absolute', left: position.left, top: position.top} }>
                      <span className={stationIcon} style={{marginRight: margin, fontSize: markSize}}></span>
                      {/*<span>{props.sensorX}, {props.sensorY}; {position.left}, {position.top}; </span>*/}
                      <i className={ iconclass } aria-hidden="true" style={{fontSize: fontSize}}></i>
                      <SensorLabel showLabel={ state.showLabel || displayLabel }
                                   {...props} hasAlert={hasAlerts} alert={alertTypes}
                                   position={{left: position.left, top: position.top} }/>
                  </div>
              )
          }
          else {
              return null
          }
          //hide the same station: show all the stations without stationIcon
      } else {
          return (
              <div
                  onMouseOver={ this._onMouseOver}
                  onMouseOut={ this._onMouseOut }
                  style={ {position: 'absolute', left: position.left, top: position.top} }>
                  {/*<span>{props.sensorX}, {props.sensorY}; {position.left}, {position.top}; </span>*/}
                  <i className={ iconclass } aria-hidden="true" style={{fontSize: fontSize}}></i>
                  <SensorLabel showLabel={ state.showLabel || displayLabel }
                               {...props} hasAlert={hasAlerts} alert={alertTypes}
                               position={{left: position.left, top: position.top} }/>
              </div>
          )
      }
  }
}

const SensorLabel = (props) => {
  if (props.showLabel) {
    const fillcolor = props.alerts.length > 0 ? 'rgb(128,0,0)' : 'rgb(0,128,0)'
      const sosIcon = "fa fa-ambulance fa-lg text-danger"
      const missingIcon = "fa fa-deaf fa-lg text-danger"
      const breakAwayIcon = "fa fa-chain-broken fa-lg text-danger"
      const powerOffIcon="fa fa-power-off fa-lg text-danger"
      const highImpactIcon = "fa fa-medkit fa-lg text-danger"
      const lowBatteryIcon = "fa fa-battery-1 fa-lg text-danger"
      const approachingIcon = "fa fa-warning fa-lg text-primary"
      const leavingIcon = "fa fa-bell-slash fa-lg text-muted"
    	  const exitDoorIcon = "fa fa-warning fa-lg text-primary"
      //
      let icons = [];
      let alerts = [];
      props.alert.map((each) => {
          if(each === 'SOS') {
              icons.push(sosIcon)
              alerts.push('SOS')
          }
          if(each === 'MISSING') {
              icons.push(missingIcon)
              alerts.push('Missing')
          }
          if(each === 'BREAK_AWAY') {
              icons.push(breakAwayIcon)
              alerts.push('Break Away')
          }
          if(each === 'POWER_OFF') {
              icons.push(powerOffIcon)
              alerts.push('Power Off')
          }
          if(each === 'HIGH_IMPACT') {
              icons.push(highImpactIcon)
              alerts.push('High Impact')
          }
          if(each === 'LOW_BATTERY') {
              icons.push(lowBatteryIcon)
              alerts.push('Low Battery')
          }

          if(each === 'APPROACHING') {
              icons.push(approachingIcon)
              alerts.push('Approaching')
          }
          if(each === 'LEAVING') {
              icons.push(leavingIcon)
              alerts.push('Leaving')
          }
          if(each === 'EXIT_DOOR') {
              icons.push(exitDoorIcon)
              alerts.push('Exit Door')
          }

      })
    return (
      <Tooltip placement="bottom" className="in tooltip" id="tooltip"
               style={{width: 'auto', minWidth: 100, marginLeft: -42, marginTop: -1}}>
          <p>
              {reactStringReplace(props.seniorName, props.query, (match, i) => (
              <span key={i} style={{color: 'yellow', fontWeight: 'bold'}}>{match}</span>
          ))}
          </p>
          <p>{reactStringReplace(props.roomName, props.query, (match, i) => (
              <span key={i} style={{color: 'yellow', fontWeight: 'bold'}}>{match}</span>
          ))}
          </p>
          {props.hasAlert && alerts.map((alert, index) => {
              return (
                  <p key={index}>
                      <span className={icons[index]}></span>
                      <span> {reactStringReplace(alert, props.query, (match, i) => (
                          <span key={i} style={{color: 'yellow', fontWeight: 'bold'}}>{match}</span>
                      ))}</span>
                  </p>
              )
          })
          }

        {/*<p>sensor: {props.sensorX}, {props.sensorY}</p>*/}
        {/*<p>pos: {props.position.left}, {props.position.top}</p>*/}
      </Tooltip>
    )
  } else {
    return null
  }
}

export default Sensor
