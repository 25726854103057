import React, { useState, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom';
import { FaWalking , FaBed , FaHeartbeat } from 'react-icons/fa'
import { TbHeartbeat, TbDeviceHeartMonitor, TbHeartRateMonitor } from 'react-icons/tb'
import { SiOxygen } from 'react-icons/si' 
import DatePicker from 'react-date-picker'

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material"
import StatBox from "../../components/StatBox/StatBox"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'];

export function StepCountStats() {
  const [dateValue, onDateChange] = useState(new Date("2023-3-7"));

  useEffect(() => {
    console.log("useEffect ran here");

  });

  function handleDateChange(event) {
    onDateChange(event);

  }

  // navigation to statistical analysis page
  const navigate = useNavigate();
  function handleGoHomeDisplay(e) {
      e.preventDefault();
      navigate('/');
  }

  return (
    <Box m="20px"> 
        <Box display="flex" justifycontext="space-between" alignItems="center">
            <Box width="100%">
                <Typography variant="h3" fontWeight="bold" sx={{ color: "#530C0C" }}>Step Count Statistics</Typography>
            </Box>
            <Box>
                <DatePicker onChange={handleDateChange} value={dateValue} /> {/* onDateChange */}
            </Box>
        </Box>

        {/* GRID AND CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >

            {/** ROW 1 */}
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title="Value Here" subtitle="Maximum" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title="Value Here" subtitle="Minimum" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title="Value Here" subtitle="Average" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>

            {/** ROW 2 */}
            <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Graph
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        {/* <FaHeartbeat color='#530C0C' /> */}
                    </Box>
                </Box>
                <Box height="250px" m="-15px 25px">
                    {/* <Line options={null} data={null} className='graph' id="chartHR" key={Math.random()} /> */}
                </Box>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title="" subtitle="" icon={<TbDeviceHeartMonitor color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title="" subtitle="" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
            </Box>

        </Box>
    </Box>
  );
}

export default StepCountStats;