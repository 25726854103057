import React from 'react'
import Col from 'react-bootstrap/Col'
import { Form  } from 'react-bootstrap'
class FormGroupPolicy extends React.Component {
    constructor(props) {
        super(props)
        const value0 = (props.value0 !== undefined && props.value0 !== null) ?
            props.value0 : false
        const value1 = (props.value1 !== undefined && props.value1 !== null) ?
                        props.value1 : false
        const value2 = (props.value2 !== undefined && props.value2 !== null) ?
                        props.value2 : false
        this.state = ({
            value0: value0,
            value1: value1,
            value2: value2
        })
    }

    componentDidMount() {
        if ((this.props.value0 === undefined || this.props.value0 === null)) {
            this.props.onChange(this.props.fields[0].controlId, this.props.fields[0].required,
                this.props.fields[0].validator, this.props.fields[0].defaultValue)
        }
        if ((this.props.value1 === undefined || this.props.value1 === null)) {
            this.props.onChange(this.props.fields[1].controlId, this.props.fields[1].required,
                this.props.fields[1].validator, this.props.fields[1].defaultValue)
        }
        if ((this.props.value2 === undefined || this.props.value2 === null)) {
            this.props.onChange(this.props.fields[2].controlId, this.props.fields[2].required,
                this.props.fields[2].validator, this.props.fields[2].defaultValue)
        }

    }
    _onChange = (e) => {
        const value = e.target.checked
        const name = e.target.name
        if(name === 'raise') {
            this.setState({
                value0: value ? value: false
            },() => {
                this.props.onChange(this.props.fields[0].controlId, this.props.fields[0].required,
                    this.props.fields[0].validator, value, true)
            })
        }else if(name === 'show') {
            this.setState({
                value1: value ? value: false
            },() => {
                this.props.onChange(this.props.fields[1].controlId, this.props.fields[1].required,
                    this.props.fields[1].validator, value, true)
            })
        } else if(name === 'withAudio') {
            this.setState({
                value2: value ? value: false
            }, () => {
                this.props.onChange(this.props.fields[2].controlId, this.props.fields[2].required,
                    this.props.fields[2].validator, value, true)
            })

        }

    }

    render() {
        const props = this.props
        const state = this.state
        return (
            <Form.Group>
                <Col lg={3} md={3}>
                    <Form.Label style={{ fontWeight: "bold" }}>{ props.controlId }</Form.Label>
                </Col>
                <Col lg={3} md={3}>
                    <Form.Check  checked={ state.value0 }
                              onChange={ this._onChange }
                              name={'raise'}
                    >
                        {props.fields[0].label}
                    </Form.Check >
                </Col>
                <Col lg={3} md={3}>
                    <Form.Check  checked={ state.value1 }
                              onChange={ this._onChange }
                              name={'show'}
                    >
                        {props.fields[1].label}
                    </Form.Check >
                </Col>
                <Col lg={3}>
                    <Form.Check  checked={ state.value2 }
                              onChange={ this._onChange }
                              name={'withAudio'}
                    >
                        {props.fields[2].label}
                    </Form.Check >
                </Col>
            </Form.Group>

        )
    }
}
export default FormGroupPolicy
