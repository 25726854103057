import React, { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import FacilitySelector from '../../components/FacilitySelector'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'

const SeniorSummaryReport = (props) => {
  const [selectedDay, setSelectedDay] = useState(new Date())
  const [floorId, setFloorId] = useState(null)
  const [isPdfReportLoading, setIsPdfReportLoading] = useState(false)
  const [facilitySelectorValue, setFacilitySelectorValue] = useState({
    // Todo enterpriseId: global.login.enterpriseId,
    enterpriseId: '',
    filterType: 'Floor',
  })

  const _onFacilityFilterChange = (filter) => {
    if (_.isEmpty(filter) || !filter.floorId) {
      return
    }
    setFloorId(filter.floorId)
  }

  const onBigCalendarViewChange = (view) => {
    console.log('_onView() view = ', view)
  }

  const onBigCalendarNavigate = (date) => {
    setSelectedDay(date)
  }

  const _onDayPickerClick = (day, { selected }) => {
    setSelectedDay(day)
  }

  const titleAccessor = (event) => {
    if (event.type === 'LOCATION') {
      return event.room
    } else if (event.type === 'ALERT_TRIGGERED') {
      return (
        event.alertType +
        ' alert occurred at ' +
        event.room +
        ' (' +
        event.building +
        ', ' +
        event.floor +
        ')'
      )
    } else if (event.type === 'ALERT_CLEARED') {
      return (
        event.alertType +
        ' alert cleared at ' +
        event.room +
        ' (' +
        event.building +
        ', ' +
        event.floor +
        ')'
      )
    } else if (event.title) {
      return event.title
    } else {
      return 'fix me (unknown event type or missing title)'
    }
  }

  const eventPropGetter = (event, start, end, isSelected) => {
    if (event.type === 'ALERT_TRIGGERED') {
      return { style: { backgroundColor: 'red' } }
    } else if (event.type === 'ALERT_CLEARED') {
      return { style: { backgroundColor: 'green' } }
    } else {
      return {}
    }
  }

  const _getPdfReport = (filename) => {
    setIsPdfReportLoading(true)
    ApiSingleton.makeHttpRequest(
      'post',
      '/bstream/api/v1/pdf/findByName',
      { dataType: 'json', name: filename },
      {}
    ).then(
      (fileData) => {
        saveAs(filename, fileData)
      },
      (err) => {
        console.log('err', err)
        console.error(
          'Failed to fetch report data for resident! Reason: ',
          err
        )
       /*  this.setState({
          events: [],
        }) */
      }
    )
  }

  const saveAs = (filename, fileData) => {
    console.log('got', filename, 'pdf report', fileData)
    console.log('file content length', fileData.content.length)
    var base64 = fileData.content
    var binary = atob(base64)
    var array = new Uint8Array(binary.length)
    for (var i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i)
    }
    var blob = new Blob([array])
    var url = URL.createObjectURL(blob)
    var link = document.createElement('a');
	  if (typeof link.download === 'string') {
		console.log('create a new link', url)
		// Safari and Firefox requires the link to be in the body
	    document.body.appendChild(link)
	    link.download = filename
	    link.href = url
	    link.click()
	    document.body.removeChild(link) //remove the link when done
	  } else {
		console.log('replace with url', url)
	    location.replace(url)
	  }
  }
  const _onDownloadDailySummaryReport = () => {
	  if (!floorId) {
		  return
	  }
	  const filename = floorId + '-' + moment(selectedDay).format("YYYY-MM-DD") + '-DailySummaryReport.pdf'
	  //console.log('daily summary report filename', filename)
	  _getPdfReport(filename)
  }
  var loading = (
  <span>
    Crunching hard numbers
    <i className="fa fa-circle-o-notch fa-spin right"/>
  </span>)
  return (
    <div>
      <FacilitySelector
        onChange={ _onFacilityFilterChange }
        filterLabel='Filter Report By'
        value={ facilitySelectorValue }
      />
      <Row style={{marginLeft: '-5px'}}>
        <Col sm={3} md={2} lg={2}>
          <div>
            <DayPicker
              selectedDays={selectedDay}
              month={selectedDay}
              onDayClick={_onDayPickerClick}
            />
          </div>
          <div>
              <Button bsStyle='primary' block onClick={_onDownloadDailySummaryReport}>
              <FontAwesomeIcon icon="fa-sharp fa-solid fa-download" /> Daily Summary</Button>
        </div>
        </Col>
      </Row>
    </div>
  )
}

export default SeniorSummaryReport
