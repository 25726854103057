import React from 'react'
import Relay from './Relay'
import Sensor from './Sensor'
import { Form  } from 'react-bootstrap'
class FloorMap extends React.Component{
    constructor(props) {
        super(props)
        this.zoomIn = this.zoomIn.bind(this)
        this.zoomOut = this.zoomOut.bind(this)
        this.changeOpacity = this.changeOpacity.bind(this)
        this.reset = this.reset.bind(this)
        this.showStation = this.showStation.bind(this)
        this.showThisStation = this.showThisStation.bind(this)
        // this._fetchStations = this._fetchStations.bind(this)
        this.centerX = 0
        this.centerY = 0
    }

    state = {
        scale: 1,
        opacity: 0.2,
        mode: 'glyphicon glyphicon-eye-open',
        text: 'Show',
        centerX: 0,
        centerY: 0,
        svgCenterX: 0,
        svgCenterY: 0,
        resetX: 0,
        resetY: 0,
        showSameStation: false,
        showSameStationText: 'Show',
        sameStationIds:[],
        stations: this.props.stations ? this.props.stations: []
    }


    showStation() {
        this.setState((state) => {
            return{
                showSameStation: state.showSameStation ? false : true,
                showSameStationText: state.showSameStation ?
                    'Show' : 'Hide',
                stations:this.props.stations,
                sameStationIds: this.state.stations
            }
        })
    }

    changeOpacity() {
        this.setState((state) => {
            if(state.opacity == 0.2) {
                return {
                    opacity: 0.8,
                    mode: 'glyphicon glyphicon-eye-close',
                    text: 'Hide'
                }
            } else {
                return {
                    opacity: 0.2,
                    mode: 'glyphicon glyphicon-eye-open',
                    text: 'Show'
                }
            }
        })
    }

    zoomIn() {
        const x = document.getElementById('svg').getBoundingClientRect().left;
        const y = document.getElementById('svg').getBoundingClientRect().top;
        const width = document.getElementById('svg').getBoundingClientRect().width;
        const height = document.getElementById('svg').getBoundingClientRect().height;

        const svgCenterX = x + 1 / 2 * width;
        const svgCenterY = y + 1 / 2 * height;

        const increasedScale = 0.25;
        const curtScale = this.state.scale  + increasedScale;

        this.setState(() => {
            return {
                scale: curtScale,
                svgCenterX: svgCenterX,
                svgCenterY: svgCenterY
            }
        })
    }

    zoomOut(){
        const decreasedScale = -0.25;
        const curtScale = this.state.scale  + decreasedScale;

        const x = document.getElementById('svg').getBoundingClientRect().left;
        const y = document.getElementById('svg').getBoundingClientRect().top;
        const width = document.getElementById('svg').getBoundingClientRect().width;
        const height = document.getElementById('svg').getBoundingClientRect().height;

        const svgCenterX = x + 1 / 2 * width;
        const svgCenterY = y + 1 / 2 * height;

        this.setState(() => {
            return {
                scale: curtScale,
                svgCenterX: svgCenterX,
                svgCenterY: svgCenterY
            }
        })
    }

    componentDidMount() {
        //before dragging: the initial state:
        const floorX = document.getElementById('floorMap').getBoundingClientRect().left;
        const floorY = document.getElementById('floorMap').getBoundingClientRect().top;

        const w = document.getElementById('floorMap').getBoundingClientRect().width;
        const h= document.getElementById('floorMap').getBoundingClientRect().height;

        const floorCenterX = floorX  + 1 / 2 * w;
        const floorCenterY = floorY + 1 / 2 * h;

        this.floorCenterX = floorCenterX;
        this.floorCenterY = floorCenterY;

        const x = document.getElementById('svg').getBoundingClientRect().left;
        const y = document.getElementById('svg').getBoundingClientRect().top;

        const width = document.getElementById('svg').getBoundingClientRect().width;
        const height = document.getElementById('svg').getBoundingClientRect().height;

        const svgCenterX = x + 1 / 2 * width;
        const svgCenterY = y + 1 / 2 * height;

        this.svgCenterX = svgCenterX;
        this.svgCenterY = svgCenterY;

        const containerX = document.getElementById('container').getBoundingClientRect().left;
        const containerY = document.getElementById('container').getBoundingClientRect().top;


        const centerX = svgCenterX - containerX ;
        const centerY = svgCenterY - containerY;

        this.centerX = centerX;
        this.centerY = centerY;


        this.setState(() => {
            return {
                centerX: centerX,
                centerY: centerY,
                svgCenterX: svgCenterX,
                svgCenterY: svgCenterY
            }
        })

        $(this.refs.map).draggable({
            stop: function () {
            }
        })
    }
    reset() {
        this.setState((state) => {
            return {
                scale: 1
            }
        })
    }
    showThisStation(stationId) {
        this.setState((state) => {
            return {
                isChecked: event.target.checked,
                sameStationIds: event.target.checked ? state.sameStationIds.concat(stationId)
                    : state.sameStationIds.filter(item => item !== stationId)
            }
        })
    }
    render() {
        const imageContent = (this.props.floor && this.props.floor.image) ?
                                atob(this.props.floor.image.content) : null,
            id = 'floorMap',
            width = this.props.width ? this.props.width : 800,
            height = this.props.height ? this.props.height : 500,
            zoomOut = 'glyphicon glyphicon-zoom-out',
            zoomIn = 'glyphicon glyphicon-zoom-in',
            container = 'container',
            reset = 'glyphicon glyphicon-repeat',
            iconStations = ['glyphicon glyphicon-asterisk', 'glyphicon glyphicon-star','glyphicon glyphicon-heart'];
        return (
            <div>
                <div className="btn-toolbar" role="toolbar" style={{ marginTop: 5, marginBottom: 5 }} >
                    <div className="btn-group mr-2" role="group">
                        {/*zoom in*/}
                        <button type="button" className="btn btn-outline-primary btn-sm floor-button"
                                onClick={() => this.zoomIn()}>
                            <span className={zoomIn}></span>
                        </button>

                        {/*zoom out*/}
                        <button type="button" title={this.state.text}
                                className="btn btn-outline-primary btn-sm floor-button"
                                onClick={() => this.zoomOut()}>
                            <span className={zoomOut}></span>
                        </button>

                        <button type="button" className="btn btn-outline-primary btn-sm floor-button"
                                onClick={() => this.reset()}>
                            <span className={reset}></span>
                        </button>
                    </div>

                    {this.props.stations && this.props.stations.length > 0 &&
                    <button type="button" className="btn btn-outline-primary btn-sm pull-right floor-button"
                            onClick={() => this.showStation()}>
                        {this.state.showSameStationText} Station
                    </button>}

                    {this.props.show &&
                    <button type="button" className="btn btn-outline-primary btn-sm pull-right floor-button"
                            onClick={() => this.changeOpacity()} >
                        {this.state.text} Rockbox
                    </button>
                    }

                    <div style={{textAlign: 'center'}}>
                        {this.state.showSameStation && this.state.stations.length > 0 &&
                        this.state.stations.map((station, index) => {
                            return (
                                <Form.Check  key={index}
                                          onChange={() => this.showThisStation(this.props.stationIds[index])}
                                          style={{display: 'inline', marginRight: 16, marginLeft: 16}}
                                >
                                    <span className={iconStations[index]}>{station}</span>
                                </Form.Check >
                            )
                        })}
                    </div>

                </div>
                {/*{console.log(this.props.stationIds)}*/}
                {/*display the map*/}
                <div id={container} style={{ overflow: 'hidden', border: '.5px solid rgb(192,192,192, .2)',
                    margin: '0 10 10 10'}}>
                    <div id={id} ref="map" style={{ cursor: 'move'}}>
                        <svg id='svg' width={width} height={height}
                             style= {{transform: `scale(${this.state.scale}, ${this.state.scale})`,
                             transformOrigin: `(${this.state.svgCenterX}px, ${this.state.svgCenterY}px)`
                             }}>
                            <image xlinkHref={imageContent} width={width} height={height} />
                        </svg>
                        {this.props.sensors.map((sensor, index) => {
                            return <Sensor key={'sensor_' + index} {...sensor}
                                           iconStations={ iconStations }
                                           stations={this.state.stations}
                                           stationIds={this.props.stationIds}
                                           scale={this.state.scale}
                                           centerX={this.state.centerX}
                                           centerY={this.state.centerY}
                                           showStation={this.state.showSameStation}
                                           stationId={this.state.stationId}
                                           sameStationIds={this.state.sameStationIds}
                                           query={this.props.query}

                            />
                        })
                        }

                        {this.props.relays.map((relay, index) => {
                            return <Relay key={'relay_' + index} {...relay}
                                          opacity={this.state.opacity}
                                          scale={this.state.scale}
                                          centerX={this.state.centerX}
                                          centerY={this.state.centerY}
                                          query={this.props.query}
                            />
                        })
                        }

                    </div>
                </div>
            </div>
        )
    }
}
export default FloorMap

