import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import ApiSingleton from "../../utils/Axios";

class FormGroupEnterprise extends React.Component {
  constructor(props) {
    super(props);
    this._getEnterpriseOptions = this._getEnterpriseOptions.bind(this);
    this.state = {
      enterpriseOptions: [],
      selectedOption: null,
      enterpriseId: props.enterpriseId ? props.enterpriseId : null,
    };
  }

  componentWillMount() {
    this._getEnterpriseOptions();
  }
  _getEnterpriseOptions() {
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/enterprise/find",
      { dataType: "json" },
      {}
    )
      .then((data) => {
        const options = data.map((item, index) => {
          if (item.id === enterpriseId) {
            setSelectedOption({
              value: item.id,
              label: item.name,
            });
          }
          return {
            value: item.id,
            label: item.name,
          };
        });
        setEnterpriseOptions(options);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  _onChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption,
    });
    this.props.onChange("enterprise", null, null, selectedOption, true);
  };
  render() {
    return (
      <div>
        <Form.Group as={Row} className="mb-3" controlId={"enterpriseOptions"}>
          <Col sm={3}>
            <ControlLabel>Enterprise</ControlLabel>
          </Col>
          <Col sm={7}>
            <Select
              options={this.state.enterpriseOptions}
              value={this.state.selectedOption}
              onChange={this._onChange}
            />
          </Col>
        </Form.Group>
      </div>
    );
  }
}
export default FormGroupEnterprise;
