import React, { Component, createContext } from "react"

const ToastContext = createContext()

class ToastProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
          toast: { message: "", visible: false },
          showToast: this.showToast,
        }
    }
    showToast = (message, duration = 3000) => {
        this.setState({ toast: { message, visible: true } })
        setTimeout(() => {
          this.setState({ toast: { message: "", visible: false } })
        }, duration)
    }
    render() {
        return (
          <ToastContext.Provider value={this.state}>
            {this.props.children}
          </ToastContext.Provider>
        )
    }
}

export { ToastContext, ToastProvider }
