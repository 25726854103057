import React from 'react'
import { Form }from "react-bootstrap"

class FormGroupPolicyAlert extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props
        return (
            <div className="panel panel-default" style={{textAlign: 'center'}}>
                <div className="panel-heading">
                    <Form.Label style={{ fontWeight: "bold" }}>{props.controlId}</Form.Label>
                </div>
            </div>
        )
    }
}
export default FormGroupPolicyAlert

