/**
 * TODOs: 
 * Add a sticky table header
 * maybe filter by how much data is there??? (conditions: red if low, yellow if eh or no color, green if good)
 * add search bar
 * figure out how to make it date dynamic
 * 
 * ASK ALINA:: 
 * possible to add % total data collected for patient (need for conditional formatting)
 * 
 * 
 */

import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import ApiSingleton from '../../utils/Axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TextField from "@mui/material/TextField";




export function AllPatientsTable() {
    //react-date-picker addition
    const [dateValue, setDate] = useState();
    const [startDate, setStartDate] = useState(""); //TODO: figure out how to do this (issue: separate files with dates)
    const [endDate, setEndDate] = useState("");

    const [userList, setUserList] = useState([]);
    const [rows, setRows] = useState([]);
    const [searched, setSearched] = useState("");

    function fetchDateData(date) {
        const postData = {
            seniorName: date,
            key: "dataConsistency_summary",
        }
        let tempData = null;
        ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
        )
        .then((data) => {
            tempData = JSON.parse(base64_decode(data[0].document));
            console.log(tempData);
        })
        .then((data) => {
            setDate(new Date(tempData.date.start));

            // set up the table information
            const filled_rows = constructRows(tempData.summary);
            setRows(filled_rows);

            // saved for reset on search
            setUserList(filled_rows);

        })
    }

    useEffect(() => {
        //TODO: Make dynamic selection by date
        fetchDateData("all_patient_STOP_2023-06-07")

    }, []);

    function createData(
        id,  //: string,
        count_hr,    //:    Number,
        percent_hr,  //: Number, 
        count_hrv,   //: Number,
        percent_hrv, //: Number,
        count_rhr,   //: Number,
        percent_rhr, //: Number,
        count_bos,   //: Number,
        percent_bos, //: Number,
        count_more1g,    //: Number,
        percent_more1g  //: Number,
    ) {
        return { id, count_hr, percent_hr, count_hrv, percent_hrv, count_rhr, percent_rhr, count_bos, percent_bos, count_more1g, percent_more1g };
    }

    function constructRows(data) {
        // ensure data not null
        if (data[0] == null) return;

        const rows = [];
        const id_list = [];
        let data_len = data.length;
        let idx = 0;
        let row_idx = 0;

        while (idx < data_len-1) {
            // ensure no duplicates
            if (!id_list.includes(data[idx].patient_id)) {
                rows.push(createData( data[idx].patient_id, 
                                        data[idx].CountHeartRate, 
                                        data[idx].PercentageHeartRate, 
                                        data[idx].CountheartRateVariability, 
                                        data[idx].PercentheartRateVariability, 
                                        data[idx].CountrestingHeartRate, 
                                        data[idx].PercentrestingHeartRate, 
                                        data[idx].CountbloodOxygenSaturation, 
                                        data[idx].PercentbloodOxygenSaturation, 
                                        data[idx].Countmore1G, 
                                        data[idx].Percentagemore1G ));

                id_list.push(data[idx].patient_id);
                row_idx++;
            }

            idx++;
        }

        return rows;
    }

    // handle search
    function handleSearch(event) {
        const filter_rows = [];
        // resets search
        if (event.target.value == "") {
            filter_rows = [...userList];
        }
        else {
            // go through user list and look for matches
            
            let idx = 0;
            let row_len = userList.length;
            while (idx < row_len) {
                // found searched string in id
                if (userList[idx].id.indexOf(event.target.value) > -1) {
                    // add row to final row list
                    filter_rows.push(userList[idx]);
                }
                idx++;
            }
        }
        setRows(filter_rows);
    }


    function handleDateChange(event) {
        setDate(event);
    }
  
    // navigation to statistical analysis page
    const navigate = useNavigate();
    function handleGoUser(e) {
        // e.preventDefault();
        navigate('/dataConsistency'); //, { state: { id: e}});
    }

    return (
        <Paper>
            <Box align="right">
                <DatePicker showIcon={true} onChange={handleDateChange} selected={dateValue} />
            </Box>
            <Box align="left">
                <TextField
                    id="outlined-basic"
                    onChange={handleSearch}
                    variant="outlined"
                    label="Search"
                />
            </Box>
            <TableContainer component={Paper}>
                <Table stickyHeader={true} aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    <TableCell>User ID</TableCell>
                    <TableCell align="right">Count Heart Rate</TableCell>
                    <TableCell align="right">% Heart Rate</TableCell>
                    <TableCell align="right">Count HRV</TableCell>
                    <TableCell align="right">% HRV</TableCell>
                    <TableCell align="right">Count Resting HR</TableCell>
                    <TableCell align="right">% Resting HR</TableCell>
                    <TableCell align="right">Count BOS</TableCell>
                    <TableCell align="right">% BOS</TableCell>
                    <TableCell align="right">Count More 1G</TableCell>
                    <TableCell align="right">% More 1G</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } ,
                              backgroundColor: "white",
                              ...(row.percent_hr < 70 && {
                                backgroundColor: "#f9bfbf",
                              })}}
                        onClick={handleGoUser}
                    >
                        <TableCell component="th" scope="row" >{row.id}</TableCell>
                        <TableCell align="right">{row.count_hr}</TableCell>
                        <TableCell align="right">{row.percent_hr}</TableCell>
                        <TableCell align="right">{row.count_hrv}</TableCell>
                        <TableCell align="right">{row.percent_hrv}</TableCell>
                        <TableCell align="right">{row.count_rhr}</TableCell>
                        <TableCell align="right">{row.percent_rhr}</TableCell>
                        <TableCell align="right">{row.count_bos}</TableCell>
                        <TableCell align="right">{row.percent_bos}</TableCell>
                        <TableCell align="right">{row.count_more1g}</TableCell>
                        <TableCell align="right">{row.percent_more1g}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
  }
  
  export default AllPatientsTable;