import axios from "axios"

class ApiSingleton {
  static instance
  static getInstance() {
    if (!ApiSingleton.instance) {
      ApiSingleton.instance = axios.create({
        crossdomain: true,
        withCredentials: true,
        baseURL: ApiSingleton.getApiUrl(),
        headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      })
    }
    return ApiSingleton.instance
  }

  static getApiUrl() {
    if (
      window.location.hostname === "www.aiportal.co" ||
      window.location.hostname === "aiportal.co" ||
      window.location.hostname === "localhost"
    ) {
      return `https://api.aiportal.co`
    } else {
      return `https://${window.location.hostname}`
    }
  }

  static makeHttpRequest(method, url, data = null, params = null) {
    const ai = ApiSingleton.getInstance()
    const config = {
      url,
      data,
      method,
      params,
    }
    return ai.request(config)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (error.response.status === 401) {
          console.log('Session timeout, go to login page')
          console.log('goto login')
        }
      })
  }
}

export default ApiSingleton
