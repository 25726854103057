import React from "react"
import { Box, Typography } from "@mui/material";


const StatBox = ({ title, subtitle, icon }) => {
    

    return (
        <Box width="100%" m="0 30px">
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Typography variant="h4" fontWeight="bold" sx={{ color: "#797979" }}>
                        {title}
                    </Typography>
                </Box>
                <Box>
                    {icon}
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h5" sx={{ color: "#530C0C" }}>
                        {subtitle}
                </Typography>
            </Box>

        </Box>
    );
};

export default StatBox;