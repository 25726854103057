/* global $ */
import React from 'react'
import BaseTable from '../../components/BaseTable'
import ApiSingleton from '../../utils/Axios';

class LeavingWhiteList extends React.Component {
    constructor(props) {
        super(props);
        this.onRefresh = this.onRefresh.bind(this)
        this.state = {
            relays: props.data ? props.data: null,
            enterpriseId: props.enterpriseId ? props.enterpriseId : null
        }
    }
    handleEnterpriseChange(enterpriseId) {
        this.setState({
            enterpriseId: enterpriseId
        })
    }

    _fetchRelayById(id) {
        let relay = {}
        ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/relay/findById",
            { dataType: "json" },
            {id: id}
          )
            .then((data) => {
                relay.name = data.name
                relay.roomName = data.roomName
            })
            .catch((err) => {});
        return relay
    }
    _fetchGroupPolicyById(id) {
        ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/groupPolicy/findById",
            { dataType: "json" },
            { id }
          )
            .then((data) => {
                if(this.props.controlId === 'approachingWhiteList') {
                    this.setState({
                        relays: data.approachingWhiteList
                    })
                } else {
                    this.setState({
                        relays: data.leavingWhiteList
                    })
                }
            })
            .catch((err) => {
                const { showToast } = this.context
                showToast('Failed to fetch list of group policies'+ '! Reason: ' + err)
            });
    }

    onRefresh() {
        this._fetchGroupPolicyById(this.props.groupPolicyId)
    }

    render() {
        const props = this.props;

        const columnProps = [{
            config: {
                dataField: 'relayId',
                isKey: true,
                hidden: true
            },
            label: 'id'
        },
            {
                config: {
                    dataField: 'name',
                    dataSort: false,
                },
                label: 'Name'
            }, {
                config: {
                    dataField: 'roomName',
                    dataSort: false,
                },
                label: 'Room'
            }]
        const addModalFields = [
            {
                controlId: 'relayId',
                label: 'Relay Name',
                enterpriseId: this.state.enterpriseId,
                type: 'select',
                urlEntity: 'relay',
                labelKey: 'name',
                valueKey: 'id'
            },
        ]

        const addConfig = {
            title: 'Add Relay to Group Policy',
            submitText: 'Add',
            cancelText: 'Cancel',
            fields: addModalFields,
            beforeSubmit: (data) => {
                data.id = props.groupPolicyId
                data.updateUrl='groupPolicyRelay'
                data.type = props.controlId
                const relays = []
                relays.push(data.relayId)
                const relay = this._fetchRelayById(data.relayId)
                data.relays = relays
                this.setState({
                    relays: this.state.relays.concat(relay)
                })
                return data
            }
        }
        const deleteConfig = {
            title: 'Delete Relay',
            body: 'Are you sure you want to delete the selected Relay?',
            name: 'groupPolicy',
        }

        const updateConfig = {
            title: 'Edit the Relay',
            submitText: 'Edit',
            cancelText: 'Cancel',
            fields: addModalFields,
            beforeSubmit: (data) => {
                data.type = props.controlId
                data.id = data.id
                data.updateUrl = 'updateRelay'
                const relays = []
                relays.push(data.relayId)
                data.relays = relays
                return data
            }
        }
        const detailModalFields = [
            {
                controlId: 'name',
                label: 'Relay Name',
                type: 'text'
            },
            {
                controlId: 'roomName',
                label: 'Room Name',
                type: 'text'
            },
        ]
        const detailConfig = {
            cancelText: 'Close',
            fields: detailModalFields
        }
        return (
            <BaseTable autoLoad={false}
                       striped={ true }
                       height={props.height}
                       pagination={ false }
                       hideSelectColumn={ false }
                       sortChange={this.handleSortChange}
                       deleteConfig={deleteConfig}
                       detailConfig={detailConfig}
                       updateConfig={updateConfig}
                       addConfig={addConfig}
                       hideFilterSearchBar={ true }
                       columnProps={columnProps}
                       onHandleEnterpriseChange = {this.handleEnterpriseChange}
                       { ...props }
                       sortedData={this.state.relays}
                       refresh={this.onRefresh}
                       urlRelayChange={'relayChange'}
                       groupPolicyId={this.props.groupPolicyId}/>
        )
    }
}
export default LeavingWhiteList

