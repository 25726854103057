import React from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import BaseFormModal from '../BaseFormModal'
import ConfirmDelete from './ConfirmDelete'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Form  } from 'react-bootstrap'

const defaultActions = [{
  name: 'refresh'
}, {
  name: 'add'
}, {
  name: 'update'
}, {
  name: 'delete'
}, {
  name: 'detail'
}]

const BaseTableToolBar = (props) => {
  let actions = props.actions
  if (! props.actions) {
    actions = defaultActions // take the default if no customization
  } else if (props.selectAction) {
    actions = _.concat(props.actions, props.selectAction)
  }
//  console.log('BaseTableToolBar actions = ', actions)
  return (
    <div className='table-toolbar'>
      <BaseTableBtnGroup {...props} />

    { actions.map((action) => {
        if (action.name === 'add') {
          return (
            <BaseFormModal key={action.name}
              isAdd={true}
              {...props.addConfig}
            />
          )
        } else if (action.name === 'update') {
          return (
            <BaseFormModal key={action.name}
              isAdd={false}
              {...props.updateConfig}
            />
          )
        } else if (action.name === 'detail') {
            return (
              <BaseFormModal key={action.name}
                isAdd={false}
                hideCancelButton={true}
                {...props.detailConfig}
              />
            )
        } else if (action.name === 'delete') {
          return (
            <ConfirmDelete key={action.name}
              {...props.deleteConfig}
            />
          )
        } else {
          return null
        }
      })
    }
    </div>
  )
}

const BaseTableBtnGroup = (props) => {
  let actions = props.actions
  if (!actions) {
    actions = defaultActions // take the default if no customization
  } else if (actions.length === 0) { // no actions buttons
    return null
  }
  return (
    <Row><Col md={6}>
    <ButtonGroup>
    {  actions.map((action) => {
        let iconClass = 'fa fa-refresh'
        let actionHandler = props.onRefresh
        let btnDisabled = false
        if (action.name === 'refresh') {
          // https://fontawesome.com/v4.7.0/icons/
          iconClass = 'fa fa-refresh'
          actionHandler = props.onRefresh
        } else if (action.name === 'add') {
          iconClass = 'fa fa-plus'
          actionHandler = props.onAdd
        } else if (action.name === 'update') {
          iconClass = 'fa fa-pen'
          actionHandler = props.onUpdate
          btnDisabled = !props.selectedRow
        } else if (action.name === 'detail') {
            iconClass = 'fa fa-expand'
            actionHandler = props.onDetail
            btnDisabled = !props.selectedRow
        } else if (action.name === 'delete') {
          iconClass = 'fa fa-remove'
          actionHandler = props.onDelete
          btnDisabled = !props.selectedRow
        } else {
          console.error('Unsupported action name ' + action.name + ' found!')
        }
        return (
          <Button key={action.name} disabled={btnDisabled} onClick={actionHandler}>
            <span className={iconClass} aria-hidden="true"></span>
          </Button>
        )
      })
    }
    </ButtonGroup>
    </Col>
    <Col md={3}>
        <Form.Check  checked={props.showEnabledOnly} onChange={props.onClickShowEnabledOnly}>
	    Show enabled items only
	    </Form.Check >
	</Col>
        {(props.hideFilterSearchBar === false) && <Col>
            <form role="search" autoComplete="off" onSubmit={ (e) => e.preventDefault()} >
                <div className="input-group">
                    {/*implement the function of the search bar*/}
                    <input type="text" placeholder="Search for..." className="form-control pull-right"
                           name="search" onChange={props.onSearchChange} value={ props.query } />
                    <span className="input-group-addon">
                        <span className="glyphicon glyphicon-remove" onClick={ props.handleClear }></span>
                    </span>
                </div>
            </form>
        </Col>}


    </Row>
  )
}

export default BaseTableToolBar
