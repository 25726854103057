import React, { useState, useEffect } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { FaHeartbeat } from 'react-icons/fa'
import { TbHeartbeat } from 'react-icons/tb'
import { SiOxygen } from 'react-icons/si' 
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import Annotation from 'chartjs-plugin-annotation';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import ApiSingleton from '../../utils/Axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material"
import StatBox from "../../components/StatBox/StatBox"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Annotation,
);

const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];

export const lineDateOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1 | 2,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        usePointStyle: true,
      },
    },
    // annotation: {
    //   annotations: {
    //     min: {
    //       type: 'line',
    //       borderColor: 'gray',
    //       borderWidth: 2,
    //       scaleID: 'y',
    //       value: 90,
    //       label: {
    //         display: true,
    //         content: '90',
    //         backgroundColor: 'transparent',
    //         color: 'black',
    //         position: 'end',
    //         xAdjust: 10,
    //         yAdjust: 10,
    //       },
    //     },
    //     max: {
    //       type: 'line',
    //       borderColor: 'gray',
    //       borderWidth: 2,
    //       scaleID: 'y',
    //       value: 100,
    //       label: {
    //         display: true,
    //         content: '100',
    //         backgroundColor: 'transparent',
    //         color: 'black',
    //         position: 'end',
    //         xAdjust: 10,
    //         yAdjust: 0,
    //       },
    //     },
    //   },
    // },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Hours of the Day',
      },
      ticks: {
        autoSkip: false,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Blood Oxygen Saturation',
      },
      position: 'left',
      offset: true,
    },
  },
};

export const lineOutlierOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1 | 2,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        usePointStyle: true,
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Date',
      },
      offset: true,
    },
    y: {
      title: {
        display: true,
        text: 'Outlier Count',
      },
      position: 'left',
      // max: 5,
    },
  },
};

export const lineMonthOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1 | 2,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        usePointStyle: true,
      },
    },
    // annotation: {
    //   annotations: {
    //     min: {
    //       type: 'line',
    //       borderColor: 'gray',
    //       borderWidth: 2,
    //       scaleID: 'y',
    //       value: 90,
    //       label: {
    //         display: true,
    //         content: '90',
    //         backgroundColor: 'transparent',
    //         color: 'black',
    //         position: 'end',
    //         xAdjust: 10,
    //         yAdjust: 10,
    //       },
    //     },
    //     max: {
    //       type: 'line',
    //       borderColor: 'gray',
    //       borderWidth: 2,
    //       scaleID: 'y',
    //       value: 100,
    //       label: {
    //         display: true,
    //         content: '100',
    //         backgroundColor: 'transparent',
    //         color: 'black',
    //         position: 'end',
    //         xAdjust: 10,
    //         yAdjust: 0,
    //       },
    //     },
    //   },
    // },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Date',
      },
      offset: true,
    },
    y: {
      title: {
        display: true,
        text: 'Blood Oxygen Saturation',
      },
      position: 'left',
      offset: true,
    },
  },
};

export function RHRStats() {
  const {state} = useLocation();
  const [seniorId, setSeniorId] = useState(state.id);
  const [personalBench, setPersonalBench] = useState({});

  //react-date-picker addition
  const [dateValue, setDate] = useState(new Date("2023-3-30"));
  const [endDateValue, setEndDateValue] = useState(new Date("2023-6-8"));
  const [startDate, setStartDate] = useState(""); //2-18-2023
  const [endDate, setEndDate] = useState(""); //6-8-2023

  // stores all user data
  const [dailyData, setDailyData] = useState({datasets: [],});
  const [monthlyData, setMonthlyData] = useState({datasets: [],});

  // stores current display options
  const [rhrDateOptions, setRHRDateOptions] = useState(lineDateOptions);
  const [rhrOutlierOptions, setRHROutlierOptions] = useState(lineOutlierOptions);
  const [rhrMonthOptions, setRHRMonthOptions] = useState(lineMonthOptions);

  // stores current display data
  const [rhrDateData, setRHRDateData] = useState({datasets: [],});
  const [rhrOutlierData, setRHROutlierData] = useState({datasets: [],});
  const [rhrMonthData, setRHRMonthData] = useState({datasets: [],});

  const [rhrDateMax, setRHRDateMax] = useState("N/A");
  const [rhrDateMin, setRHRDateMin] = useState("N/A");
  const [rhrDateMed, setRHRDateMed] = useState("N/A");

  const [rhrLTMax, setRHRLTMax] = useState("N/A");
  const [rhrLTMin, setRHRLTMin] = useState("N/A");
  const [rhrLTMed, setRHRLTMed] = useState("N/A");

  const [rhrMaxLine, setRHRMaxLine] = useState([]);
  const [rhrMinLine, setRHRMinLine] = useState([]);
  const [rhrMedLine, setRHRMedLine] = useState([]);

  function _fetchRHR(seniorId) {
    console.log("entered fetch RHR data");
    const postData = {
        seniorName: seniorId,
        key: "restingHeartRate",
    }
    let tempData = null;
    if (seniorId) {
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
        { dataType: "json" },
        postData
      )
        .then((data) => {
          console.log("getting rhr data from API");
            tempData = JSON.parse(base64_decode(data[0].document));
            console.log("fetched rhr data: ", tempData);
        })
        .then(data => {
          console.log("setting RHR data");
          setDailyData(tempData.daily);
          setMonthlyData(tempData.longterm);

          let currentDate = dateValue
          const dateData = getDateData(currentDate, tempData.daily);
          const monthData = getMonthData(endDateValue, tempData.longterm);
          setDate(currentDate);

          const rhrDate = getRHRDateData(dateData);
          const rhrOutlier = getRHROutlierData(monthData);
          const rhrMonthMax = getRHRMonthMax(monthData);
          const rhrMonthMin = getRHRMonthMin(monthData);
          const rhrMonthMed = getRHRMonthMed(monthData);
          const stats = getRHRDateMaxMin(currentDate, monthData);
          const ltStats = getRHRLTMaxMin(monthData);

          setRHRMaxLine(rhrMonthMax);
          setRHRMinLine(rhrMonthMin);
          setRHRMedLine(rhrMonthMed);

          if (stats) {
            setRHRDateMax(stats[0]); // stats elements in order: [max, min, med]
            setRHRDateMin(stats[1]);
            setRHRDateMed(stats[2]);
          }

          if (ltStats) {
            setRHRLTMax(ltStats[0]); // stats elements in order: [max, min, med]
            setRHRLTMin(ltStats[1]);
            setRHRLTMed(ltStats[2]);
          }

          setRHRDateOptions({
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1 | 2,
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  usePointStyle: true,
                },
              },
              annotation: {
                annotations: {
                  min: {
                    type: 'line',
                    borderColor: 'gray',
                    borderWidth: 2,
                    scaleID: 'y',
                    value: tempData.benchmark_personalize.low,
                    label: {
                      display: true,
                      content: tempData.benchmark_personalize.low.toString(),
                      backgroundColor: 'transparent',
                      color: 'black',
                      position: 'end',
                      xAdjust: 10,
                      yAdjust: 10,
                    },
                  },
                  max: {
                    type: 'line',
                    borderColor: 'gray',
                    borderWidth: 2,
                    scaleID: 'y',
                    value: tempData.benchmark_personalize.high,
                    label: {
                      display: true,
                      content: tempData.benchmark_personalize.high.toString(),
                      backgroundColor: 'transparent',
                      color: 'black',
                      position: 'end',
                      xAdjust: 10,
                      yAdjust: 0,
                    },
                  },
                },
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Hours of the Day',
                },
                ticks: {
                  autoSkip: false,
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Blood Oxygen Saturation',
                },
                position: 'left',
                offset: true,
              },
            },
          })

          setRHRMonthOptions({
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1 | 2,
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  usePointStyle: true,
                },
              },
              annotation: {
                annotations: {
                  min: {
                    type: 'line',
                    borderColor: 'gray',
                    borderWidth: 2,
                    scaleID: 'y',
                    value: tempData.benchmark_personalize.low,
                    label: {
                      display: true,
                      content: tempData.benchmark_personalize.low.toString(),
                      backgroundColor: 'transparent',
                      color: 'black',
                      position: 'end',
                      xAdjust: 10,
                      yAdjust: 10,
                    },
                  },
                  max: {
                    type: 'line',
                    borderColor: 'gray',
                    borderWidth: 2,
                    scaleID: 'y',
                    value: tempData.benchmark_personalize.high,
                    label: {
                      display: true,
                      content: tempData.benchmark_personalize.high.toString(),
                      backgroundColor: 'transparent',
                      color: 'black',
                      position: 'end',
                      xAdjust: 10,
                      yAdjust: 0,
                    },
                  },
                },
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Date',
                },
                offset: true,
              },
              y: {
                title: {
                  display: true,
                  text: 'Resting Heart Rate',
                },
                position: 'left',
                offset: true,
              },
            },
          });

          setRHRDateData({
            labels: [],
            datasets: [
              {
                label: 'Personalized Threshold',
                data: [],
                pointStyle: 'line',
                backgroundColor: 'black',
                borderColor: 'black',
              },  
              {
                  label: 'Resting HR',
                  data: rhrDate,
                  pointStyle: 'circle',
                  backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                  borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                  spanGaps: true,
                  yAxisID: 'y',
              },  
            ],
          });

          setRHROutlierData({
            labels: [],
            datasets: [
                {
                    label: 'Outlier Count',
                    data: rhrOutlier,
                    pointStyle: 'circle',
                    backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                    borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                    spanGaps: true,
                    yAxisID: 'y',
                },
            ],
          });

          setRHRMonthData({
            labels: [],
            datasets: [
              {
                label: 'Personalized Threshold',
                data: [],
                pointStyle: 'line',
                backgroundColor: 'black',
                borderColor: 'black',
              }, 
              {
                  label: 'Daily Max',
                  data: rhrMonthMax,
                  pointStyle: 'rect',
                  backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                  borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                  spanGaps: true,
                  yAxisID: 'y',
                  pointRadius: 0,
                  fill: '+1',
              },
              {
                  label: 'Daily Min',
                  data: rhrMonthMin,
                  pointStyle: 'rect',
                  backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
                  borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
                  spanGaps: true,
                  yAxisID: 'y',
                  pointRadius: 0,
                  fill: false,
              },
              {
                  label: 'Daily Median',
                  data: rhrMonthMed,
                  pointStyle: 'circle',
                  backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                  borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                  spanGaps: true,
                  yAxisID: 'y',
              },
            ],
          });

          setStartDate(tempData.date.start);
          setEndDate(tempData.date.end);
        })
        .catch((err) => {});
    }

    return tempData;
  }

  useEffect(() => {

    _fetchRHR(seniorId);

    console.log("useEffect ran here");
  }, []);

  // finds the right data that corresponds to the date
  function getDateData (date, data) {
    if (date == null || data[0] == null)
      return null;

    var preDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
    var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

    const dateData = []
    let data_len = data.length;
    let i = 0;
    let n = 0;
    var currDate = new Date(data[0].year, data[0].month - 1, data[0].day); // month - 1 because month is 0 - 11 not 1 - 12

    while (currDate < afterDate) {
      if (i < data_len) {
        currDate = new Date(data[i].year, data[i].month - 1, data[i].day);
        i++;
      }
      else {
        console.log("ERROR -- exceeded max date in RHR getDateData ");
        break;
      }

      // at the correct date
      if (currDate > preDate && currDate < afterDate) {
        dateData[n] = data[i];
        n++;
      }
      else { continue; }
    }

    // PROBLEM: getting one more data point than intended at the end and missing one at beginning
    // console.log("date data: ", dateData);
    return dateData;
  }

  // finds the month that current date is in
  // currently fixed (need to change later)
  // return the data from start to end date value (15 days, 5 weeks, 10 weeks, 15 weeks)
  function getMonthData (date, data) {
    // if (data == null) {
    //   console.log("data is null");
    //   return data;
    // }

    if (date == null || data[0] == null)
      return null;

    var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

    const longtermData = []
    let data_len = data.length;
    let i = 0;
    let n = 0;
    var currDate = new Date(data[0].year, data[0].month - 1, data[0].day); // month - 1 because month is 0 - 11 not 1 - 12

    while (currDate < afterDate) {
      if (i < data_len) {
        currDate = new Date(data[i].year, data[i].month - 1, data[i].day);
        i++;
      }
      else {
        console.log("ERROR -- exceeded max date in RHR getDateData ");
        break;
      }

      // at the correct date
      if (currDate < afterDate) {
        if (data[i] != null) {
          longtermData[n] = data[i];
          n++;
        }
      }
      else { continue; }
    }

    // PROBLEM: getting one more data point than intended at the end and missing one at beginning
    // console.log("longterm data: ", longtermData);
    return longtermData;
  }

  function getRHRDateData (date_data) {
    if (date_data == null) {
      console.log("data is null");
      return date_data;
    }
    const dateData = []; // each element is a pair of {"hour", "blood oxy sat"}
    let i = 0;
    let n = 0;
    let prev = -1;
    // console.log("date data: ", date_data);

    if (date_data.length > 0) {
      while (i < 24) {
        // console.log("date data hour: ", date_data[n].hour);
        if (date_data[n].hour != i) {
            if (date_data[n].hour == prev) {
                n++;
                continue;
            }
            else {
                dateData[i] = [i.toString(), null];
            }   
        }
        else {
            dateData[i] = [date_data[n].hour.toString(), date_data[n].restingHeartRate]; //date_data[n].hour.toString(), 
            prev = date_data[n].hour;
            n++;
        }
        i++;
      }
    }
    
    return dateData;
  }

  function getRHROutlierData(month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    const outlierData = []; // each element is formatted {"date", num outliers}
    let length = month_data.length;
    let i = 0;

    while (i < length) {
        outlierData[i] = [month_data[i].year.toString() + '-' + month_data[i].month.toString() + '-' + month_data[i].day.toString(), month_data[i].outlier];
        i++;
    }
    return outlierData;
  }

  function getRHRMonthMax(month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    const max = []; // elements in form [date, max value]

    let i = 0;
    let length = month_data.length;

    while (i < length) {
        max[i] = [month_data[i].year.toString() + '-' + month_data[i].month.toString() + '-' + month_data[i].day.toString(), month_data[i].max];
        i++;
    }

    return max;
  }

  function getRHRMonthMin(month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    const min = []; // in form [date, min value]

    let i = 0;
    let length = month_data.length;

    while (i < length) {
        min[i] = [month_data[i].year.toString() + '-' + month_data[i].month.toString() + '-' + month_data[i].day.toString(), month_data[i].min];
        i++;
    }

    return min;
  }

  function getRHRMonthMed(month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    const med = []; // in form [date, median value]

    let i = 0;
    let length = month_data.length;

    while (i < length) {
        med[i] = [month_data[i].year.toString() + '-' + month_data[i].month.toString() + '-' + month_data[i].day.toString(), month_data[i].median];
        i++;
    }

    return med;
  }

  // sets the selected date's max min median values
  function getRHRDateMaxMin (date, month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    let i = 0;
    let length = month_data.length;
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const stats = ["N/A", "N/A", "N/A"]; // elements are in order [max, min, med]

    while (i < length) {
        if (month_data[i].year == year && month_data[i].month == month && month_data[i].day == day) {
            stats[0] = month_data[i].max;
            stats[1] = month_data[i].min;
            stats[2] = month_data[i].median;
            break;
        }
        else { i++; }
    }

    return stats;
  }

  // TODO: Fix for when implementing multiple long term periods
  // sets the long term's max min median values
  function getRHRLTMaxMin (month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    let i = 0;
    let length = month_data.length;
    const stats = []; // elements are in order [max, min, med]
    const max = [];
    const min = [];
    const med = [];

    while (i < length) {
      max.push(month_data[i].max);
      min.push(month_data[i].min);
      med.push(month_data[i].median);
      i++;
    }

    stats[0] = Math.max.apply(null, max);
    stats[1] = Math.min.apply(null, min);
    const sortMed = med.sort(function(a, b){return a - b});
    stats[2] = sortMed[sortMed.length % 2];

    return stats;
  }

  // handles the trial time from start to 15 days, 5 weeks, 10 weeks, 15 weeks
  function handleTrialChange(type) {
    let endDate = endDateValue;

    if (type == 1) { // 15 days - 3/4/23
      setEndDateValue(new Date("2023-3-5"));
      endDate = new Date("2023-3-5");
    }
    else if (type == 2) { // 5 weeks - 3/24/23
      setEndDateValue(new Date("2023-3-25"));
      endDate = new Date("2023-3-25");
    }
    else if (type == 3) { // 10 weeks - 4/28/23
      setEndDateValue(new Date("2023-4-29"));
      endDate = new Date("2023-4-29");
    }
    else if (type == 4) { // 15 weeks - 6/7/23
      setEndDateValue(new Date("2023-6-8"));
      endDate = new Date("2023-6-8");
    }
    else {return;}

    const monthData = getMonthData(endDate, monthlyData);
    console.log("button 1 pressed: ", monthData);

    const rhrOutlier = getRHROutlierData(monthData);
    const rhrMonthMax = getRHRMonthMax(monthData);
    const rhrMonthMin = getRHRMonthMin(monthData);
    const rhrMonthMed = getRHRMonthMed(monthData);
    const ltStats = getRHRLTMaxMin(monthData);

    setRHRMaxLine(rhrMonthMax);
    setRHRMinLine(rhrMonthMin);
    setRHRMedLine(rhrMonthMed);

    if (ltStats) {
      setRHRLTMax(ltStats[0]); // stats elements in order: [max, min, med]
      setRHRLTMin(ltStats[1]);
      setRHRLTMed(ltStats[2]);
    }

    setRHROutlierData({
      labels: [],
      datasets: [
          {
              label: 'Outlier Count',
              data: rhrOutlier,
              pointStyle: 'circle',
              backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
              borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
              spanGaps: true,
              yAxisID: 'y',
          },
      ],
    });

    setRHRMonthData({
      labels: [],
      datasets: [
        {
          label: 'Personalized Threshold',
          data: [],
          pointStyle: 'line',
          backgroundColor: 'black',
          borderColor: 'black',
        }, 
        {
            label: 'Daily Max',
            data: rhrMonthMax,
            pointStyle: 'rect',
            backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
            borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
            spanGaps: true,
            yAxisID: 'y',
            pointRadius: 0,
            fill: '+1',
        },
        {
            label: 'Daily Min',
            data: rhrMonthMin,
            pointStyle: 'rect',
            backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
            borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
            spanGaps: true,
            yAxisID: 'y',
            pointRadius: 0,
            fill: false,
        },
        {
            label: 'Daily Median',
            data: rhrMonthMed,
            pointStyle: 'circle',
            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
            spanGaps: true,
            yAxisID: 'y',
        },
      ],
    });
  }

  // handles date change event
  function handleDateChange(event) {
    setDate(event);
    console.log("event time is: ", event);
    
    // load database data
    const d = getDateData(event, dailyData);
    const m = getMonthData(endDateValue, monthlyData);
    const lt = monthlyData;

    const rhrDate = getRHRDateData(d);
    const rhrOutlier = getRHROutlierData(m);
    const rhrMonthMax = getRHRMonthMax(m);
    const rhrMonthMin = getRHRMonthMin(m);
    const rhrMonthMed = getRHRMonthMed(m);
    const stats = getRHRDateMaxMin(event, lt);
    const ltStats = getRHRLTMaxMin(m);

    setRHRMaxLine(rhrMonthMax);
    setRHRMinLine(rhrMonthMin);
    setRHRMedLine(rhrMonthMed);

    if (stats) {
      setRHRDateMax(stats[0]); // stats elements in order: [max, min, med]
      setRHRDateMin(stats[1]);
      setRHRDateMed(stats[2]);
    }

    if (ltStats) {
      setRHRLTMax(ltStats[0]); // stats elements in order: [max, min, med]
      setRHRLTMin(ltStats[1]);
      setRHRLTMed(ltStats[2]);
    }

    setRHRDateData({
        labels: [],
        datasets: [
          {
            label: 'Personalized Threshold',
            data: [],
            pointStyle: 'line',
            backgroundColor: 'grey',
            borderColor: 'grey',
          }, 
          {
              label: 'Resting HR',
              data: rhrDate,
              pointStyle: 'circle',
              backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
              borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
              spanGaps: true,
              yAxisID: 'y',

          },
        ],
    });

    setRHROutlierData({
        labels: [],
        datasets: [
            {
                label: 'Outlier Count',
                data: rhrOutlier,
                pointStyle: 'circle',
                backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                spanGaps: true,
                yAxisID: 'y',
            },
        ],
    });

    setRHRMonthData({
        labels: [],
        datasets: [
          {
            label: 'Personalized Threshold',
            data: [],
            pointStyle: 'line',
            backgroundColor: 'grey',
            borderColor: 'grey',
          }, 
          {
            label: 'Daily Max',
            data: rhrMaxLine,
            pointStyle: 'rect',
            backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
            borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
            spanGaps: true,
            yAxisID: 'y',
            pointRadius: 0,
            fill: '+1',
          },
          {
            label: 'Daily Min',
            data: rhrMinLine,
            pointStyle: 'rect',
            backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
            borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
            spanGaps: true,
            yAxisID: 'y',
            pointRadius: 0,
            fill: false,
          },
          {
            label: 'Daily Median',
            data: rhrMedLine,
            pointStyle: 'circle',
            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
            spanGaps: true,
            yAxisID: 'y',
          },
        ],
    });

  }

  return (
    <Box m="20px"> 
        <Box display="flex" justifycontext="space-between" alignItems="center">
            <Box width="100%">
                <Typography variant="h3" fontWeight="bold" sx={{ color: "#530C0C" }}>Resting Heart Rate</Typography>
            </Box>
            <Box>
              <button onClick={() => {handleTrialChange(1)}}>15 Days</button>
            </Box>
            <Box>
              <button onClick={() => {handleTrialChange(2)}}>5 Weeks</button>
            </Box>
            <Box>
              <button onClick={() => {handleTrialChange(3)}}>10 Weeks</button>
            </Box>
            <Box>
              <button onClick={() => {handleTrialChange(4)}}>15 Weeks</button>
            </Box>
            <Box>
              <DatePicker showIcon={true} minDate={new Date(startDate)} maxDate={new Date(endDate)} onChange={handleDateChange} selected={dateValue} />            
            </Box>
        </Box>

        {/* GRID AND CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >

            {/** ROW 1 */}
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={startDate} subtitle="Start Trial" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={endDate} subtitle="End Trial" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Daily Resting Heart Rate
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        <FaHeartbeat color='#530C0C' />
                    </Box>
                </Box>
                <Box height="250px" m="0px 25px">
                    <Line options={rhrDateOptions} data={rhrDateData} className='graph' id="chartHR" key={Math.random()} />
                </Box>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={rhrDateMax} subtitle="Daily Maximum" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={rhrDateMin} subtitle="Daily Minimum" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>

            {/** ROW 2 */}
            <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Trial Resting Heart Rate
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        <FaHeartbeat color='#530C0C' />
                    </Box>
                </Box>
                <Box height="250px" m="0px 25px">
                    <Line options={rhrMonthOptions} data={rhrMonthData} className='graph' id="chartHR" key={Math.random()} />
                </Box>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={rhrDateMed} subtitle="Daily Median" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={rhrLTMax} subtitle="Long Term Maximum" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
            </Box>

            {/** ROW 3 */}
            <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Trial Resting HR Outlier Count
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        <FaHeartbeat color='#530C0C' />
                    </Box>
                </Box>
                <Box height="250px" m="0px 25px">
                    <Line options={rhrOutlierOptions} data={rhrOutlierData} className='graph' id="chartHR" key={Math.random()} />
                </Box>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={rhrLTMin} subtitle="Long Term Minimum" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={rhrLTMed} subtitle="Long Term Median" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
            </Box>

        </Box>
    </Box>
  );
}

export default RHRStats;