import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

class FormCaregiverBands extends React.Component {
  constructor(props) {
      super(props)
  }

  render() {
      const props = this.props,
          state = this.state,
          labelWidth = props.labelWidth ? props.labelWidth : 3,
          valueWidth = props.valueWidth ? props.valueWidth : 7,
          validationWidth = 12 - labelWidth - valueWidth,
          sensors = this.props.sensors
      return (
          <Form.Group as={Row} className="mb-3">
              <Col sm={ labelWidth }>
                  <ControlLabel>{ props.label }</ControlLabel>
              </Col>
              <Col sm={ valueWidth }>
                  {sensors.map((sensor, index) => {
                      if(sensor.enabled) {
                          return (
                              <FormControl
                                  key={index}
                                  type={ 'text' }
                                  value={ sensor.mac }
                              />
                          )
                      }
                  })}
              </Col>
          </Form.Group>
      )
  }
}
export default FormCaregiverBands
