import _ from "lodash";

const Utils = {
  notifySuccess: (notifier, message) => {
    notifier.success(message, "Success", {
      closeButton: true,
      timeOut: 5000,
      extendedTimeOut: 10000,
    });
  },

  notifyError: (notifier, message, err) => {
    const reason =
      err.responseJSON && err.responseJSON.message
        ? err.responseJSON.message
        : "";
    notifier.error(message ? message + reason : reason, "Error", {
      closeButton: true,
      timeOut: 10000,
      extendedTimeOut: 10000,
    });
  },

  // set the attributes specified by paths to null and return a new object
  setNull(src, paths) {
    const dst = { ...src };
    _.forEach(src, (value, key) => {
      if (key === paths || _.indexOf(paths, key) !== -1) {
        dst[key] = null;
      }
    });
    return dst;
  },

  getQueryParamOfFacilityFilter(filter) {
    if (_.isEmpty(filter)) {
      return {};
    }
    const filterType = filter.filterType;
    switch (filterType) {
      case "Enterprise":
        return { enterpriseId: filter.enterpriseId };
      case "Venue":
        return { venueId: filter.venueId };
      case "Building":
        return { buildingId: filter.buildingId };
      case "Floor":
        return { floorId: filter.floorId };
      case "Room":
        return { roomId: filter.roomId };
      default:
        return false;
    }
  },
};

export default Utils;
