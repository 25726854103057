import React from 'react'

export const CellFormatter = (cell, row, formatExtraData, rowIdx) => {
  let textClassName = null,
      text = cell,
      validationState = {}
  if (formatExtraData.required && (!cell || cell.trim().length ===0)) {
    textClassName = 'text-danger'
    text = '*'
    validationState = {
      status: 'error',
      message: 'required field missing'
    }
  } else if (formatExtraData.validator) {
    validationState = formatExtraData.validator(cell)
    if (validationState.status !== 'success') {
      textClassName = 'text-danger'
    }
  }
  return <span className={textClassName}>{text}</span>
}

export default CellFormatter
