import React from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import { embeddedNameFormatter, enabledFormatter, sensorListFormatter,
        hasAudioFormatter, peopleFormatter, caregiverSensorListFormatter } from '../../components/BaseTable/CellFormatter'
import FacilitySelector from '../../components/FacilitySelector'
import Row from 'react-bootstrap/Row'
import BaseTableToolBar from "../../components/BaseTable/BaseTableToolBar"

class SeniorTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1, // current page number
            sizePerPage: 10, // current size per page,
            totalDataSize: 0,
            seniors: [],
            enterpriseId: null,
            showEnabledOnly: true,
            isConfirmDeleteShown: false,
            isAddModalShown: false,
            isUpdateModalShown: false,
            isDetailModalShown: false,
        }
        this.urlPrefix = '/bstream/api/v1/senior/'
        this.renderShowsTotal = this.renderShowsTotal.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.onSizePerPageList = this.onSizePerPageList.bind(this)
        this.handleRowSelect = this.handleRowSelect.bind(this)

        this.selectRowProp = {
            mode: 'radio',
            clickToSelect: true,  // click to select, default is false
            clickToExpand: true, // click to expand row, default is false
            onSelect: this.handleRowSelect
        }
    }

    _fetchSeniors(enterpriseId, page, sizePerPage, enabled) {
        const postData = {
            enterpriseId: enterpriseId,
            page: page - 1,
            pageSize: sizePerPage,
        }
        if(enabled) {
            postData.enabled = true
        }
        ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/senior/findWithPage",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                const seniors = data.list
                for(let i = 0; i < seniors.length; i++) {
                    if(seniors[i].groupPolicyId !== null) {
                        seniors[i].groupPolicy = this._fetchGroupPolicy(seniors[i].groupPolicyId)
                    }
                }
                this.setState({
                    seniors: seniors,
                    totalDataSize: data.totalCount,
                    currentPage: page,
                    sizePerPage: sizePerPage
                })
            })
            .catch((err) => {});
    }
    renderShowsTotal(start, to, total) {
        return (
            <span style={{float: 'right'}}>
        Total: {total}
      </span>
        )
    }


    _fetchGroupPolicy(id) {
        let groupPolicy = ""
        if(id !== null) {
            ApiSingleton.makeHttpRequest(
                "post",
                "/bstream/api/v1/groupPolicy/findById",
                { dataType: "json", id },
                postData
              )
                .then((data) => {
                    groupPolicy =  data.name
                })
                .catch((err) => {
                    console.log('can not fetch group policy for the senior with groupPolicyId: ' + id + ' Reason: ', err)
                    groupPolicy = ""
                });
        }
        return groupPolicy
    }
    onSizePerPageList(sizePerPage) {
        this._fetchSeniors(this.state.enterpriseId, this.state.currentPage, sizePerPage, this.state.showEnabledOnly)
        this.setState({
            sizePerPage: sizePerPage
        })
    }

    onPageChange(page, sizePerPage) {
        this._fetchSeniors(this.state.enterpriseId, page, sizePerPage, this.state.showEnabledOnly)
        this.setState({
            currentPage: page,
            sizePerPage: sizePerPage
        })
    }

    _onFacilityFilterChange = (filter) => {
        this._fetchSeniors(filter.enterpriseId, 1, 10, this.state.showEnabledOnly)
        this.setState({
            enterpriseId: filter.enterpriseId
        })
    }

    onClickShowEnabledOnly = (e) => {
        this._fetchSeniors(this.state.enterpriseId, this.state.currentPage, this.state.sizePerPage, e.target.checked)
        this.setState({
            showEnabledOnly: e.target.checked
        })
    }

    onRefresh = () => {
        this._fetchSeniors(this.state.enterpriseId, this.state.currentPage, this.state.sizePerPage, this.state.showEnabledOnly)
    }

    onDelete = () => {
        this.setState({isConfirmDeleteShown: true})
    }

    onConfirmDeleteClose = () => {
        this.setState({isConfirmDeleteShown: false})
    }

    onConfirmDeleteConfirm = () => {

    }

    onAdd = () => {
        this.setState({isAddModalShown: true})
    }

    onAddModalClose = () => {
        this.setState({isAddModalShown: false})
    }

    onAddModalSubmit = (data, successCallback, errorCallback) => {
        if (this.props.beforeAddSubmit) {
            // hook to process form data before add submit
            data = this.props.beforeAddSubmit(data)
        }

    }

    onUpdate = () => {
        if (this.props.refreshSuspend) {
            this.props.refreshSuspend()
        }
        this.setState({isUpdateModalShown: true})
    }

    onDetail = () => {
        this.setState({isDetailModalShown: true})
    }

    onUpdateModalClose = () => {
        if (this.props.refreshResume) {
            this.props.refreshResume()
        }
        this.setState({isUpdateModalShown: false})
        if (this.props.clearSelectOnUpdateModalClose) {
            //this.handleRowSelect(null, false)
            // clear selection
            this.refs.table.cleanSelected()
            this.handleRowSelect(null, false)
        }
    }

    onUpdateModalSubmit = (data, successCallback, errorCallback) => {
        if (this.props.beforeUpdateSubmit) {
            // hook to process form data before add submit
            data = this.props.beforeUpdateSubmit(data)
        }
    }

    onDetailModalClose = () => {
        this.setState({isDetailModalShown: false})
    }

    handleRowSelect(row, isSelected, e) {
        this.setState({selectedRow: isSelected ? {...row} : null})

    }
    render() {
        const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        }, {
            config: {
                dataField: 'userName',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'User Name'
        }, {
            config: {
                dataField: 'firstName',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'First Name',
        }, {
            config: {
                dataField: 'lastName',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Last Name'
        }, {
            config: {
                dataField: 'room',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Room'
        }, {
            config: {
                dataField: 'station',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: embeddedNameFormatter
            },
            label: 'Station'
        }, {
            config: {
                dataField: 'enabled',
                dataSort: false,
                width: '90px',
                dataFormat: enabledFormatter
            },
            label: 'Enabled'
        }, {
            config: {
                dataField: 'sensorList',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: caregiverSensorListFormatter
            },
            label: 'RockBand'
        },
            {
                config: {
                    dataField: 'groupPolicy',
                    dataSort: false,
                    formatExtraData: this.state.query,
                    dataFormat: peopleFormatter
                },
                label: 'Group Policy'
            },
            {
                config: {
                    dataField: 'audioList',
                    dataSort: false,
                    formatExtraData: this.state.query,
                    dataFormat: hasAudioFormatter
                },
                label: 'hasAudio'
            }
        ]

        const addModalFields = [{
            controlId: 'userName',
            label: 'User Name',
            type: 'text',
            required: true
        }, {
            controlId: 'firstName',
            label: 'First Name',
            type: 'text',
            required: true
        }, {
            controlId: 'lastName',
            label: 'Last Name',
            type: 'text',
            required: true
        }, {
            controlId: 'enabled',
            label: 'Enabled',
            type: 'checkbox',
            required: true
        },
            {
                type: 'room_with_station',
                fields: {
                    enterprise: {
                        controlId: 'enterpriseId',
                        label: 'Enterprise',
                        required: true,
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    groupPolicy: {
                        controlId: 'groupPolicyId',
                        label: 'Group Policy',
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    venue: {
                        controlId: 'venueId',
                        label: 'Venue',
                        required: true,
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    building: {
                        controlId: 'buildingId',
                        label: 'Building',
                        required: true,
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    floor: {
                        controlId: 'floorId',
                        label: 'Floor',
                        required: true,
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    room: {
                        controlId: 'roomId',
                        label: 'Room',
                        required: true,
                        labelKey: 'name',
                        valueKey: 'id'
                    },
                    station: {
                        controlId: 'stationId',
                        label: 'Station',
                        required: false,
                        labelKey: 'name',
                        valueKey: 'id'
                    }
                }
            },
            {
                controlId: 'uploadAudio',
                type: 'upload_audio',
                label: 'Upload Audio',
                urlEntity: 'senior'
            },
            {
                controlId: 'sensorList',
                label: 'Rockbands',
                type: 'caregiver_rockbands'
            }
        ]

        const updateModalFields = ((fields) => {
            return [].concat(fields)
        })(addModalFields)

        const detailModalFields = ((fields) => {
            return [].concat(fields)
        })(addModalFields)

        let addConfig = {
            title: 'Add Resident',
            submitText: 'Add',
            cancelText: 'Cancel',
            fields: addModalFields
        }

        let updateConfig = {
            title: 'Edit Resident',
            submitText: 'Edit',
            cancelText: 'Cancel',
            fields: updateModalFields
        }

       let detailConfig = {
            title: 'Resident Details',
            fields: detailModalFields
        }

        let deleteConfig = {
            title: 'Delete Resident',
            body: 'Are you sure you want to delete the selected resident?'
        }

        const state = this.state
        addConfig = Object.assign(addConfig, {
            show: state.isAddModalShown,
            data: state.addModalData,
            onClose: this.onAddModalClose,
            onSubmit: this.onAddModalSubmit
        })

        updateConfig = Object.assign(updateConfig, {
            show: state.isUpdateModalShown,
            data: updateConfig.defaultData ? _.assign(_.cloneDeep(state.selectedRow), updateConfig.defaultData) : _.cloneDeep(state.selectedRow),
            onClose: this.onUpdateModalClose,
            onSubmit: this.onUpdateModalSubmit
        })

        detailConfig = Object.assign(detailConfig, {
            show: state.isDetailModalShown,
            data: detailConfig.defaultData ? _.assign(_.cloneDeep(state.selectedRow), detailConfig.defaultData) : _.cloneDeep(state.selectedRow),
            onClose: this.onDetailModalClose,
            onSubmit: this.onDetailModalClose
        })

        deleteConfig = Object.assign(deleteConfig, {
            show: state.isConfirmDeleteShown,
            data: state.selectedRow,
            onClose: this.onConfirmDeleteClose,
            onSubmit: this.onConfirmDeleteConfirm
        })
        return (
            <div>
                <Row>
                    <FacilitySelector onChange={this._onFacilityFilterChange}
                                      fixedFilterType='Enterprise'/>
                </Row>
                <BaseTableToolBar
                    onClickShowEnabledOnly={ this.onClickShowEnabledOnly }
                    showEnabledOnly={ state.showEnabledOnly }
                    onRefresh={ this.onRefresh }
                    onAdd={ this.onAdd }
                    selectedRow={ state.selectedRow }
                    onUpdate={ this.onUpdate }
                    onDetail={ this.onDetail }
                    onDelete={ this.onDelete }
                    addConfig={ addConfig}
                    updateConfig={ updateConfig }
                    detailConfig={ detailConfig }
                    deleteConfig={ deleteConfig }
                />
                <BootstrapTable
                    ref='table'
                    data={ this.state.seniors }
                    remote={true}
                    fetchInfo={{ dataTotalSize: state.totalDataSize }}
                    pagination={true}
                    selectRow={ this.selectRowProp }
                    options={{
                        page: state.currentPage,
                        sizePerPageList: [ 10, 25, 50, 100 ],
                        sizePerPage: state.sizePerPage,
                        paginationShowsTotal: this.renderShowsTotal,
                        onPageChange: this.onPageChange,
                        onSizePerPageList: this.onSizePerPageList,
                    }}>
                    { columnProps.map((column) => {
                        return (
                            <TableHeaderColumn {...column.config}
                                               key={column.config.dataField}
                                               width={ column.config.width }
                            >
                                {column.label}
                            </TableHeaderColumn>
                        )
                    })
                    }
                </BootstrapTable>

            </div>
        )
    }
}
export default SeniorTable
