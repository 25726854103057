import React from 'react'

class SensorAlertSubscription extends React.Component {
    constructor(props) {
        super(props)

    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps) {

    }

    render() {
        return (
            <div>

            </div>
        )
    }
}
export default SensorAlertSubscription