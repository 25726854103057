import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import FacilitySelector from "../../components/FacilitySelector";
import Utils from "../../utils";
import _ from "lodash";
import "../../components/react-select.css";
import ApiSingleton from "../../utils/Axios";
import "react-big-calendar/lib/css/react-big-calendar.css";
import './styles.css'

const localizer = momentLocalizer(moment);

class SeniorLocationReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Residents Activities",
      seniorId: null,
      selectedDay: new Date(),
      minInterval: 60 * 60 * 4,
      events: [],
    };
  }

  _onFacilityFilterChange = (filter) => {
    if (_.isEmpty(filter)) {
      return;
    }
    this._getSeniorOptions(filter);
  };

  _getSeniorOptions = (facilityFilter) => {
    this.setState({
      isSeniorLoading: true,
    });
    let filter = {
      orderBy: "firstName",
      ascendingOrder: true,
      enabled: true,
    };
    filter = _.assign(
      filter,
      Utils.getQueryParamOfFacilityFilter(facilityFilter)
    );
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/senior/find",
      { dataType: "json" },
      filter
    )
      .then((data) => {
        this.setState({
          isSeniorLoading: false,
          seniorOptions: [],
          filter: facilityFilter,
        });
      })
      .catch((err) => {
        console.error(
          "console.errorFailed to fetch list of residents! Reason: ",
          err
        );
        this.setState({
          isSeniorLoading: false,
          seniorOptions: [],
          filter: facilityFilter,
        });
      });
  };

  _getSeniorLocations = (seniorId, date) => {
    const minStartUtc = moment(date).startOf("day");
    const maxStartUtc = moment(date).endOf("day");
    const maxResults = 1000;
    const req_data = {
      seniorId: seniorId,
      minInterval: this.state.minInterval,
      minStartUtc: minStartUtc.toISOString(),
      maxStartUtc: maxStartUtc.toISOString(),
      maxResults: maxResults,
    };

    this.setState({
      isSeniorLocationLoading: true,
    });

    // const url = '/bstream/api/v1/seniorLocationInterval/findByDate'
    const url = "/bstream/api/v1/seniorLocationInterval/findRangeWithSelect";
    ApiSingleton.makeHttpRequest("post", url, { dataType: "json" }, req_data)
      .then((data) => {
        const events = this._transformSeniorLocations(data, maxStartUtc);
        this.setState({
          isSeniorLocationLoading: false,
          events: events,
        });
      })
      .catch((err) => {
        console.error(
          "console.errorFailed to fetch location data for resident! Reason: ",
          err
        );
        this.setState({
          isSeniorLocationLoading: false,
          events: [],
        });
      });
  };

  _transformSeniorLocations = (data, maxStartUtc) => {
    return data.map((item, index) => {
      // item.endUtc may fall outside of the maxStartUtc
      const endUtc = moment(item.endUtc).isAfter(maxStartUtc)
        ? maxStartUtc.toISOString()
        : item.endUtc;
      return {
        type: "LOCATION",
        room: item.room ? item.room.name : "NA",
        start: new Date(item.startUtc),
        end: new Date(endUtc),
      };
    });
  };

  _onSeniorChange = (senior) => {
    this.setState({
      seniorId: senior ? senior.value : null,
      seniorName: senior ? senior.label : null,
    });
    if (senior) {
      this._getSeniorLocations(senior.value, this.state.selectedDay);
    }
  };

  _onMinIntervalChange = (minInterval) => {
    this.setState({ minInterval: minInterval.value }, () => {
      if (this.state.seniorId && this.state.selectedDay) {
        this._getSeniorLocations(this.state.seniorId, this.state.selectedDay);
      }
    });
  };

  _onBigCalendarViewChange = (view) => {
    var minInterval;
    if (view === "month") {
      minInterval = 60 * 60 * 4;
    } else if (view === "week") {
      minInterval = 60 * 15;
    } else if (view === "day") {
      minInterval = 60 * 15;
    } else {
      minInterval = 60 * 5;
    }

    if (minInterval != this.state.minInterval) {
      this.setState({ minInterval: minInterval }, () => {
        if (this.state.seniorId && this.state.selectedDay) {
          this._getSeniorLocations(this.state.seniorId, this.state.selectedDay);
        }
      });
    }
  };

  _onBigCalendarNavigate = (date) => {
    this.setState(
      {
        selectedDay: date,
      },
      () => {
        if (this.state.seniorId) {
          this._getSeniorLocations(this.state.seniorId, this.state.selectedDay);
        }
      }
    );
  };

  _onDayPickerClick = (day, { selected }) => {
    this.setState({
      selectedDay: day,
    });
    if (this.state.seniorId) {
      this._getSeniorLocations(this.state.seniorId, day);
    }
  };

  _titleAccessor = (event) => {
    if (event.type === "LOCATION") {
      return event.room;
    } else if (event.type === "ALERT_TRIGGERED") {
      return (
        event.alertType +
        " alert occurred at " +
        event.room +
        " (" +
        event.building +
        ", " +
        event.floor +
        ")"
      );
    } else if (event.type === "ALERT_CLEARED") {
      return (
        event.alertType +
        " alert cleared at " +
        event.room +
        " (" +
        event.building +
        ", " +
        event.floor +
        ")"
      );
    } else if (event.title) {
      return event.title;
    } else {
      return "fix me (unknown event type or missing title)";
    }
  };

  _eventPropGetter = (event, start, end, isSelected) => {
    if (event.type === "ALERT_TRIGGERED") {
      return { style: { backgroundColor: "red" } };
    } else if (event.type === "ALERT_CLEARED") {
      return { style: { backgroundColor: "green" } };
    } else {
      return {};
    }
  };

  _getPdfReport = (filename) => {
    this.setState({
      isPdfReportLoading: true,
    });
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/pdf/findByName",
      { dataType: "json" },
      {
        name: filename,
      }
    )
      .then((data) => {
        this._saveAs(filename, fileData);
      })
      .catch((err) => {
        console.error(
          "console.errorFailed to fetch report data for resident! Reason: ",
          err
        );
        this.setState({
          isSeniorLocationLoading: false,
          events: [],
        });
      });
  };

  _saveAs = (filename, fileData) => {
    // console.log('got', filename, 'pdf report', fileData)
    this.setState({
      isSeniorLocationLoading: false,
    });
    console.log("file content length", fileData.content.length);
    var base64 = fileData.content;
    var binary = atob(base64);
    var array = new Uint8Array(binary.length);
    for (var i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([array]);
    var url = URL.createObjectURL(blob);
    var link = document.createElement("a");
    if (typeof link.download === "string") {
      // console.log('create a new link', url)
      // Safari and Firefox requires the link to be in the body
      document.body.appendChild(link);
      link.download = filename;
      link.href = url;
      link.click();
      document.body.removeChild(link); //remove the link when done
    } else {
      // console.log("replace with url", url);
      location.replace(url);
    }
  };

  // Check the implementation in
  // https://github.com/notablemind/downloadbutton/blob/master/index.js
  _onDownloadWeeklyReport = () => {
    const filename =
      this.state.seniorName +
      "-" +
      moment(this.state.selectedDay).format("YYYY-MM-DD") +
      "-Weekly.pdf";
    console.log("weeky report filename", filename);
    this._getPdfReport(filename);
  };

  _onDownloadDailyReport = () => {
    const filename =
      this.state.seniorName +
      "-" +
      moment(this.state.selectedDay).format("YYYY-MM-DD") +
      "-Daily.pdf";
    console.log("daily report filename", filename);
    this._getPdfReport(filename);
  };

  render() {
    const props = this.props,
      state = this.state;
    var loading = (
      <div className="fa-3x" style={{ zIndex: 10 }}>
        <i className="fa fa-spinner fa-spin" style={{ color: "green" }}></i>
      </div>
    );

    return (
      <div>
        {state.isSeniorLocationLoading || state.isSeniorLoading ? (
          loading
        ) : (
          <div />
        )}
        <FacilitySelector
          onChange={this._onFacilityFilterChange}
          fixedFilterType="Enterprise"
          filterLabel="Filter Residents By"
        />
        <Row style={{ marginLeft: "-5px" }}>
          <Col sm={3} md={2} lg={2}>
            <div style={{width:'100%'}}>
              <p>{"Senior"}</p>
              <Select
                name={"senior"}
                clearable={false}
                value={state.seniorId}
                options={state.seniorOptions}
                onChange={this._onSeniorChange}
              />
            </div>
            <div>
              <DayPicker
                selectedDays={state.selectedDay}
                month={state.selectedDay}
                onDayClick={this._onDayPickerClick}
              />
            </div>
            {/*
            <div>
            		{ false && <Button bsStyle='primary' block onClick={this._onDownloadWeeklyReport}>
            			<Glyphicon glyph='download-alt' /> Weekly Report</Button> }
            		<Button bsStyle='primary' block onClick={this._onDownloadDailyReport}>
            			<Glyphicon glyph='download-alt' /> Daily Report</Button>
    			</div>
    		*/}
            <div>
              <p>{"Min Update Interval"}</p>
              <Select
                name={"minInterval"}
                clearable={false}
                value={state.minInterval}
                options={[
                  { value: 60, label: "1 minute" },
                  { value: 60 * 2, label: "2 minutes" },
                  { value: 60 * 3, label: "3 minutes" },
                  { value: 60 * 5, label: "5 minutes" },
                  { value: 60 * 10, label: "10 minutes" },
                  { value: 60 * 15, label: "15 minutes" },
                  { value: 60 * 30, label: "30 minutes" },
                  { value: 60 * 60, label: "1 hour" },
                  { value: 60 * 60 * 4, label: "4 hours" },
                ]}
                onChange={this._onMinIntervalChange}
              />
            </div>
          </Col>

          <Col
            sm={12}
            md={{ span: 2, offset: 2 }}
            lg={8}
            style={{ height: "800px",}}
            className="mobile-space"
          >
            <Calendar
              localizer={localizer}
              popup
              events={state.events}
              date={state.selectedDay}
              onView={this._onBigCalendarViewChange}
              onNavigate={this._onBigCalendarNavigate}
              titleAccessor={this._titleAccessor}
              eventPropGetter={this._eventPropGetter}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default SeniorLocationReport;
