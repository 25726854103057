import React from 'react'
import FormGroup from 'react-bootstrap/FormGroup'
import { Form }from "react-bootstrap"
import Col from 'react-bootstrap/Col'

import RelayPosition from './RelayPosition'

class FormGroupRelayPosition extends React.Component {
  constructor(props) {
    super(props)
  }

  _onChange = (value) => {
//    console.log('FormGroupRelayPosition onChange')
//    console.log(value)
    this.props.onChange(this.props.controlId, this.props.required, this.props.validator, value)
  }

  render() {
    const props = this.props,
          state = this.state,
          labelWidth = props.labelWidth ? props.labelWidth : 3,
          valueWidth = 8,
          imageContent = (props.data && props.data.roomImage) ? atob(props.data.roomImage.content) : null,
          roomCoordinates = (props.data && props.data.roomCoordinates) ? props.data.roomCoordinates : null
    return (
      <Form.Group
        controlId={ props.controlId }
        validationState={ props.validationState.status }>
        <Col sm={ labelWidth }>
          <Form.Label>{ props.label }</Form.Label>
        </Col>
        <Col sm={ valueWidth }>
          <p>{ props.helpText }</p>
        </Col>
        <Col smOffset={ labelWidth } sm={ valueWidth } style={{overflow: 'auto'}}>
          <RelayPosition
            imageContent={ imageContent }
            imageWidth={800} imageHeight={500}
            roomCoordinates={ roomCoordinates }
            value={ props.value }
            onChange={ this._onChange } />
        </Col>
      </Form.Group>
    )
  }
}

export default FormGroupRelayPosition
