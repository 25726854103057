/* global $ */

import React from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'

class BaseTable extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const props = this.props
    let data = this.props.data
    return (
        <BootstrapTable
          ref='table'
          hover
          height={ props.height }
          maxHeight={ props.maxHeight }
          striped={ props.striped === undefined ? true : props.striped }
          data={ data }
          remote={ true }
          pagination={ false }
          options={ props.options ? props.options : {
              expandRowBgColor: '#2f4050'
          }} >
          { 
        	  props.columnProps.map((column) => {
        		  return (
        				  <TableHeaderColumn {...column.config}
                          	key={column.config.dataField}
                          	width={ column.config.width }
                          >
                             {column.label}
                          </TableHeaderColumn>
        		  )
        	  })
           }
        </BootstrapTable>
    )
  }
}

export default BaseTable
