import React from 'react'
import FacilitySelector from '../../components/FacilitySelector'
import Row from 'react-bootstrap/Row'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import { embeddedNameFormatter, enabledFormatter, sensorListFormatter,
         hasAudioFormatter, temperatureFormatter, peopleFormatter } from '../../components/BaseTable/CellFormatter'
import moment from 'moment'
import BaseTableToolBar from "../../components/BaseTable/BaseTableToolBar";
import ApiSingleton from '../../utils/Axios'

export default class TemperatureTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1, // current page number
            sizePerPage: 10, // current size per page,
            totalDataSize: 0,
            seniors: [],
            reqPayload: {},
            timer: null,
            showEnabledOnly: true
        }
        this.renderShowsTotal = this.renderShowsTotal.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.onSizePerPageList = this.onSizePerPageList.bind(this)
        this._refresh = this._refresh.bind(this)
    }

    //the temperature page will refresh every 10 seconds
    _refreshResume = () => {
        if (!this.state.timer) {
            var timer = setInterval(this._refresh, 30000)
            this.setState({timer:timer})
        }
    }
    _refreshSuspend = () => {
        if (this.state.timer) {
            clearInterval(this.state.timer)
            this.setState({timer:null})
        }
    }

    _updateSeniorsTemperature = (seniors) =>  {
        if(seniors) {
            const residents = seniors
            for(let i = 0; i < seniors.length; i++) {
                residents[i].tempList = this._fetchTemperature(seniors[i].id)
            }
            this.setState({
                seniors: residents
            })
        }
    }

    onClickShowEnabledOnly = (e) => {
        this._fetchSeniors(this.state.reqPayload, this.state.currentPage, this.state.sizePerPage, e.target.checked)
        this.setState({
            showEnabledOnly: e.target.checked
        })
    }

    _refresh = () => {
        if(this.state.seniors) {
            this._updateSeniorsTemperature(this.state.seniors)
        }

        // this._fetchSeniors(this.state.reqPayload, this.state.currentPage, this.state.sizePerPage)

    }

    componentDidMount() {
        this._fetchSeniors(this.state.reqPayload, this.state.currentPage, this.state.sizePerPage, this.state.showEnabledOnly)
        this._refreshResume()
    }

    componentWillUnmount() {
        this._refreshSuspend()
    }

    _fetchSeniors(reqPayload, page, sizePerPage, enabled) {
        const postData = {
            page: page - 1,
            pageSize: sizePerPage,
        }
        if(enabled) {
            postData.enabled = true
        }
        if(reqPayload) {
            if(reqPayload.enterpriseId) {
                postData.enterpriseId = reqPayload.enterpriseId
            }
            if(reqPayload.venueId) {
                postData.venueId = reqPayload.venueId
            }
            if(reqPayload.floorId) {
                postData.floorId = reqPayload.floorId
            }
            if(reqPayload.buildingId) {
                postData.buildingId = reqPayload.buildingId
            }
            if(reqPayload.roomId) {
                postData.roomId = reqPayload.roomId
            }
        }
        // console.log('fetchSeniors', postData)
        ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/senior/findWithPage",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                const seniors = data.list
                console.log("templist: ", data);
                for(let i = 0; i < seniors.length; i++) {
                    seniors[i].tempList = this._fetchTemperature(seniors[i].id)
                }
                this.setState({
                    seniors: seniors,
                    totalDataSize: data.totalCount,
                    currentPage: page,
                    sizePerPage: sizePerPage
                })
            })
            .catch((err) => {});
    }


    _fetchTemperature(seniorId) {
        const maxCreateUtc = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS").toString() + "Z"
        const minCreateUtc = moment().subtract('30', 'minutes').utc().format("YYYY-MM-DDTHH:mm:ss.SSS").toString() + "Z"
        let tempList = []
        // console.log("Before templist: ", tempList);
        const postData = {
            seniorId: seniorId,
            interval: 30,
            minCreateUtc: minCreateUtc,
            maxCreateUtc: maxCreateUtc,
            orderBy: 'createUtc',
            ascendingOrder: true
        }
        if(seniorId) {
            ApiSingleton.makeHttpRequest(
                "post",
                "/bstream/api/v1/seniorTemperature/findRange",
                { dataType: "json" },
                postData
              )
                .then((data) => {
                    tempList = data
                })
                .catch((err) => {});
        }
        console.log("after templist: ", tempList);
        return tempList
    }

    renderShowsTotal(start, to, total) {
        return (
            <span style={{float: 'right'}}>
                Total: {total}
            </span>
        )
    }

    onSizePerPageList(sizePerPage) {
        this._fetchSeniors(this.state.reqPayload, this.state.currentPage, sizePerPage, this.state.showEnabledOnly)
        this.setState({
            sizePerPage: sizePerPage
        })

    }

    onPageChange(page, sizePerPage) {
        this._fetchSeniors(this.state.reqPayload, page, sizePerPage, this.state.showEnabledOnly)
        this.setState({
            currentPage: page,
            sizePerPage: sizePerPage
        })
    }


    _onFacilityFilterChange = (filter) => {
        this._fetchSeniors(filter, 1, 10, true)
        this.setState({
            reqPayload: filter
        })
    }

    render() {
        const state= this.state
       const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        }, {
            config: {
                dataField: 'userName',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'User Name'
        }, {
            config: {
                dataField: 'firstName',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'First Name',
        }, {
            config: {
                dataField: 'lastName',
                dataSort: false,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Last Name'
        }, {
                config: {
                    dataField: 'tempList',
                    dataSort: false,
                    formatExtraData: this.state.query,
                    dataFormat: temperatureFormatter,
                    width: '800px'
                },
                label: 'Temperature'
        }]
        return (
            <div>
                <Row>
                    <FacilitySelector onChange={this._onFacilityFilterChange}
                                      filterLabel='Filter By'
                    />
                </Row>
                <BaseTableToolBar
                    actions={[{name: 'refresh'}]}
                    onRefresh={ this._refresh }
                    onClickShowEnabledOnly={ this.onClickShowEnabledOnly }
                    showEnabledOnly={ state.showEnabledOnly }
                />
                <BootstrapTable
                    ref='table'
                    data={ this.state.seniors }
                    remote={true}
                    fetchInfo={{ dataTotalSize: state.totalDataSize }}
                    pagination={true}
                    options={{
                        page: state.currentPage,
                        sizePerPageList: [ 10, 25, 50, 100 ],
                        sizePerPage: state.sizePerPage,
                        paginationShowsTotal: this.renderShowsTotal,
                        onPageChange: this.onPageChange,
                        onSizePerPageList: this.onSizePerPageList,
                    }}>
                    { columnProps.map((column) => {
                        return (
                            <TableHeaderColumn {...column.config}
                                               key={column.config.dataField}
                                               width={ column.config.width }
                            >
                                {column.label}
                            </TableHeaderColumn>
                        )
                    })
                    }
                </BootstrapTable>
            </div>
        )
    }
}