/* global $ */

import React from 'react'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import FacilitySelector from '../FacilitySelector'
import { eachSeries } from 'async'
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'

class BaseTable extends React.Component {
  static contextType = ToastContext
  constructor(props) {[]
    super(props)
    this.urlPrefix = '/bstream/api/v1/' + props.urlEntity + '/'
    this.handleRowSelect = this.handleRowSelect.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
    this.renderShowsTotal = this.renderShowsTotal.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onSizePerPageList = this.onSizePerPageList.bind(this)
    this.onRefresh = this.onRefresh.bind(this)
    // this.onHandleQuery = this.onHandleQuery.bind(this)
    this.state = {
      data: props.data ? props.data : [],
      sortedData: props.sortedData ? props.sortedData : [],
      totalDataSize: props.totalDataSize ? props.totalDataSize : 0,
      sortName: props.sortName ? props.sortName : undefined, // current sort name
      sortOrder: props.sortOrder ? props.sortOrder : undefined, // current sort order
      currentPage: 1, // current page number
      sizePerPage: 10, // current size per page
      showEnabledOnly: true,
      query: '',
      filter: { filterType:'System' },
    }

    this.selectRowProp = {
      hideSelectColumn: true,
      mode: 'radio',
      clickToSelect: true,
      clickToExpand: true, // click to expand row, default is false
      onSelect: this.handleRowSelect
    }
  }

  componentWillMount() {
      const sortName = this.props.sortName ? this.props.sortName : undefined
      const sortOrder = this.props.sortOrder ? this.props.sortOrder : undefined
      if (this.props.autoLoad !== false) {
          this._remoteFetchRows({
              page: this.state.currentPage - 1,
              pageSize: this.state.sizePerPage,
          }, this.props.filter, {sortName, sortOrder})
      }
  }

  componentWillUnmount() {
	  if (this.props.refreshResume) {
		  this.props.refreshResume()
	  }
  }
  
  componentWillReceiveProps(nextProps) {
    if(nextProps.sortName) {
        this.setState({sortName : nextProps.sortName})
    }
    if(nextProps.sortOrder) {
        this.setState({sortOrder: nextProps.sortOrder})
    }

    if(nextProps.urlRelayChange === 'relayChange') {
        this.setState({sortedData : nextProps.sortedData})
    }
  }
  
  onSearchChange = (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.setState({
          query: e.target.value
      }, this.props.onHandleQuery(e.target.value))

  }
  handleClear = (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.setState({
          query: ''
      }, this.props.onHandleQuery(''))
  }
  
  handleRowSelect(row, isSelected, e) {
    this.setState({selectedRow: isSelected ? {...row} : null})
    if (isSelected && this.props.selectAction) {
      if (this.props.selectAction.name === 'update') {
        this.onUpdate()
      } else if (this.props.selectAction.name === 'detail') {
          this.onDetail()    	  
      }
    }
    if (this.props.handleRowSelect) {
      this.props.handleRowSelect(row, isSelected)
    }
  }
  onSortChange(sortName, sortOrder) {
      //base:  local sort
    if(!this.props.urlEntity || this.props.urlEntity === 'sensorAlert') {
        this.props.sortChange(sortName, sortOrder)
    } else {
        this._remoteFetchRows({
            page: this.state.currentPage - 1,
            pageSize: this.state.sizePerPage,
            orderBy: sortName === 'room' ? sortName + "_name" : sortName,
            ascendingOrder: sortOrder === 'asc'
        }, this.props.filter, {
            sortName: sortName === 'room' ? sortName + "_name" : sortName,
            sortOrder: sortOrder
        })
    }
  }

  onSizePerPageList(sizePerPage) {
    this._remoteFetchRows({
      page: 0,
      pageSize: sizePerPage
    }, this.props.filter, {
      currentPage: 0,
      sizePerPage: sizePerPage,
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder
    })
  }

  onPageChange(page, sizePerPage) {
    this._remoteFetchRows({
      page: page - 1,
      pageSize: sizePerPage
    }, this.props.filter, {
      currentPage: page,
      sizePerPage: sizePerPage,
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder
    })
  }

  renderShowsTotal(start, to, total) {
    return (
      <span style={{float: 'right'}}>
        Total: {total}
      </span>
    )
  }

  onRefresh = () => {
    // clear selection
    this.refs.table.cleanSelected()
    this.handleRowSelect(null, false)
    // refresh table content
    if (this.props.refresh) {
        this.props.refresh()
    } else {
        this._remoteFetchRows({}, this.props.filter, {sortName: this.state.sortName, sortOrder: this.state.sortOrder})
    }
  }

  onClickShowEnabledOnly = (e) => {
	if ((this.state.showEnabledOnly && !e.target.checked) ||
		(!this.state.showEnabledOnly && e.target.checked)) {

	    // clear selection
	    this.refs.table.cleanSelected()
	    this.handleRowSelect(null, false)
	    // refresh table content
	    if (this.props.refresh) {
	      this.props.refresh()
	    } else {
	    	  const reqPayload = { page: this.state.currentPage - 1,
	    		      pageSize: this.state.sizePerPage }
	      this._remoteFetchRows(reqPayload, this.state.filter, {showEnabledOnly: e.target.checked})
	    }
	}
  }
  _onFacilityFilterChange = (filter) => {
    this._remoteFetchRows({}, filter, {
      filter: {...filter},
      addModalData: _.omit(filter, 'filterType')
    })
  }

  // return a human readable name to identity a row
  _getEntityName = (data) => {
    if (this.props.entityNameField) {
      return data[this.props.entityNameField]
    } else {
      return data ? data.id : null
    }
  }

  //remote fetch the data:
  _remoteFetchRows(reqPayload, filter, newState) {
    const { showToast } = this.context
    newState.data = []
    newState.totalDataSize = 0
    if (!filter) {
      filter = this.state.filter	
    }
    if (filter) {
      if (filter.filterType === 'System') {
        // no id needed
      } else if (filter.filterType === 'Enterprise') {
        if (filter.enterpriseId) {
          reqPayload.enterpriseId = filter.enterpriseId
            if(this.props.urlName === 'groupPolicy') {
                this.props.onHandleEnterpriseChange(filter.enterpriseId)
            }
        } else {
            if(this.props.urlName === 'groupPolicy') {
                this.props.onHandleEnterpriseChange(null)
            }
          this.setState(newState)
          return
        }
      } else if (filter.filterType === 'Venue') {
        if (filter.venueId) {
          reqPayload.venueId = filter.venueId
        } else {
          this.setState(newState)
          return
        }
      } else if (filter.filterType === 'Building') {
        if (filter.buildingId) {
          reqPayload.buildingId = filter.buildingId
        } else {
          this.setState(newState)
          return
        }
      } else if (filter.filterType === 'Floor') {
        if (filter.floorId) {
          reqPayload.floorId = filter.floorId
        } else {
          this.setState(newState)
          return
        }
      } else if (filter.filterType === 'Room') {
        if (filter.roomId) {
          reqPayload.roomId = filter.roomId
        } else {
          this.setState(newState)
          return
        }
      } else {
        // filter either with unknown type or the filter id is not ready yet
        // skip refresh
        this.setState(newState)
        return
      }
    } else {
        this.setState(newState)
        return    	
    }
    
    // set the sort data in the payload if not specified
    // if (reqPayload.sortName === undefined && this.state.sortName) {
      if (newState.sortName !== undefined) {
        reqPayload.orderBy = newState.sortName
    }

    if (newState.sortOrder !== undefined) {
        reqPayload.ascendingOrder = newState.sortOrder === 'asc'
    }
    // set the pagination in the payload if not specified
    if (reqPayload.page === undefined) {
        reqPayload.page = this.state.currentPage - 1
    }
    if (reqPayload.pageSize === undefined) {
        reqPayload.pageSize = this.state.sizePerPage
    }

    if (newState.showEnabledOnly !== undefined) {
    	  if (newState.showEnabledOnly) {
    	      reqPayload.enabled = true
    	  }
    } else if (this.state.showEnabledOnly !== undefined) {
        if (this.state.showEnabledOnly) {
  	      reqPayload.enabled = true
  	  }    	
    }
    
    if (this.props.reqPayload) {
    	reqPayload = Object.assign(this.props.reqPayload, reqPayload)
    }
    ApiSingleton.makeHttpRequest(
      "post",
      this.urlPrefix + 'findWithPage',
      { dataType: "json" },
      reqPayload
    )
      .then((data) => {
        let seniors = data.list
	      eachSeries(
	    	seniors,
	    	(senior, cb) => {
	    		let reqPayload = { seniorId: senior.id }
	    		let url = '/bstream/api/v1/questionnaire/findBySeniorId'
          ApiSingleton.makeHttpRequest(
            "post",
            url,
            { dataType: "json" },
            reqPayload
          )
            .then((data) => {
              let list = data
	   					if (list && list.length > 0) {
	   						senior.reports = list
	    				} else {
	    					senior.reports = []
	    				}
	   					//senior.totalReports = senior.reports.length
	    				return cb()
            })
            .catch((err) => {
              cb(err)
            });
	    	},
	        		
	        (err) => {
	        	if (err) {
					showToast('Failed to fetch list of questionnaire! Reason: '+ err)	        		
	        	} else {
		        	newState.data = seniors
					this.setState(newState)	        		
	        	}
	        }
	      )
      })
      .catch((err) => {
        if (err) {
          showToast('Failed to fetch list of seniors! Reason: '+ err)					
        }	
      });  
  }

  render() {
    const props = this.props
    const state = this.state

    let data = (this.props.sortedData) ? this.props.sortedData : this.state.data

    return (
      <div>
        {(() => {
          if (props.facilityFilter) {
            return (
              <FacilitySelector
                onChange={ this._onFacilityFilterChange }
                filterLevel={ props.facilityFilterLevel }
                filterLabel='Filter By'
              />
            )
          }
        })()}
        <BootstrapTable
          ref='table'
          hover
          height={ props.height }
          maxHeight={ props.maxHeight }
          striped={ props.striped === undefined ? true : props.striped }
          data={ data }
          remote={ true }
          pagination={ props.pagination === undefined ? true : props.pagination }
          selectRow={ this.selectRowProp }
          fetchInfo={ { dataTotalSize: props.totalDataSize ? props.totalDataSize : state.totalDataSize} }
          expandableRow={ props.expandableRow }
          expandComponent={ props.expandComponent }
          expandColumnOptions={ { expandColumnVisible: true } }
          options={ props.options ? props.options : {
              onSortChange: this.onSortChange,
              page: state.currentPage,
              sizePerPageList: [ 10, 25, 50, 100 ],
              sizePerPage: 10,
              paginationShowsTotal: this.renderShowsTotal,
              onPageChange: this.onPageChange,
              onSizePerPageList: this.onSizePerPageList,
              expandRowBgColor: '#2f4050'
          }} >
          { 
        	  props.columnProps.map((column) => {
        		  return (
        				  <TableHeaderColumn {...column.config}
                          	key={column.config.dataField}
                          	width={ column.config.width }
                          >
                             {column.label}
                          </TableHeaderColumn>
        		  )
        	  })
           }
        </BootstrapTable>
      </div>
    )
  }
}

export default BaseTable
