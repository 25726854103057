import React from 'react'
import FormGroup from 'react-bootstrap/FormGroup'
import { Form }from "react-bootstrap"
import Col from 'react-bootstrap/Col'

class FormGroupReadOnlyTable extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const props = this.props,
          labelWidth = props.labelWidth ? props.labelWidth : 3,
          valueWidth = props.valueWidth ? props.valueWidth : 9,
          value = props.valueRender ? props.valueRender(props.value) : props.value
    return (
      <Form.Group
        controlId={ props.controlId } >
        <Col sm={ labelWidth }>
          <Form.Label style={{ fontWeight: "bold" }}>{ props.label }</Form.Label>
        </Col>
        <Col sm={ valueWidth }>
          <div className='form-table'>
            {value}
          </div>
        </Col>
        <Col smOffset={ labelWidth } sm={ valueWidth }>
          <p>{ props.helpText }</p>
        </Col>
      </Form.Group>
    )
  }
}

export default FormGroupReadOnlyTable
