/* global $ */

import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import FacilitySelector from "../../components/FacilitySelector";
import BaseTableToolBar from "./BaseTableToolBar";
import moment from "moment";
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'
import { filter } from "async";

class BaseTable extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props);
    this.urlPrefix = "/bstream/api/v1/" + props.urlEntity + "/";
    this.handleRowSelect = this.handleRowSelect.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.renderShowsTotal = this.renderShowsTotal.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSizePerPageList = this.onSizePerPageList.bind(this);
    this.onRefresh = this.onRefresh.bind(this);

    // this.onHandleQuery = this.onHandleQuery.bind(this)
    this.addToApproachingWhiteList = [];
    this.removeFromApproachingWhiteList = [];
    this.state = {
      data: props.data ? props.data : [],
      sortedData: props.sortedData ? props.sortedData : [],
      totalDataSize: props.totalDataSize ? props.totalDataSize : 0,
      sortName: props.sortName ? props.sortName : undefined, // current sort name
      sortOrder: props.sortOrder ? props.sortOrder : undefined, // current sort order
      currentPage: 1, // current page number
      sizePerPage: 10, // current size per page
      isConfirmDeleteShown: false,
      isAddModalShown: false,
      isUpdateModalShown: false,
      isDetailModalShown: false,
      showEnabledOnly: true,
      query: "",
      filter: { filterType: "System" }
      // addToApproachingWhiteList: props.addToApproachingWhiteList ? props.addToApproachingWhiteList : [],
      // removeFromApproachingWhiteList: props.removeFromApproachingWhiteList ? props.removeFromApproachingWhiteList : []
    };

    this.selectRowProp = props.hideSelectColumn
      ? {
        hideSelectColumn: true,
        mode: "radio",
        clickToSelect: true,
        clickToExpand: true, // click to expand row, default is false
        onSelect: this.handleRowSelect,
      }
      : {
        mode: "radio",
        clickToSelect: true, // click to select, default is false
        clickToExpand: true, // click to expand row, default is false
        onSelect: this.handleRowSelect,
      };
  }

  componentWillMount() {
    const sortName = this.props.sortName ? this.props.sortName : undefined;
    const sortOrder = this.props.sortOrder ? this.props.sortOrder : undefined;
    if (this.props.autoLoad !== false) {
      this._remoteFetchRows(
        {
          page: this.state.currentPage - 1,
          pageSize: this.state.sizePerPage,
        },
        this.props.filter,
        { sortName, sortOrder }
      );
    }
  }

  componentWillUnmount() {
    if (this.props.refreshResume) {
      this.props.refreshResume();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sortName) {
      this.setState({ sortName: nextProps.sortName });
    }
    if (nextProps.sortOrder) {
      this.setState({ sortOrder: nextProps.sortOrder });
    }

    if (nextProps.urlRelayChange === "relayChange") {
      this.setState({ sortedData: nextProps.sortedData });
    }

    // if(nextProps.addToApproachingWhiteList) {
    //     this.setState({
    //         addToApproachingWhiteList: nextProps.addToApproachingWhiteList
    //     })
    //     this.addToApproachingWhiteList = nextProps.addToApproachingWhiteList
    //
    // }
    //
    // if(nextProps.removeFromApproachingWhiteList) {
    //     this.setState({
    //         removeFromApproachingWhiteList: nextProps.removeFromApproachingWhiteList
    //     })
    //     this.removeFromApproachingWhiteList = nextProps.removeFromApproachingWhiteList
    // }
  }
  // handleSearch =(e) => {
  //     this.setState({
  //         query: e.target.search.value
  //     })
  // }

  setIds = (valueId) => {
    const sortName = this.props.sortName ? this.props.sortName : undefined;
    const sortOrder = this.props.sortOrder ? this.props.sortOrder : undefined;
    let obj = {};
    if (this.state.filter.filterType == "Enterprise") {
      obj = { filterType: this.state.filter.filterType, enterpriseId: valueId }
    } else if (this.state.filter.filterType == "Venue") {
      obj = { filterType: this.state.filter.filterType, venueId: valueId }
    } else if (this.state.filter.filterType == "Building") {
      obj = { filterType: this.state.filter.filterType, buildingId: valueId }
    } else if (this.state.filter.filterType == "Floor") {
      obj = { filterType: this.state.filter.filterType, floorId: valueId }
    } else if (this.state.filter.filterType == "Room") {
      obj = { filterType: this.state.filter.filterType, roomId: valueId }
    }
    this._remoteFetchRows(
      {
        page: this.state.currentPage - 1,
        pageSize: this.state.sizePerPage,
      },
      obj,
      { sortName, sortOrder }
    );
  }
  onSearchChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      {
        query: e.target.value,
      },
      this.props.onHandleQuery(e.target.value)
    );
  };
  handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      {
        query: "",
      },
      this.props.onHandleQuery("")
    );
  };
  handleRowSelect(row, isSelected, e) {
    this.setState({ selectedRow: isSelected ? { ...row } : null });
    if (isSelected && this.props.selectAction) {
      if (this.props.selectAction.name === "update") {
        this.onUpdate();
      } else if (this.props.selectAction.name === "detail") {
        this.onDetail();
      }
    }
    if (this.props.handleRowSelect) {
      this.props.handleRowSelect(row, isSelected);
    }
  }
  onSortChange(sortName, sortOrder) {
    //base:  local sort
    if (!this.props.urlEntity || this.props.urlEntity === "sensorAlert") {
      this.props.sortChange(sortName, sortOrder);
    } else {
      this._remoteFetchRows(
        {
          page: this.state.currentPage - 1,
          pageSize: this.state.sizePerPage,
          orderBy: sortName === "room" ? sortName + "_name" : sortName,
          ascendingOrder: sortOrder === "asc",
        },
        this.props.filter,
        {
          sortName: sortName === "room" ? sortName + "_name" : sortName,
          sortOrder: sortOrder,
        }
      );
    }
  }

  onSizePerPageList(sizePerPage) {
    this._remoteFetchRows(
      {
        page: 0,
        pageSize: sizePerPage,
      },
      this.props.filter,
      {
        currentPage: 0,
        sizePerPage: sizePerPage,
        sortName: this.state.sortName,
        sortOrder: this.state.sortOrder,
      }
    );
  }

  onPageChange(page, sizePerPage) {
    this._remoteFetchRows(
      {
        page: page - 1,
        pageSize: sizePerPage,
      },
      this.props.filter,
      {
        currentPage: page,
        sizePerPage: sizePerPage,
        sortName: this.state.sortName,
        sortOrder: this.state.sortOrder,
      }
    );
  }

  renderShowsTotal(start, to, total) {
    return <span style={{ float: "right" }}>Total: {total}</span>;
  }

  onRefresh = () => {
    // clear selection
    this.refs.table.cleanSelected();
    this.handleRowSelect(null, false);
    // refresh table content
    if (this.props.refresh) {
      this.props.refresh();
    } else {
      this._remoteFetchRows({}, this.props.filter, {
        sortName: this.state.sortName,
        sortOrder: this.state.sortOrder,
      });
    }
  };

  onDelete = () => {
    this.setState({ isConfirmDeleteShown: true });
  };

  onConfirmDeleteClose = () => {
    this.setState({ isConfirmDeleteShown: false });
  };

  onConfirmDeleteConfirm = () => {
    const { showToast } = this.context
    if (
      this.props.deleteConfig &&
      this.props.deleteConfig.name === "groupPolicy"
    ) {
      // this.props.deleteRelayById(this.state.selectedRow.relayId)
      // this.setState({
      //     removeFromApproachingWhiteList: this.state.removeFromApproachingWhiteList.concat(this.state.selectedRow.relayId)
      // })

      const postData = {
        id: this.props.groupPolicyId,
      };
      const list = [];
      list.push(this.state.selectedRow.relayId);
      if (this.props.controlId === "approachingWhiteList") {
        postData.removeFromApproachingWhiteList = JSON.stringify(list);
      } else {
        postData.removeFromLeavingWhiteList = JSON.stringify(list);
      }
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/groupPolicy/update",
        { dataType: "json" },
        postData
      )
        .then((data) => {
          this.onConfirmDeleteClose();
          this.onRefresh();
        })
        .catch((err) => {
          console.error(err);
          showToast(
            "Failed to delete the relay" + ")! Reason: " +
            err
          );
          // Will need to replace this with a supported taost message
        });
    } else {
      this._remoteDeleteRow(this.state.selectedRow, this.onConfirmDeleteClose);
    }
  };

  onAdd = () => {
    this.setState({ isAddModalShown: true });
  };

  onAddModalClose = () => {
    this.setState({ isAddModalShown: false });
  };

  onAddModalSubmit = (data, successCallback, errorCallback) => {
    if (this.props.beforeAddSubmit) {
      // hook to process form data before add submit
      data = this.props.beforeAddSubmit(data);
    }

    // console.log('postData', data)
    if (data && data.updateUrl && data.updateUrl === "groupPolicyRelay") {
      // this.addToApproachingWhiteList.concat(data.id)
      // this.setState({
      //     addToApproachingWhiteList: this.state.addToApproachingWhiteList.concat(data.id)
      // })
      // this.onAddModalClose()
      const postData = { id: data.id };

      if (data.type === "approachingWhiteList") {
        postData.addToApproachingWhiteList = JSON.stringify(data.relays);
      } else {
        postData.AddToLeavingWhiteList = JSON.stringify(data.relays);
      }
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/groupPolicy/update",
        { dataType: "json" },
        postData
      )
        .then((data) => {
          this.onRefresh();
          this.onAddModalClose();
        })
        .catch((err) => { });
    } else {
      this._remoteAddRow(data, successCallback, errorCallback);
    }
  };

  onUpdate = () => {
    if (this.props.refreshSuspend) {
      this.props.refreshSuspend();
    }
    this.setState({ isUpdateModalShown: true });
  };

  onDetail = () => {
    this.setState({ isDetailModalShown: true });
  };

  onClickShowEnabledOnly = (e) => {
    if (
      (this.state.showEnabledOnly && !e.target.checked) ||
      (!this.state.showEnabledOnly && e.target.checked)
    ) {
      // clear selection
      this.refs.table.cleanSelected();
      this.handleRowSelect(null, false);
      // refresh table content
      if (this.props.refresh) {
        this.props.refresh();
      } else {
        const reqPayload = {
          page: this.state.currentPage - 1,
          pageSize: this.state.sizePerPage,
        };
        this._remoteFetchRows(reqPayload, this.state.filter, {
          showEnabledOnly: e.target.checked,
        });
      }
    }
  };

  onUpdateModalClose = () => {
    if (this.props.refreshResume) {
      this.props.refreshResume();
    }
    this.setState({ isUpdateModalShown: false });
    if (this.props.clearSelectOnUpdateModalClose) {
      //this.handleRowSelect(null, false)
      // clear selection
      this.refs.table.cleanSelected();
      this.handleRowSelect(null, false);
    }
  };

  onUpdateModalSubmit = (data, successCallback, errorCallback) => {
    if (this.props.beforeUpdateSubmit) {
      // hook to process form data before add submit
      data = this.props.beforeUpdateSubmit(data);
    }

    if (data && data.updateUrl && data.updateUrl === "groupPolicy") {
      let postData = {
        id: data.id,
      };
      if (data.type === "approachingWhiteList") {
        postData.addToApproachingWhiteList = JSON.stringify(data.relays);
      } else {
        postData.AddToLeavingWhiteList = JSON.stringify(data.relays);
      }
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/groupPolicy/update",
        { dataType: "json" },
        postData
      )
        .then((data) => {
          this.onUpdateModalClose();
        })
        .catch((err) => { });
    } else {
      this._remoteUpdateRow(data, successCallback, errorCallback);
    }

    // if (this.props.beforeUpdateSubmit) {
    //   // hook to process form data before add submit
    //   data = this.props.beforeUpdateSubmit(data)
    // }
  };

  onDetailModalClose = () => {
    this.setState({ isDetailModalShown: false });
    if (this.props.clearSelectOnDetailModalClose) {
      //this.handleRowSelect(null, false)
      // clear selection
      this.refs.table.cleanSelected();
      this.handleRowSelect(null, false);
    }
  };

  _onFacilityFilterChange = (filter) => {
    this._remoteFetchRows({}, filter, {
      filter: { ...filter },
      addModalData: _.omit(filter, "filterType"),
    });
  };

  // return a human readable name to identity a row
  _getEntityName = (data) => {
    if (this.props.entityNameField) {
      return data[this.props.entityNameField];
    } else {
      return data ? data.id : null;
    }
  };

  //remote fetch the data:
  _remoteFetchRows(reqPayload, filter, newState) {
    const { showToast } = this.context
    newState.data = [];
    newState.totalDataSize = 0;
    if (!filter) {
      filter = this.state.filter;
    }
    if (filter) {
      if (filter.filterType === "System") {
        // no id needed
      } else if (filter.filterType === "Enterprise") {
        if (filter.enterpriseId) {
          reqPayload.enterpriseId = filter.enterpriseId;
          if (this.props.urlName === "groupPolicy") {
            this.props.onHandleEnterpriseChange(filter.enterpriseId);
          }
        } else {
          if (this.props.urlName === "groupPolicy") {
            this.props.onHandleEnterpriseChange(null);
          }
          this.setState(newState);
          return;
        }
      } else if (filter.filterType === "Venue") {
        if (filter.venueId) {
          reqPayload.venueId = filter.venueId;
        } else {
          this.setState(newState);
          return;
        }
      } else if (filter.filterType === "Building") {
        if (filter.buildingId) {
          reqPayload.buildingId = filter.buildingId;
        } else {
          this.setState(newState);
          return;
        }
      } else if (filter.filterType === "Floor") {
        if (filter.floorId) {
          reqPayload.floorId = filter.floorId;
        } else {
          this.setState(newState);
          return;
        }
      } else if (filter.filterType === "Room") {
        if (filter.roomId) {
          reqPayload.roomId = filter.roomId;
        } else {
          this.setState(newState);
          return;
        }
      } else {
        // filter either with unknown type or the filter id is not ready yet
        // skip refresh
        this.setState(newState);
        return;
      }
    } else {
      this.setState(newState);
      return;
    }

    // set the sort data in the payload if not specified
    // if (reqPayload.sortName === undefined && this.state.sortName) {
    if (newState.sortName !== undefined) {
      reqPayload.orderBy = newState.sortName;
    }

    if (newState.sortOrder !== undefined) {
      reqPayload.ascendingOrder = newState.sortOrder === "asc";
    }
    // set the pagination in the payload if not specified
    if (reqPayload.page === undefined) {
      reqPayload.page = this.state.currentPage - 1;
    }
    if (reqPayload.pageSize === undefined) {
      reqPayload.pageSize = this.state.sizePerPage;
    }

    if (newState.showEnabledOnly !== undefined) {
      if (newState.showEnabledOnly) {
        reqPayload.enabled = true;
      }
    } else if (this.state.showEnabledOnly !== undefined) {
      if (this.state.showEnabledOnly) {
        reqPayload.enabled = true;
      }
    }

    //    console.log('BaseTable._remoteFetchRows() AJAX sent')
    //fetch Group Policy
    if (this.props.urlEntity === "groupPolicy") {
      ApiSingleton.makeHttpRequest(
        "post",
        this.urlPrefix + "/find",
        { dataType: "json" },
        reqPayload
      )
        .then((data) => {
          newState.data = data;
          this.setState(newState);
        })
        .catch((err) => {
          this.setState(newState);
          showToast(
            "Failed to fetch list of " + this.props.urlEntity + "! Reason: " +
            err
          );
        });
    } else if (this.props.urlEntity === "senior") {
      ApiSingleton.makeHttpRequest(
        "post",
        this.urlPrefix + "findWithPage",
        { dataType: "json" },
        reqPayload
      )
        .then((data) => {

          // const seniors = data.list;
          // for (let i = 0; i < seniors.length; i++) {
          //   if (seniors[i].groupPolicyId !== null) {
          //   seniors[i].groupPolicy = this._fetchGroupPolicy(
          //     seniors[i].groupPolicyId
          //   );
          // }
          // }

          // const xx = this._fetchGroupPolicy(
          //   "9cc31b1f-ca1e-48c4-a596-ea679b88cd2e"
          // );

          newState.data = data.list;
          newState.totalDataSize = data.totalCount;
          this.setState(newState);
        })
        .catch((err) => {
          this.setState(newState);
          showToast(
            "Failed to fetch list of " + this.props.urlEntity + "! Reason: " +
            err
          );
        });
    } else if (this.props.urlEntity === "temperature") {
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/senior/findWithPage",
        { dataType: "json" },
        reqPayload
      )
        .then((data) => {
          const seniors = data.list;
          for (let i = 0; i < seniors.length; i++) {
            seniors[i].tempList = this._fetchTemperature(seniors[i].id);
          }
          newState.data = data.list;
          newState.totalDataSize = data.totalCount;
          this.setState(newState);
        })
        .catch((err) => {
          this.setState(newState);
        });
    } else if (this.props.urlEntity === "ECG") {
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/senior/findWithPage",
        { dataType: "json" },
        reqPayload
      )
        .then((data) => {
          const seniors = data.list;
          for (let i = 0; i < seniors.length; i++) {
            seniors[i].ecgList = this._fetchECG(seniors[i].id);
          }
          newState.data = data.list;
          newState.totalDataSize = data.totalCount;
          this.setState(newState);
        })
        .catch((err) => {
          this.setState(newState);
        });
    } else if (this.props.urlEntity && this.props.urlEntity !== "sensorAlert") {
      ApiSingleton.makeHttpRequest(
        "post",
        this.urlPrefix + "findWithPage",
        { dataType: "json" },
        reqPayload
      )
        .then((data) => {
          newState.data = data.list;
          newState.totalDataSize = data.totalCount;
          this.setState(newState);
        })
        .catch((err) => {
          this.setState(newState);
          showToast(
            "Failed to fetch list of " + this.props.urlEntity + "! Reason: " +
            err
          );
        });
    }
  }

  _fetchGroupPolicy(id) {
    let groupPolicy = "";
    if (id) {
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/groupPolicy/findById",
        { dataType: "json", id },
        postData
      )
        .then((data) => {
          groupPolicy = data.name;
        })
        .catch((err) => {
          console.log(
            "can not fetch group policy for the senior with groupPolicyId: " +
            id +
            " Reason: ",
            err
          );
          groupPolicy = "";
        });
    }
    return groupPolicy;
  }

  _remoteAddRow(row, successCallback, errorCallback) {
    const { showToast } = this.context
    if (this.props.urlEntity) {
      ApiSingleton.makeHttpRequest(
        "post",
        this.urlPrefix + "create",
        { dataType: "json" },
        row
      )
        .then((data) => {
          if (successCallback) {
            successCallback(data);
          }
          this.context.notifySuccess(
            this.props.urlEntity +
            " (" +
            this._getEntityName(row) +
            ") was added successfully!"
          );
          this.onRefresh();
        })
        .catch((err) => {
          console.error(err);
          if (errorCallback) {
            errorCallback(err);
          }
          showToast(
            "Error adding " +
            this.props.urlEntity +
            " (" +
            this._getEntityName(row) +
            " )! Reason: " +
            err
          );
        });
    }
  }

  _remoteUpdateRow(row, successCallback, errorCallback) {
    const { showToast } = this.context
    ApiSingleton.makeHttpRequest(
      "post",
      this.urlPrefix +
      (this.props.updateUrl ? this.props.updateUrl : "update"),
      { dataType: "json" },
      row
    )
      .then((data) => {
        if (successCallback) {
          successCallback(data);
        }
        this.context.notifySuccess(
          this.props.urlEntity +
          " (" +
          this._getEntityName(row) +
          ") was updated successfully!"
        );
        this.onRefresh();
      })
      .catch((err) => {
        if (errorCallback) {
          errorCallback(err);
        }
        showToast(
          "Failed to update " +
          this.props.urlEntity +
          " (" +
          this._getEntityName(row) +
          " )! Reason: " +
          err
        );
      });
  }

  _remoteDeleteRow(row, successCallback, errorCallback) {
    const { showToast } = this.context
    ApiSingleton.makeHttpRequest(
      "post",
      this.urlPrefix + "delete",
      { dataType: "json", id: row.id },
      postData
    )
      .then((data) => {
        if (successCallback) {
          successCallback();
        }
        this.context.notifySuccess(
          this.props.urlEntity +
          " (" +
          this._getEntityName(row) +
          " ) was deleted successfully!"
        );
        this.onRefresh();
      })
      .catch((err) => {
        console.error(err);
        if (errorCallback) {
          errorCallback(err);
        }
        showToast(
          "Failed to delete " +
          this.props.urlEntity +
          " (" +
          this._getEntityName(row) +
          " )! Reason: " +
          err
        );
      });
  }

  render() {
    const props = this.props;
    const state = this.state;
    let addConfig = props.addConfig ? { ...props.addConfig } : {};
    addConfig = Object.assign(addConfig, {
      show: state.isAddModalShown,
      data: state.addModalData,
      onClose: this.onAddModalClose,
      onSubmit: this.onAddModalSubmit,
    });

    let updateConfig = props.updateConfig ? { ...props.updateConfig } : {};
    updateConfig = Object.assign(updateConfig, {
      show: state.isUpdateModalShown,
      data: updateConfig.defaultData
        ? _.assign(_.cloneDeep(state.selectedRow), updateConfig.defaultData)
        : _.cloneDeep(state.selectedRow),
      onClose: this.onUpdateModalClose,
      onSubmit: this.onUpdateModalSubmit,
    });

    let detailConfig = props.detailConfig ? { ...props.detailConfig } : {};
    detailConfig = Object.assign(detailConfig, {
      show: state.isDetailModalShown,
      data: detailConfig.defaultData
        ? _.assign(_.cloneDeep(state.selectedRow), detailConfig.defaultData)
        : _.cloneDeep(state.selectedRow),
      onClose: this.onDetailModalClose,
      onSubmit: this.onDetailModalClose,
    });

    let deleteConfig = props.deleteConfig ? { ...props.deleteConfig } : {};
    deleteConfig = Object.assign(deleteConfig, {
      show: state.isConfirmDeleteShown,
      data: state.selectedRow,
      onClose: this.onConfirmDeleteClose,
      onSubmit: this.onConfirmDeleteConfirm,
    });

    let data = this.props.sortedData ? this.props.sortedData : this.state.data;
    if (this.state.query && this.state.query !== "") {
      const query = this.state.query.toLowerCase();
      data = data.filter((row) => {
        return (
          (row.userName && row.userName.toLowerCase().includes(query)) ||
          (row.firstName && row.firstName.toLowerCase().includes(query)) ||
          (row.lastName && row.lastName.toLowerCase().includes(query)) ||
          (row.email && String(row.email).toLowerCase().includes(query)) ||
          (row.station &&
            row.station.name &&
            row.station.name.toLowerCase().includes(query)) ||
          (row.building &&
            row.building.name &&
            row.building.name.toLowerCase().includes(query)) ||
          (row.floor &&
            row.floor.name &&
            row.floor.name.toLowerCase().includes(query)) ||
          (row.room &&
            row.room.name &&
            row.room.name.toLowerCase().includes(query)) ||
          (row.venue &&
            row.venue.name &&
            row.venue.name.toLowerCase().includes(query)) ||
          (row.enterprise &&
            row.enterprise.name &&
            row.enterprise.name.toLowerCase().includes(query)) ||
          (row.sensorList &&
            row.sensorList.length >= 1 &&
            row.sensorList[0].name &&
            row.sensorList[0].name.toLowerCase().includes(query)) ||
          (row.name && row.name.toLowerCase().includes(query)) ||
          (row.description && row.description.toLowerCase().includes(query)) ||
          (row.groupPolicy && row.groupPolicy.toLowerCase().includes(query)) ||
          (row.mac && row.mac.toLowerCase().includes(query)) ||
          (row.fwVersion &&
            String(row.fwVersion).toLowerCase().includes(query)) ||
          (row.ipv4Address &&
            String(row.ipv4Address).toLowerCase().includes(query)) ||
          (row.bleVersion &&
            String(row.bleVersion).toLowerCase().includes(query)) ||
          (row.mcuVersion &&
            String(row.mcuVersion).toLowerCase().includes(query)) ||
          (row.batteryLevel &&
            String(row.batteryLevel).toLowerCase().includes(query)) ||
          (row.senior &&
            (row.senior.firstName + " " + row.senior.lastName)
              .toLowerCase()
              .includes(query)) ||
          (row.lastSeenUtc &&
            (
              moment(row.lastSeenUtc).local().format("YYYY/MM/DD HH:mm:ss") +
              " (" +
              String(moment(row.lastSeenUtc).toNow(true)) +
              " ago)"
            )
              .toLowerCase()
              .includes(query))
        );
      });
    }

    return (
      <div>
        {(() => {
          if (props.facilityFilter) {
            return (
              <FacilitySelector
                onChange={this._onFacilityFilterChange}
                filterLevel={props.facilityFilterLevel}
                filterLabel="Filter By"
                setIds={this.setIds}
              />
            );
          }
        })()}
        <BaseTableToolBar
          entityName={props.urlEntity}
          actions={props.actions}
          selectAction={props.selectAction}
          selectedRow={state.selectedRow}
          showEnabledOnly={state.showEnabledOnly}
          onRefresh={this.onRefresh}
          onAdd={this.onAdd}
          onUpdate={this.onUpdate}
          onDetail={this.onDetail}
          onDelete={this.onDelete}
          onClickShowEnabledOnly={this.onClickShowEnabledOnly}
          addConfig={addConfig}
          updateConfig={updateConfig}
          detailConfig={detailConfig}
          deleteConfig={deleteConfig}
          handleClear={this.handleClear}
          onSearchChange={this.onSearchChange}
          query={this.state.query}
          hideFilterSearchBar={
            props.hideFilterSearchBar ? props.hideFilterSearchBar : false
          }
        />
        <BootstrapTable
          ref="table"
          hover
          height={props.height}
          maxHeight={props.maxHeight}
          striped={props.striped === undefined ? true : props.striped}
          data={data}
          remote={true}
          pagination={props.pagination === undefined ? true : props.pagination}
          selectRow={this.selectRowProp}
          fetchInfo={{
            dataTotalSize: props.totalDataSize
              ? props.totalDataSize
              : state.totalDataSize,
          }}
          expandableRow={props.isExpandableRow}
          expandComponent={props.expandComponent}
          options={
            props.options
              ? props.options
              : {
                onSortChange: this.onSortChange,
                page: state.currentPage,
                sizePerPageList: [10, 25, 50, 100],
                sizePerPage: 10,
                paginationShowsTotal: this.renderShowsTotal,
                onPageChange: this.onPageChange,
                onSizePerPageList: this.onSizePerPageList,
                expandRowBgColor: "#2f4050",
              }
          }
        >
          {props.columnProps.map((column) => {
            return (
              <TableHeaderColumn
                {...column.config}
                key={column.config.dataField}
                width={column.config.width}
              >
                {column.label}
              </TableHeaderColumn>
            );
          })}
        </BootstrapTable>
      </div>
    );
  }
}

export default BaseTable;
