import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../../hooks/useAuth'
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import './TopNav.scss'


var menuShow = true
const toggleMenu = () => {
  if (!menuShow) {
    $('.navbar-static-side').css('width', '220px')
    $('#page-wrapper').css('margin-left', '220px')
    $('#page-wrapper').css('width', '85%')
    menuShow = true
  } else {
    $('.navbar-static-side').css('width', '0px')
    $('#page-wrapper').css('margin-left', '0px')
    $('#page-wrapper').css('width', '100%')
    menuShow = false
  }
}


export const TopNav = (props) => {
  const auth = useAuth()
  return (
  <div className="row border-bottom">
    <nav className="navbar navbar-static-top" role="navigation">
      <div className="navbar-header">
        <a className="navbar-minimalize minimalize-styl-2 btn btn-primary " onClick={toggleMenu}>
          <i className="fa fa-bars"></i>
        </a>
        {/*<form role="search" className="navbar-form-custom" action="search_results.html">*/}
          {/*<div className="form-group">*/}
            {/*<input type="text" placeholder="Search for something..." className="form-control" name="top-search" id="top-search"/>*/}
          {/*</div>*/}
        {/*</form>*/}
      </div>
      <ul className="nav navbar-top-links navbar-right">
        <li>
          <span className="m-r-sm text-muted welcome-message">Welcome {props.username}!</span>
        </li>
        <li role="presentation" className="">
          <a role="button" href="#">
            <button type="submit" className="btn btn-sm btn-primary" onClick={() => auth.logout()}>
              <span className="glyphicon glyphicon-log-out"></span>
              Logout
            </button>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  )
}

export default TopNav
