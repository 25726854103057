import React, { createContext, useEffect, useState, ReactNode } from 'react'
import constants from '../configs/constants'
import ApiSingleton from '../utils/Axios'

export const defaultProvider = {
    user: null,
    loading: true,
    setUser: () => null,
    setLoading: () => Boolean,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(defaultProvider.user)
    const [loading, setLoading] = useState(defaultProvider.loading)
    useEffect(() => {
        const loginPath = '/login'
        const userData = JSON.parse(window.localStorage.getItem(constants.userDataKeyName))
        if(!userData && window.location.pathname !== loginPath){
            window.location.href = '/login'
        }
        if(window.location.pathname !== loginPath){
            ApiSingleton.makeHttpRequest(
                'post',
                '/bstream/api/v1/user/getSessionState',
                { dataType: 'json' },
                {}
            ).then(async (user) => {
            })
            .catch((err) => {
                window.location.href = '/login'
            })
        }

    }, [])
    const handleLogin = (params, errorCallback) => {
        ApiSingleton.makeHttpRequest(
            'post',
            '/bstream/api/v1/user/login',
            { dataType: 'json' },
            params
        ).then(async (userData) => {
            await window.localStorage.setItem(constants.userDataKeyName, JSON.stringify(userData))
            setUser(userData)
            if(userData.isAdmin){
                // route to admin dashboard
                window.location.href = '/dashboard2'
            }
            else if(userData.isCareGiver){
                // route to care giver dashboard
                window.location.href = '/dashboardCaregiver'
            }
        }).catch(err => {
            if (errorCallback) errorCallback(err)
            window.localStorage.removeItem(constants.userDataKeyName)
            window.localStorage.removeItem(constants.storageTokenKeyName)
            window.location.href = '/login'
        })
    }

    const handleLogout = () => {
        setUser(null)
        ApiSingleton.makeHttpRequest(
            'post',
            '/bstream/api/v1/user/logout',
            { dataType: 'json' },
            {}
        ).then((response) => {
            localStorage.removeItem(constants.userDataKeyName)
            window.location.href = '/login'
        })
        .catch(err => {
            window.localStorage.removeItem(constants.userDataKeyName)
            console.log('error signing out', err)
            window.location.href = '/login'
        })
    }

    const values = {
        user,
        loading,
        setUser,
        setLoading,
        login: handleLogin,
        logout: handleLogout,
    }
    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
