import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import SensorLocationTable from "./SensorLocationTable";
import SensorRssiTable from "./SensorRssiTable";
import SensorAlertTable from "./SensorAlertTable";
import ClosedSensorAlertTable from "./ClosedSensorAlertTable";
import _ from "lodash";
import moment from "moment";
import { ToastContext } from '../../context/ToastContext'

class BuildingDashboard extends React.Component{
    static contextType = ToastContext
    constructor(props) {
        super(props);
        this._fetchSensorLocations = this._fetchSensorLocations.bind(this)
        this._fetchSensorRssis = this._fetchSensorRssis.bind(this)
        this._fetchSensorAlerts = this._fetchSensorAlerts.bind(this)
        this._transformSensorLatestLocation = this._transformSensorLatestLocation.bind(this)
        this._refreshResume = this._refreshResume.bind(this)
        this._refreshSuspend = this._refreshSuspend.bind(this)
        this._transformSensorAlert = this._transformSensorAlert.bind(this)
        this._transformSensorAlertNoteList = this._transformSensorAlertNoteList.bind(this)
        this._handleSensorLocationRowSelect = this._handleSensorLocationRowSelect.bind(this)
        this._handleSensorRssiRowSelect = this._handleSensorRssiRowSelect.bind(this)
        this._handleSensorAlertRowSelect = this._handleSensorAlertRowSelect.bind(this)

        this.state ={
            activeTab: 'sensorLocation',
            batteryLevel: null,
            sensorLocations: [],
            sensorRssis: [],
            sensorAlerts: [],
            timer: null,
            buildingId: null,
            summary: {
                relayCount: '',
                sensorCount: '',
                sensorAlerts: null
            },
        }

    }
    _refreshResume = () => {
        if (!this.state.timer) {
            var timer = setInterval(this._refresh_periodically, 10000)
            this.setState({timer:timer})
        }
    }


    _refreshSuspend = () => {
        if (this.state.timer) {
            clearInterval(this.state.timer)
            this.setState({timer:null})
        }
    }

    componentDidMount() {
        // refresh every 10 second
        this._refreshResume()
    }

    componentWillUnmount() {
        //clearInterval(this.timer)
        this._refreshSuspend()
    }

    componentWillReceiveProps(nextProps) {
        const buildingId = nextProps.buildingId
        if (buildingId && buildingId !== this.props.buildingId) {
            this._refresh(true, buildingId)
        }
    }

    _refresh_periodically = () => {
        this._refresh(true)
    }

    _refresh = (forceload, currentBuildingId, newState) => {
        const state = this.state
        const buildingId = currentBuildingId ? currentBuildingId : this.props.buildingId
        if (!buildingId) {
            return
        }
        let activeTab
        if (newState && newState.activeTab) {
            activeTab = newState.activeTab
        } else {
            activeTab = state.activeTab
        }
        if (newState) {
            this.setState(newState)
        }

        // always refresh the alerts to make sure the totalAlerts count is correct at whole time
        this._fetchSensorAlerts(false, buildingId)

       if (activeTab === 'sensorLocation') {
            if (forceload || !state.expandedSensorLocation) {
                // avoid refresh when any row is expanded
                this._fetchSensorLocations(buildingId)
            }
        } else if (activeTab === 'sensorRssi') {
            if (forceload || !state.expandedSensorRssi) {
                // avoid refresh when any row is expanded
                this._fetchSensorRssis(buildingId)
            }
        } else if (activeTab === 'sensorAlert') {
            //if (forceload || !state.expandedSensorAlert) {
            // avoid refresh when any row is expanded
            //  this._fetchSensorAlerts(false, floorId)
            //}
        } else if (activeTab === 'sensorAlertClosed') {
            if (forceload || !state.expandedSensorAlertClosed) {
                // avoid refresh when any row is expanded
                this._fetchSensorAlerts(true, buildingId)
            }
        }
    }
    _handleSensorLocationRowSelect = (row, isSelected) => {
        this.setState({expandedSensorLocation : isSelected ?  row :  null})
    }

    _handleSensorRssiRowSelect = (row, isSelected) => {
        this.setState({expandedSensorRssi : isSelected ?  row :  null})
    }

    _handleSensorAlertRowSelect = (row, isSelected) => {
        this.setState({expandedSensorAlert : isSelected ?  row :  null})
    }
    _transformSensorAlert(v) {
        let closedUtc
        if (v.closedUtc) {
            const createUtc = moment.utc(v.createUtc)
            closedUtc = moment.utc(v.closedUtc)
            const duration = closedUtc.to(createUtc, true)
            closedUtc = closedUtc.local().format('YYYY/MM/DD HH:mm:ss') + ' (closed in ' + duration + ')'
        } else {
            closedUtc = 'NA'
        }
        const alert = {
            id: v.id,
            description: v.description,
            alertType: v.alertType,
            alertStatus: v.alertStatus,
            createUtc: moment.utc(v.createUtc),
            closedUtc: closedUtc,
            lastUpdateUtc: v.lastUpdateUtc ? moment.utc(v.lastUpdateUtc) : null,
            sensorId: v.sensorId,
            sensorName: v.sensor ? v.sensor.name : 'unknown',
            sensorX: (v.sensor && v.sensor.x) ? v.sensor.x : 0,
            sensorY: (v.sensor && v.sensor.y) ? v.sensor.y : 0,
            seniorName: v.senior ? v.senior.firstName + ' ' + v.senior.lastName: 'unknown',
            roomName: v.room ? v.room.name : 'unknown',
            sensorAlertNoteList: this._transformSensorAlertNoteList(v.sensorAlertNoteList)
        }

        return alert
    }

    _transformSensorAlertNoteList(list) {
        return list.map((v) => {
            if (v.adminId) {
                v.who = v.admin.userName
            } else if (v.careGiverId) {
                v.who = v.careGiver.userName
            }
            return v
        })
    }

    _fetchSensorLocations(buildingId) {
        const { showToast } = this.context
        if (this.state.sensorLocationsLoading) {
            return
        }
        this.setState({sensorLocationsLoading: true})
        const select = [
            'createUtc',
            'relayId',
            'sensorId',
            'sensor',
            'range',
            'senior:firstName',
            'senior:lastName',
            'room:name',
            'relay:name',
            'relay:mac']

        $.post({
            url: '/bstream/api/v1/sensorLocation/findSensorLatestLocationByBuildingId',
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            data: { buildingId: buildingId, select:JSON.stringify(select) },
            dataType: 'json'
        }).then((data) => {
            const now = Date.now()
            data = _.reduce(data, (o, v, k) => {
                // sort record based on relayId and sensorId pair
                const key = v.sensorId
                if (!o[key]) {
                    o[key] = []
                }
                o[key].push(this._transformSensorLatestLocation(v))
                // console.log('testing222', this._transformSensorLatestLocation(v))
                return o
            }, {})
            data = _.reduce(data, (o, v, k) => {
                const v1 = _.reverse(_.sortBy(v, 'createUtc'))
                const v2 =  v1[0]
                v2.items = v1
                o.push(v2)
                return o
            }, [])
            data = _.sortBy(data, 'createUtc')
            this.setState({
                sensorLocations: data,
                sensorLocationsLoading: false
            })
        }, (err) => {
            showToast('Failed to fetch list of RockBand location by floor! Reason: ' + err)
            this.setState({
                sensorLocations: null,
                sensorLocationsLoading: false
            })
        })
    }

    _fetchSensorRssis(buildingId) {
        const { showToast } = this.context
        if (this.state.sensorRssisLoading) {
            return
        }
        this.setState({sensorRssisLoading: true})
        $.post({
            url: '/bstream/api/v1/sensorRssi/findSensorLatestRssiByBuildingId',
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            data: { buildingId: buildingId },
            dataType: 'json'
        }).then((data) => {
            const now = Date.now()
            data = _.reduce(data, (o, v, k) => {
                // sort record based on relayId and sensorId pair
                const key = v.sensorId
                if (!o[key]) {
                    o[key] = []
                }
                o[key].push({
                    createUtc: moment.utc(v.createUtc),
                    relayId: v.relayId,
                    sensorId: v.sensorId,
                    sensorName: v.sensor ? v.sensor.name : 'unknown',
                    sensorMac: v.sensor ? v.sensor.mac : 'unknown',
                    sensorRssi: v.rssi,
                    seniorName: v.senior ? v.senior.firstName + ' ' + v.senior.lastName: 'unknown',
                    roomName: v.room ? v.room.name : 'unknown',
                    relayName: v.relay ? v.relay.name : 'unknown',
                    relayMac: v.relay ? v.relay.mac : 'unknown',
                    batteryLevel: v.sensor ? v.sensor.batteryLevel : 'unknown'
                })
                return o
            }, {})
            data = _.reduce(data, (o, v, k) => {
                const v1 = _.reverse(_.sortBy(v, 'createUtc'))
                const v2 =  v1[0]
                v2.items = v1
                o.push(v2)
                return o
            }, [])

            data = _.sortBy(data, 'createUtc')
            this.setState({
                sensorRssis: data,
                sensorRssisLoading: false
            })
        }, (err) => {
            showToast('Failed to fetch list of RockBand location by floor! Reason: ' + err)
            this.setState({
                sensorRssis: null,
                sensorRssisLoading: false
            })
        })
    }

    _transformSensorLatestLocation(v, alertSummary) {
        const sensor = {
            createUtc: moment.utc(v.createUtc),
            relayId: v.relayId,
            sensorId: v.sensorId,
            sensorName: v.sensor ? v.sensor.name : 'unknown',
            sensorMac: v.sensor ? v.sensor.mac : 'unknown',
            sensorX: (v.sensor && v.sensor.x) ? v.sensor.x : 0,
            sensorY: (v.sensor && v.sensor.y) ? v.sensor.y : 0,
            sensorRange: v.range,
            senior: v.senior ? v.senior : {},
            seniorName: v.senior ? v.senior.firstName + ' ' + v.senior.lastName: 'unknown',
            roomName: v.room ? v.room.name : 'unknown',
            relayName: v.relay ? v.relay.name : 'unknown',
            relayMac: v.relay ? v.relay.mac : 'unknown',
            alerts: []
        }
        if (v.sensor.highImpactAlertTriggered) {
            sensor.alerts.push({type:'HIGH_IMPACT', when:v.sensor.highImpactAlertUtc})
            if (alertSummary) alertSummary.highImpactAlertCount ++
        }
        if (v.sensor.sosAlertTriggered) {
            sensor.alerts.push({type:'SOS', when:v.sensor.sosAlertUtc})
            if (alertSummary) alertSummary.sosAlertCount ++
        }
        if (v.sensor.powerOffAlertTriggered) {
            sensor.alerts.push({type:'POWER_OFF', when:v.sensor.powerOffAlertUtc})
            if (alertSummary) alertSummary.powerOffAlertCount ++
        }
        if (v.sensor.lowBatteryAlertTriggered) {
            sensor.alerts.push({type:'LOW_BATTERY', when:v.sensor.lowBatteryAlertUtc})
            if (alertSummary) alertSummary.lowBatteryAlertCount ++
        }
        if (v.sensor.breakAwayAlertTriggered) {
            sensor.alerts.push({type:'BREAK_AWAY', when:v.sensor.breakAwayAlertUtc})
            if (alertSummary) alertSummary.breakAwayAlertCount ++
        }
        if (v.sensor.missingAlertTriggered) {
            sensor.alerts.push({type:'MISSING', when:v.sensor.lastSeenUtc})
            if (alertSummary) alertSummary.missingAlertCount ++
        }
        if (v.sensor.approachingAlertTriggered) {
            sensor.alerts.push({type:'APPROACHING', when:v.sensor.lastSeenUtc})
            if (alertSummary) alertSummary.approachingAlertCount ++
        }
        if (v.sensor.leavingAlertTriggered) {
            sensor.alerts.push({type:'LEAVING', when:v.sensor.lastSeenUtc})
            if (alertSummary) alertSummary.leavingAlertCount ++
        }
        if (v.sensor.exitDoorAlertTriggered) {
            sensor.alerts.push({type:'EXIT_DOOR', when:v.sensor.lastSeenUtc})
            if (alertSummary) alertSummary.exitDoorAlertCount ++
        }
        // TODO: temporary workaround for lacking x and y from backend
        if (sensor.sensorX === 0 && sensor.sensorY === 0) {
            sensor.sensorX = v.relay.x + 30
            sensor.sensorY = v.relay.y + 30
            //sensor.alerts.push('powerOffAlertTriggered')
        }
        return sensor
    }

    _fetchSensorAlerts = (closed, buildingId) => {
        if (!buildingId) {
            return
        }
        if (!closed && this.state.sensorAlertsLoading) {
            return
        } else if (closed && this.state.sensorAlertsClosedLoading) {
            return
        }

        const select = [
            'id',
            'description',
            'alertType',
            'alertStatus',
            'createUtc',
            'closedUtc',
            'lastUpdateUtc',
            'sensorId',
            'sensor:name',
            'sensor:x',
            'sensor:y',
            'senior:firstName',
            'senior:lastName',
            'room:name',
            'sensorAlertNoteList']

        const postData = (() => {
            if (!closed) {
                this.setState({sensorAlertsLoading: true})
                return {buildingId: buildingId, noAlertStatus: "DONE", select:JSON.stringify(select), maxResults:100}
            } else {
                this.setState({sensorAlertsClosedLoading: true})
                return {buildingId: buildingId, alertStatus: "DONE", select:JSON.stringify(select), maxResults:100}
            }
        })()

        $.post({
            url: '/bstream/api/v1/sensorAlert/findWithSelect',
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            data: postData,
            dataType: 'json'
        }).then((data) => {
            const now = Date.now()
            data = _.reduce(data, (o, v, k) => {
                // sort record based on relayId and sensorId pair
                o.push(this._transformSensorAlert(v))
                return o
            }, [])

            let closedData = _.remove(data, (item) => {
                return item.alertStatus === 'DONE'
            })
            if (!closed) {
                data = _.reverse(_.sortBy(data, 'createUtc'))
            } else {
                closedData = _.reverse(_.sortBy(closedData, 'createUtc'))
            }
            if (!closed) {
                this.setState({
                    sensorAlerts: data,
                    sensorAlertsLoading: false
                })
            }else {
                this.setState({
                    sensorAlertsClosed: closedData,
                    sensorAlertsClosedLoading: false
                })
            }
        }, (err) => {
            const { showToast } = this.context
            showToast('Failed to fetch list of RockBand Alerts by floor! Reason: ' + err)
            if (!closed) {
                this.setState({
                    sensorAlerts: null,
                    sensorAlertsLoading: false
                })
            } else {
                this.setState({
                    sensorAlertsClosed: null,
                    sensorAlertsClosedLoading: false
                })
            }
        })
    }
    _handleTabSelect = (key) => {
        this.setState({stations: []})
        this._refresh(true, this.props.buildingId, {activeTab: key})
    }
    _getTabTitleWithLoadingIcon = (title, stateParams) => {
        let loading = false
        stateParams.forEach((stateParam) => {
            if (this.state[stateParam]) {
                loading = true
            }
        })
        if (loading) {
            return <div>{title}<img src="/assets/ajax-loader.gif" style={{marginLeft: '15px'}}/></div>
        } else if (title === 'RockBand Alerts (Active)') {
            const totalAlerts = this.state.sensorAlerts ? this.state.sensorAlerts.length : 0
            if (this.state.activeTab === 'sensorAlert') {
                return <span>{title} <span className="badge" style={{backgroundColor: '#b94a48', color: '#ffffff'}}>{totalAlerts}</span></span>
            } else {
                return <span>{title} <span className="badge" style={{backgroundColor: '#b94a48'}}>{totalAlerts}</span></span>
            }
        } else {
            return title
        }
    }
    render() {
        if(this.props.buildingId && !this.state.buildingId) {
            this._fetchSensorLocations(this.props.buildingId);
            this.setState({buildingId: this.props.buildingId})
        }
        return (
            <Tabs defaultActiveKey={'sensorLocation'} onSelect={this._handleTabSelect} id='building-dashboard' >
                <Tab eventKey={'sensorLocation'}
                     title={this._getTabTitleWithLoadingIcon('RockBand Locations', ['sensorLocationsLoading'])} >
                    <br/>
                    <SensorLocationTable
                        data={ this.state.sensorLocations }
                        handleRowSelect={this._handleSensorLocationRowSelect}
                    />
                </Tab>
                <Tab eventKey={'sensorRssi'}
                     title={this._getTabTitleWithLoadingIcon('RockBand RSSIs', ['sensorRssisLoading'])} >
                    <br/>
                    <SensorRssiTable
                        data={ this.state.sensorRssis }
                        handleRowSelect={this._handleSensorRssiRowSelect}
                    />
                </Tab>
                <Tab eventKey={'sensorAlert'}
                     title={this._getTabTitleWithLoadingIcon('RockBand Alerts (Active)', ['sensorAlertsLoading'])} >
                    <br/>
                    {/*{console.log('testing', state.sensorAlerts)}*/}
                    <SensorAlertTable
                        data={ this.state.sensorAlerts }
                        handleRowSelect={this._handleSensorAlertRowSelect}
                        refresh={this._fetchSensorAlerts.bind(this, false, this.props.buildingId)}
                        refreshResume={this._refreshResume}
                        refreshSuspend={this._refreshSuspend}
                    />
                </Tab>
                <Tab eventKey={'sensorAlertClosed'}
                     title={this._getTabTitleWithLoadingIcon('RockBand Alerts (Closed)', ['sensorAlertsClosedLoading'])} >
                    <br/>
                    <ClosedSensorAlertTable
                        data={ this.state.sensorAlertsClosed }
                        handleRowSelect={this._handleSensorAlertRowSelect}
                        refresh={this._fetchSensorAlerts.bind(this, true, this.props.buildingId)}
                    />
                </Tab>
            </Tabs>
        )
    }
}
export default BuildingDashboard