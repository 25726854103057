import React, { Suspense } from "react";
import { Navbar } from "react-bootstrap";
import MetisMenu from "react-metismenu";
import { Link, useMatch } from "react-router-dom";

import "./Menu.scss";
import SideBar from "./SIdebar/Accordion";
import { IconDashboard } from "@tabler/icons-react";
// import "react-metismenu/dist/react-metismenu-standart.min.css";

// icon is defined here:
// https://fontawesome.com/v4.7.0/icons/

const adminMenus = [
  {
    icon: "fa-home",
    label: "Dashboards",
    to: "dashboard2",
  },
  // {
  //   icon: "fa-building",
  //   label: "Facilities",
  //   to: "facilities",
  // },
  // {
  //   icon: "fa-users",
  //   label: "People",
  //   content: [
  //     {
  //       icon: "fa-user-circle",
  //       label: "Susbscribers",
  //       to: "subscribers",
  //     },
  //     {
  //       icon: "fa-user-md",
  //       label: "Care Givers",
  //       to: "caregivers",
  //     },
  //     {
  //       icon: "fa-users",
  //       label: "Residents",
  //       to: "seniors",
  //     },
  //     {
  //       icon: "fa-user",
  //       label: "Administrators",
  //       to: "admins",
  //     },
  //     {
  //       icon: "fa-user-circle",
  //       label: "Stations",
  //       to: "stations",
  //     },
  //   ],
  // },
  // {
  //   icon: "fa-server",
  //   label: "Devices",
  //   content: [
  //     {
  //       icon: "fa-cube",
  //       label: "RockBoxes",
  //       to: "relays",
  //     },
  //     {
  //       icon: "fa-heartbeat",
  //       label: "RockBands",
  //       to: "bands",
  //     },
  //   ],
  // },
  {
    icon: "fa-bar-chart",
    label: "User Data",
    content: [
      {
        icon: "fa-bolt",
        label: "Aggregate Display",
        to: "aggregateDisplay",
      },
      {
        icon: "fa-bolt",
        label: "User List", //Daily Energy
        to: "userList", //dailyEnergy
      },
      // {
      //   icon: "fa-bolt",
      //   label: "Data Consistency",
      //   to: "allPatients", // TODO CHANGE TO DATACONSISTENCY
      // },
      // {
      //   icon: "fa-bar-chart",
      //   label: "Step Count",
      //   to: "stepCount",
      // },
    ],
  },
  {
    icon: "fa-bolt",
    label: "Data Consistency",
    to: "allPatients",
  },

  // {
  //   icon: "fa-area-chart",
  //   label: "Other Sensors",
  //   content: [
  //     {
  //       icon: "fa-thermometer",
  //       label: "Temperature",
  //       to: "temperature",
  //     },

  //     {
  //       icon: "fa-heartbeat",
  //       label: "Heart Rate",
  //       to: "ECG",
  //     },
  //   ],
  // },

  // {
  //   icon: "fa-user-plus",
  //   label: "Policy",
  //   content: [
  //     {
  //       icon: "fa-cogs",
  //       label: "Group Policy",
  //       to: "GroupPolicy",
  //     },
  //   ],
  // },

  // {
  //   icon: "fa-bar-chart",
  //   label: "Reports",
  //   content: [
  //     {
  //       icon: "fa-calendar",
  //       label: "Residents Activities",
  //       to: "SeniorIndividualReports",
  //     },
  //     {
  //       icon: "fa-calendar",
  //       label: "Summary Activities",
  //       to: "SeniorSummaryReports",
  //     },
  //   ],
  // },

  // {
  //   icon: "fa-bar-chart",
  //   label: "Questionnaire",
  //   content: [
  //     {
  //       icon: "fa-bar-chart",
  //       label: "Questionnaire",
  //       to: "Questionnaire",
  //     },
  //   ],
  // },
];

const caregiverMenus = [
  {
    icon: "fa-dashboard",
    label: "Dashboards",
    to: "dashboardCaregiver",
  },

  {
    icon: "fa-users",
    label: "People",
    content: [
      {
        icon: "fa-users",
        label: "Residents",
        to: "seniorsCaregiver",
      },
    ],
  },
];

const Menu = (props) => {
  const { location } = props;
  const match = useMatch(location.pathname);
  const menus = props.login.isAdmin ? adminMenus : caregiverMenus;
  const renderLink = (linkProps) => {
    return <Link to={linkProps.to}>{linkProps.label}</Link>;
  };

  return (
    <nav
      className="navbar-default navbar-static-side"
      role="navigation"
      style={{ margin: 0, padding: 0 }}
    >
      <div className="sidebar-collapse" >
        <div>
          <a href="https://aicare.co" target="_blank">
            <img
              title="AiCare"
              alt="AiCare"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                padding: "0px 10px",
              }}
              src="/assets/aicare_logo.png"
            />
          </a>
        </div>
        {/* <MetisMenu content={menus} LinkComponent={renderLink} activeLinkFromLocation={match.url}/> */}
        <div style={{height:'100%'}}>
          <SideBar
            content={menus}
            LinkComponent={renderLink}
            activeLinkFromLocation={match.url}
          />
        </div>
      </div>
    </nav>
  );
};

const MenuWithSuspense = (props) => (
  <Suspense fallback={<div>Loading menu...</div>}>
    <Menu {...props} />
  </Suspense>
);

export default MenuWithSuspense;
