import React from 'react'
import BaseTable from '../../components/BaseTable'
import {enabledFormatter } from '../../components/BaseTable/CellFormatter'
import {MatchingPasswordRequired, MatchingPasswordAllowUndefined, StripConfirmPassword, StripEmptyAndConfirmPassword} from '../../components/BaseFormModal/Utils'
import { peopleFormatter } from '../../components/BaseTable/CellFormatter'
import _ from 'lodash'

class SubscriberList extends React.Component {
    constructor(props) {
        super(props)
        this.handleQuery = this.handleQuery.bind(this)
        this.state = {
            query: ''
        }
    }
    handleQuery(query) {
        this.setState({
            query: query
        })
    }
    render() {
        const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        }, {
            config: {
                dataField: 'userName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'User Name'
        }, {
            config: {
                dataField: 'firstName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'First Name',
        }, {
            config: {
                dataField: 'lastName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Last Name'
        }, {
            config: {
                dataField: 'email',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: peopleFormatter
            },
            label: 'Email'
        }, {
            config: {
                dataField: 'enabled',
                dataSort: true,
                width: '90px',
                dataFormat: enabledFormatter,
            },
            label: 'Enabled'
        }]

        const addModalFields = [{
            controlId: 'userName',
            label: 'User Name',
            type: 'text',
            required: true
        }, {
            controlId: 'firstName',
            label: 'First Name',
            type: 'text',
            required: true
        }, {
            controlId: 'lastName',
            label: 'Last Name',
            type: 'text',
            required: true
        }, {
            controlId: 'password',
            label: 'Password',
            type: 'password',
            required: true
        }, {
            controlId: 'password2',
            label: 'Confirm Password',
            componentClass: 'input',
            type: 'password',
            required: true
        }, {
            controlId: 'email',
            label: 'Email',
            type: 'text',
            required: true
        }, {
            controlId: 'enabled',
            label: 'Enabled',
            type: 'checkbox',
            defaultValue: true
        }, {
            controlId: 'enterpriseId',
            label: 'Enterprise',
            type: 'select',
            urlEntity: 'enterprise',
            labelKey: 'name',
            valueKey: 'id'
        }]

        const updateModalFields = ((fields) => {
            return _.reduce(fields, (o, v, i) => {
                const newv = {...v}
                if (newv.controlId === 'password' || newv.controlId === 'password2') {
                    delete newv.required
                    newv.helpText = 'Leave it empty for no change'
                }
                o.push(newv)
                return o
            }, [])
        })(addModalFields)

        const detailModalFields = ((fields) => {
            return _.reduce(fields, (o, v, i) => {
                const newv = {...v}
                if (newv.controlId !== 'password' && newv.controlId !== 'password2') {
                    o.push(newv)
                }
                return o
            }, [])
        })(addModalFields)

        const addConfig = {
            title: 'Add Subscriber',
            submitText: 'Add',
            cancelText: 'Cancel',
            fields: addModalFields,
            beforeSubmit: StripConfirmPassword,
            formValidator: MatchingPasswordRequired
        }

        const updateConfig = {
            title: 'Edit Subscriber',
            submitText: 'Edit',
            cancelText: 'Cancel',
            fields: updateModalFields,
            beforeSubmit: StripEmptyAndConfirmPassword,
            formValidator: MatchingPasswordAllowUndefined
        }

        const detailConfig = {
            title: 'Subscriber Details',
            fields: detailModalFields
        }

        const deleteConfig = {
            title: 'Delete Subscriber',
            body: 'Are you sure you want to delete the selected subscriber?'
        }
        return (
            <BaseTable
                facilityFilter
                facilityFilterLevel='enterprise'
                urlEntity='subscriber'
                entityNameField='userName'
                columnProps={columnProps}
                addConfig={addConfig}
                updateConfig={updateConfig}
                detailConfig={detailConfig}
                deleteConfig={deleteConfig}
                onHandleQuery={ this.handleQuery }
            />
        )
    }
}

export default SubscriberList
