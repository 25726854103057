/* global $ */
import React from 'react'
import BaseTable from '../../components/BaseTable'
import {enabledFormatter } from '../../components/BaseTable/CellFormatter'
import { peopleFormatter } from '../../components/BaseTable/CellFormatter'
import _ from 'lodash'

class GroupPolicyList extends React.Component {
    constructor(props) {
        super(props)
        this.handleQuery = this.handleQuery.bind(this)
        this.state = {
            query: '',
            enterpriseId: null
        }
    }

    handleQuery(query) {
        this.setState({
            query: query,
        })
    }

    handleEnterpriseChange = (enterpriseId) => {
        this.setState({
            enterpriseId: enterpriseId
        })
    }

    render() {
        const props = this.props
        const columnProps = [{
            config: {
                dataField: 'id',
                isKey: true,
                hidden: true
            },
            label: 'id'
        },

            {
                config: {
                    dataField: 'enterpriseId',
                    hidden: true
                },
                label: 'enterpriseId'
            },

            {
            config: {
                dataField: 'name',
                formatExtraData: this.state.query,
                dataSort: false,
                dataFormat: peopleFormatter
            },
            label: 'Name'

        }, {
            config: {
                dataField: 'description',
                formatExtraData: this.state.query,
                dataSort: false,
                dataFormat: peopleFormatter
            },
            label: 'Description'

        },
            {
                config: {
                    dataField: 'enabled',
                    dataSort: false,
                    dataFormat: enabledFormatter
                },
                label: 'enabled'

            }

        ]

        const addModalFields = [
            {
            controlId: 'name',
            label: 'Name',
            type: 'text',
            required: true
            },
            {
                controlId: 'description',
                label: 'Description',
                type: 'text',
            }, {
                controlId: 'enabled',
                label: 'Enabled',
                type: 'checkbox',
            },
            {
                controlId: 'enterpriseId',
                label: 'Enterprise',
                enterpriseId: this.state.enterpriseId,
                type: 'enterprise'
            }
        ]


        const addConfig = {
            title: 'Add Group Policy',
            submitText: 'Add',
            cancelText: 'Cancel',
            fields: addModalFields,
            beforeSubmit: (data) => {
                if(data.enterprise) {
                    data.enterpriseId = data.enterprise.value
                }
                return _.pick(data, ['enterpriseId', 'name', 'description', 'enabled'])
            }
        }

        const deleteConfig = {
            title: 'Delete Group Policy',
            body: 'Are you sure you want to delete the selected group policy?',
            submitText: 'Delete',
            cancelText: 'Cancel',
        }
        const updateModalFields = [
            {
                controlId: 'Other Alerts',
                type: 'group_policy_alert',
            },

            {
                controlId: 'SOS Alert',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raiseSOSAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showSOSAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withSOSAlertAudio',
                        label: 'withAudio'
                    }
                ]
            },
            {
                controlId: 'Low Battery Alert',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raiseLowBatteryAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showLowBatteryAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withLowBatteryAlertAudio',
                        label: 'withAudio'
                    }
                ]
            },

            {
                controlId: 'Power Off Alert',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raisePowerOffAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showPowerOffAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withPowerOffAlertAudio',
                        label: 'withAudio'
                    }
                ],

            },
            {
                controlId: 'Power On Alert',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raisePowerOnAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showPowerOnAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withPowerOnAlertAudio',
                        label: 'withAudio'
                    }
                ]
            },

            {
                controlId: 'Very High Impact Alert',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raiseVeryHighImpactAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showVeryHighImpactAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withVeryHighImpactAlertAudio',
                        label: 'withAudio'
                    }
                ]
            },

            {
                controlId: 'High Impact Alert',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raiseHighImpactAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showHighImpactAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withHighImpactAlertAudio',
                        label: 'withAudio'
                    }
                ]
            },

            {
                controlId: 'Location Alerts',
                type: 'group_policy_alert',
            },
            {
                controlId: 'Missing Alert At Exit Door',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raiseMissingAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showMissingAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withMissingAlertAudio',
                        label: 'withAudio'
                    }
                ]
            },
            {
                controlId: 'Approaching Alert',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raiseApproachingAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showApproachingAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withApproachingAlertAudio',
                        label: 'withAudio'
                    }
                ]
            },
            {
                controlId: 'Leaving Alert',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raiseLeavingAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showLeavingAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withLeavingAlertAudio',
                        label: 'withAudio'
                    }
                ]
            },

            {
                controlId: 'Exit Door Alert',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raiseExitDoorAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showExitDoorAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withExitDoorAlertAudio',
                        label: 'withAudio'
                    }
                ]
            },
            {
                controlId: 'Break Away Alert',
                type: 'group_policy',
                fields: [
                    {
                        controlId: 'raiseBreakAwayAlert',
                        label: 'raise'
                    },
                    {
                        controlId: 'showBreakAwayAlert',
                        label: 'show'
                    },
                    {
                        controlId: 'withBreakAwayAlertAudio',
                        label: 'withAudio'
                    },

                ]
            },


            {
                controlId: 'approachingWhiteList',
                type: 'group_policy_list',
                name: 'Approaching White List',
                enterpriseId: this.state.enterpriseId
            },

            {
                controlId: 'leavingWhiteList',
                type: 'group_policy_list',
                name: 'Leaving White List',
                enterpriseId: this.state.enterpriseId
            }
        ]

        const updateConfig = {
            title: 'Edit Group Policy',
            submitText: 'Edit',
            cancelText: 'Cancel',
            fields: updateModalFields
        }

        const detailModalFields = [
            {
                controlId: 'name',
                label: 'Name',
                type: 'text',
                readOnly: true
            },
            {
                controlId: 'description',
                label: 'Description',
                type: 'text',
                readOnly: true
            }, {
                controlId: 'enabled',
                label: 'Enabled',
                type: 'checkbox',
                readOnly: true
            },
            ...updateModalFields
        ]
        const detailConfig = {
            title: 'Group Policy Detail',
            fields: detailModalFields
        }
        return (
            <BaseTable
                facilityFilter
                facilityFilterLevel = 'enterprise'
                sortName = {'id'}
                sortOrder = {'asc'}
                urlEntity = 'groupPolicy'
                addConfig={addConfig}
                detailConfig={detailConfig}
                columnProps = {columnProps}
                updateConfig = {updateConfig}
                onHandleQuery={ this.handleQuery }
                deleteConfig={deleteConfig}
                urlName={'groupPolicy'}
                onHandleEnterpriseChange={ this.handleEnterpriseChange }

            />
        )
    }
}

export default GroupPolicyList
