import React from 'react'
import FormGroup from 'react-bootstrap/FormGroup'
import FormControl from 'react-bootstrap/FormControl'
import { FormLabel } from "react-bootstrap"
import Col from 'react-bootstrap/Col'
import Select from 'react-select'
import Utils from '../../utils'
import '../react-select.css'
import ApiSingleton from '../../utils/Axios'
import { ToastContext } from '../../context/ToastContext'
import AsyncSelect from 'react-select/async';

const SeniorControlLevels = ['enterprise', 'venue', 'building', 'senior']

class FormGroupSeniorWithBuilding extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props)
    var state = {
      value: {
        enterprise: props.value ? { label: props.value.enterprise.name, value: props.value.enterprise.id } : null,
        venue: props.value ? { label: props.value.venue.name, value: props.value.venue.id } : null,
        building: props.value ? { label: props.value.building.name, value: props.value.building.id } : null,
        senior: props.value ? { label: props.value.senior.userName, value: props.value.senior.id } : null
      },
      seniorOptions: []
    }
    this.state = state

    this._onEnterpriseChange = this._onEnterpriseChange.bind(this)
    this._onVenueChange = this._onVenueChange.bind(this)
    this._onBuildingChange = this._onBuildingChange.bind(this)
    this._onSeniorChange = this._onSeniorChange.bind(this)

    this._getEnterpriseOptions = this._getEnterpriseOptions.bind(this)
    this._getVenueOptions = this._getVenueOptions.bind(this)
    this._getBuildingOptions = this._getBuildingOptions.bind(this)
    this._getSeniorOptions = this._getSeniorOptions.bind(this)
  }

  componentDidMount() {
    if (this.state.value.enterprise) {
      this._getVenueOptions(this.state.value.enterprise.value, false)
    }
    if (this.state.value.venue) {
      this._getBuildingOptions(this.state.value.venue.value, false)
    }
    if (this.state.value.building) {
      this._getSeniorOptions(this.state.value.building.value, false)
    }
  }

  _onEnterpriseChange(e) {
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        enterprise: e
      }
    }))
    // this._onChange('enterprise', value)
    // if (value.value) {
    //   this._getVenueOptions(value.value, true)
    // }
  }

  _onVenueChange(e) {
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        venue: e
      }
    }))
    // this._onChange('venue', value)
    // if (value.value) {
    //   this._getBuildingOptions(value.value, true)
    // }
  }

  _onBuildingChange(e) {
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        building: e
      }
    }))
    // this._onChange('building', value)
    // if (value.value) {
    //   this._getSeniorOptions(value.value, true)
    // }
  }

  _onSeniorChange(e) {
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        senior: e
      }
    }))
    // this._onChange('senior', value)
  }

  _onChange(key, value) {
    let newValue = { ...this.state.value }
    newValue[key] = value ? value.value : null

    const subLevels = _.slice(SeniorControlLevels, _.indexOf(SeniorControlLevels, key) + 1)

    // based on the attribute being changed, we need to reset the values of others
    newValue = Utils.setNull(newValue, subLevels)

    this.setState({
      value: newValue
    })

    // based on the type of the filter, we need to pick out the set of attribute to notify parents
    const notifyValue = _.pick(newValue, _.slice(SeniorControlLevels, 0, _.indexOf(SeniorControlLevels, key) + 1))
    const notifyObj = {}, notifyFields = {}
    _.forEach(notifyValue, (item, key) => {
      const { controlId, required, validator } = this.props.fields[key]
      notifyObj[controlId] = item
      notifyFields[controlId] = this.props.fields[key]
    })
    // based on the attribute being changed, notify
    this.props.onChange(notifyObj, notifyFields)
  }

  _getEnterpriseOptions() {
    return this._getOptions('enterprise', 'name', {})
  }

  _getVenueOptions(enterpriseId, resetDownstream) {
    this.setState({
      isVenueLoading: true
    })
    this._getOptions('venue', 'name', { enterpriseId: enterpriseId }).then((data) => {
      this._onVenueOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onVenueOptionsLoad(null, resetDownstream)
    })
  }

  _onVenueOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isVenueLoading: false,
        venueOptions: data ? data.options : [],
        buildingOptions: [],
        seniorOptions: []
      })
    } else {
      this.setState({
        isVenueLoading: false,
        venueOptions: data ? data.options : []
      })
    }
  }

  _getBuildingOptions(venueId, resetDownstream) {
    this.setState({
      isBuildingLoading: true
    })
    this._getOptions('building', 'name', { venueId: venueId }).then((data) => {
      this._onBuildOptionsLoad(data, resetDownstream)
    }, (err) => {
      this._onBuildOptionsLoad(null, resetDownstream)
    })
  }

  _onBuildOptionsLoad(data, resetDownstream) {
    if (resetDownstream) {
      this.setState({
        isBuildingLoading: false,
        buildingOptions: data ? data.options : [],
        seniorOptions: []
      })
    } else {
      this.setState({
        isBuildingLoading: false,
        buildingOptions: data ? data.options : []
      })
    }
  }

  _getSeniorOptions(buildingId, resetDownstream) {
    this.setState({
      isSeniorLoading: true
    })
    this._getOptions('senior', 'username', { buildingId: buildingId })
      .then((data) => {
        this.setState({
          isSeniorLoading: false,
          seniorOptions: data ? data.options : []
        })
      }, (err) => {
        this.setState({
          isSeniorLoading: false,
          seniorOptions: []
        })
      })
  }

  _getOptions(entity, orderBy, filter) {
    const { showToast } = this.context
    if (orderBy) {
      if (!filter) {
        filter = {}
      }
      filter.orderBy = orderBy
      filter.ascendingOrder = true
    }
    return ApiSingleton.makeHttpRequest(
      'post',
      '/bstream/api/v1/' + entity + '/find',
      { dataType: 'json' },
      filter
    ).then((data) => {
      const options = data.map((item, index) => {
        return { value: item[this.props.fields[entity].valueKey], label: item[this.props.fields[entity].labelKey] }
      })
      return {
        options: options
      }
    })
      .catch((err) => {
        showToast('Failed to fetch list of ' + entity + '! Reason: ' + err)
      })
  }

  render() {
    const props = this.props,
      fields = props.fields,
      state = this.state,
      labelWidth = props.labelWidth ? props.labelWidth : 3,
      valueWidth = props.valueWidth ? props.valueWidth : 7,
      validationWidth = 12 - labelWidth - valueWidth

    return (
      <div>
        <FormGroup
          controlId={fields.enterprise.controlId}
          validationState={props.validationState[fields.enterprise.controlId].status}>
          <Col sm={labelWidth}>
            <FormLabel>{fields.enterprise.label}</FormLabel>
          </Col>
          <Col sm={valueWidth}>
            <AsyncSelect
              name={fields.enterprise.controlId}
              autoload={props.autoLoad}
              searchable
              value={state.value.enterprise}
              loadOptions={this._getEnterpriseOptions}
              onChange={this._onEnterpriseChange}
            />
            <FormControl.Feedback />
          </Col>
          <Col sm={validationWidth}>
            <p>{props.validationState[fields.enterprise.controlId].message}</p>
          </Col>
          <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
            <p>{fields.enterprise.helpText}</p>
          </Col>
        </FormGroup>
        <FormGroup
          controlId={fields.venue.controlId}
          validationState={props.validationState[fields.venue.controlId].status}>
          <Col sm={labelWidth}>
            <FormLabel>{fields.venue.label}</FormLabel>
          </Col>
          <Col sm={valueWidth}>
            <Select
              name={fields.venue.controlId}
              searchable
              value={state.value.venue}
              isLoading={state.isVenueLoading}
              options={state.venueOptions}
              onChange={this._onVenueChange}
            />
            <FormControl.Feedback />
          </Col>
          <Col sm={validationWidth}>
            <p>{props.validationState[fields.venue.controlId].message}</p>
          </Col>
          <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
            <p>{fields.venue.helpText}</p>
          </Col>
        </FormGroup>
        <FormGroup
          controlId={fields.building.controlId}
          validationState={props.validationState[fields.building.controlId].status}>
          <Col sm={labelWidth}>
            <FormLabel>{fields.building.label}</FormLabel>
          </Col>
          <Col sm={valueWidth}>
            <Select
              name={fields.building.controlId}
              searchable
              value={state.value.building}
              isLoading={state.isBuildingLoading}
              options={state.buildingOptions}
              onChange={this._onBuildingChange}
            />
            <FormControl.Feedback />
          </Col>
          <Col sm={validationWidth}>
            <p>{props.validationState[fields.building.controlId].message}</p>
          </Col>
          <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
            <p>{fields.building.helpText}</p>
          </Col>
        </FormGroup>
        <FormGroup
          controlId={fields.senior.controlId}
          validationState={props.validationState[fields.senior.controlId].status}>
          <Col sm={labelWidth}>
            <FormLabel>{fields.senior.label}</FormLabel>
          </Col>
          <Col sm={valueWidth}>
            <Select
              name={fields.senior.controlId}
              searchable
              value={state.value.senior}
              isLoading={state.isSeniorLoading}
              options={state.seniorOptions}
              onChange={this._onSeniorChange}
            />
            <FormControl.Feedback />
          </Col>
          <Col sm={validationWidth}>
            <p>{props.validationState[fields.senior.controlId].message}</p>
          </Col>
          <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
            <p>{fields.senior.helpText}</p>
          </Col>
        </FormGroup>
      </div>
    )
  }
}

export default FormGroupSeniorWithBuilding
