import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class FormGroupInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value : "",
    };
  }

  _onChange = (e) => {
    const value = e.target.value;
    this.setState({
      value: value ? value : "",
    });
    this.props.onChange(
      this.props.controlId,
      this.props.required,
      this.props.validator,
      value
    );
  };

  _onBlur = (e) => {
    this.props.onBlur(
      this.props.controlId,
      this.props.required,
      this.props.validator,
      e.target.value
    );
  };

  render() {
    const props = this.props,
      state = this.state,
      labelWidth = props.labelWidth ? props.labelWidth : 3,
      valueWidth = props.valueWidth ? props.valueWidth : 7,
      validationWidth = 12 - labelWidth - valueWidth,
      type = props.type ? props.type : "text";
    return (
      <Form.Group
        as={Row}
        className="mb-3"
        controlId={props.controlId}
        validationState={props.validationState.status}
      >
        <Col sm={labelWidth}>
          <Form.Label style={{ fontWeight: "bold" }}>{props.label}</Form.Label>
        </Col>
        <Col sm={valueWidth}>
          <Form.Control
            type={props.type}
            placeholder={props.placeholder}
            value={state.value}
            onChange={this._onChange}
            onBlur={this._onBlur}
          />
          <Form.Control.Feedback />
        </Col>
        <Col sm={validationWidth}>
          <p>{props.validationState.message}</p>
        </Col>
        <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
          <p>{props.helpText}</p>
        </Col>
      </Form.Group>
    );
  }
}

export default FormGroupInput;
