import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AiFillSmile, AiOutlineCalendar } from 'react-icons/ai';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import ApiSingleton from '../../utils/Axios';

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import StatBox from "../../components/StatBox/StatBox";

  export function UserListTable() {
    const [userList, setUserList] = useState([]);
    const [rows, setRows] = useState([]);
    const [searched, setSearched] = useState("");

    function _fetchUserList() {
        const postData = {
            seniorName: "UI_metadata",
            key: "UI_metadata",
        }
        let tempData = null;
        ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
            { dataType: "json" },
            postData
          )
            .then((data) => {
                console.log("getting user list from API");
                tempData = JSON.parse(base64_decode(data[0].document));
                console.log("fetched user list: ", tempData);
            })
            .then(data => {
                console.log("setting User List");

                setUserList(tempData.STOP);
                setRows(tempData.STOP);
            })
            .catch((err) => {});
    }

    useEffect(() => {
        _fetchUserList();

        console.log("useEffect ran here");
    }, []);

    const requestSearch = (searchedVal) => {
        const filteredRows = userList.filter((row) => {
            return row.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };
  
    // navigation to statistical analysis page
    const navigate = useNavigate();
    function handleGoUser(e) {
        // e.preventDefault();
        console.log("event e: ", e);
        navigate('/userDisplay', { state: { id: e}});
    }

    return (
        <Paper>
            {/* <SearchBar
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
            /> */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell>User ID</TableCell>
                    {/* <TableCell align="right">Calories</TableCell>
                    <TableCell align="right">Fat&nbsp;(g)</TableCell>
                    <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userList.map((row) => (
                    <TableRow
                        key={row}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" onClick={() => handleGoUser(row)}>{row}</TableCell>
                        {/* <TableCell align="right">{row.calories}</TableCell>
                        <TableCell align="right">{row.fat}</TableCell>
                        <TableCell align="right">{row.carbs}</TableCell>
                        <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
        </Paper>
        
    //     <Box m="20px"> 
    //         <Box display="flex" justifycontext="space-between" alignItems="center">
    //             <Box width="100%">
    //                 <Typography variant="h3" fontWeight="bold" sx={{ color: "#530C0C" }}>User List</Typography>
    //             </Box>
    //         </Box>

    //         {/* GRID AND CHARTS */}
    //         <Box
    //         display="grid"
    //         gridTemplateColumns="repeat(12, 1fr)"
    //         gridAutoRows="140px"
    //         gap="20px"
    //         >
  
    //             {/** ROW 1 */}
    //             <Box gridColumn="span 12" gridRow="span 1" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoUser1}>
    //                 <StatBox title="AppId:fc165b5993.resident" subtitle="User 1" icon={<AiFillSmile color='green' size='50px' />}></StatBox>
    //             </Box>

    //             {/** ROW 2 */} 
    //             <Box gridColumn="span 12" gridRow="span 1" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoUser2}>
    //                 <StatBox title="AppId:118b961abb.resident" subtitle="User 2" icon={<AiFillSmile color='green' size='50px' />}></StatBox>
    //             </Box>

    //             {/** ROW 3 */}
    //             {/* <Box gridColumn="span 12" gridRow="span 1" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center" onClick={handleGoUser3}>
    //                 <StatBox title="AppId:d3c09ab8ed.resident" subtitle="User 3" icon={<AiFillSmile color='green' size='50px' />}></StatBox>
    //             </Box> */}
    //       </Box>
    //   </Box>
    );
  }
  
  export default UserListTable;