/* global $ */
import React from 'react'
import BaseTable from '../../components/BaseTable'
import ApiSingleton from '../../utils/Axios';
import { ToastContext } from '../../context/ToastContext'

class ApproachingWhiteList extends React.Component {
    static contextType = ToastContext
    constructor(props) {
        super(props);
        this.onRefresh = this.onRefresh.bind(this)
        this.state = {
            relays: props.data ? props.data: null,
            enterpriseId: props.enterpriseId ? props.enterpriseId : null
        }
    }

    _fetchRelayById(id) {
        const { showToast } = this.context
        let relay = {}
        ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/relay/findById",
            { dataType: "json" },
            { id }
          )
            .then((data) => {
                relay.relayId = data.id
                relay.name = data.name
                relay.roomName = data.roomName
            })
            .catch((err) => { showToast('Failed to fetch list of group policies'+ '! Reason: ' + err) });
        return relay
    }

    _fetchGroupPolicyById(id) {
        const { showToast } = this.context
        ApiSingleton.makeHttpRequest(
            "post",
            "/bstream/api/v1/groupPolicy/findById",
            { dataType: "json" },
            { id }
          )
            .then((data) => {
                if(this.props.controlId === 'approachingWhiteList') {
                    this.setState({
                        relays: data.approachingWhiteList
                    })
                } else {
                    this.setState({
                        relays: data.leavingWhiteList
                    })
                }
            })
            .catch((err) => { showToast('Failed to fetch list of group policies'+ '! Reason: ' + err) });
    }

    onRefresh() {
        this._fetchGroupPolicyById(this.props.groupPolicyId)
    }


    handleEnterpriseChange(enterpriseId) {
        this.setState({
            enterpriseId: enterpriseId
        })
    }
    render() {
        const props = this.props;
        const columnProps = [{
            config: {
                dataField: 'relayId',
                isKey: true,
                hidden: true
            },
            label: 'id'
        },
            {
                config: {
                    dataField: 'name',
                    dataSort: false,
                },
                label: 'Name'
            }, {
                config: {
                    dataField: 'roomName',
                    dataSort: false,
                },
                label: 'Room'
            }]
        const addModalFields = [
            {
                controlId: 'relayId',
                label: 'Relay Name',
                enterpriseId: this.state.enterpriseId,
                type: 'select',
                urlEntity: 'relay',
                labelKey: 'name',
                valueKey: 'id'
            },
        ]

        const addConfig = {
            title: 'Add Relay to Group Policy',
            submitText: 'Add',
            cancelText: 'Cancel',
            fields: addModalFields,
            beforeSubmit: (data) => {
                data.id = props.groupPolicyId
                data.updateUrl='groupPolicyRelay'
                data.type = props.controlId
                const relay = this._fetchRelayById(data.relayId)
                data.relay = relay
                const relays = []
                relays.push(data.relayId)
                data.relays = relays
                this.setState({
                    relays: this.state.relays.concat(relay)
                })

                return data
            }
        }
        const deleteConfig = {
            title: 'Delete Relay',
            body: 'Are you sure you want to delete the selected Relay?',
            name: 'groupPolicy'
        }

        const detailModalFields = [
            {
                controlId: 'name',
                label: 'Relay Name',
                type: 'text'
            },
            {
                controlId: 'roomName',
                label: 'Room Name',
                type: 'text'
            },
        ]
        const detailConfig = {
            cancelText: 'Close',
            fields: detailModalFields
        }

        return (
            <BaseTable autoLoad={false}
                       striped={ true }
                       height={props.height}
                       pagination={ false }
                       hideSelectColumn={ false }
                       sortChange={this.handleSortChange}
                       deleteConfig={deleteConfig}
                       detailConfig={detailConfig}
                       addConfig={addConfig}
                       hideFilterSearchBar={ true }
                       columnProps={columnProps}
                       onHandleEnterpriseChange = {this.handleEnterpriseChange}
                       { ...props }
                       urlRelayChange={'relayChange'}
                       sortedData={this.state.relays}
                       refresh={this.onRefresh}
                       groupPolicyId={this.props.groupPolicyId}
            />
        )
    }
}
export default ApproachingWhiteList

