const _ = require('lodash')

export const IsRequired = (value, row) => {
  const response = {valid: true, status: 'success', message: ''}
  if (!value || (_.isString(value) &&value.trim().length === 0) || (_.isArray(value) && value.length === 0)) {
    return {valid: false, status: 'error', message: 'required field'}
  }
  return response
}


export const MatchingPasswordRequired = (data, validationState) => {
  if (data.password && data.password2 && data.password !== data.password2) {
    validationState.password = {valid: false, status: 'error', message: 'passwords not matching'}
    validationState.password2 = {valid: false, status: 'error', message: 'passwords not matching'}
  } else if (data.password) {
    validationState.password = IsRequired(data.password)
  } else if (data.password2) {
    validationState.password2 = IsRequired(data.password2)
  }
}

export const MatchingPasswordAllowUndefined = (data, validationState) => {
  if (data.password && data.password2 && data.password !== data.password2) {
    validationState.password = {valid: false, status: 'error', message: 'passwords not matching'}
    validationState.password2 = {valid: false, status: 'error', message: 'passwords not matching'}
  } else {
    validationState.password = {valid: true, status: 'success', message: ''}
    validationState.password2 = {valid: true, status: 'success', message: ''}
  }
}

export const StripConfirmPassword = (data) => {
  delete data.password2
  return data
}

export const StripEmptyAndConfirmPassword = (data) => {
  if (data.password && data.password.trim().length === 0) {
    delete data.password
  }
  delete data.password2
  return data
}
