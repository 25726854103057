import React from "react";
import { Form, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";

class FormGroupCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        props.value !== undefined && props.value !== null
          ? props.value
          : props.defaultValue !== undefined
          ? props.defaultValue
          : false,
    };
  }

  componentDidMount() {
    if (
      (this.props.value === undefined || this.props.value === null) &&
      this.props.defaultValue !== undefined
    ) {
      this.props.onChange(
        this.props.controlId,
        this.props.required,
        this.props.validator,
        this.props.defaultValue
      );
    }
  }

  _onChange = (e) => {
    const value = e.target.checked;
    this.setState({
      value: value ? value : false,
    });
    this.props.onChange(
      this.props.controlId,
      this.props.required,
      this.props.validator,
      value
    );
  };

  render() {
    const props = this.props,
      state = this.state,
      labelWidth = props.labelWidth ? props.labelWidth : 3,
      valueWidth = props.valueWidth ? props.valueWidth : 7,
      validationWidth = 12 - labelWidth - valueWidth;
    return (
      <Form.Group
        controlId={props.controlId}
        as={Row}
        className="mb-3"
        validationState={props.validationState.status}
      >
        <Col sm={labelWidth}>
          <Form.Label style={{ fontWeight: "bold" }}>{props.label}</Form.Label>
        </Col>
        <Col sm={valueWidth}>
          <Form.Check
            type="checkbox"
            checked={state.value}
            onChange={this._onChange}
            label={props.label}
          />
          <Form.Control.Feedback />
        </Col>
        <Col sm={validationWidth}>
          <p>{props.validationState.message}</p>
        </Col>
        <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
          <p>{props.helpText}</p>
        </Col>
      </Form.Group>
    );
  }
}

export default FormGroupCheckbox;
