import React from 'react'
import TreeView from './TreeView'

const BaseTree = (props) => {
  if (!props.data) {
    return null
  }
  return (
    <TreeView
      data={props.data}
      onTreeNodeExpandChange={props.onTreeNodeExpandChange}
      onTreeNodeSelectChange={props.onTreeNodeSelectChange}
    />
  )
}

export default BaseTree
