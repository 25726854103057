import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Select from "react-select";
import AsyncSelect from "react-select/async";

import Utils from "../../utils";
import ApiSingleton from "../../utils/Axios";

const CityControlLevels = ["country", "state", "city"];

const FormGroupCity = (props) => {
  const [country, setCountry] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [isCityLoading, setIsCityLoading] = React.useState(false);
  const [isStateLoading, setIsStateLoading] = React.useState(false);
  const [stateOptions, setStateOptions] = React.useState([]);
  const [cityOptions, setCityOptions] = React.useState([]);

  React.useEffect(() => {
    _getStateOptions(country, false);
    _getCityOptions(state, false);
  }, []);

  const _onCountryChange = (value) => {
    _onChange("country", value);
    if (value && value.value) {
      _getStateOptions(value.value);
    } else {
      setStateOptions([]);
      setCityOptions([]);
    }
  };

  const _onStateChange = (value) => {
    _onChange("state", value);

    if (value && value.value) {
      _getCityOptions(value.value);
    } else {
      setCityOptions([]);
    }
  };

  const _onCityChange = (value) => {
    _onChange("city", value);
  };

  const _onChange = (key, value) => {
    let newValue = { country, state, city };
    newValue[key] = value && value.value ? value.value : null;

    const subLevels = _.slice(
      CityControlLevels,
      _.indexOf(CityControlLevels, key) + 1
    );

    // based on the attribute being changed, we need to reset the values of others
    newValue = Utils.setNull(newValue, subLevels);

    setCountry(newValue.country);
    setState(newValue.state);
    setCity(newValue.city);

    // based on the type of the filter, we need to pick out the set of attribute to notify parents
    const notifyValue = _.pick(
      newValue,
      _.slice(CityControlLevels, 0, _.indexOf(CityControlLevels, key) + 1)
    );
    const notifyObj = {},
      notifyFields = {};
    _.forEach(notifyValue, (item, key) => {
      const { controlId, required, validator } = props.fields[key];
      notifyObj[controlId] = item;
      notifyFields[controlId] = props.fields[key];
    });
    // based on the attribute being changed, notify
    console.log("_onChange notfiyObj", notifyObj, "notifyFields", notifyFields);
    props.onChange(notifyObj, notifyFields);
  };

  const filterCountry = (countryOptionList, inputValue) => {
    return countryOptionList.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const _getCountryOptions = (inputValue) =>
    new Promise(async (resolve) => {
      const countries = await _getOptions("country", "name", {});
      resolve(filterCountry(countries.options, inputValue));
    });

  const _getStateOptions = (countryId, resetDownstream) => {
    setIsStateLoading(true);

    _getOptions("state", "name", { countryId: countryId }).then((data) => {
      setIsStateLoading(false);
      setStateOptions(data.options);
      setCityOptions([]);
    });
  };

  const _getCityOptions = (stateId, resetDownstream) => {
    setIsCityLoading(true);

    _getOptions("city", "name", { stateId: stateId }).then((data) => {
      setIsCityLoading(false);
      setCityOptions(data.options);
    });
  };

  const _getOptions = async (entity, orderBy, filter) => {
    if (orderBy) {
      if (!filter) {
        filter = {};
      }
      filter.orderBy = orderBy;
      filter.ascendingOrder = true;
    }
    return await ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/" + entity + "/find",
      { dataType: "json" },
      filter
    )
      .then((data) => {
        const options = data.map((item, index) => {
          return {
            value: item[this.props.fields[entity].valueKey],
            label: item[this.props.fields[entity].labelKey],
          };
        });
        return {
          options: options,
        };
      })
      .catch((err) => {
        // this.context.notifyError('Failed to fetch list of ' + entity + '! Reason: ', err)
        console.log("Failed to fetch list of " + entity + "! Reason: ", err);
      });
  };

  const fields = props.fields,
    labelWidth = props.labelWidth ? props.labelWidth : 3,
    valueWidth = props.valueWidth ? props.valueWidth : 7,
    validationWidth = 12 - labelWidth - valueWidth;

  return (
    <div>
      <Form>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId={fields.country.controlId}
          validationState={
            props.validationState[fields.country.controlId].status
          }
        >
          <Col sm={labelWidth}>
            <Form.Label style={{ fontWeight: "bold" }}>
              {fields.country.label}
            </Form.Label>
          </Col>
          <Col sm={valueWidth}>
            <AsyncSelect
              name={fields.country.controlId}
              searchable
              cacheOptions
              defaultOptions
              loadOptions={_getCountryOptions}
            />
            <Form.Control.Feedback />
          </Col>

          <Col sm={validationWidth}>
            <p>{props.validationState[fields.country.controlId].message}</p>
          </Col>
          <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
            <p>{fields.country.helpText}</p>
          </Col>
        </Form.Group>

        <Form.Group
          controlId={fields.state.controlId}
          as={Row}
          className="mb-3"
          validationState={props.validationState[fields.state.controlId].status}
        >
          <Col sm={labelWidth}>
            <Form.Label style={{ fontWeight: "bold" }}>
              {fields.state.label}
            </Form.Label>
          </Col>
          <Col sm={valueWidth}>
            <Select
              name={fields.state.controlId}
              autoload={props.autoLoad}
              searchable
              value={state}
              isLoading={isStateLoading}
              options={stateOptions}
              onChange={_onStateChange}
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={validationWidth}>
            <p>{props.validationState[fields.state.controlId].message}</p>
          </Col>
          <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
            <p>{fields.state.helpText}</p>
          </Col>
        </Form.Group>

        <Form.Group
          controlId={fields.city.controlId}
          as={Row}
          className="mb-3"
          validationState={props.validationState[fields.city.controlId].status}
        >
          <Col sm={labelWidth}>
            <Form.Label style={{ fontWeight: "bold" }}>
              {fields.city.label}
            </Form.Label>
          </Col>
          <Col sm={valueWidth}>
            <Select
              name={fields.city.controlId}
              autoload={props.autoLoad}
              searchable
              value={city}
              isLoading={isCityLoading}
              options={cityOptions}
              onChange={_onCityChange}
            />
            <Form.Control.Feedback />
          </Col>
          <Col sm={validationWidth}>
            <p>{props.validationState[fields.city.controlId].message}</p>
          </Col>
          <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
            <p>{fields.city.helpText}</p>
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
};

export default FormGroupCity;
