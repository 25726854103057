import React from "react";
import SplitPane from "react-split-pane";
import Card from "react-bootstrap/Card";
import FacilityTree from "./FacilityTree";
import SelectedFacilityView from "./SelectedFacilityView";
import FacilityOptions from "./FacilityOptions";
import ApiSingleton from "../../utils/Axios";
import { ToastContext } from '../../context/ToastContext'

import _ from "lodash";

class FacilityPanel extends React.Component {
  static contextType = ToastContext
  constructor(props) {
    super(props);
    this.state = {
      data: {
        text: "System",
        type: "system",
        rawData: {},
      },
    };
  }

  componentWillMount() {
    this._remoteLoadSelected();
  }

  componentDidMount() {
    // work around for the react-split-pane package
    $(".SplitPane").css("position", "");
  }

  _getChildType = (type) => {
    switch (type) {
      case "system":
        return "enterprise";
      case "enterprise":
        return "venue";
      case "venue":
        return "building";
      case "building":
        return "floor";
      case "floor":
        return "room";
      case "room":
        return "relay";
      case "relay":
        return "unknown";
    }
  };

  _getParentType = (type) => {
    switch (type) {
      case "system":
        return "unknown";
      case "enterprise":
        return "system";
      case "venue":
        return "enterprise";
      case "building":
        return "venue";
      case "floor":
        return "building";
      case "room":
        return "floor";
      case "relay":
        return "room";
    }
  };

  _remoteLoadSelected = (node) => {
    const selected = node
        ? { type: node.type, data: node.rawData }
        : this.state.data
        ? this.state.data
        : { type: "system" },
      data = {};
    switch (selected.type) {
      case "enterprise":
        data.enterpriseId = selected.data.id;
        break;
      case "venue":
        data.enterpriseId = selected.data.enterpriseId;
        data.venueId = selected.data.id;
        break;
      case "building":
        data.enterpriseId = selected.data.enterpriseId;
        data.venueId = selected.data.venueId;
        data.buildingId = selected.data.id;
        break;
      case "floor":
        data.enterpriseId = selected.data.enterpriseId;
        data.venueId = selected.data.venueId;
        data.buildingId = selected.data.buildingId;
        data.floorId = selected.data.id;
        break;
      case "room":
        data.enterpriseId = selected.data.enterpriseId;
        data.venueId = selected.data.venueId;
        data.buildingId = selected.data.buildingId;
        data.floorId = selected.data.floorId;
        data.roomId = selected.data.id;
        break;
    }

    // console.log(selected);

    data.orderBy = "name";
    data.ascendingOrder = true;

    // $.post({
    //   url: '/bstream/api/v1/' + this._getChildType(selected.type) + '/find',
    //   headers: { 'Content-type': 'application/x-www-form-urlencoded' },
    //   data: data,
    //   dataType:'json',
    //   success: (resp) => {
    //     const newTreeData = _.cloneDeep(this.state.data)
    //     this._addData(newTreeData, selected, resp)
    //     this.setState({
    //       data: newTreeData
    //     })
    //     if (selected.type === 'floor' && node) {
    //       // make sure we save all the rooms coordinates to render on the floor image
    //       node.rawData.rooms = _.map(resp, (room, index) => {
    //         return room.coordinates
    //       })
    //       this.setState({
    //         selected: {
    //           type: node.type,
    //           data: node.rawData
    //         }
    //       })
    //     } else if (selected.type === 'room' && node) {
    //       // mak sure we save all the relay coordinates to render on the floor image
    //       node.rawData.relays = _.map(resp, (relay, index) => {
    //         return {x: relay.x, y: relay.y}
    //       })
    //       this.setState({
    //         selected: {
    //           type: node.type,
    //           data: node.rawData
    //         }
    //       })
    //     }
    //   },
    //   error: (err) => {
    //     console.log("err-->");
    //     console.log(err);
    //     // showToast('Failed to fetch list of ' + selected.type + '! Reason: ', err)
    //   }
    // })
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/" + this._getChildType(selected.type) + "/find",
      { dataType: "json" },
      data
    )
      .then((resp) => {
        const newTreeData = _.cloneDeep(this.state.data);
        this._addData(newTreeData, selected, resp);
        this.setState({
          data: newTreeData,
        });

        if (selected.type === "floor" && node) {
          // make sure we save all the rooms coordinates to render on the floor image
          node.rawData.rooms = _.map(resp, (room, index) => {
            return room.coordinates;
          });
          this.setState({
            selected: {
              type: node.type,
              data: node.rawData,
            },
          });
        } else if (selected.type === "room" && node) {
          // mak sure we save all the relay coordinates to render on the floor image
          node.rawData.relays = _.map(resp, (relay, index) => {
            return { x: relay.x, y: relay.y };
          });

          this.setState({
            selected: {
              type: node.type,
              data: node.rawData,
            },
          });
        }
      })
      .catch((err) => {
        const { showToast } = this.context
        showToast('Failed to fetch list of ' + selected.type + '! Reason: '+ err)
      });
  };

  _addData = (treeNode, parentNode, nodeData) => {
    if (
      treeNode.type === parentNode.type &&
      (treeNode.type === "system" ||
        (treeNode.type !== "system" &&
          treeNode.entityId === parentNode.data.id))
    ) {
      treeNode.nodes = _.map(nodeData, (node, index) => {
        delete node.enterprise;
        return {
          text: node.name,
          type: this._getChildType(parentNode.type),
          entityId: node.id,
          rawData: node,
        };
      });
      treeNode.tags = ["" + treeNode.nodes.length];
      treeNode.loaded = true;
      delete treeNode.loading;
    } else {
      _.forEach(treeNode.nodes, (node, index) => {
        this._addData(node, parentNode, nodeData);
      });
    }
  };

  _selectTreeNode = (parentNode, node, selectedNode) => {
    if (
      node.entityId === selectedNode.entityId &&
      node.type === selectedNode.type
    ) {
      if (!node.state) {
        node.state = {};
      }
      node.state.selected = true;
      if (!node.loaded && selectedNode.type !== "relay") {
        node.loading = true;
        node.state.expanded = true;
        this._remoteLoadSelected(selectedNode);
      }
      if (selectedNode.type === "floor") {
        // because floor needs all the room's coordinates
        selectedNode.rawData.rooms = _.map(node.nodes, (subnode, index) => {
          return subnode.rawData.coordinates;
        });
      } else if (selectedNode.type === "room") {
        // because room required parent floor image
        selectedNode.rawData.image = parentNode.rawData.image;
        selectedNode.rawData.relays = _.map(node.nodes, (subnode, index) => {
          return { x: subnode.rawData.x, y: subnode.rawData.y };
        });
      } else if (selectedNode.type === "relay") {
        const matchingFloorNodes = [];
        this._findMyFloor(
          selectedNode.rawData.floorId,
          this.state.data,
          matchingFloorNodes
        );
        if (matchingFloorNodes.length > 0) {
          selectedNode.rawData.roomImage = matchingFloorNodes[0].rawData.image;
        }
        selectedNode.rawData.roomCoordinates = parentNode.rawData.coordinates;
        selectedNode.rawData.coordinates = {
          x: selectedNode.rawData.x,
          y: selectedNode.rawData.y,
        };
      }
    } else if (node.state && node.state.selected) {
      node.state.selected = false;
    }
    _.forEach(node.nodes, (subnode, index) => {
      this._selectTreeNode(node, subnode, selectedNode);
    });
  };

  _expandTreeNode = (node, expandedNode) => {
    if (
      node.entityId === expandedNode.entityId &&
      node.type === expandedNode.type
    ) {
      if (!node.state) {
        node.state = {};
      }
      node.state.expanded = true;
    } else {
      _.forEach(node.nodes, (subnode, index) => {
        this._expandTreeNode(subnode, expandedNode);
      });
    }
  };

  _findMyFloor = (floorId, node, matchNodes) => {
    if (node.type === "floor" && node.entityId === floorId) {
      matchNodes.push(node);
    } else if (node.type !== "floor") {
      _.forEach(node.nodes, (subnode, index) => {
        this._findMyFloor(floorId, subnode, matchNodes);
      });
    }
  };

  _onTreeNodeSelectChange = (node, selected) => {
    // clear current selection
    //    console.log('onTreeNodeSelectChange')
    //    console.log(node)
    //    console.log(selected)
    if (selected) {
      const newTreeData = _.cloneDeep(this.state.data);
      this._selectTreeNode(null, newTreeData, node);
      this.setState({
        selected: {
          type: node.type,
          data: node.rawData,
        },
        data: newTreeData,
      });
    }
  };

  _onTreeNodeExpandChange = (node, expanded) => {
    // clear current selection
    console.log('----->');

    if (expanded) {
      const newTreeData = _.cloneDeep(this.state.data);
      console.log(newTreeData);
      this._expandTreeNode(newTreeData, node);
      this.setState({ data: newTreeData });
    }
  };

  _deleteTreeNode = (treeNode, parentType, entityId) => {
    if (treeNode.type === parentType) {
      const removed = _.remove(treeNode.nodes, (node, index) => {
        return node.entityId === entityId;
      });
      if (removed.length > 0) {
        treeNode.tags = ["" + treeNode.nodes.length];
      }
    } else {
      _.forEach(treeNode.nodes, (node, index) => {
        return this._deleteTreeNode(node, parentType, entityId);
      });
    }
  };

  _updateTreeNode = (treeNode, type, data) => {
    if (treeNode.type === type && treeNode.entityId === data.id) {
      treeNode.text = data.name;
      treeNode.rawData = data;
    } else {
      _.forEach(treeNode.nodes, (node, index) => {
        return this._updateTreeNode(node, type, data);
      });
    }
  };

  _onTreeNodeDeleted = (type, data) => {
    const parentType = this._getParentType(type);
    const newTreeData = _.cloneDeep(this.state.data);
    this._deleteTreeNode(newTreeData, parentType, data.id);
    this.setState({
      selected: null,
      data: newTreeData,
    });
  };

  _onTreeNodeUpdated = (type, data) => {
    const newTreeData = _.cloneDeep(this.state.data);
    this._updateTreeNode(newTreeData, type, data);
    const selected = _.cloneDeep(this.state.selected);
    this.setState({
      selected: {
        type: type,
        data: _.assign(selected.data, data),
      },
      data: newTreeData,
    });
  };

  render() {
    const state = this.state;
    return (
      <Card>
        <Card.Header as="h3">Facilities</Card.Header>
        <Card.Body>
          <SplitPane
            split="vertical"
            minSize={200}
            defaultSize={250}
            paneStyle={{ overflow: "auto" }}
          >
            <div>
              <FacilityTree
                data={[state.data]}
                onTreeNodeSelectChange={this._onTreeNodeSelectChange}
                onTreeNodeExpandChange={this._onTreeNodeExpandChange}
              />
            </div>
            <div>
              <SelectedFacilityView
                data={state.selected ? state.selected.data : null}
                options={
                  state.selected ? FacilityOptions[state.selected.type] : []
                }
                remoteLoadSelected={this._remoteLoadSelected}
                onTreeNodeUpdated={this._onTreeNodeUpdated}
                onTreeNodeDeleted={this._onTreeNodeDeleted}
              />
            </div>
          </SplitPane>
        </Card.Body>
      </Card>
    );
  }
}

export default FacilityPanel;
