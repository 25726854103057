import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseForm from "./BaseForm";

import "./BaseFormModal.scss";

const _ = require("lodash");

class BaseFormModal extends React.Component {
  constructor(props) {
    super(props);
    const validationState = this._initValidationState(props.fields, props.data);
    this.state = {
      data: this._initDataState(props.data),
      validationState: validationState,
      canSubmit: !props.isAdd && this._canSubmit(validationState),
    };
  }

  componentWillReceiveProps(nextProps) {
    const validationState = this._initValidationState(
      nextProps.fields,
      nextProps.data
    );
    this.setState({
      data: this._initDataState(nextProps.data),
      validationState: validationState,
      canSubmit: !nextProps.isAdd && this._canSubmit(validationState),
    });
  }

  _initDataState = (data) => {
    return data ? { ...data } : {};
  };

  _initValidationState = (fields, data) => {
    const validationState = {};
    if (_.isArray(fields)) {
      fields.forEach((field) => {
        this._initValidationStateField(validationState, field, data);
      });
    } else {
      _.forOwn(fields, (field, index) => {
        this._initValidationStateField(validationState, field, data);
      });
    }
    return validationState;
  };

  _initValidationStateField = (validationState, field, data) => {
    if (field.controlId) {
      // single field
      const value = data ? data[field.controlId] : null;
      if (
        field.required &&
        (_.isNull(value) ||
          _.isUndefined(value) ||
          (_.isString(value) && value.trim().length === 0) ||
          (_.isArray(value) && value.length === 0))
      ) {
        // check required field first
        validationState[field.controlId] = {
          valid: false,
          status: "error",
          message: "required field",
        };
      } else if (field.validator) {
        // if validator defined, run validator
        validationState[field.controlId] = field.validator(value);
      } else {
        validationState[field.controlId] = {
          valid: true,
          status: undefined,
          message: "",
        };
      }
    } else if (field.fields) {
      _.assign(validationState, this._initValidationState(field.fields, data));
    }
  };

  _canSubmit = (validationState) => {
    let canSubmit = true;
    _.forOwn(validationState, (value, key) => {
      if (!value.valid) {
        canSubmit = false;
      }
    });
    return canSubmit;
  };

  _onChange = (key, required, validator, value) => {
    if (_.isString(key)) {
      this._onChangeOrBlur(key, required, validator, value, true);
    } else {
      this._onChangeBulk(key, required); // in this case, key is the the new object, required is the fields
    }
  };

  _onBlur = (key, required, validator, value) => {
    //this._onChangeOrBlur(key, required, validator, value)
  };

  _onChangeBulk = (newValue, fields) => {
    const data = _.assign({ ...this.state.data }, newValue);
    const validationState = { ...this.state.validationState };
    _.forEach(newValue, (value, key) => {
      const { controlId, required, validator } = fields[key];
      if (
        required &&
        (!value ||
          (_.isString(value) && value.trim().length === 0) ||
          (_.isArray(value) && value.length === 0))
      ) {
        validationState[key] = {
          valid: false,
          status: "error",
          message: "required field",
        };
      } else if (validator) {
        validationState[key] = validator(value);
      } else {
        validationState[key] = {
          valid: true,
          status: "success",
          message: "",
        };
      }
    });
    if (this.props.formValidator) {
      // for form level validation check
      this.props.formValidator(data, validationState);
    }

    const canSubmit = this._canSubmit(validationState);

    this.setState({
      data: data,
      validationState: validationState,
      canSubmit: canSubmit,
    });
  };

  _onChangeOrBlur = (key, required, validator, value, dataChanged) => {
    //    console.log('BaseFormModal._onChangeOrBlur() key = ', key, ', value = ', value)
    // update date
    const data = { ...this.state.data };
    if (dataChanged) {
      data[key] = value;
    }

    const validationState = { ...this.state.validationState };
    if (
      required &&
      (_.isNull(value) ||
        _.isUndefined(value) ||
        (_.isString(value) && value.trim().length === 0) ||
        (_.isArray(value) && value.length === 0))
    ) {
      validationState[key] = {
        valid: false,
        status: "error",
        message: "required field",
      };
    } else if (validator) {
      validationState[key] = validator(value);
    } else {
      validationState[key] = {
        valid: true,
        status: "success",
        message: "",
      };
    }
    if (dataChanged && this.props.formValidator) {
      // for form level validation check
      this.props.formValidator(data, validationState);
    }

    const canSubmit = this._canSubmit(validationState);

    this.setState({
      data: data,
      validationState: validationState,
      canSubmit: canSubmit,
    });
  };

  _onSubmit = () => {
    let data = { ...this.state.data };
    if (this.props.beforeSubmit) {
      // hook to process form data before submit
      data = this.props.beforeSubmit(data);
    }
    //console.log(data)
    this.props.onSubmit(data, this.props.onClose, this._onFail);
  };

  render() {
    const props = this.props,
      state = this.state,
      modalTitle = props.title ? props.title : "Default Title",
      submitText = props.submitText ? props.submitText : "Ok",
      cancelText = props.cancelText ? props.cancelText : "Cancel",
      bsSize = "lg";
    const cancelButton = !props.hideCancelButton ? (
      <Button type="submit" bsStyle="primary" onClick={props.onClose}>
        <FontAwesomeIcon icon="fas fa-times" /> {cancelText}
      </Button>
    ) : (
      <div></div>
    );

    return (
      <Modal
        show={props.show}
        onHide={props.onClose}
        size={bsSize}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BaseForm
            fields={props.fields}
            data={state.data}
            validationState={state.validationState}
            onChange={this._onChange}
            onBlur={this._onBlur}
          />
        </Modal.Body>
        <Modal.Footer>
          {cancelButton}
          <Button
            type="submit"
            bsStyle="primary"
            onClick={this._onSubmit}
            disabled={!state.canSubmit}
          >
            <FontAwesomeIcon icon="fas fa-check" /> {submitText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BaseFormModal;
