import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

const _ = require("lodash");

export class CellEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue,
      validationState: this._validation(props.defaultValue),
    };
  }

  focus() {
    this.inputRef.focus();
  }

  _onChange = (e) => {
    const value = e.target.value;
    this.setState({
      value: value,
      validationState: this._validation(value),
    });
  };

  _onBlur = (e) => {
    const value = e.target.value;
    this.props.onUpdate(value);
  };

  //  _onKeyDown = (e) => {
  //    const keyCode = e.keyCode || e.which
  //    if (keyCode == 9) {
  //      const value = e.target.value
  //      this.props.onUpdate(value)
  //    }
  //  }

  _validation(value) {
    const validationState = {
      status: "success",
      message: "",
    };
    if (this.props.required && (!value || value.trim().length === 0)) {
      validationState.status = "error";
      validationState.message = "required field missing";
    }
    if (this.props.validator) {
      _.assign(validationState, this.props.validator(value));
    }
    return validationState;
  }

  render() {
    const props = this.props,
      state = this.state,
      type = props.type ? props.type : "text";

    return (
      <Form.Group validationState={state.validationState.status}>
        <Form.Control
          inputRef={(ref) => {
            this.inputRef = ref;
          }}
          type={props.type}
          value={state.value}
          onChange={this._onChange}
          onBlur={this._onBlur}
        />
      </Form.Group>
    );
  }
}

export default CellEditor;
