import React from 'react';
import PropertyGrid from '../../components/PropertyGrid';

const Alert = ({ label, value, iconClass }) => {
  const style = { lineHeight: '32px' },
    valueStyle = { fontWeight: '400' };

  return (
    <tr style={style}>
      <td width='48px'>
        <i className={iconClass} aria-hidden='true'></i>
      </td>
      <td>
        <span style={valueStyle}>
          {value}
        </span>
        ({label})
      </td>
    </tr>
  );
};

const FloorSummary = () => {
  const summaryFields = {
    floorName: {
      label: 'Current Floor: ',
    },
    relayCount: {
      label: 'RockBoxes: ',
    },
    sensorCount: {
      label: 'RockBands: ',
    },
    sensorAlerts: {
      label: 'Sensor Alerts: ',
      render: (value, data) => {
        if (!value) {
          return null;
        }

        return (
          <table>
            <tbody>
              {value.sosAlertCount > 0 && (
                <Alert
                  label='SOS'
                  value={value.sosAlertCount}
                  iconClass='fa fa-ambulance fa-lg text-danger'
                />
              )}
              {value.highImpactAlertCount > 0 && (
                <Alert
                  label='High Impact'
                  value={value.highImpactAlertCount}
                  iconClass='fa fa-medkit fa-lg text-danger'
                />
              )}
              {value.powerOffAlertCount > 0 && (
                <Alert
                  label='Power Off'
                  value={value.powerOffAlertCount}
                  iconClass='fa fa-power-off fa-lg text-danger'
                />
              )}
              {value.lowBatteryAlertCount > 0 && (
                <Alert
                  label='Low Battery'
                  value={value.lowBatteryAlertCount}
                  iconClass='fa fa-battery-1 fa-lg text-danger'
                />
              )}
              {value.breakAwayAlertCount > 0 && (
                <Alert
                  label='Break Away'
                  value={value.breakAwayAlertCount}
                  iconClass='fa fa-chain-broken fa-lg text-danger'
                />
              )}
              {value.missingAlertCount > 0 && (
                <Alert
                  label='Missing'
                  value={value.missingAlertCount}
                  iconClass='fa fa-deaf fa-lg text-warning'
                />
              )}

              {value.approachingAlertCount > 0 && (
                <Alert
                  label='Approaching'
                  value={value.approachingAlertCount}
                  iconClass='fa fa-warning fa-lg text-primary'
                />
              )}

              {value.leavingAlertCount > 0 && (
                <Alert
                  label='Leaving'
                  value={value.leavingAlertCount}
                  iconClass='fa fa-bell-slash fa-lg text-muted'
                />
              )}

              {value.exitDoorAlertCount > 0 && (
                <Alert
                  label='Exit Door'
                  value={value.exitDoorAlertCount}
                  iconClass='fa fa-warning fa-lg text-primary'
                />
              )}
            </tbody>
          </table>
        );
      },
    },
  };

  return <PropertyGrid data={summaryFields} />;
};

export default FloorSummary;
