import React from 'react'

import './PropertyGrid.css'
import _ from 'lodash'
import reactStringReplace from "react-string-replace";

const PropertyGrid = (props) => {
  if (!props.data || _.isEmpty(props.data)) {
    return null
  }
  return (
    <table className='table table-borderless property-grid'>
      <tbody>
      { _.map(props.data, (value, key) => (
          <PropertyGridRow
            key={key}
            labelWidth={ props.labelWidth }
            /* field={ props.fields[key] } */
            value={ value }
            data={ props.data }
            query={ props.query }
          />
        ))
      }
      </tbody>
    </table>
  )
}

const PropertyGridRow = (props) => {

  const labelWidth = props.labelWidth ? props.labelWidth : '120px',
        label = props.field && props.field.label ? props.field.label : ''
  if (!props.field) {
    return null
  } else if (!props.field.render) {
    return (
        //begin rendering the fields:
      <tr>
        <td style={{width: labelWidth, fontWeight: 'bold'}}>
            {reactStringReplace(label, props.query, (match, i) => (
                <span key={i} style={{ backgroundColor: 'yellow' }}>{match}</span>
            ))}
        </td>
        <td style={{overflow: 'auto'}}>
            {props.value}
        </td>
      </tr>
    )
  } else {
    return (
      <tr>
        <td style={{width: labelWidth, fontWeight: 'bold'}}>{label}</td>
        <td style={{overflow: 'auto'}}>{props.field.render(props.value, props.data)}</td>
      </tr>
    )
  }
}

export default PropertyGrid
