import React, { useState } from 'react'
import FacilitySelector from '../../components/FacilitySelector'
import FloorDashboard from './FloorDashboard'
import SystemDashboard from './SystemDashboard'
import BuildingDashboard from './BuildingDashboard'

const Dashboard = (props) => {
  const [filter, setFilter] = useState({ filterType: 'System' })
  const [facilitySelectorValue, setFacilitySelectorValue] = useState({
    enterpriseId: global.login?.enterpriseId,
    filterType: 'System',
  })

  const onFacilityFilterChange = (_filter) => {
    console.log('onFacilityFilterChange', _filter)
    setFilter(_filter)
  }
  
  let comp
  if (filter.filterType === 'System') {
    comp = <SystemDashboard />
  } else if (filter.filterType === 'Enterprise') {
    comp = <SystemDashboard enterpriseId={filter.enterpriseId} />
  } else if (filter.filterType === 'Venue') {
    // console.log('filter', filter)
    comp = <SystemDashboard venueId={filter.venueId} />
  } else if (filter.filterType === 'Building') {
    comp = <BuildingDashboard buildingId={filter.buildingId} />
  } else if (filter.filterType === 'Floor') {
    comp = <FloorDashboard floorId={filter.floorId} />
  }

  return (
    <div>
      <FacilitySelector
        onChange={onFacilityFilterChange}
        filterLabel="Switch Dashboard"
        value={facilitySelectorValue}
      />
      <br />
      {comp}
    </div>
  )
}

export default Dashboard
