import React from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class FormGroupImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageContent:
        props.value && props.value.content ? atob(props.value.content) : null,
    };
  }

  _onChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadstart = (ev) => {
      //console.log('onloadstart ev:', ev)
    };

    reader.onprogress = (ev) => {
      //console.log('onprogress ev:', ev)
    };

    reader.onload = (ev) => {
      //console.log('onload ev:', ev)
      const value = {
        imageFile: file,
        imageContent: reader.result,
      };
      this.setState(value);
      //console.log('result:', JSON.stringify(reader.result))
      this.props.onChange(
        this.props.controlId,
        this.props.required,
        this.props.validator,
        value
      );
    };

    reader.onloadend = (ev) => {
      //console.log('onloadend ev:', ev)
    };

    reader.readAsDataURL(file);
  };

  render() {
    const props = this.props,
      state = this.state,
      labelWidth = props.labelWidth ? props.labelWidth : 3,
      valueWidth = props.valueWidth ? props.valueWidth : 7,
      validationWidth = 12 - labelWidth - valueWidth;
    let imagePreview = null;
    if (state.imageContent) {
      imagePreview = <img width={200} height={200} src={state.imageContent} />;
    } else {
      imagePreview = <div>Floor plan picture has not been selected</div>;
    }
    return (
      <Form.Group
        as={Row}
        className="mb-3"
        controlId={props.controlId}
        validationState={props.validationState.status}
      >
        <Col sm={labelWidth}>
          <Form.Label style={{ fontWeight: "bold" }}>{props.label}</Form.Label>
        </Col>
        <Col sm={valueWidth}>
          <FormControl type="file" accept="image/*" onChange={this._onChange} />
          <Form.Control.Feedback />
        </Col>
        <Col sm={validationWidth}>
          <p>{props.validationState.message}</p>
        </Col>
        <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
          <p>{props.helpText}</p>
        </Col>
        <Col smOffset={labelWidth} sm={valueWidth + validationWidth}>
          {imagePreview}
        </Col>
      </Form.Group>
    );
  }
}

export default FormGroupImage;
