import React, { Component } from "react"
import { ToastContext } from "../../context/ToastContext"

class Toast extends Component {
  static contextType = ToastContext

  render() {
    const { toast } = this.context

    return (
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          background: "rgba(0,0,0,0.7)",
          color: "white",
          padding: "10px",
          borderRadius: "5px",
          display: toast.visible ? "block" : "none",
        }}
      >
        {toast.message}
      </div>
    )
  }
}

export default Toast
