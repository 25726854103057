import React, { useState, useEffect } from "react";
import BaseTable from "../../components/BaseTable";
import moment from "moment";
import {
  timestampFormatter,
  sensorAlertListFormatter,
  expandableRelay,
  sensorLocationFormatter,
} from "../../components/BaseTable/CellFormatter";
import _ from "lodash";

const SensorLocationTable = (props) => {
  const [data, setData] = useState(props.data || []);
  const [sortName, setSortName] = useState(undefined);
  const [sortOrder, setSortOrder] = useState(undefined);
  const [query, setQuery] = useState(props.query || "");

  useEffect(() => {
    let filteredData = props.data;
    if (filteredData) {
      if (sortName) {
        filteredData =
          sortOrder === "asc"
            ? _.sortBy(filteredData, sortName)
            : _.reverse(_.sortBy(filteredData, sortName));
      }
      setData(filteredData);
      setQuery(props.query);
    }
  }, [props.data, props.query, sortName, sortOrder]);

  const handleSortChange = (sortName, sortOrder) => {
    setSortName(sortName);
    setSortOrder(sortOrder);
    setData(
      sortOrder === "asc"
        ? _.sortBy(data, sortName)
        : _.reverse(_.sortBy(data, sortName))
    );
  };

  let filteredData = data;
  if (query && query !== "") {
    const lowerCaseQuery = query.toLowerCase();
    filteredData = data.filter((row) => {
      return (
        (moment(row.createUtc) &&
          moment(row.createUtc)
            .local()
            .format("YYYY/MM/DD HH:mm:ss")
            .toLowerCase()
            .includes(lowerCaseQuery)) ||
        (row.sensorName &&
          row.sensorName.toLowerCase().includes(lowerCaseQuery)) ||
        (row.seniorName &&
          row.seniorName.toLowerCase().includes(lowerCaseQuery)) ||
        (row.relayName &&
          row.relayName.toLowerCase().includes(lowerCaseQuery)) ||
        (row.roomName && row.roomName.toLowerCase().includes(lowerCaseQuery)) ||
        (row.sensorRange &&
          String(row.sensorRange).toLowerCase().includes(lowerCaseQuery))
      );
    });
  }
  const columnProps = [
    {
      config: {
        dataField: "createUtc",
        isKey: true,
        dataFormat: timestampFormatter,
        formatExtraData: query,
      },
      label: "Timestamp",
    },
    {
      config: {
        dataField: "sensorName",
        dataFormat: sensorLocationFormatter,
        formatExtraData: query,
      },
      label: "RockBand",
    },
    {
      config: {
        dataField: "seniorName",
        dataFormat: sensorLocationFormatter,
        formatExtraData: query,
      },
      label: "Resident",
    },
    {
      config: {
        dataField: "relayName",
        dataFormat: expandableRelay,
        formatExtraData: query,
      },
      label: "RockBox",
    },
    {
      config: {
        dataField: "roomName",
        dataFormat: sensorLocationFormatter,
        formatExtraData: query,
      },
      label: "Room",
    },
    {
      config: {
        dataField: "sensorRange",
        dataFormat: sensorLocationFormatter,
        formatExtraData:query,
      },
      label: "Distance (feet)",
    },
    {
      config: {
        dataField: "alerts",
        dataFormat: sensorAlertListFormatter,
        formatExtraData: query,
      },
      label: "Alerts",
    },
  ];
  const expandColumnProps = [
    {
      config: {
        dataField: "createUtc",
        isKey: true,
        dataFormat: timestampFormatter,
      },
      label: "Timestamp",
    },
    {
      config: {
        dataField: "relayName",
      },
      label: "RockBox",
    },
    {
      config: {
        dataField: "roomName",
      },
      label: "Room",
    },
    {
      config: {
        dataField: "sensorRange",
      },
      label: "Distance (feet)",
    },
  ];

  const isExpandableRow = (row) => {
    return row.items.length > 1;
  };

  const expandComponent = (row) => {
    return (
      <BaseTable
        autoLoad={false}
        striped={false}
        pagination={false}
        actions={[]}
        columnProps={expandColumnProps}
        sortedData={row.items}
      />
    );
  };

  return (
    <BaseTable
      autoLoad={false}
      hideSelectColumn={true}
      striped={true}
      height={props.height}
      pagination={false}
      actions={[]}
      sortChange={handleSortChange}
      columnProps={columnProps}
      isExpandableRow={isExpandableRow}
      expandComponent={expandComponent}
      {...props}
      sortedData={data}
    />
  );
};
export default SensorLocationTable;
