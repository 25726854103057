import React, { useState, useEffect } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { FaHeartbeat } from 'react-icons/fa'
import { TbHeartbeat } from 'react-icons/tb'
import { SiOxygen } from 'react-icons/si' 
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import Annotation from 'chartjs-plugin-annotation';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import ApiSingleton from '../../utils/Axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material"
import StatBox from "../../components/StatBox/StatBox"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Annotation,
);

const labels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];

export const lineDateOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1 | 2,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        usePointStyle: true,
      },
    },
    annotation: {
      annotations: {
        min: {
          type: 'line',
          borderColor: 'gray',
          borderWidth: 2,
          scaleID: 'y',
          value: 90,
          label: {
            display: true,
            content: '90',
            backgroundColor: 'transparent',
            color: 'black',
            position: 'end',
            xAdjust: 10,
            yAdjust: 10,
          },
        },
        max: {
          type: 'line',
          borderColor: 'gray',
          borderWidth: 2,
          scaleID: 'y',
          value: 100,
          label: {
            display: true,
            content: '100',
            backgroundColor: 'transparent',
            color: 'black',
            position: 'end',
            xAdjust: 10,
            yAdjust: 0,
          },
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Hours of the Day',
      },
      ticks: {
        autoSkip: false,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Blood Oxygen Saturation',
      },
      position: 'left',
      offset: true,
    },
  },
};

export const lineOutlierOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1 | 2,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        usePointStyle: true,
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Date',
      },
      offset: true,
    },
    y: {
      title: {
        display: true,
        text: 'Outlier Count',
      },
      position: 'left',
      max: 5,
    },
  },
};

export const lineMonthOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1 | 2,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        usePointStyle: true,
      },
    },
    annotation: {
      annotations: {
        min: {
          type: 'line',
          borderColor: 'gray',
          borderWidth: 2,
          scaleID: 'y',
          value: 90,
          label: {
            display: true,
            content: '90',
            backgroundColor: 'transparent',
            color: 'black',
            position: 'end',
            xAdjust: 10,
            yAdjust: 10,
          },
        },
        max: {
          type: 'line',
          borderColor: 'gray',
          borderWidth: 2,
          scaleID: 'y',
          value: 100,
          label: {
            display: true,
            content: '100',
            backgroundColor: 'transparent',
            color: 'black',
            position: 'end',
            xAdjust: 10,
            yAdjust: 0,
          },
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Date',
      },
      offset: true,
    },
    y: {
      title: {
        display: true,
        text: 'Blood Oxygen Saturation',
      },
      position: 'left',
      offset: true,
    },
  },
};

export function ECGStats() {
  const {state} = useLocation();
  const [seniorId, setSeniorId] = useState(state.id);

  //react-date-picker addition
  const [dateValue, setDate] = useState(new Date("2023-3-30"));
  const [startDate, setStartDate] = useState(""); //2-18-2023
  const [endDate, setEndDate] = useState(""); //6-8-2023

  // stores all user data
  const [dailyData, setDailyData] = useState({datasets: [],});
  const [monthlyData, setMonthlyData] = useState({datasets: [],});

  // stores current display options
  const [bosDateOptions, setBOSDateOptions] = useState();
  const [bosOutlierOptions, setBOSOutlierOptions] = useState();
  const [bosMonthOptions, setBOSMonthOptions] = useState();

  // stores current display data
  const [bosDateData, setBOSDateData] = useState({datasets: [],});
  const [bosOutlierData, setBOSOutlierData] = useState({datasets: [],});
  const [bosMonthData, setBOSMonthData] = useState({datasets: [],});

  const [bosDateMax, setBOSDateMax] = useState([]);
  const [bosDateMin, setBOSDateMin] = useState([]);
  const [bosDateMed, setBOSDateMed] = useState([]);

  const [bosLTMax, setBOSLTMax] = useState(0);
  const [bosLTMin, setBOSLTMin] = useState(0);
  const [bosLTMed, setBOSLTMed] = useState(0);

  const [bosMaxLine, setBOSMaxLine] = useState([]);
  const [bosMinLine, setBOSMinLine] = useState([]);
  const [bosMedLine, setBOSMedLine] = useState([]);

  function _fetchBOS(seniorId) {
    console.log("entered fetch BOS data");
    const postData = {
        seniorName: seniorId,
        key: "",
    }
    let tempData = null;
    if (seniorId) {
      ApiSingleton.makeHttpRequest(
        "post",
        "/bstream/api/v1/seniorDocument/findBySeniorNameAndKey",
        { dataType: "json" },
        postData
      )
        .then((data) => {
          console.log("getting bos data from API");
            tempData = JSON.parse(base64_decode(data[0].document));
            console.log("fetched bos data: ", tempData);
        })
        // .then(data => {
        //   console.log("setting BOS data");
        //   setDailyData(tempData.daily);
        //   setMonthlyData(tempData.longterm);

        //   let currentDate = dateValue
        //   const dateData = getDateData(currentDate, tempData.daily);
        //   const monthData = getMonthData(currentDate, tempData.longterm);
        //   setDate(currentDate);

        //   const bosDate = getBOSDateData(dateData);
        //   const bosOutlier = getBOSOutlierData(monthData);
        //   const bosMonthMax = getBOSMonthMax(monthData);
        //   const bosMonthMin = getBOSMonthMin(monthData);
        //   const bosMonthMed = getBOSMonthMed(monthData);
        //   const stats = getBOSDateMaxMin(currentDate, monthData);
        //   const ltStats = getBOSLTMaxMin(monthData);

        //   setBOSMaxLine(bosMonthMax);
        //   setBOSMinLine(bosMonthMin);
        //   setBOSMedLine(bosMonthMed);

        //   if (stats) {
        //     setBOSDateMax(stats[0]); // stats elements in order: [max, min, med]
        //     setBOSDateMin(stats[1]);
        //     setBOSDateMed(stats[2]);
        //   }

        //   if (ltStats) {
        //     setBOSLTMax(ltStats[0]); // stats elements in order: [max, min, med]
        //     setBOSLTMin(ltStats[1]);
        //     setBOSLTMed(ltStats[2]);
        //   }

        //   setBOSDateData({
        //     labels: [],
        //     datasets: [
        //       {
        //         label: 'Personalized Threshold',
        //         data: [],
        //         pointStyle: 'line',
        //         backgroundColor: 'black',
        //         borderColor: 'black',
        //       },  
        //       {
        //           label: 'Blood Oxygen Sat',
        //           data: bosDate,
        //           pointStyle: 'circle',
        //           backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
        //           borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
        //           spanGaps: true,
        //           yAxisID: 'y',
        //       },  
        //     ],
        //   });

        //   setBOSOutlierData({
        //     labels: [],
        //     datasets: [
        //         {
        //             label: 'Outlier Count',
        //             data: bosOutlier,
        //             pointStyle: 'circle',
        //             backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
        //             borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
        //             spanGaps: true,
        //             yAxisID: 'y',
        //         },
        //     ],
        //   });

        //   setBOSMonthData({
        //     labels: [],
        //     datasets: [
        //       {
        //         label: 'Personalized Threshold',
        //         data: [],
        //         pointStyle: 'line',
        //         backgroundColor: 'black',
        //         borderColor: 'black',
        //       }, 
        //       {
        //           label: 'Daily Max',
        //           data: bosMonthMax,
        //           pointStyle: 'rect',
        //           backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
        //           borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
        //           spanGaps: true,
        //           yAxisID: 'y',
        //           pointRadius: 0,
        //           fill: '+1',
        //       },
        //       {
        //           label: 'Daily Min',
        //           data: bosMonthMin,
        //           pointStyle: 'rect',
        //           backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
        //           borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
        //           spanGaps: true,
        //           yAxisID: 'y',
        //           pointRadius: 0,
        //           fill: false,
        //       },
        //       {
        //           label: 'Daily Median',
        //           data: bosMonthMed,
        //           pointStyle: 'circle',
        //           backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
        //           borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
        //           spanGaps: true,
        //           yAxisID: 'y',
        //       },
        //     ],
        //   });
        // })
        .catch((err) => {});
    }

    return tempData;
  }


  useEffect(() => {
    setBOSDateOptions(lineDateOptions);
    setBOSOutlierOptions(lineOutlierOptions);
    setBOSMonthOptions(lineMonthOptions);

    _fetchBOS(seniorId);

    console.log("useEffect ran here");
  }, []);

  // finds the right data that corresponds to the date
  function getDateData (date, data) {
    if (data == null) {
      console.log("ERROR ---- data is null");
      return data;
    }
    var preDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
    var afterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

    const dateData = []
    let data_len = data.length;
    let i = 0;
    let n = 0;
    var currDate = new Date(data[0].year, data[0].month - 1, data[0].day); // month - 1 because month is 0 - 11 not 1 - 12

    while (currDate < afterDate) {
      if (i < data_len) {
        currDate = new Date(data[i].year, data[i].month - 1, data[i].day);
        i++;
      }
      else {
        console.log("ERROR -- exceeded max date in BOS getDateData ");
        break;
      }

      // at the correct date
      if (currDate > preDate && currDate < afterDate) {
        dateData[n] = data[i];
        n++;
      }
      else { continue; }
    }

    // PROBLEM: getting one more data point than intended at the end and missing one at beginning
    // console.log("date data: ", dateData);
    return dateData;
  }

  // finds the month that current date is in
  // currently fixed (need to change later)
  function getMonthData (date, data) {
    if (data == null) {
      console.log("data is null");
      return data;
    }
    return data;
  }

  function getBOSDateData (date_data) {
    if (date_data == null) {
      console.log("data is null");
      return date_data;
    }
    const dateData = []; // each element is a pair of {"hour", "blood oxy sat"}
    let i = 0;
    let n = 0;
    let prev = -1;
    // console.log("date data: ", date_data);

    if (date_data.length > 0) {
      while (i < 24) {
        // console.log("date data hour: ", date_data[n].hour);
        if (date_data[n].hour != i) {
            if (date_data[n].hour == prev) {
                n++;
                continue;
            }
            else {
                dateData[i] = [i.toString(), null];
            }   
        }
        else {
            dateData[i] = [date_data[n].hour.toString(), date_data[n].bloodOxygenSaturation]; //date_data[n].hour.toString(), 
            prev = date_data[n].hour;
            n++;
        }
        i++;
      }
    }
    
    return dateData;
  }

  function getBOSOutlierData(month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    const outlierData = []; // each element is formatted {"date", num outliers}
    let length = month_data.length;
    let i = 0;

    while (i < length) {
        outlierData[i] = [month_data[i].year.toString() + '-' + month_data[i].month.toString() + '-' + month_data[i].day.toString(), month_data[i].outlier];
        i++;
    }
    return outlierData;
  }

  function getBOSMonthMax(month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    const max = []; // elements in form [date, max value]

    let i = 0;
    let length = month_data.length;

    while (i < length) {
        max[i] = [month_data[i].year.toString() + '-' + month_data[i].month.toString() + '-' + month_data[i].day.toString(), month_data[i].max];
        i++;
    }

    return max;
  }

  function getBOSMonthMin(month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    const min = []; // in form [date, min value]

    let i = 0;
    let length = month_data.length;

    while (i < length) {
        min[i] = [month_data[i].year.toString() + '-' + month_data[i].month.toString() + '-' + month_data[i].day.toString(), month_data[i].min];
        i++;
    }

    return min;
  }

  function getBOSMonthMed(month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    const med = []; // in form [date, median value]

    let i = 0;
    let length = month_data.length;

    while (i < length) {
        med[i] = [month_data[i].year.toString() + '-' + month_data[i].month.toString() + '-' + month_data[i].day.toString(), month_data[i].median];
        i++;
    }

    return med;
  }

  // sets the selected date's max min median values
  function getBOSDateMaxMin (date, month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    let i = 0;
    let length = month_data.length;
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const stats = ["N/A", "N/A", "N/A"]; // elements are in order [max, min, med]

    while (i < length) {
        if (month_data[i].year == year && month_data[i].month == month && month_data[i].day == day) {
            stats[0] = month_data[i].max;
            stats[1] = month_data[i].min;
            stats[2] = month_data[i].median;
            break;
        }
        else { i++; }
    }

    return stats;
  }

  // TODO: Fix for when implementing multiple long term periods
  // sets the long term's max min median values
  function getBOSLTMaxMin (month_data) {
    if (month_data == null) {
      console.log("data is null");
      return month_data;
    }
    let i = 0;
    let length = month_data.length;
    const stats = []; // elements are in order [max, min, med]
    const max = [];
    const min = [];
    const med = [];

    while (i < length) {
      max.push(month_data[i].max);
      min.push(month_data[i].min);
      med.push(month_data[i].median);
      i++;
    }

    stats[0] = Math.max.apply(null, max);
    stats[1] = Math.min.apply(null, min);
    const sortMed = med.sort(function(a, b){return a - b});
    stats[2] = sortMed[sortMed.length % 2];

    return stats;
  }

  // handles date change event
  function handleDateChange(event) {
    setDate(event);
    console.log("event time is: ", event);
    
    // load database data
    const d = getDateData(event, dailyData);
    const m = getMonthData(event, monthlyData);

    const bosDate = getBOSDateData(d);
    const bosOutlier = getBOSOutlierData(m);
    const bosMonthMax = getBOSMonthMax(m);
    const bosMonthMin = getBOSMonthMin(m);
    const bosMonthMed = getBOSMonthMed(m);
    const stats = getBOSDateMaxMin(event, m);
    const ltStats = getBOSLTMaxMin(m);

    setBOSMaxLine(bosMonthMax);
    setBOSMinLine(bosMonthMin);
    setBOSMedLine(bosMonthMed);

    if (stats) {
      setBOSDateMax(stats[0]); // stats elements in order: [max, min, med]
      setBOSDateMin(stats[1]);
      setBOSDateMed(stats[2]);
    }

    if (ltStats) {
      setBOSLTMax(ltStats[0]); // stats elements in order: [max, min, med]
      setBOSLTMin(ltStats[1]);
      setBOSLTMed(ltStats[2]);
    }

    setBOSDateData({
        labels: [],
        datasets: [
          {
            label: 'Personalized Threshold',
            data: [],
            pointStyle: 'line',
            backgroundColor: 'grey',
            borderColor: 'grey',
          }, 
          {
              label: 'Blood Oxygen Sat',
              data: bosDate,
              pointStyle: 'circle',
              backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
              borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
              spanGaps: true,
              yAxisID: 'y',

          },
        ],
    });

    setBOSOutlierData({
        labels: [],
        datasets: [
            {
                label: 'Outlier Count',
                data: bosOutlier,
                pointStyle: 'circle',
                backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
                borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
                spanGaps: true,
                yAxisID: 'y',
            },
        ],
    });

    setBOSMonthData({
        labels: [],
        datasets: [
          {
            label: 'Personalized Threshold',
            data: [],
            pointStyle: 'line',
            backgroundColor: 'grey',
            borderColor: 'grey',
          }, 
          {
            label: 'Daily Max',
            data: bosMaxLine,
            pointStyle: 'rect',
            backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
            borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
            spanGaps: true,
            yAxisID: 'y',
            pointRadius: 0,
            fill: '+1',
          },
          {
            label: 'Daily Min',
            data: bosMinLine,
            pointStyle: 'rect',
            backgroundColor: 'rgba(170, 1, 20, 0.2)', // the color of the points
            borderColor: 'rgba(170, 1, 20, 0.2)', // the color of the line
            spanGaps: true,
            yAxisID: 'y',
            pointRadius: 0,
            fill: false,
          },
          {
            label: 'Daily Median',
            data: bosMedLine,
            pointStyle: 'circle',
            backgroundColor: 'rgba(170, 1, 20, 0.5)', // the color of the points
            borderColor: 'rgba(170, 1, 20, 0.5)', // the color of the line
            spanGaps: true,
            yAxisID: 'y',
          },
        ],
    });

  }

  return (
    <Box m="20px"> 
        <Box display="flex" justifycontext="space-between" alignItems="center">
            <Box width="100%">
                <Typography variant="h3" fontWeight="bold" sx={{ color: "#530C0C" }}>Blood Oxygen Saturation</Typography>
            </Box>
            <Box>
              <DatePicker showIcon={true} minDate={new Date(startDate)} maxDate={new Date(endDate)} onChange={handleDateChange} selected={dateValue} />            
            </Box>
        </Box>

        {/* GRID AND CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >

            {/** ROW 1 */}
            <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Daily Blood Oxygen Saturation
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        <FaHeartbeat color='#530C0C' />
                    </Box>
                </Box>
                <Box height="250px" m="0px 25px">
                    <Line options={bosDateOptions} data={bosDateData} className='graph' id="chartHR" key={Math.random()} />
                </Box>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={bosDateMax} subtitle="Daily Maximum" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={bosDateMin} subtitle="Daily Minimum" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>

            {/** ROW 2 */}
            <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Trial Blood Oxygen Saturation
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        <FaHeartbeat color='#530C0C' />
                    </Box>
                </Box>
                <Box height="250px" m="0px 25px">
                    <Line options={bosMonthOptions} data={bosMonthData} className='graph' id="chartHR" key={Math.random()} />
                </Box>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={bosDateMed} subtitle="Daily Median" icon={<TbHeartbeat color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={bosLTMax} subtitle="Long Term Maximum" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
            </Box>

            {/** ROW 3 */}
            <Box gridColumn="span 8" gridRow="span 2" backgroundColor="#DEDEDE" > {/* onClick={handleGoHRStats} */}
                <Box mt="25px" p="0 30px" display="flex" justifycontext="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="#530C0C">
                            Trial Blood Oxy Sat Outlier Count
                        </Typography>
                    </Box>
                    <Box m="0px 8px">
                        <FaHeartbeat color='#530C0C' />
                    </Box>
                </Box>
                <Box height="250px" m="0px 25px">
                    <Line options={bosOutlierOptions} data={bosOutlierData} className='graph' id="chartHR" key={Math.random()} />
                </Box>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={bosLTMin} subtitle="Long Term Minimum" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
            </Box>
            <Box gridColumn="span 4" backgroundColor="#DEDEDE" display="flex" alignItems="center" justifycontent="center">
                <StatBox title={bosLTMed} subtitle="Long Term Median" icon={<SiOxygen color='#530C0C' size='0px' />}></StatBox>
            </Box>

        </Box>
    </Box>
  );
}

export default ECGStats;