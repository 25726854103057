import React from 'react'
import BaseTable from '../../components/BaseTable'
import { timestampFormatter, expandableAlertNotes, sensorAlertListTableFormatter, sensorLocationFormatter } from '../../components/BaseTable/CellFormatter'
import moment from "moment"

class ClosedSensorAlertTable extends React.Component {
    constructor(props) {
        super(props)
        this.handleSortChange = this.handleSortChange.bind(this)
        this.onRefresh = this.onRefresh.bind(this)
        this.onRefreshSuspend = this.onRefreshSuspend.bind(this)
        this.state = {
            data: [],
            sortName: undefined,
            sortOrder: undefined,
            query: props.query ? props.query : ''
        }
    }

    onRefresh=() => {
        if (this.props.refresh) {
            this.props.refresh()
        }
    }
    onRefreshSuspend = () => {
        if(this.props.refreshSuspend) {
            this.props.refreshSuspend()
        }
    }
    componentWillReceiveProps(nextProps) {
        let data = nextProps.data
        if (data) {
            if(this.state.sortName) {
                data = this.state.sortOrder === 'asc' ? _.sortBy(data, this.state.sortName)
                    : _.reverse( _.sortBy(data, this.state.sortName))
            }
            this.setState({
                data: data,
                query: nextProps.query
            })
        }
    }
    handleSortChange = (sortName, sortOrder) => {
        this.setState((state) => {
            return {
                sortName: sortName,
                sortOrder: sortOrder,
                data: sortOrder === 'asc' ? _.sortBy(state.data, sortName) : _.reverse(_.sortBy(state.data, sortName))
            }
        })
    }
    render() {
        const props = this.props
        const columnProps = [{
            config: {
                dataField: 'createUtc',
                isKey: true,
                dataSort: true,
                dataFormat: timestampFormatter,
                formatExtraData: this.state.query
            },
            label: 'Created Time'
        }, {
            config: {
                dataField: 'sensorName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'RockBand'
        }, {
            config: {
                dataField: 'seniorName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Resident'
        }, {
            config: {
                dataField: 'roomName',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat:sensorLocationFormatter
            },
            label: 'Room'
        }, {
            config: {
                dataField: 'description',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Description'
        }, {
            config: {
                dataField: 'alertType',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Alert Type'
        }, {
            config: {
                dataField: 'closedUtc',
                dataSort: true,
                formatExtraData: this.state.query,
                dataFormat: sensorLocationFormatter
            },
            label: 'Closed Time'
        }, {
            config: {
                dataField: 'sensorAlertNoteList',
                dataSort: true,
                dataFormat: expandableAlertNotes
            },
            label: 'Notes',
        }]
        const updateModalFields = [{
            controlId: 'alertEvent',
            label: 'Update Type',
            type: 'radiogroup',
            inline: true,
            required: true,
            options: [{
                label: 'UPDATE',
                value: 'UPDATE',
                checked: true
            }, {
                label: 'CLOSE',
                value: 'CLOSE'
            }]
        }, {
            controlId: 'description',
            label: 'Description',
            type: 'text',
            required: true
        }]

        const updateConfig = {
            title: 'Update RockBand Alert',
            submitText: 'Update',
            cancelText: 'Cancel',
            fields: updateModalFields,
            defaultData: {
                alertEvent: 'UPDATE',
                description: ''
            },
            beforeSubmit: (data) => {
                return _.pick(data, ['id', 'alertEvent', 'description'])
            }
        }

        const detailModalFields = [
            {
            controlId: 'createUtc',
            label: 'When',
            dataFormat: timestampFormatter
            },

            {
             controlId: 'who',
             label: 'Who',
             type: 'text',
            },
            {
            controlId: 'description',
            label: 'Description',
            type: 'text',
        }]
        const detailConfig = {
            title: 'Detail Update RockBand Alert',
            cancelText: 'Cancel',
            fields: detailModalFields,
        }

        const addConfig = {
            title: 'Update RockBand Alert',
            submitText: 'Update',
            cancelText: 'Cancel',
            fields: updateModalFields,
            defaultData: {
                alertEvent: 'UPDATE',
                description: ''
            },
        }
        const expandColumnProps = [{
            config: {
                dataField: 'createUtc',
                isKey: true,
                width: '20%',
                dataFormat: timestampFormatter
            },
            label: 'When'
        }, {
            config: {
                dataField: 'who',
                width: '20%',
                dataSort: false
            },
            label: 'Who',
        }, {
            config: {
                dataField: 'description',
                dataSort: false
            },
            label: 'Description'
        }]
        const deleteConfig = {

        }
        const isExpandableRow = (row) => {
            return row.sensorAlertNoteList.length > 0
        }

        const expandComponent = (row) => {
            const updateExpandConfig = {
                title: 'Update RockBand Alert',
                submitText: 'Update',
                cancelText: 'Cancel',
                fields: updateModalFields,
                defaultData: {
                    alertEvent: 'UPDATE',
                    description: ''
                },
                beforeSubmit: (data) => {
                    const updateData = _.pick(data, ['alertEvent', 'description'])
                    updateData.id = row.id
                    return updateData
                }
            }
            const deleteExpandConfig = {
                title: 'Delete Update Alert Type',
                body: 'Are you sure you want to delete the update alert type?',
                beforeSubmit: (data) => {
                }
            }
            return  (
                <BaseTable
                    autoLoad={ false }
                    striped={ false }
                    pagination={ false }
                    columnProps={ expandColumnProps }
                    updateConfig={ updateExpandConfig }
                    addConfig={ addConfig }
                    urlEntity='sensorAlert'
                    updateUrl='triggerEvent'
                    detailConfig={ detailConfig }
                    deleteConfig={ deleteExpandConfig }
                    refresh={ this.onRefresh }
                    refreshSuspend={ this.onRefreshSuspend }
                    sortedData={ row.sensorAlertNoteList }
                    hideFilterSearchBar={ true }
                />
            )
        }
        let data = this.state.data
        if (this.state.query && this.state.query !== '') {
            const query = this.state.query.toLowerCase()
            data = data.filter(row => {
                return (row.createUtc && moment(row.createUtc).local().format("YYYY/MM/DD HH:mm:ss").toLowerCase().includes(query))||
                    (row.sensorName && row.sensorName.toLowerCase().includes(query)) ||
                    (row.seniorName && row.seniorName.toLowerCase().includes(query)) ||
                    (row.roomName && row.roomName.toLowerCase().includes(query)) ||
                    (row.description && row.description.toLowerCase().includes(query)) ||
                    (row.alertType && row.alertType.toLowerCase().includes(query)) ||
                    (row.closedUtc && row.closedUtc.toLowerCase().includes(query))
            })
        }
        return (
            <BaseTable autoLoad={false}
                       height={props.height}
                       pagination={ false }
                       urlEntity='sensorAlert'
                       updateUrl='triggerEvent'
                       actions={[{name: 'update'}]}
                       updateConfig={ updateConfig }
                       columnProps={ columnProps }
                       isExpandableRow={ isExpandableRow }
                       refresh={ this.onRefresh }
                       refreshSuspend={ this.onRefreshSuspend }
                       sortChange={this.handleSortChange}
                       expandComponent={ expandComponent }
                       { ...props }
                       sortedData={data}
                       hideFilterSearchBar={ true }
            />
        )
    }
}

export default ClosedSensorAlertTable
