import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FormLabel } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ApiSingleton from "../../utils/Axios";

class FormUploadAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      missingAudio: null,
      missingAudioId: null,
      missingAudioName: null,
      sosAudio: null,
      sosAudioId: null,
      sosAudioName: null,
      highImpactAudio: null,
      highImpactAudioId: null,
      highImpactAudioName: null,
      exitDoorAudio: null,
      exitDoorAudioId: null,
      exitDoorAudioName: null,
      approachingAudio: null,
      approachingAudioId: null,
      approachingAudioName: null,
      leavingAudio: null,
      leavingAudioId: null,
      leavingAudioName: null,
    };
  }

  componentDidMount() {
    if (this.props.enterpriseId) {
      this.getAlertByEnterprise(this.props.enterpriseId, "MISSING_AUDIO");
      this.getAlertByEnterprise(this.props.enterpriseId, "SOS_AUDIO");
      this.getAlertByEnterprise(this.props.enterpriseId, "HIGH_IMPACT_AUDIO");
      this.getAlertByEnterprise(this.props.enterpriseId, "EXIT_DOOR_AUDIO");
      this.getAlertByEnterprise(this.props.enterpriseId, "APPROACHING_AUDIO");
      this.getAlertByEnterprise(this.props.enterpriseId, "LEAVING_AUDIO");
    }
  }
  handleAlertData = (data, type) => {
    if (type === "MISSING_AUDIO") {
      this.setState({
        missingAudio: data.content,
        missingAudioId: data.id,
        missingAudioName: data.name,
      });
    } else if (type === "SOS_AUDIO") {
      this.setState({
        sosAudio: data.content,
        sosAudioId: data.id,
        sosAudioName: data.name,
      });
    } else if (type === "HIGH_IMPACT_AUDIO") {
      this.setState({
        highImpactAudio: data.content,
        highImpactAudioId: data.id,
        highImpactAudioName: data.name,
      });
    } else if (type === "EXIT_DOOR_AUDIO") {
      this.setState({
        exitDoorAudio: data.content,
        exitDoorAudioId: data.id,
        exitDoorAudioName: data.name,
      });
    } else if (type === "APPROACHING_AUDIO") {
      this.setState({
        approachingAudio: data.content,
        approachingAudioId: data.id,
        approachingAudioName: data.name,
      });
    } else if (type === "LEAVING_AUDIO") {
      this.setState({
        leavingAudio: data.content,
        leavingAudioId: data.id,
        leavingAudioName: data.name,
      });
    }
  };
  getAlertByEnterprise = (enterpriseId, type) => {
    const postData = {
      enterpriseId: enterpriseId,
      audioType: type,
    };
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/audio/find",
      { dataType: "json" },
      postData
    )
      .then((data) => {
        if (data && Array.isArray(data) && data.length >= 1) {
          this.handleAlertData(data[0], type);
        }
      })
      .catch((err) => {
        console.log(
          "fail to fetch" + type + " alert by enterpriseId: " + enterpriseId,
          err
        );
      });
  };

  createAlertByEnterprise = (enterpriseId, type, content, name) => {
    const postData = {
      enterpriseId: enterpriseId,
      audioType: type,
      name: name,
      content: content,
    };
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/audio/createForAlerts",
      { dataType: "json" },
      postData
    )
      .then((data) => {
        this.handleAlertData(data, type);
      })
      .catch((err) => {
        console.log(
          "fail to create " + type + " alert for enterprise " + enterpriseId,
          err
        );
      });
  };

  updateAlertByEnterprise = (id, enterpriseId, content, type, name) => {
    const postData = {
      id: id,
      enterpriseId: enterpriseId,
      content: content,
      audioType: type,
      fileName: name,
    };
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/audio/updateForAlerts",
      { dataType: "json" },
      postData
    )
      .then((data) => {
        if (type === "MISSING_AUDIO") {
          this.setState({
            missingAudio: content,
            missingAudioName: name,
          });
        } else if (type === "SOS_AUDIO") {
          this.setState({
            sosAudio: content,
            sosAudioName: name,
          });
        } else if (type === "HIGH_IMPACT_AUDIO") {
          this.setState({
            highImpactAudio: content,
            highImpactAudioName: name,
          });
        } else if (type === "EXIT_DOOR_AUDIO") {
          this.setState({
            exitDoorAudio: content,
            exitDoorAudioName: name,
          });
        } else if (type === "APPROACHING_AUDIO") {
          this.setState({
            approachingAudio: content,
            approachingAudioName: name,
          });
        } else if (type === "LEAVING_AUDIO") {
          this.setState({
            leavingAudio: content,
            leavingAudioName: name,
          });
        }
      })
      .catch((err) => {
        console.log(
          "fail to update " + type + " alert for enterpriseId: " + enterpriseId
        );
      });
  };
  _onChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const type = e.target.name;
    if (file !== null) {
      reader.readAsBinaryString(file);
      reader.onloadend = (ev) => {
        //console.log('onload ev:', ev)
        const content = btoa(reader.result);
        if (this.props.enterpriseId) {
          const enterpriseId = this.props.enterpriseId;
          if (type === "MISSING_AUDIO") {
            if (this.state.missingAudio) {
              this.updateAlertByEnterprise(
                this.state.missingAudioId,
                enterpriseId,
                content,
                type,
                file.name
              );
            } else {
              this.createAlertByEnterprise(
                enterpriseId,
                type,
                content,
                file.name
              );
            }
          } else if (type === "SOS_AUDIO") {
            if (this.state.sosAudio) {
              this.updateAlertByEnterprise(
                this.state.sosAudioId,
                enterpriseId,
                content,
                type,
                file.name
              );
            } else {
              this.createAlertByEnterprise(
                enterpriseId,
                type,
                content,
                file.name
              );
            }
          } else if (type === "HIGH_IMPACT_AUDIO") {
            if (this.state.highImpactAudio) {
              this.updateAlertByEnterprise(
                this.state.highImpactAudioId,
                enterpriseId,
                content,
                type,
                file.name
              );
            } else {
              this.createAlertByEnterprise(
                enterpriseId,
                type,
                content,
                file.name
              );
            }
          } else if (type === "EXIT_DOOR_AUDIO") {
            if (this.state.exitDoorAudio) {
              this.updateAlertByEnterprise(
                this.state.exitDoorAudioId,
                enterpriseId,
                content,
                type,
                file.name
              );
            } else {
              this.createAlertByEnterprise(
                enterpriseId,
                type,
                content,
                file.name
              );
            }
          } else if (type === "APPROACHING_AUDIO") {
            if (this.state.approachingAudio) {
              this.updateAlertByEnterprise(
                this.state.approachingAudioId,
                enterpriseId,
                content,
                type,
                file.name
              );
            } else {
              this.createAlertByEnterprise(
                enterpriseId,
                type,
                content,
                file.name
              );
            }
          } else if (type === "LEAVING_AUDIO") {
            if (this.state.leavingAudio) {
              this.updateAlertByEnterprise(
                this.state.leavingAudioId,
                enterpriseId,
                content,
                type,
                file.name
              );
            } else {
              this.createAlertByEnterprise(
                enterpriseId,
                type,
                content,
                file.name
              );
            }
          }
        }
      };
    }
  };

  deleteAudio = (e, type) => {
    e.preventDefault();
    const postData = {};
    if (type === "MISSING_AUDIO") {
      postData.id = this.state.missingAudioId;
    } else if (type === "SOS_AUDIO") {
      postData.id = this.state.sosAudioId;
    } else if (type === "HIGH_IMPACT_AUDIO") {
      postData.id = this.state.highImpactAudioId;
    } else if (type === "EXIT_DOOR_AUDIO") {
      postData.id = this.state.exitDoorAudioId;
    } else if (type === "APPROACHING_AUDIO") {
      postData.id = this.state.approachingAudioId;
    } else if (type === "LEAVING_AUDIO") {
      postData.id = this.state.leavingAudioId;
    }
    ApiSingleton.makeHttpRequest(
      "post",
      "/bstream/api/v1/audio/delete",
      { dataType: "json" },
      postData
    )
      .then((data) => {
        if (type === "MISSING_AUDIO") {
          this.setState({
            missingAudioId: null,
            missingAudio: null,
          });
        } else if (type === "SOS_AUDIO") {
          this.setState({
            sosAudioId: null,
            sosAudio: null,
          });
        } else if (type === "HIGH_IMPACT_AUDIO") {
          this.setState({
            highImpactAudioId: null,
            highImpactAudio: null,
          });
        } else if (type === "EXIT_DOOR_AUDIO") {
          this.setState({
            exitDoorAudioId: null,
            exitDoorAudio: null,
          });
        } else if (type === "APPROACHING_AUDIO") {
          this.setState({
            approachingAudioId: null,
            approachingAudio: null,
          });
        } else if (type === "LEAVING_AUDIO") {
          this.setState({
            leavingAudioId: null,
            leavingAudio: null,
          });
        }
      })
      .catch((err) => {
        console.log(
          "fail to delete " +
            type +
            "audio for enterpriseId: " +
            this.props.enterpriseId
        );
      });
  };
  handleAudioPreview = (type) => {
    let audioPreview = null,
      audioContent = null,
      title = null,
      audioFile = null,
      helpText = null;
    if (type === "MISSING_AUDIO") {
      audioContent = this.state.missingAudio;
      title = this.state.missingAudioName;
      helpText = "Missing Alert";
    } else if (type === "SOS_AUDIO") {
      audioContent = this.state.sosAudio;
      title = this.state.sosAudioName;
      helpText = "SOS Alert";
    } else if (type === "HIGH_IMPACT_AUDIO") {
      audioContent = this.state.highImpactAudio;
      title = this.state.highImpactAudioName;
      helpText = "High Impact Alert";
    } else if (type === "EXIT_DOOR_AUDIO") {
      audioContent = this.state.exitDoorAudio;
      title = this.state.exitDoorAudioName;
      helpText = "Exit Door Alert";
    } else if (type === "APPROACHING_AUDIO") {
      audioContent = this.state.approachingAudio;
      title = this.state.approachingAudioName;
      helpText = "Approaching Alert";
    } else if (type === "LEAVING_AUDIO") {
      audioContent = this.state.leavingAudio;
      title = this.state.leavingAudioName;
      helpText = "Leaving Alert";
    }
    if (audioContent) {
      audioFile = atob(audioContent);
      let array = new Uint8Array(audioFile.length);
      for (let i = 0; i < audioFile.length; i++) {
        array[i] = audioFile.charCodeAt(i);
      }
      var b = new Blob([array]);
    }
    if (audioContent) {
      audioPreview = (
        <div style={{ marginTop: "10px" }}>
          <Col sm={5}>
            <audio
              style={{ height: "40px" }}
              controls
              src={URL.createObjectURL(b)}
              title={title}
            ></audio>
          </Col>
          <Col sm={2}>
            <button
              style={{ height: "40px", border: "0px" }}
              className={"btn btn-danger"}
              onClick={(e) => {
                this.deleteAudio(e, type);
              }}
            >
              <span className={"glyphicon glyphicon-remove"}></span>
            </button>
          </Col>
        </div>
      );
    } else {
      audioPreview = <p>{helpText} audio file has not been selected</p>;
    }
    return audioPreview;
  };
  render() {
    const props = this.props,
      state = this.state,
      labelWidth = props.labelWidth ? props.labelWidth : 3,
      valueWidth = props.valueWidth ? props.valueWidth : 7,
      validationWidth = 12 - labelWidth - valueWidth;
    return (
      <div>
        {props.fields.map((field) => {
          let audioPreview = this.handleAudioPreview(field.alertType);
          return (
            <Form.Group
              as={Row}
              className="mb-3"
              key={field.controlId}
              controlId={field.controlId}
            >
              <Col sm={labelWidth}>
                <Form.Label style={{fontWeight:'bold'}}>{field.label}</Form.Label>
              </Col>
              <Col sm={valueWidth}>
                <input
                  type="file"
                  accept="audio/*"
                  style={{ color: "transparent" }}
                  name={field.alertType}
                  onChange={this._onChange}
                />
                {audioPreview}
              </Col>
              {/* <Col smOffset={3} sm={valueWidth + validationWidth}>
              </Col> */}
            </Form.Group>
          );
        })}
      </div>
    );
  }
}

export default FormUploadAlert;
