/* global $ */
import React from 'react'
import FormGroup from "react-bootstrap/FormGroup"
import Col from "react-bootstrap/Col"
import { Form }from "react-bootstrap"
import Select from 'react-select'
import '../react-select.css'
import ApiSingleton from '../../utils/Axios'

class FormGroupRelayList extends React.Component {
    constructor(props) {
        super(props)
        this._getRelayOptions = this._getRelayOptions.bind(this)
        this.state = {
            relayOptions: [],
            selectedOption: null,
            enterpriseId: props.enterpriseId ? props.enterpriseId : null
        }
    }
    componentDidMount() {
        if(this.props.enterpriseId) {
            this._getRelayOptions(this.props.enterpriseId)
        }
    }

    _onChange = (selectedOption) => {
        this.setState({
            selectedOption: selectedOption
        })
    }
    _getRelayOptions(enterpriseId) {
        ApiSingleton.makeHttpRequest(
            'post',
            '/bstream/api/v1/relay/find',
            { dataType: 'json',enterpriseId: enterpriseId },
            {}
          ).then((data) => {
            const options = data.map((item, index) => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
            this.setState({
                relayOptions: options
            })
          }).catch((err) => {
            console.error(err);
          })
    }
    render() {
        return (
            <div>
                <Form.Group controlId={'relayOptions'}>
                    <Col sm={3}>
                        <Form.Label style={{ fontWeight: "bold" }}>Relay Name</Form.Label>
                    </Col>
                    <Col sm={7}>
                        <Select options={this.state.relayOptions}
                                value={this.state.selectedOption}
                                onChange={this._onChange}
                        />
                    </Col>
                </Form.Group>
            </div>
        )
    }
}

export default FormGroupRelayList
